import PropTypes from 'prop-types'

import { AdasBox, AdasContainer, AdasImage } from '@components/wrapper-components'
import CaradasPlusLogo from '../../assets/images/logo-carADASplus.svg'
import classes from './carPlusLogo.module.css'

export const CaradasPlusLogoWrapper = (props) => {
  const { size } = props

  return (
    <AdasContainer maxWidth={size ? size : 'false'}>
      <AdasBox className={classes.logo}>
        <AdasBox
          sx={{
            maxWidth: '25%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <AdasImage src={CaradasPlusLogo} alt='logo' />
        </AdasBox>
        {props.children}
      </AdasBox>
    </AdasContainer>
  )
}

CaradasPlusLogoWrapper.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node
}
