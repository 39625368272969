import { Redirect, Route } from 'react-router-dom'

import { useAuthStore } from '@caradasstore/AuthStore'
import { PageLayout } from '@components/layout'

export const PrivateRoute = ({ component: Component, usePageLayout = true, layoutProps = {}, componentProps = {}, ...rest }) => {
  const authCtx = useAuthStore()
  const isAuthenticated = authCtx.isLoggedIn

  const renderContent = (props) => {
    const content = <Component {...props} {...componentProps} />
    return usePageLayout ? <PageLayout {...layoutProps}>{content}</PageLayout> : content
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? renderContent(props) : <Redirect to={{ pathname: '/authenticate', state: { from: props.location } }} />
      }
    />
  )
}
