import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { AdasBox, AdasListItemText, AdasMenuList, AdasPaper, AdasPopper, AdasTypography } from '@components/wrapper-components'
import { useHistory } from 'react-router-dom'
import { getCalibrationCenter } from '../../../api/api'
import './CalibrationCenterBar.css'

export const CalibrationCenterBar = ({ currentUser, centerDetail, setCenterDetail, isSideBarOpen = true }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const history = useHistory()
  const [ccList, setCcList] = useState([])

  const handleCCSwitch = async (cc) => {
    const ccResponse = await getCalibrationCenter({
      ccid: cc.id
    })
    if (ccResponse.status === 200) {
      setCenterDetail(ccResponse.data)
      history.push('/')
    }
    setAnchorEl(null)
  }

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const ccList = Array.from(
        new Set(
          [...currentUser.calibrationCentersOwned, ...currentUser.calibrationCentersManaged, ...currentUser.calibrationCentersTech].filter(
            (cc) => cc.is_active
          )
        )
      )
      setCcList(ccList)
    }
  }, [currentUser])

  const handleOpen = (evt) => {
    setAnchorEl(evt.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const displayStyle = isSideBarOpen ? 'flex' : 'contents'

  return (
    <AdasBox
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '.5rem',
        marginRight: 'auto',
        marginLeft: 'auto'
      }}
    >
      <AdasTypography
        component={'div'}
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          color: theme.palette.primary.main,
          marginTop: '.75rem',
          marginRight: '1.5rem',
          border: '1px solid',
          display: displayStyle,
          width: { xs: '100vw', sm: '18vw' },
          overflow: 'hidden',
          whiteSpace: { xs: 'nowrap' },
          textOverflow: { xs: 'ellipsis' }
        }}
        onClick={handleOpen}
        key={1}
      >
        {!isSideBarOpen && <ArrowDropDownCircleOutlinedIcon />}
        {isSideBarOpen && (
          <AdasListItemText
            sx={{
              span: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                padding: '4px',
                cursor: 'pointer'
              }
            }}
          >
            {centerDetail.name}
          </AdasListItemText>
        )}
      </AdasTypography>
      <AdasPopper open={open} anchorEl={anchorEl} placement='bottom-start' className='popper-top'>
        <AdasPaper>
          <AdasMenuList
            id='user-popover-menu'
            onClickAway={handleClose}
            variant='withClickAway'
            type={1}
            items={ccList?.map((cc) => ({
              label: cc.name,
              onClick: () => handleCCSwitch(cc)
            }))}
          />
        </AdasPaper>
      </AdasPopper>
    </AdasBox>
  )
}

CalibrationCenterBar.propTypes = {
  currentUser: PropTypes.object,
  centerDetail: PropTypes.object,
  setCenterDetail: PropTypes.func,
  isSideBarOpen: PropTypes.bool
}
