import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useState, useMemo, useCallback } from 'react'

import { AdasBox, AdasButton, AdasDatePicker, AdasModal, AdasPaper, AdasTypography } from '@components/wrapper-components'
import { BUTTONS_DISPLAY_LABEL, DATE_FORMATS } from '../../../constants'
import { formatDate } from '../../../utils/utils'
import { useTheme } from '@mui/material'

export const DatePickerModal = ({
  open,
  handleClose,
  title,
  cancelLabel = BUTTONS_DISPLAY_LABEL.CANCEL,
  confirmLabel,
  message,
  dateRangeLimit = 2,
  handleSubmit,
  dateRangeLimitWarning
}) => {
  const theme = useTheme()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const handleCloseModal = useCallback(() => {
    setStartDate('')
    setEndDate('')
    setErrorMessage('')
    handleClose()
  }, [setStartDate, setEndDate, setErrorMessage, handleClose])

  const handleSubmitModal = useCallback(
    (startDate, endDate) => {
      handleSubmit(startDate, endDate).then(() => {
        // Clear fields after successful submission
        setStartDate('')
        setEndDate('')
        setErrorMessage('')
      })
    },
    [handleSubmit, setStartDate, setEndDate, setErrorMessage]
  )

  const formattedStartDate = useMemo(() => formatDate(startDate, DATE_FORMATS.ISO_DATE_STRING), [startDate])
  const formattedEndDate = useMemo(() => formatDate(endDate, DATE_FORMATS.ISO_DATE_STRING), [endDate])

  const handleStartDateChange = useCallback(
    (newDate) => {
      const startDate = new Date(newDate)
      const endDateTemp = new Date(endDate)

      //Setting the max date range with in limit
      const maxSelectableEndDate = new Date(startDate)
      maxSelectableEndDate.setMonth(maxSelectableEndDate.getMonth() + dateRangeLimit)
      maxSelectableEndDate.setHours(23, 59, 59, 999)

      if (endDateTemp && endDateTemp <= startDate) {
        setErrorMessage('Start date must be less than end date')
      } else if (endDateTemp && endDateTemp >= maxSelectableEndDate) {
        setErrorMessage(dateRangeLimitWarning)
      } else {
        setErrorMessage('') // doing this since date picker shows the last clicked date even it's not set in state
      }
      let formattedDate = formatDate(startDate)
      setStartDate(formattedDate)
    },
    [endDate]
  )

  const handleEndDateChange = useCallback(
    (newDate) => {
      const endDate = new Date(newDate).setHours(23, 59, 59, 999)
      const startDateTemp = new Date(startDate)

      //Setting the max date range with in limit
      const maxSelectableEndDate = new Date(startDate)
      maxSelectableEndDate.setMonth(maxSelectableEndDate.getMonth() + dateRangeLimit)

      if (startDateTemp && endDate <= startDateTemp) {
        setErrorMessage('End date must be greater than start date')
      } else if (maxSelectableEndDate && endDate > maxSelectableEndDate) {
        setErrorMessage(dateRangeLimitWarning)
      } else {
        setErrorMessage('')
      }
      const formattedDate = formatDate(endDate)
      setEndDate(formattedDate)
    },
    [startDate]
  )

  return (
    <AdasModal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <AdasPaper
        variant='outlined'
        sx={{
          position: 'relative',
          padding: '20px',
          minHeight: '200px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <AdasBox sx={{ display: 'flex', justifyContent: 'space-between', mb: '12px' }}>
          <AdasTypography
            variant='h6'
            sx={{
              marginTop: '0',
              fontSize: '18px',
              fontWeight: '700',
              lineHeight: '28px'
            }}
          >
            {title}
          </AdasTypography>
          <AdasButton
            buttonType='iconButton'
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '16px'
              },
              color: theme.palette.info.main,
              padding: '2px',
              cursor: 'pointer'
            }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </AdasButton>
        </AdasBox>
        <AdasTypography sx={{ mb: '20px' }}>{message}</AdasTypography>
        <AdasBox sx={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end' }}>
          <AdasDatePicker
            label='Start Date'
            variant='outlined'
            name='start_date'
            disableFuture={true}
            sx={{ marginRight: '16px', flex: 1 }}
            value={formattedStartDate}
            format={DATE_FORMATS.ISO_DATE_STRING}
            onChange={handleStartDateChange}
          />
          <AdasDatePicker
            disableFuture={true}
            label='End Date'
            variant='outlined'
            name='end_date'
            sx={{ marginRight: '16px', flex: 1 }}
            value={formattedEndDate}
            format={DATE_FORMATS.ISO_DATE_STRING}
            onChange={handleEndDateChange}
          />
        </AdasBox>
        {errorMessage && (
          <AdasTypography
            variant='body1'
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.15000000596046448px',
              textAlign: 'left',
              mt: '20px',
              color: theme.palette.error.main
            }}
          >
            {errorMessage}
          </AdasTypography>
        )}
        <AdasBox sx={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end' }}>
          <AdasButton sx={{ marginRight: '5px' }} variant='outlined' onClick={handleCloseModal}>
            {cancelLabel}
          </AdasButton>
          <AdasButton
            disabled={!!errorMessage || !startDate || !endDate}
            variant='contained'
            onClick={() => handleSubmitModal(startDate, endDate)}
          >
            {confirmLabel}
          </AdasButton>
        </AdasBox>
      </AdasPaper>
    </AdasModal>
  )
}

DatePickerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  dateRangeLimitWarning: PropTypes.string
}
