import PropTypes from 'prop-types'

import { DynamScanner } from '@components/vin'
import { AdasBox, AdasFormControl, AdasModal, AdasPaper } from '@components/wrapper-components'
import classes from '../shared/VehicleInfo.module.css'

export const VinScannerModal = (props) => {
  const { open, onClose, vinSuccessHandler } = props

  return (
    <AdasPaper elevation={0} sx={{ padding: '20px' }}>
      <AdasBox className={classes.vehicle}>
        <AdasFormControl fullWidth>
          <AdasModal
            key={'modal'}
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            slotProps={{
              backdrop: { sx: { backgroundColor: 'white' } }
            }}
          >
            <>
              <DynamScanner setVinNumber={vinSuccessHandler} closeModalFunc={onClose} switchToManual={onClose}></DynamScanner>
            </>
          </AdasModal>
        </AdasFormControl>
      </AdasBox>
    </AdasPaper>
  )
}

VinScannerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vinSuccessHandler: PropTypes.func.isRequired
}
