import PropTypes from 'prop-types'
import { Card, Typography } from '@mui/material'
import { CaradasAvatar } from '@components/user'
import { styled } from '@mui/system'
import theme from 'theme/theme'
import './SchedularCalendarEvent.css'
import { schedularStatusStyles } from '../schedule-calendar/helper'

// Define card styles based on type

// Styled Card component using MUI's `styled`
const StyledCard = styled(Card)(({ cardType }) => ({
  backgroundColor: schedularStatusStyles[cardType]?.backgroundColor,
  color: schedularStatusStyles[cardType]?.color,
  borderLeft: `4px solid ${schedularStatusStyles[cardType]?.borderColor}`,
  borderRadius: '4px',
  height: '100%',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(0.5, 1)
}))

export const SchedularCalendarEvent = ({ type, timeText, title, fullName, assingeeName, repair_order_num, isActive }) => {
  return (
    <StyledCard cardType={type}>
      <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
        {timeText}
      </Typography>
      <Typography
        variant='body2'
        mb={1}
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1
        }}
      >
        {fullName}
      </Typography>
      {isActive && (
        <div style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <CaradasAvatar user={{ name: assingeeName }} size='sm' />
          <Typography variant='caption' color={theme.typography.territoryColor}>
            {assingeeName}
          </Typography>
        </div>
      )}
      {repair_order_num && (
        <Typography
          variant='body2'
          mt={1}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1
          }}
        >
          RO# {repair_order_num}
        </Typography>
      )}
    </StyledCard>
  )
}

SchedularCalendarEvent.propTypes = {
  type: PropTypes.oneOf(['Scheduled', 'Arrived', 'InProgress', 'OnHold', 'InTransit', 'Delivered']).isRequired,
  timeText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}
