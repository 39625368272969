import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { useUserStore } from '@caradasstore/UserStore'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCard,
  AdasCheckbox,
  AdasDrawer,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import {
  getEmailHistory,
  getQWIByInvoiceId,
  getQWIByQuoteId,
  getQWIByWorkOrderId,
  sendInvoiceEmail,
  sendQuoteEmail,
  sendWorkorderEmail
} from '../../api/api'
import { Logger } from '../../logger'

import parse from 'html-react-parser'
import { convertToMBSize } from 'utils/utils'
import theme from 'theme/theme'

const AddEmailAddressButton = (props) => {
  const { onClickFunc } = props
  return (
    <AdasBox sx={{ textAlign: 'end' }}>
      <AdasButton startIcon={<AddOutlinedIcon />} sx={{}} onClick={() => onClickFunc(true)}>
        Add Email Address
      </AdasButton>
    </AdasBox>
  )
}

const AddEmailInput = (props) => {
  const { isBcc = false, saveEmail, setIsAddBcc, setIsAddSendTo } = props
  const [emailInput, setEmailInput] = useState('')
  const emailInputRef = useRef()
  const handleInputChange = (e) => {
    setEmailInput(e.target.value)
  }
  const handleClose = () => {
    if (isBcc) {
      setIsAddBcc(false)
    } else {
      setIsAddSendTo(false)
    }
  }
  return (
    <AdasButtonGroup sx={{ boxShadow: 'none', width: '100%' }} variant='contained' aria-label='outlined primary button group'>
      <AdasTextField
        sx={{ width: '70%', borderRadius: '0px' }}
        onChange={handleInputChange}
        id='email'
        name='email'
        label='email'
        variant='outlined'
        type={'email'}
        value={emailInput}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputRef={emailInputRef}
      />
      <AdasButton
        onClick={() => saveEmail({ email: emailInput, checked: true }, isBcc)}
        sx={{
          width: '20%',
          maxHeight: '100px',
          borderRadius: '0px',
          '&.MuiButtonGroup-root': { borderRight: '20px' }
        }}
        disabled={emailInput && emailInputRef.current && emailInputRef.current.validity.valid ? false : true}
        variant='contained'
      >
        SAVE
      </AdasButton>
      <AdasButton buttonType='iconButton' onClick={handleClose} sx={{ width: '10%' }}>
        <CloseOutlinedIcon />
      </AdasButton>
    </AdasButtonGroup>
  )
}

const EmailItem = (props) => {
  const { isChecked, item, isBcc, updateFunc, isAddedEmail, handleCheckbox, sentAt } = props
  const formattedDate = sentAt ? new Date(sentAt).toLocaleDateString() : ''
  const formattedTime = sentAt ? new Date(sentAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) : ''
  return (
    <AdasBox
      key={item.email}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        width: '100%'
      }}
    >
      <AdasCheckbox
        checked={isChecked}
        onChange={(e) => handleCheckbox(e, item, isBcc, isAddedEmail, updateFunc)}
        name='email'
        sx={{ marginRight: '10px' }}
      />

      <AdasBox sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <AdasTypography variant='subtitle2'>{item.name}</AdasTypography>
        <AdasTypography>{item.email}</AdasTypography>
        {sentAt && (
          <AdasTypography
            sx={{
              fontSize: '11px',
              color: 'rgb(96, 96, 96)'
            }}
          >
            <span style={{ fontStyle: 'italic' }}>Last Sent:&nbsp;</span>
            {formattedDate} @ {formattedTime}
          </AdasTypography>
        )}
      </AdasBox>
    </AdasBox>
  )
}

const PdfInfo = (data) =>
  data && (
    <AdasBox>
      <AdasTypography component='span' sx={{ marginLeft: '10px' }}>
        <a download={data.name} href={URL.createObjectURL(data)}>
          {data.name}
        </a>
      </AdasTypography>
      <AdasTypography component='span' sx={{ marginLeft: '10px' }}>
        {convertToMBSize(data.size)}
      </AdasTypography>
    </AdasBox>
  )

export const EmailForm = (props) => {
  const blockStyle = {
    padding: '16px 16px',
    backgroundColor: '#FFFFFF'
  }
  const { isOpen, emailData, onClose, contacts, quotePdf, workOrderPdf, invoicePdf } = props
  const [emailList, setEmailList] = useState({
    sendTos: [],
    bccs: []
  })
  const [qwiEmailHistory, setQwiEmailHistory] = useState([])
  const [isAddSendTo, setIsAddSendTo] = useState(false)
  const [isAddBcc, setIsAddBcc] = useState(false)
  const [addEmailList, setAddEmailList] = useState({
    sendTos: [],
    bccs: []
  })
  const [formTitle, setFormTitle] = useState('Email')
  const setLoading = useLoadingStore((store) => store.setLoading)
  const currentUser = useUserStore((store) => store.currentUser)
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const managementBccList = useMemo(() => {
    return Array.from(
      new Set([
        ...(centerDetail.owners ? centerDetail.owners : []),
        ...(centerDetail.managers ? centerDetail.managers : []),
        ...(contacts && contacts.bccs && contacts.bccs.length > 0 ? contacts.bccs : []),
        ...(currentUser.is_admin || currentUser.is_owner || currentUser.is_manager ? [] : [currentUser])
      ])
    )
  }, [centerDetail, contacts, currentUser])

  useEffect(() => {
    const fetchData = async () => {
      let qwiMapping = null
      const params = { qwi_id: null, qwi_stage: null }

      try {
        if (invoicePdf) {
          qwiMapping = await getQWIByInvoiceId(emailData.id)
          params.qwi_id = qwiMapping?.data?.id
          params.qwi_stage = 'INVOICE'
          setFormTitle('Email Invoice')
        } else if (quotePdf) {
          qwiMapping = await getQWIByQuoteId(emailData.id)
          params.qwi_id = qwiMapping?.data?.id
          params.qwi_stage = 'QUOTE'
          setFormTitle('Email Quote')
        } else if (workOrderPdf) {
          qwiMapping = await getQWIByWorkOrderId(emailData.id)
          params.qwi_id = qwiMapping?.data?.id
          params.qwi_stage = 'WORKORDER'
          setFormTitle('Email Work Order')
        }

        if (params.qwi_id && params.qwi_stage) {
          const response = await getEmailHistory(params)
          if (response?.data && response?.data.length > 0) {
            setQwiEmailHistory(response?.data)
          }
        }
      } catch (error) {
        Logger.error({ message: error, payload: { emailId: emailData.id, file: 'EmailForm', path: 'useEffect/fetchData' } })
      }
    }

    if (emailData.id) fetchData()
  }, [workOrderPdf, invoicePdf, quotePdf])

  const saveEmail = useCallback(
    (props, isBcc) => {
      if (isBcc) {
        setAddEmailList((prev) => {
          const bccList = [...prev.bccs]
          bccList.push(props)
          const updated = {
            ...prev,
            bccs: bccList
          }
          return updated
        })
        setIsAddBcc(false)
      } else {
        setAddEmailList((prev) => {
          const sendToList = [...prev.sendTos]
          sendToList.push(props)
          const updated = {
            ...prev,
            sendTos: sendToList
          }
          return updated
        })
        setIsAddSendTo(false)
      }
    },
    [setAddEmailList, setIsAddBcc, setIsAddSendTo]
  )

  const reset = useCallback(() => {
    setEmailList({ sendTos: [], bccs: [] })
    setAddEmailList({ sendTos: [], bccs: [] })
    setIsAddBcc(false)
    setIsAddSendTo(false)
    onClose()
  }, [onClose])

  const handleCheckbox = useCallback((e, props, isBcc, isAddedEmail, updateFunc) => {
    if (isBcc) {
      if (isAddedEmail) {
        updateFunc((prev) => {
          const bccList = [...prev.bccs]
          const index = bccList.indexOf(props)
          bccList[index].checked = e.target.checked
          const updated = {
            ...prev,
            bccs: bccList
          }
          return updated
        })
      } else {
        updateFunc((prev) => {
          const bccList = [...prev.bccs]
          if (e.target.checked) {
            bccList.push(props)
          } else {
            const index = bccList.indexOf(props)
            bccList.splice(index, 1)
          }
          const updated = {
            ...prev,
            bccs: bccList
          }
          return updated
        })
      }
    } else {
      if (isAddedEmail) {
        updateFunc((prev) => {
          const sendToList = [...prev.sendTos]
          const index = sendToList.indexOf(props)
          sendToList[index].checked = e.target.checked
          const updated = {
            ...prev,
            sendTos: sendToList
          }
          return updated
        })
      } else {
        updateFunc((prev) => {
          const sendToList = [...prev.sendTos]
          if (e.target.checked) {
            sendToList.push(props)
          } else {
            const index = sendToList.indexOf(props)
            sendToList.splice(index, 1)
          }
          const updated = {
            ...prev,
            sendTos: sendToList
          }
          return updated
        })
      }
    }
  }, [])

  const sendEmailHandler = useCallback(async () => {
    const data = {
      sendTos: [...emailList.sendTos, ...addEmailList.sendTos.filter((item) => item.checked)],
      bccs: [...emailList.bccs, ...addEmailList.bccs.filter((item) => item.checked)],
      subject: contacts.subject,
      message: contacts.message,
      info: contacts.info
    }

    const params = {
      data,
      id: emailData.id
    }

    setLoading(true)
    try {
      const sendEmailResponse = invoicePdf
        ? await sendInvoiceEmail(params)
        : quotePdf
          ? await sendQuoteEmail(params)
          : await sendWorkorderEmail(params)

      if (sendEmailResponse?.status === 200) {
        reset()
      }
    } catch (error) {
      Logger.error({ message: error, payload: { emailId: emailData.id, path: 'sendEmailHandler' } })
    } finally {
      setLoading(false)
    }
  }, [emailList, addEmailList, contacts, invoicePdf, quotePdf, emailData.id, workOrderPdf, reset])

  return (
    <AdasDrawer open={isOpen} anchor='right' onClose={reset}>
      <AdasBox className='drawer-title-container'>
        <AdasTypography variant='h6'>{formTitle}</AdasTypography>
        <AdasButton
          buttonType='iconButton'
          sx={{
            padding: '2px',
            cursor: 'pointer',
            color: theme.palette.info.main
          }}
          onClick={reset}
        >
          <CloseIcon fontSize='small' />
        </AdasButton>
      </AdasBox>
      <AdasBox sx={{ margin: '25px' }}>
        <AdasBox>
          <h5>SEND TO</h5>
          <AdasCard sx={blockStyle}>
            {contacts &&
              contacts.sendTos &&
              contacts.sendTos.length > 0 &&
              contacts.sendTos.map((item) => {
                const emailHistory = qwiEmailHistory?.find((history) => history.sent_email === item.email)
                const sentAt = emailHistory ? emailHistory.sent_at : null
                return (
                  <EmailItem
                    isChecked={emailList.sendTos.find((contact) => contact.email === item.email) ? true : false}
                    item={item}
                    updateFunc={setEmailList}
                    isBcc={false}
                    handleCheckbox={handleCheckbox}
                    key={item.email}
                    sentAt={sentAt}
                  />
                )
              })}
            {addEmailList.sendTos.length > 0 &&
              addEmailList.sendTos.map((item) => {
                const emailHistory = qwiEmailHistory?.find((history) => history.sent_email === item.email)
                const sentAt = emailHistory ? emailHistory.sent_at : null
                return (
                  <EmailItem
                    isChecked={item.checked}
                    item={item}
                    updateFunc={setAddEmailList}
                    isBcc={false}
                    isAddedEmail={true}
                    handleCheckbox={handleCheckbox}
                    key={item.email}
                    sentAt={sentAt}
                  />
                )
              })}
            {isAddSendTo && <AddEmailInput saveEmail={saveEmail} isBcc={false} setIsAddBcc={setIsAddBcc} setIsAddSendTo={setIsAddSendTo} />}
            <AddEmailAddressButton onClickFunc={() => setIsAddSendTo(true)} />
          </AdasCard>
        </AdasBox>
        <AdasBox>
          <h5>BCC (BLIND CARBON COPY)</h5>
          <AdasCard sx={blockStyle}>
            {managementBccList &&
              managementBccList.length > 0 &&
              managementBccList.map((item, index) => {
                const emailHistory = qwiEmailHistory?.find((history) => history.sent_email === item.email)
                const sentAt = emailHistory ? emailHistory.sent_at : null
                return (
                  <EmailItem
                    isChecked={emailList.bccs.find((contact) => contact.email === item.email) ? true : false}
                    item={item}
                    updateFunc={setEmailList}
                    isBcc={true}
                    handleCheckbox={handleCheckbox}
                    key={item.email + index}
                    sentAt={sentAt}
                  />
                )
              })}

            {addEmailList.bccs.length > 0 &&
              addEmailList.bccs.map((item) => {
                const emailHistory = qwiEmailHistory?.find((history) => history.sent_email === item.email)
                const sentAt = emailHistory ? emailHistory.sent_at : null
                return (
                  <EmailItem
                    isChecked={item.checked}
                    item={item}
                    updateFunc={setAddEmailList}
                    isBcc={true}
                    isAddedEmail={true}
                    handleCheckbox={handleCheckbox}
                    key={item.email}
                    sentAt={sentAt}
                  />
                )
              })}
            {isAddBcc && <AddEmailInput saveEmail={saveEmail} isBcc={true} setIsAddBcc={setIsAddBcc} setIsAddSendTo={setIsAddSendTo} />}
            <AddEmailAddressButton onClickFunc={() => setIsAddBcc(true)} />
          </AdasCard>
        </AdasBox>
        <AdasBox>
          <h5>EMAIL PREVIEW</h5>
          <AdasCard sx={blockStyle}>
            <AdasTypography sx={{ marginBottom: '5px', fontWeight: '600' }} variant='subtitle2'>
              Subject
            </AdasTypography>
            <AdasTypography sx={{ marginBottom: '20px' }}>{contacts.subject}</AdasTypography>
            <AdasTypography sx={{ marginBottom: '5px', fontWeight: '600' }} variant='subtitle2'>
              Message
            </AdasTypography>
            <AdasTypography sx={{ marginBottom: '20px' }}>{contacts.message && parse(contacts.message)}</AdasTypography>
            <AdasTypography sx={{ marginBottom: '5px', fontWeight: '600' }} variant='subtitle2'>
              Calibration Center Info
            </AdasTypography>
            <AdasTypography>{contacts.info && parse(contacts.info)}</AdasTypography>
          </AdasCard>
        </AdasBox>
        <AdasBox>
          <h5>Attachment</h5>
          <AdasCard sx={blockStyle}>{PdfInfo(quotePdf || workOrderPdf || invoicePdf)}</AdasCard>
        </AdasBox>
        <AdasBox sx={{ margin: '20px 0px' }}>
          <AdasBox sx={{ float: 'right' }}>
            <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={reset} sx={{ marginRight: '10px' }}>
              Cancel
            </AdasButton>
            <AdasButton variant='contained' aria-label='send' color='primary' onClick={sendEmailHandler}>
              SEND
            </AdasButton>
          </AdasBox>
        </AdasBox>
      </AdasBox>
    </AdasDrawer>
  )
}

EmailForm.propTypes = {
  isOpen: PropTypes.bool,
  emailData: PropTypes.object,
  onClose: PropTypes.func,
  contacts: PropTypes.object,
  quotePdf: PropTypes.object,
  isInvoice: PropTypes.bool
}

AddEmailAddressButton.propTypes = {
  onClickFunc: PropTypes.func.isRequired
}
