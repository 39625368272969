import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'

import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCard,
  AdasInputAdornment,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { removeCustomItem, submitCustomItem } from '../../../api/api'
import { currencyInput, validations } from '../../../utils/utils'
import { Logger } from '../../../logger'

export const CustomItemForm = (props) => {
  const {
    customItemData = { name: '', price: '', description: '' },
    cancel,
    onSaveSuccess,
    workOrderData,
    isNew = false,
    multiline = true,
    type
  } = props
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [customItem, setCustomItem] = useState(customItemData)
  const [itemType, setItemType] = useState(customItemData.item_type ? customItemData.item_type : 'credit')
  const [touched, setTouched] = useState({ name: false, price: false })
  const [valid, setValid] = useState({
    name: validations.name(customItemData.name),
    price: validations.price(customItemData.price)
  })

  const onPriceHandlerKeyDown = (e) => {
    // allow backspace and enter
    if (e.key === 'Backspace' || e.key === 'Enter' || e.key === 'Tab') {
      return
    }

    // Prevent non-numeric keys
    if (!/^\d$/.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleInputChange = useCallback((e) => {
    let value = e.target.value
    const name = e.target.name

    if (name === 'description') {
      value = value.replace(/[\n\r]/g, '')
    }
    if (name === 'price') {
      value = currencyInput(e.target.value) / 100
    }

    setCustomItem((prev) => {
      const customItem = {
        ...prev,
        [name]: value
      }
      return customItem
    })
    setTouched((prev) => {
      const touched = {
        ...prev
      }
      if (validations[name]) {
        touched[name] = true
      }
      return touched
    })
    setValid((prev) => {
      const valid = {
        ...prev
      }
      if (validations[name]) {
        valid[name] = validations[name](value)
      }
      return valid
    })
  }, [])

  const handleSaveCustomItem = async () => {
    setLoading(true)
    const submittedData = {
      ...customItemData,
      ...customItem,
      workorder_id: workOrderData.id,
      calibration_center_id: workOrderData.calibrationCenter.id,
      item_type: itemType
    }

    try {
      const saveCustomItemResponse = await submitCustomItem({
        type,
        data: submittedData,
        id: workOrderData.id
      })
      setLoading(false)
      if (saveCustomItemResponse.status === 200 || saveCustomItemResponse.status === 201) {
        onSaveSuccess()
      }
    } catch (error) {
      setLoading(false)
      Logger.error({ message: error, payload: { file: 'CustomItemForm/handleSaveCustomItem', submittedData } })
    }
  }

  const deleteCustomItemHandler = async () => {
    try {
      const deleteCustomItemResponse = await removeCustomItem({
        type,
        id: workOrderData.id,
        customItemId: customItemData.id
      })

      if (deleteCustomItemResponse.status === 200 || deleteCustomItemResponse.status === 201) {
        onSaveSuccess()
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'CustomItemForm/deleteCustomItemHandler', data: { type, id: workOrderData.id, customItemId: customItemData.id } }
      })
    }
  }

  return (
    <AdasCard className='adas-card'>
      <AdasTypography variant='overline' sx={{ fontWeight: '500', display: 'block' }}>
        ITEM TYPE
      </AdasTypography>
      <AdasButtonGroup variant='contained'>
        <AdasButton onClick={() => setItemType('credit')} variant={itemType === 'credit' ? 'contained' : 'outlined'}>
          CHARGE
        </AdasButton>
        <AdasButton onClick={() => setItemType('discount')} variant={itemType === 'discount' ? 'contained' : 'outlined'}>
          DISCOUNT
        </AdasButton>
      </AdasButtonGroup>
      <AdasTextField
        onChange={handleInputChange}
        name='name'
        sx={{ margin: '20px 0px' }}
        id='name'
        label='Name'
        variant='outlined'
        value={customItem && customItem.name ? customItem.name : ''}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 80
        }}
        error={touched.name && !valid.name}
        helperText={`Maximum 80 Characters`}
      />
      <AdasTextField
        type='text'
        onChange={handleInputChange}
        name='price'
        sx={{ marginBottom: '20px' }}
        id='price'
        label='Price'
        variant='outlined'
        value={
          customItem && (customItem.price || customItem.price === 0)
            ? customItem.price.toLocaleString('en-US', {
                type: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
              })
            : ''
        }
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        onKeyDown={onPriceHandlerKeyDown}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*'
        }}
        InputProps={{
          startAdornment: <AdasInputAdornment position='start'>$</AdasInputAdornment>
        }}
        error={touched.price && !valid.price}
      />
      <AdasTextField
        multiline={multiline}
        onChange={handleInputChange}
        name='description'
        sx={{ marginBottom: '20px' }}
        id='description'
        label='Description'
        variant='outlined'
        value={customItem && customItem.description ? customItem.description : ''}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 128
        }}
        helperText={`Maximum 128 Characters ${128 - customItem.description.length} remaining`}
      />
      <AdasBox sx={{ margin: '20px 0px' }}>
        {!isNew ? (
          <AdasButton startIcon={<DeleteOutlinedIcon />} isDebounceEnabled={true} sx={{ color: 'red' }} onClick={deleteCustomItemHandler}>
            Delete
          </AdasButton>
        ) : null}
        <AdasBox sx={{ float: 'right' }}>
          <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={cancel} sx={{ marginRight: '10px' }}>
            Cancel
          </AdasButton>
          <AdasButton
            disabled={!valid.name || !valid.price}
            variant='contained'
            aria-label='save'
            isDebounceEnabled={true}
            color='primary'
            onClick={handleSaveCustomItem}
          >
            Save
          </AdasButton>
        </AdasBox>
      </AdasBox>
    </AdasCard>
  )
}

CustomItemForm.propTypes = {
  customItemData: PropTypes.object,
  cancel: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  workOrderData: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  multiline: PropTypes.bool
}
