import { InputAdornment } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasInputAdornment = ({ children, position, ...props }) => (
  <InputAdornment position={position} {...props}>
    {children}
  </InputAdornment>
)

AdasInputAdornment.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['start', 'end']).isRequired
}
