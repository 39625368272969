import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { Note } from '@components/note'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { useTheme } from '@mui/material'
import { ConfirmModal } from '@components/common'

import { useRevertedInvoice } from '@caradasstore/InvoiceEditStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { AssignedUser } from '@components/assigned-user'
import { CarImageHolder } from '@components/car-image-holder'
import { CustomItem, ItemList } from '@components/custom-item'
import { Drawers } from '@components/drawers'
import { IdDisplay } from '@components/id-display'
import {
  AdasBox,
  AdasButton,
  AdasContainer,
  AdasDivider,
  AdasImage,
  AdasModal,
  AdasPaper,
  AdasStack,
  AdasStep,
  AdasStepLabel,
  AdasStepper,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import {
  convertToStandardWO,
  convertWorkOrderToInvoice,
  deleteWorkOrder,
  getCalibrationList,
  getQWIByWorkOrderId,
  getServiceList,
  getWorkOrderByReferenceNumber,
  updateNote,
  updateWorkOrderCalibrations,
  updateWorkOrderServices
} from '../../../api/api'
import { Logger } from '../../../logger'
import { useScrollTop } from '../../../utils/useScrollTop'
import { currencyFormatter } from '../../../utils/utils'
import { useUserStore } from '@caradasstore/UserStore'
import { SyncAltOutlined } from '@mui/icons-material'
import { GENERIC_MESSAGES, BUTTONS_DISPLAY_LABEL, PARAM_NAMES, REDIRECT_URLS, getUrlByNameAndId } from '../../../constants'
import { StatusEnum } from '../../../enums/enums'
import { DropdownActionButtons } from '@components/buttons'

export const WorkOrderSummaryPage = () => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const [packages, setPackages] = useState([])
  const [allCalibrations, setAllCalibrations] = useState()
  const [services, setServices] = useState([])
  const [allServices, setAllServices] = useState()
  const [isCalibrationOpen, setIsCalibrationOpen] = useState(false)
  const [isServiceOpen, setIsServiceOpen] = useState(false)
  const [deleteInput, setDeleteInput] = useState('')
  const [customItemList, setCustomItemList] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const currentUser = useUserStore((store) => store.currentUser)

  const location = useLocation()
  const isAssignedToMe = location?.state?.isAssignedToMe
  const dataFromInvoiceScreen = location?.state?.dataFromInvoiceScreen

  const [workOrderData, setWorkOrderData] = useState({})
  const [QWIData, setQWIData] = useState(undefined)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const isLoading = useLoadingStore((store) => store.loading)
  const [notes, setNotes] = useState({
    notes_external: workOrderData.notes_external || '',
    notes_internal: workOrderData.notes_internal || ''
  })
  const [isServiceOnly, setIsServiceOnly] = useState(false)

  const options = [
    {
      label: 'Support',
      action: () => history.push(REDIRECT_URLS.CREATE_SUPPORT_REQUEST, { referenceNumber }),
      visible: true
    }
  ]

  const textDisplay = { textAlign: 'center' }
  const theme = useTheme()

  const history = useHistory()
  const setRevertedInvoice = useRevertedInvoice((store) => store.setRevertedInvoice)

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const redirectPreCalibration = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.WORK_ORDER_PRE_CALIBRATION, referenceNumber))
  }

  const redirectCalibration = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.WORK_ORDER_CALIBRATION, referenceNumber))
  }
  const redirectPostCalibration = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.WORK_ORDER_POST_CALIBRATION, referenceNumber))
  }
  const redirectVehicleInfo = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.WORK_ORDER_VEHICLE_INFO, referenceNumber))
  }
  const redirectDocuments = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.WORK_ORDER_DOCUMENT, referenceNumber))
  }

  const redirectPhotos = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.WORK_ORDER_PHOTO, referenceNumber))
  }

  const saveNote = useCallback(
    async (data) => {
      const response = await updateNote({ type: 'workorders', id: workOrderData.id, data })
      if (response.status === 200 || response.status === 201) {
        setNotes(data.note)
      }
    },
    [workOrderData.id]
  )

  const packagesTotal = useMemo(() => {
    return packages.length > 0 ? packages.reduce((total, current) => total + current.price, 0) : 0
  }, [packages])

  const servicesTotal = useMemo(() => {
    return services.length > 0 ? services.reduce((total, current) => total + current.price, 0) : 0
  }, [services])

  const customItemTotal = useMemo(() => {
    return customItemList.length > 0
      ? customItemList.reduce((total, current) => {
          if (current.item_type === 'discount') {
            return total - current.price
          } else {
            return total + current.price
          }
        }, 0)
      : 0
  }, [customItemList])

  const totalCost = useMemo(() => packagesTotal + servicesTotal + customItemTotal, [packagesTotal, servicesTotal, customItemTotal])

  const onCloseService = (data) => {
    setLoading(true)
    setIsServiceOpen(false)
    const updateWorkOrderServiceResponse = updateWorkOrderServices({
      type: 'workorders',
      id: workOrderData.id,
      services: data
    })
    updateWorkOrderServiceResponse
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setServices(data)
        }
      })
      .catch((error) => {
        setLoading(false)
        Logger.error({ message: error, payload: { file: 'WorkOrderSummaryPage/updateWorkOrderServices', workOrderId: workOrderData.id } })
      })
  }

  const [open, setOpen] = useState(false)
  const handleConfirm = async () => {
    const deleteWorkOrderResponse = deleteWorkOrder({
      workOrderId: workOrderData.id
    })
    deleteWorkOrderResponse
      .then(() => {
        setRevertedInvoice(undefined)
        history.push('/')
      })
      .catch((error) => console.log(error))
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setDeleteInput('')
  }

  const handleAddToInvoice = async () => {
    try {
      const createInvoiceResponse = await convertWorkOrderToInvoice({
        workOrderId: workOrderData.id
      })

      if (createInvoiceResponse.status === 200) {
        history.replace('/invoices')
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'WorkOrderSummaryPage/handleAddToInvoice', workOrderId: workOrderData.id } })
    }
  }

  const convertServiceOnlyToStandardWo = useCallback(async () => {
    try {
      setShowConfirmModal(false)
      await convertToStandardWO(workOrderData.id)
      setIsServiceOnly(false)
    } catch (error) {
      Logger.error({
        message: error,
        payload: {
          file: 'WorkOrderSummaryPage/handleConvertToStandardWo',
          workOrderId: workOrderData.id
        }
      })
    }
  }, [workOrderData.id])

  const ConvertToStandardWoWithConfirmation = () => {
    setShowConfirmModal(true)
  }

  function onClosePackages(data) {
    setLoading(true)
    setIsCalibrationOpen(false)
    const updateWorkOrderCalibrationResponse = updateWorkOrderCalibrations({
      type: 'workorders',
      id: workOrderData.id,
      calibrations: data
    })
    updateWorkOrderCalibrationResponse
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setPackages(data)
        }
      })
      .catch((error) => {
        setLoading(false)
        Logger.error({
          message: error,
          payload: { file: 'WorkOrderSummaryPage/updateWorkOrderCalibrations', workOrderId: workOrderData.id }
        })
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const getCalibrationAndServiceData = async (ccid, effectiveDate, selectedServices = [], selectedCalibrations = []) => {
          const calibrations = {}
          const services = {}
          const [calibrationListResponse, serviceListResponse] = await Promise.all([
            getCalibrationList({
              ccid: ccid,
              effectiveDate: effectiveDate
            }),
            getServiceList({
              ccid: ccid,
              effectiveDate: effectiveDate
            })
          ])

          if (calibrationListResponse.status === 200) {
            calibrationListResponse.data.forEach((item) => {
              if (selectedCalibrations.includes(item.itemNum)) {
                item['checked'] = true
              }
              calibrations[item.itemNum] = item
            })
          }

          if (serviceListResponse.status === 200) {
            serviceListResponse.data.forEach((item) => {
              if (selectedServices.includes(item.itemNum)) {
                item['checked'] = true
              }
              services[item.itemNum] = item
            })
          }
          setAllCalibrations(calibrations)
          setAllServices(services)
        }

        const getWorkOrderData = async () => {
          const workOrderResponse = await getWorkOrderByReferenceNumber({ referenceNumber })
          if (workOrderResponse && workOrderResponse.status === 200) {
            const data = workOrderResponse.data

            //Url Tampared i.e invoice/quote record tried to open in workorder path
            if (StatusEnum[data.workOrder?.status] === StatusEnum.MOVED) {
              redirectToForbiddenPage()
              return
            }
            const QWIResponse = await getQWIByWorkOrderId(data.workOrder?.id)
            data.workOrder.safetySystemsItems = workOrderResponse.data.safetySystemsItems
            data.workOrder.calibrations = workOrderResponse.data.workOrderCalibrations
            data.workOrder.services = workOrderResponse.data.workOrderServices
            data.workOrder.customItems = workOrderResponse.data.customItems

            setIsServiceOnly(data.workOrder.type === 'SERVICE_ONLY')
            setQWIData(QWIResponse?.data)
            setWorkOrderData(data.workOrder)
            setNotes({ notes_external: data.workOrder.notes_external || '', notes_internal: data.workOrder.notes_internal || '' })
            let selectedCalibrationItemNums = []
            let selectedServiceItemNums = []
            if (data.workOrderCalibrations.length > 0) {
              const flatenedPackages = data.workOrderCalibrations.map((item) => ({
                ...item,
                calibration_id: item.calibration.id,
                name: item.calibration.name,
                price: item.calibration.price,
                description: item.calibration.description,
                calibration_center_id: item.calibration.calibration_center_id,
                itemNum: item.calibration.itemNum,
                category: item.calibration.category,
                is_defaulted: item.calibration.is_defaulted,
                is_active: item.calibration.is_active,
                calibration_created_at: item.calibration.created_at,
                calibration_updated_at: item.calibration.updated_at
              }))

              setPackages(flatenedPackages)
              selectedCalibrationItemNums = flatenedPackages.map((item) => item.itemNum)
            }

            if (data.workOrderServices.length > 0) {
              const flatenedServices = data.workOrderServices.map((item) => ({
                ...item,
                service_id: item.service.id,
                name: item.service.name,
                price: item.service.price,
                description: item.service.description,
                calibration_center_id: item.service.calibration_center_id,
                itemNum: item.service.itemNum,
                category: item.service.category,
                is_defaulted: item.service.is_defaulted,
                is_active: item.service.is_active,
                calibration_created_at: item.service.created_at,
                calibration_updated_at: item.service.updated_at
              }))

              setServices(flatenedServices)
              selectedServiceItemNums = flatenedServices.map((item) => item.itemNum)
            }

            if (data.customItems.length > 0) {
              setCustomItemList(data.customItems)
            }
            if (dataFromInvoiceScreen) setRevertedInvoice(data.workOrder)
            await getCalibrationAndServiceData(
              data.workOrder.calibrationCenter?.id,
              data.workOrder.quoteCreatedAt || data.workOrder.created_at,
              selectedServiceItemNums,
              selectedCalibrationItemNums
            )
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
        await getWorkOrderData()
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            referenceNumber: workOrderData.reference_number,
            ccid: workOrderData.calibrationCenter?.id,
            effectiveDate: workOrderData.quoteCreatedAt || workOrderData.created_at
          }
        })
      } finally {
        setLoading(false)
      }
    }
    if (referenceNumber) fetchData()
  }, [referenceNumber, isServiceOnly])

  useEffect(() => {
    setWorkOrderData((prev) => {
      const updated = {
        ...prev,
        customItems: customItemList
      }
      return updated
    })
  }, [customItemList])

  const vehicleData = workOrderData?.vehicle || {}

  const showDeleteButton = useMemo(() => {
    return !dataFromInvoiceScreen || (dataFromInvoiceScreen && currentUser.is_admin)
  }, [location, currentUser])
  return (
    <AdasContainer maxWidth='lg'>
      {workOrderData.id && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '60px' }}>
          <Drawers data={allCalibrations} isOpen={isCalibrationOpen} onClose={onClosePackages} title={'Calibrations'} />
          <Drawers data={allServices} isOpen={isServiceOpen} onClose={onCloseService} title={'Service'} />
          <AdasBox sx={{ marginBottom: '40px' }} key='section1'>
            <AdasBox sx={{ overflow: 'hidden' }} key='subSection1'>
              <span style={{ float: 'left' }}>
                <IdDisplay itemId={workOrderData.reference_number} roNumber={workOrderData.repair_order_num} itemType={'WO'} />
              </span>
              <span style={{ float: 'right' }}>
                <AssignedUser type='workorders' workOrderId={workOrderData.id} initialAssignee={workOrderData.assigned_to} />
                {/* <span>{workOrderData.user.name}</span> */}
                {workOrderData.created_at && (
                  <span style={{ marginLeft: '20px' }}>Created: {new Date(workOrderData.created_at).toLocaleDateString('en-US')}</span>
                )}
              </span>
            </AdasBox>
            <AdasBox
              style={{
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                float: 'right',
                marginTop: '2px'
              }}
            >
              <span style={{ float: 'right', padding: '6px', paddingRight: 0 }}>
                <DropdownActionButtons options={options} />
              </span>
            </AdasBox>
            {vehicleData?.year && (
              <AdasBox id='carInfo' key='carInfo'>
                <AdasBox id='image'>
                  {vehicleData.image_url ? <AdasImage src={vehicleData.image_url} alt='vehicle' /> : <CarImageHolder showText={false} />}
                  <AdasTypography sx={{ ...textDisplay, fontSize: '24px' }}>
                    <b>{`${vehicleData.year} ${vehicleData.make} ${vehicleData.model} ${vehicleData.trim || ''}`}</b>
                  </AdasTypography>
                </AdasBox>
                <AdasTypography sx={{ ...textDisplay, fontSize: '20px', fontWeight: '500' }}>
                  <b>Total Cost: {currencyFormatter(totalCost)}</b>
                </AdasTypography>
              </AdasBox>
            )}
            <AdasBox id='clientInfo' sx={{ ...textDisplay }} key='clientInfo'>
              <AdasTypography sx={{ ...textDisplay }} color={theme.typography.secondaryColor}>
                {workOrderData.client?.name}
              </AdasTypography>
            </AdasBox>
          </AdasBox>
          {!isLoading && (
            <>
              <AdasBox key='section2'>
                <AdasStepper orientation='vertical' connector={null} activeStep={-1}>
                  {!isServiceOnly && (
                    <AdasStep key='preCalibration'>
                      <AdasStepLabel>Complete Pre Calibration Set Up</AdasStepLabel>
                      {workOrderData.is_precalibration_complete ? (
                        <AdasPaper elevation={0}>
                          <AdasBox
                            sx={{
                              height: '50px',
                              border: '1px',
                              display: 'flex',
                              backgroundColor: '#D7E2FF',
                              alignItems: 'center',
                              paddingLeft: '20px',
                              borderRadius: '4px'
                            }}
                          >
                            <CheckCircleOutlineOutlinedIcon />
                            <AdasTypography sx={{ margin: '4px' }}>Pre Calibration Set Up Complete</AdasTypography>
                          </AdasBox>
                          <AdasBox sx={{ textAlign: 'end' }}>
                            <AdasButton sx={{}} onClick={redirectPreCalibration}>
                              REVIEW PRE CALIBRATION SET UP
                            </AdasButton>
                          </AdasBox>
                        </AdasPaper>
                      ) : (
                        <AdasPaper
                          variant='outlined'
                          sx={{
                            ...textDisplay
                          }}
                        >
                          <AdasButton
                            variant='outlined'
                            sx={{ width: '90%', margin: '20px 0px' }}
                            endIcon={<ArrowForwardIosIcon fontSize='small' />}
                            onClick={redirectPreCalibration}
                          >
                            Complete PRE CALIBRATION SET UP
                          </AdasButton>
                        </AdasPaper>
                      )}
                    </AdasStep>
                  )}
                  <AdasStep key='packagesx'>
                    <AdasStepLabel>Add/Remove {!isServiceOnly && 'Calibrations and '}Services</AdasStepLabel>
                    <AdasPaper variant='outlined'>
                      {!isServiceOnly && [
                        <AdasBox sx={{ margin: '20px' }} key='notServiceOnly'>
                          <span>
                            <b>Calibrations</b>
                          </span>
                          <span style={{ float: 'right' }}>
                            <b>{currencyFormatter(packagesTotal)}</b>
                          </span>
                          <ItemList itemList={packages} name='Packages' />
                          <AdasBox sx={{ textAlign: 'end' }}>
                            <AdasButton startIcon={<LibraryAddCheckOutlinedIcon />} sx={{}} onClick={() => setIsCalibrationOpen(true)}>
                              Manage Calibrations
                            </AdasButton>
                          </AdasBox>
                        </AdasBox>,

                        <AdasDivider orientation='horizontal' flexItem sx={{ margin: '20px' }} key='divider' />
                      ]}
                      <AdasBox sx={{ margin: '20px' }}>
                        <span>
                          <b>Services</b>
                        </span>
                        <span style={{ float: 'right' }}>
                          <b>{currencyFormatter(servicesTotal)}</b>
                        </span>
                        <ItemList itemList={services} name='Services' />
                        <AdasBox sx={{ textAlign: 'end' }}>
                          <AdasButton startIcon={<LibraryAddCheckOutlinedIcon />} sx={{}} onClick={() => setIsServiceOpen(true)}>
                            Manage Services
                          </AdasButton>
                        </AdasBox>
                      </AdasBox>
                      <AdasDivider orientation='horizontal' flexItem sx={{ margin: '20px' }} />
                      <CustomItem
                        type='workorders'
                        customItemTotal={customItemTotal}
                        existingCustomItemList={customItemList}
                        workOrderData={workOrderData}
                        onSaveCustomItem={(props) => setCustomItemList(props)}
                      />
                    </AdasPaper>
                  </AdasStep>
                  {isServiceOnly && (
                    <AdasStep key='notesServiceOnly'>
                      <AdasStepLabel>Notes</AdasStepLabel>
                      <Note
                        existingExternalNote={notes.notes_external}
                        existingInternalNote={notes.notes_internal}
                        submit={(props) => saveNote(props)}
                        allowEdit={true}
                      />
                    </AdasStep>
                  )}
                  {!isServiceOnly && [
                    <AdasStep key='calibrationServiceOnly'>
                      <AdasStepLabel>Calibration Documentation</AdasStepLabel>
                      {workOrderData.is_calibration_step_complete ? (
                        <AdasPaper elevation={0}>
                          <AdasBox
                            sx={{
                              height: '50px',
                              border: '1px',
                              display: 'flex',
                              backgroundColor: '#D7E2FF',
                              alignItems: 'center',
                              paddingLeft: '20px',
                              borderRadius: '4px'
                            }}
                          >
                            <CheckCircleOutlineOutlinedIcon />
                            <AdasTypography sx={{ margin: '4px' }}>Calibration Documentation Complete</AdasTypography>
                          </AdasBox>
                          <AdasBox sx={{ textAlign: 'end' }}>
                            <AdasButton sx={{}} onClick={redirectCalibration}>
                              REVIEW CALIBRATION DOCUMENTATION
                            </AdasButton>
                          </AdasBox>
                        </AdasPaper>
                      ) : (
                        <AdasPaper
                          variant='outlined'
                          sx={{
                            ...textDisplay
                          }}
                        >
                          <AdasButton
                            variant='outlined'
                            sx={{ width: '90%', margin: '20px 0px' }}
                            endIcon={<ArrowForwardIosIcon fontSize='small' />}
                            onClick={redirectCalibration}
                          >
                            CALIBRATION SET UP
                          </AdasButton>
                        </AdasPaper>
                      )}
                    </AdasStep>,
                    <AdasStep key='postCalibration'>
                      <AdasStepLabel>Post Calibration Checklist</AdasStepLabel>
                      {workOrderData.is_postcalibration_complete ? (
                        <AdasPaper elevation={0}>
                          <AdasBox
                            sx={{
                              height: '50px',
                              border: '1px',
                              display: 'flex',
                              backgroundColor: '#D7E2FF',
                              alignItems: 'center',
                              paddingLeft: '20px',
                              borderRadius: '4px'
                            }}
                          >
                            <CheckCircleOutlineOutlinedIcon />
                            <AdasTypography sx={{ margin: '4px' }}>Post Calibration Set Up Complete</AdasTypography>
                          </AdasBox>
                          <AdasBox sx={{ textAlign: 'end' }}>
                            <AdasButton sx={{}} onClick={redirectPostCalibration}>
                              REVIEW POST CALIBRATION SET UP
                            </AdasButton>
                          </AdasBox>
                        </AdasPaper>
                      ) : (
                        <AdasPaper
                          variant='outlined'
                          sx={{
                            ...textDisplay
                          }}
                        >
                          <AdasButton
                            variant='outlined'
                            sx={{ width: '90%', margin: '20px 0px' }}
                            endIcon={<ArrowForwardIosIcon fontSize='small' />}
                            onClick={redirectPostCalibration}
                          >
                            COMPLETE POST CALIBRATION SET UP
                          </AdasButton>
                        </AdasPaper>
                      )}
                    </AdasStep>
                  ]}
                </AdasStepper>
              </AdasBox>
            </>
          )}
          <AdasBox sx={{ margin: '10px 0px' }} key='section3'>
            <AdasStack
              direction='row'
              spacing={2}
              divider={<AdasDivider orientation='vertical' flexItem sx={{ backgroundColor: '#0071E3' }} />}
            >
              <AdasButton onClick={redirectVehicleInfo} startIcon={<InfoOutlinedIcon />} sx={{ width: '30%' }}>
                Vehicle Info
              </AdasButton>
              <AdasButton startIcon={<PhotoCameraOutlinedIcon />} sx={{ width: '30%' }} onClick={redirectPhotos}>
                Photos
              </AdasButton>
              <AdasButton startIcon={<InsertDriveFileOutlinedIcon />} sx={{ width: '30%' }} onClick={redirectDocuments}>
                Documents
              </AdasButton>
            </AdasStack>
            <AdasDivider orientation='horizontal' flexItem sx={{ marginTop: '10px' }} />
          </AdasBox>
          <AdasBox sx={{ marginBottom: '20px' }} key='section4'>
            <AdasBox
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' }
              }}
            >
              <AdasButton
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                onClick={() => history.push('/workorders', { isAssignedToMe: isAssignedToMe })}
              >
                Work Orders
              </AdasButton>

              <AdasBox
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', md: 'row', sm: 'column' },
                  gap: { xs: '1rem', sm: '0' },
                  ml: { sm: 'auto' }
                }}
              >
                {isServiceOnly && !QWIData?.invoiceId && (
                  <AdasButton startIcon={<SyncAltOutlined />} onClick={ConvertToStandardWoWithConfirmation}>
                    Add Calibrations
                  </AdasButton>
                )}
                {showDeleteButton && (
                  <AdasButton
                    startIcon={<DeleteOutlinedIcon />}
                    sx={{ color: 'red' }}
                    disabled={!currentUser.is_admin && QWIData && QWIData.invoiceId ? true : false}
                    onClick={handleOpen}
                  >
                    Delete Work Order
                  </AdasButton>
                )}
              </AdasBox>

              <AdasButton
                variant='contained'
                startIcon={<AddOutlinedIcon />}
                sx={{ marginLeft: { sm: '2rem' }, marginTop: '11px', marginBottom: '11px' }}
                isDebounceEnabled={true}
                disabled={
                  !isServiceOnly &&
                  !(
                    workOrderData.is_precalibration_complete &&
                    workOrderData.is_postcalibration_complete &&
                    workOrderData.is_calibration_step_complete
                  )
                }
                onClick={handleAddToInvoice}
              >
                {dataFromInvoiceScreen ? 'Update Invoice' : 'Add To Invoice'}
              </AdasButton>
            </AdasBox>

            <AdasModal
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AdasPaper
                variant='outlined'
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingBottom: '20px'
                }}
              >
                <h2>Delete Work Order?</h2>
                <p>To confirm that you want to delete this work order, type DELETE below.</p>
                <AdasTextField
                  onChange={(e) => setDeleteInput(e.target.value.toUpperCase())}
                  name='deleteInput'
                  sx={{ marginBottom: '20px' }}
                  id='deleteInput'
                  label='Delete Input'
                  variant='outlined'
                  value={deleteInput}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <AdasBox sx={{ display: 'flex' }}>
                  <AdasButton variant='outlined' sx={{ marginLeft: 'auto', mr: '5px' }} onClick={handleClose}>
                    Cancel
                  </AdasButton>
                  <AdasButton variant='contained' disabled={deleteInput === 'DELETE' ? false : true} onClick={handleConfirm}>
                    Delete
                  </AdasButton>
                </AdasBox>
              </AdasPaper>
            </AdasModal>
            {showConfirmModal && (
              <ConfirmModal
                cancelLabel={BUTTONS_DISPLAY_LABEL.CANCEL}
                confirmLabel={BUTTONS_DISPLAY_LABEL.CONFIRM}
                open={showConfirmModal}
                title={GENERIC_MESSAGES.ADD_CALIBRATIONS}
                message={GENERIC_MESSAGES.CLICK_CONFIRM_SERVICE_ONLY_WO_TO_STANDARD_WO_MESSAGE}
                handleClose={() => setShowConfirmModal(false)}
                handleContinue={convertServiceOnlyToStandardWo}
              />
            )}
          </AdasBox>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}
