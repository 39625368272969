import { useState, useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { downloadClientOEMStatementPDF, getClientInvoiceDoc } from '../../../api/api'
import { FileUpload } from '@components/file-upload'
import { FILE_UPLOAD } from '../../../constants'
import {
  AdasGrid,
  AdasTypography,
  AdasPaper,
  AdasContainer,
  AdasLink,
  AdasButton,
  AdasBox,
  AdasDivider
} from '@components/wrapper-components'
import { CircularProgress } from '@mui/material'

export const ClientInvoiceDocumentPage = () => {
  const history = useHistory()
  const location = useLocation()
  const workOrderData = location.state
  const [documents, setDocuments] = useState({
    preCalibrationReport: '',
    oemProcedure: '',
    wheelAlignmentReport: '',
    calibrationReport: '',
    postTestScanReport: '',
    collisionRepairEstimate: '',
    calibrationRequiredReport: '',
    partsInvoice: '',
    qwiOemPositionStatements: []
  })
  const [additionalDocuments, setAdditionalDocuments] = useState([])
  const [loadedAdditionalDocs, setLoadedAdditionalDocs] = useState([])
  const [statementLoading, setStatementLoading] = useState(false)

  const classes = {
    subHeading: {
      fontWeight: '700 !important',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '266%',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: 'rgba(26, 27, 31, 0.87)',
      padding: '10px 0px'
    }
  }

  const removeAdditionalFileHandler = (index) => {
    setAdditionalDocuments((prev) => {
      prev[index].removed = true
      const data = [...prev]
      return data
    })
  }

  const removeLoadedAdditionalFileHandler = (index) => {
    setLoadedAdditionalDocs((prev) => {
      prev[index].removed = true
      const data = [...prev]
      return data
    })
  }

  const removeFileHandler = (fileName) => {
    if (fileName) {
      setDocuments((prevState) => {
        const updatedDocuments = {
          ...prevState,
          [fileName]: null
        }
        return updatedDocuments
      })
    }
  }

  useEffect(() => {
    const requestDocuments = async (params) => {
      const response = await getClientInvoiceDoc(params)
      if (response.status === 200) {
        const documentSummaryData = response.data
        Object.keys(documents).forEach((key) => {
          if (documentSummaryData[key]) {
            setDocuments((prevState) => {
              const updatedDocuments = {
                ...prevState,
                [key]: documentSummaryData[key]
              }
              return updatedDocuments
            })
          }
        })
        if (documentSummaryData.additionalDocuments != null) {
          let loadedAdditionalDocs = []
          documentSummaryData.additionalDocuments.forEach((doc) => {
            loadedAdditionalDocs.push(doc)
            if (loadedAdditionalDocs.length > 0) {
              setLoadedAdditionalDocs(() => {
                const data = [...loadedAdditionalDocs]
                return data
              })
            }
          })
        }
      }
    }
    const params = {
      token: workOrderData.tempToken,
      invoiceId: workOrderData.id
    }
    requestDocuments(params)
    /* eslint-disable */
  }, [])

  const handleOemStatementClick = useCallback(
    async (event) => {
      const id = event.target.id
      setStatementLoading(id)
      try {
        const dataBlob = await downloadClientOEMStatementPDF({
          token: workOrderData.tempToken,
          invoiceId: workOrderData.id,
          oemPositionStatementsId: id
        })
        const downloadURL = URL.createObjectURL(dataBlob.data)
        window.open(downloadURL, '_blank')
      } catch (error) {
        Logger.error({ message: error, payload: { id, path: 'ClientInvoiceDocumentPage/handleOemStatementClick' } })
      } finally {
        setStatementLoading(false)
      }
    },
    [workOrderData.id, workOrderData.tempToken]
  )

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper elevation={0} sx={{ padding: '20px' }}>
        <AdasBox>
          <AdasPaper
            variant='outlined'
            sx={{
              padding: '0px 20px',
              marginBottom: '20px'
            }}
          >
            <AdasTypography sx={classes.subHeading}>REPORTS</AdasTypography>
            <AdasGrid container spacing={2}>
              {documents.preCalibrationReport && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('preCalibrationReport')}
                    existingData={documents.preCalibrationReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_PRE_CALIBRATION_SCAN_REPORT}
                    label={FILE_UPLOAD.LABEL.PRE_CALIBRATION_SCAN_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PRE_CALIBRATION_SCAN_REPORT}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
              {documents.oemProcedure && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('oemProcedure')}
                    existingData={documents.oemProcedure}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_OEM_PROCEDURE}
                    label={FILE_UPLOAD.LABEL.OEM_PROCEDURE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.OEM_PROCEDURE}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
              {documents.wheelAlignmentReport && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('wheelAlignmentReport')}
                    existingData={documents.wheelAlignmentReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_WHEEL_ALIGNMENT_REPORT}
                    label={FILE_UPLOAD.LABEL.WHEEL_ALIGNMENT_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.WHEEL_ALIGNMENT_REPORT}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
              {documents.calibrationReport && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('calibrationReport')}
                    existingData={documents.calibrationReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_CALIBRATION_REPORT}
                    label={FILE_UPLOAD.LABEL.CALIBRATION_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.CALIBRATION_REPORT}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
              {documents.postTestScanReport && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('postTestScanReport')}
                    existingData={documents.postTestScanReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_POST_TEST_DRIVE_SCAN_REPORT}
                    label={FILE_UPLOAD.LABEL.POST_TEST_DRIVE_SCAN_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.POST_TEST_DRIVE_SCAN_REPORT}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
            </AdasGrid>
          </AdasPaper>
        </AdasBox>
        <AdasBox>
          <AdasPaper
            variant='outlined'
            sx={{
              padding: '0px 20px',
              marginBottom: '20px'
            }}
          >
            <AdasTypography sx={classes.subHeading}>ADDITIONAL DOCUMENTS</AdasTypography>
            <AdasGrid container spacing={2}>
              {documents.collisionRepairEstimate && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('collisionRepairEstimate')}
                    existingData={documents.collisionRepairEstimate}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_COLLISION_REPAIR_ESTIMATE}
                    label={FILE_UPLOAD.LABEL.COLLISION_REPAIR_ESTIMATE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PRE_CALIBRATION_REPAIR_ESTIMATE_SCAN}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
              {documents.calibrationRequiredReport && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('calibrationRequiredReport')}
                    existingData={documents.calibrationRequiredReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_CALIBRATION_REQUIRED_REPORT}
                    label={FILE_UPLOAD.LABEL.CALIBRATION_REQUIRED_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.CALIBRATION_REQUIRED_REPORT}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
              {documents.partsInvoice && (
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('partsInvoice')}
                    existingData={documents.partsInvoice}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_PARTS_INVOICE}
                    label={FILE_UPLOAD.LABEL.PARTS_INVOICE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PARTS_INVOICE}
                    isClientInvoice={true}
                  />
                </AdasGrid>
              )}
              {loadedAdditionalDocs.map((doc, index) => {
                if (doc && !doc.removed) {
                  return (
                    <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                      <FileUpload
                        key={index}
                        removeFile={() => removeLoadedAdditionalFileHandler(index)}
                        existingData={doc}
                        workOrderData={workOrderData}
                        title={`custom_document_${index}`}
                        typeLabel={FILE_UPLOAD.TYPE_LABEL.ADDITIONAL_DOCUMENT}
                        isClientInvoice={true}
                      />
                    </AdasGrid>
                  )
                } else {
                  return null
                }
              })}
              {additionalDocuments.map((doc, index) => {
                if (doc && !doc.removed) {
                  return (
                    <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                      <FileUpload
                        key={index}
                        removeFile={() => removeAdditionalFileHandler(index)}
                        additionalDocument={doc}
                        workOrderData={workOrderData}
                        title={`custom_document_${index}`}
                        typeLabel={FILE_UPLOAD.TYPE_LABEL.ADDITIONAL_DOCUMENT}
                        isClientInvoice={true}
                      />
                    </AdasGrid>
                  )
                } else {
                  return null
                }
              })}
            </AdasGrid>
          </AdasPaper>
        </AdasBox>
        {documents?.qwiOemPositionStatements.length > 0 && (
          <>
            <AdasBox sx={{ mb: '20px' }}>
              <AdasBox>
                <AdasTypography variant='overline' sx={classes.subHeading}>
                  OEM POSITION STATEMENT LINES
                </AdasTypography>
              </AdasBox>
              {documents.qwiOemPositionStatements.map((data, index) => (
                <AdasBox key={index} sx={{ display: 'flex', alignItems: 'center', mb: '10px' }}>
                  {statementLoading == data.oemPositionStatements.id && <CircularProgress size={15} sx={{ mr: '10px' }} />}
                  <AdasLink
                    key={index}
                    id={data.oemPositionStatements.id}
                    sx={{
                      fontSize: '14px',
                      textDecoration: 'none',
                      color: '#0077cc',
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer'
                    }}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleOemStatementClick}
                  >
                    {data.oemPositionStatements.name}
                  </AdasLink>
                </AdasBox>
              ))}
            </AdasBox>
            <AdasDivider sx={{ mb: '20px' }} />
          </>
        )}
        <AdasBox sx={{ overflow: 'auto' }}>
          <AdasButton sx={{ float: 'right' }} variant='outlined' aria-label='goBack' color='primary' onClick={() => history.goBack()}>
            Return To Summary
          </AdasButton>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
