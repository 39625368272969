import CheckIcon from '@mui/icons-material/Check'
import PropTypes from 'prop-types'

import { CarImageHolder } from '@components/car-image-holder'
import { AdasBox, AdasButton, AdasTypography, AdasImage } from '@components/wrapper-components'
import { useTheme } from '@mui/material'
import classes from './VehicleInfoDetails.module.css'

export const VehicleInfoDetails = (props) => {
  const theme = useTheme()
  const { data, isQuote = false, disabled = false, hideAddRemoveSystems = false } = props
  const imageUrl = data.imageUrl
  return (
    <AdasBox className={classes.section}>
      <AdasBox id='image'>
        {imageUrl ? <AdasImage src={imageUrl} alt='vehicle' /> : <CarImageHolder showText={false} />}
        {data?.model && (
          <AdasTypography variant='h5' sx={{ paddingBottom: '8px' }}>
            {`${data.year} ${data.make} ${data.model} ${data.trim || ''}`}
          </AdasTypography>
        )}
        <AdasTypography variant='body2' color={theme.palette.secondary.dark} sx={{ paddingBottom: '8px' }}>
          VIN: {data.vin}
        </AdasTypography>
      </AdasBox>
      <hr className={classes.solid} />
      <AdasBox id='details' display='flex' gap={1}>
        <AdasBox className={classes.column}>
          <AdasBox>
            <AdasTypography component='span' className={classes.label}>
              Engine
            </AdasTypography>
            <AdasTypography component='span' className={classes.data}>
              {data.engine}
            </AdasTypography>
          </AdasBox>
          <AdasBox>
            <AdasTypography component='span' className={classes.label}>
              Type
            </AdasTypography>
            <AdasTypography component='span' className={classes.data}>
              {data.type}
            </AdasTypography>
          </AdasBox>
        </AdasBox>
        <AdasBox className={classes.column}>
          <AdasBox>
            <AdasTypography component='span' className={classes.label}>
              Color
            </AdasTypography>
            <AdasTypography component='span' className={classes.data}>
              {data.color}
            </AdasTypography>
          </AdasBox>
          <AdasBox>
            <AdasTypography component='span' className={classes.label}>
              Door
            </AdasTypography>
            <AdasTypography component='span' className={classes.data}>
              {data.doors}
            </AdasTypography>
          </AdasBox>
        </AdasBox>
      </AdasBox>
      <AdasBox
        id='safety'
        className={classes.safety}
        sx={{
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.main
        }}
      >
        <h3>Installed Safety Systems</h3>
        <ul>
          {data.safetySystemsItems && data.safetySystemsItems.length > 0
            ? data.safetySystemsItems.map((safety, index) => {
                return (
                  <li key={index}>
                    <CheckIcon className={classes.icon} /> {safety.description}
                  </li>
                )
              })
            : null}
        </ul>
      </AdasBox>
      {!isQuote && !hideAddRemoveSystems && (
        <AdasBox className={classes.add}>
          <AdasButton onClick={props.openDraw} variant='text' disabled={disabled}>
            Add/Remove Systems
          </AdasButton>
        </AdasBox>
      )}
    </AdasBox>
  )
}

VehicleInfoDetails.propTypes = {
  data: PropTypes.object,
  isQuote: PropTypes.bool,
  disabled: PropTypes.bool,
  hideAddRemoveSystems: PropTypes.bool
}
