import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasGrid = ({ children, ...props }) => {
  return <Grid {...props}>{children}</Grid>
}

AdasGrid.propTypes = {
  children: PropTypes.node.isRequired
}
