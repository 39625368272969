import { useEffect, useState } from 'react'
import { getYears, getMakes, getModels, getStyles } from '../../api/api'
import {
  AdasBox,
  AdasFormControl,
  AdasFormLabel,
  AdasInputLabel,
  AdasCheckbox,
  AdasFormGroup,
  AdasFormControlLabel,
  AdasSelect,
  AdasPaper
} from '@components/wrapper-components'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { Logger } from '../../logger'
export const VehicleInfoManuallPage = (props) => {
  const {
    formInputValues,
    setFormInputValues,
    handleVehicleManuallChange,
    manualOverRideVin,
    setManualOverRideVin,
    isFromQuote = false
  } = props
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [years, setYears] = useState([])
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [trims, setTrims] = useState([]) //{styleId,trim}
  const convertMakesDataToIdValueArray = (data) => {
    return data.map((option) => {
      return { id: option.make, makeCode: option.makeCode, value: option.make }
    })
  }

  const convertModelDataToIdValueArray = (data) => {
    return data.map((option) => {
      return { id: option.description, value: option.description }
    })
  }

  const convertStylesDataToIdValueArray = (data) => {
    return data.map((option) => {
      return { id: option.trim, value: option.trim }
    })
  }

  const fetchVehicleData = async (year, makeCode, model) => {
    try {
      setLoading(true)

      // Fetch makes if a year is provided, and find the selected make if makeCode is available
      const makesResponse = year ? await getMakes({ year }) : { data: { makes: [] } }

      const makes = convertMakesDataToIdValueArray(makesResponse?.data?.makes || [])
      setMakes(makes)

      // Find the selected make using makeCode
      const selectedMake = makeCode ? makes.find((make) => make.value === makeCode) : null

      // Fetch models only if year and selectedMake are available
      const modelsResponse = year && selectedMake ? await getModels({ year, makeCode: selectedMake.makeCode }) : { data: { models: [] } }

      setModels(convertModelDataToIdValueArray(modelsResponse?.data?.models || []))

      //Fetch trims only if year make and model is provided
      const stylesResponse =
        year && selectedMake && model ? await getStyles({ year, makeCode: selectedMake.makeCode, model: model }) : { data: { styles: [] } }

      setTrims(convertStylesDataToIdValueArray(stylesResponse?.data?.styles || []))

      // Fetch years data regardless of year/make selection
      const yearsResponse = await getYears()
      setYears(yearsResponse?.data?.years || [])
    } catch (error) {
      Logger.error({
        message: error,
        payload: { year, makeCode, path: 'fetchVehicleData' }
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (manualOverRideVin) {
      fetchVehicleData(formInputValues.vehicleYear, formInputValues.vehicleMake ? formInputValues.vehicleMake : null)
    }
  }, [manualOverRideVin, formInputValues.vin, formInputValues.vehicleYear, formInputValues.vehicleMake, formInputValues.trim])

  const handleVehicleFormChange = async (e) => {
    const { name, value } = e.target

    // Update form values based on the field being changed
    setFormInputValues((prevState) => ({
      ...prevState,
      vehicleMake: name === 'vehicleYear' ? '' : prevState.vehicleMake,
      vehicleModel: name === 'vehicleMake' ? '' : prevState.vehicleModel,
      vehicleTrim: name === 'vehicleModel' ? '' : prevState.vehicleTrim
      //vin: '',
      //[name]: value
    }))

    // Handle form changes based on which field is being updated
    if (name === 'vehicleYear') {
      await fetchVehicleData(value, null) // Fetch makes based on the selected year
    } else if (name === 'vehicleMake') {
      const selectedMake = makes.find((make) => make.value === value)
      if (selectedMake) {
        await fetchVehicleData(formInputValues?.vehicleYear, selectedMake.makeCode) // Fetch models based on year and selected make
      }
    } else if (name === 'vehicleModel') {
      const selectedModel = models.find((make) => make.value === value)
      if (selectedModel) {
        await fetchVehicleData(formInputValues?.vehicleYear, formInputValues?.vehicleMake, selectedModel.value) // Fetch styles/trims based on year, make and selected model
      }
    }
    handleVehicleManuallChange(e)
  }
  return (
    <>
      {!isFromQuote && (
        <AdasBox sx={{ mb: '1rem', ml: '1rem' }}>
          <AdasFormGroup>
            <AdasFormControlLabel
              control={
                <AdasCheckbox
                  checked={manualOverRideVin}
                  onChange={() => setManualOverRideVin(!manualOverRideVin)}
                  name='manuallyEnterVehicle'
                />
              }
              label='Manually Enter Vehicle Details'
            />
          </AdasFormGroup>
        </AdasBox>
      )}
      {manualOverRideVin && (
        <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
          {!isFromQuote && (
            <AdasBox sx={{ mb: 2, mt: 2 }}>
              <AdasFormLabel key='s1' sx={{ fontWeight: '500', color: '#000000', fontSize: '14px' }}>
                Vehicle Details
              </AdasFormLabel>
            </AdasBox>
          )}
          <AdasBox sx={{ mb: 2, mt: 2 }}>
            <AdasBox sx={{ mb: 2 }}>
              <AdasFormControl fullWidth>
                <AdasInputLabel id='vehicle-year-label'>Year</AdasInputLabel>
                <AdasSelect
                  fullWidth
                  id='vehicleYear'
                  labelId='vehicle-year-label'
                  name='vehicleYear'
                  label='Year'
                  value={formInputValues?.vehicleYear}
                  onChange={handleVehicleFormChange}
                  options={years}
                />
              </AdasFormControl>
            </AdasBox>
            <AdasBox sx={{ mb: 2 }}>
              <AdasFormControl fullWidth>
                <AdasInputLabel id='vehicle-Make-label'>Make</AdasInputLabel>
                <AdasSelect
                  fullWidth
                  id='vehicleMake'
                  labelId='vehicle-Make-label'
                  name='vehicleMake'
                  label='Make'
                  value={formInputValues?.vehicleMake}
                  onChange={handleVehicleFormChange}
                  options={makes}
                />
              </AdasFormControl>
            </AdasBox>
            <AdasBox sx={{ mb: 2 }}>
              <AdasFormControl fullWidth>
                <AdasInputLabel id='vehicle-Model-label'>Model</AdasInputLabel>
                <AdasSelect
                  fullWidth
                  id='vehicleModel'
                  labelId='vehicle-Model-label'
                  name='vehicleModel'
                  label='Model'
                  value={formInputValues?.vehicleModel}
                  onChange={handleVehicleFormChange}
                  options={models}
                />
              </AdasFormControl>
            </AdasBox>
            <AdasBox sx={{ mb: 2 }}>
              <AdasFormControl fullWidth>
                <AdasInputLabel id='vehicle-Trim-label'>Trim</AdasInputLabel>
                <AdasSelect
                  fullWidth
                  id='vehicleTrim'
                  labelId='vehicle-Trim-label'
                  name='vehicleTrim'
                  label='Trim'
                  value={formInputValues?.vehicleTrim}
                  onChange={handleVehicleFormChange}
                  options={trims}
                />
              </AdasFormControl>
            </AdasBox>
          </AdasBox>
        </AdasPaper>
      )}
    </>
  )
}
