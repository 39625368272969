import { ThemeProvider } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasThemeProvider = ({ children, theme }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

AdasThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired
}
