import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { AdasAccordion, AdasAccordionDetails, AdasAccordionSummary } from '@components/wrapper-components'

export const Accordion = styled((props) => (
  <AdasAccordion defaultExpanded={props.defaultExpanded} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  backgroundColor: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:last-child': {
    marginBottom: 10
  },

  '&:before': {
    display: 'none'
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: 'transparent', // Apply transparent background to AccordionSummary
    borderRadius: '5px' // Set your desired border radius
  }
}))

export const AccordionSummary = styled((props) => (
  <AdasAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

export const AccordionDetails = AdasAccordionDetails

Accordion.propTypes = {
  defaultExpanded: PropTypes.bool
}
