import CloseIcon from '@mui/icons-material/Close'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'

import { CaradasLogoWrapper } from '@components/logo-wrapper'
import { AdasBox, AdasButton, AdasLinearProgress, AdasImage } from '@components/wrapper-components'
import { deleteCalibrationCenterLogo, useUploadCalibrationCenterLogo } from '../../../api/api'

export const CalibrationCenterLogo = (props) => {
  const { calibrationCenterData, isEdit, onSuccess } = props
  const [imageUrl, setImageUrl] = useState(calibrationCenterData.image)
  const fileRef = useRef(null)
  const { progress, uploadData, isSuccess } = useUploadCalibrationCenterLogo()

  const uploadFile = useCallback(
    async (file) => {
      const formData = new FormData()
      formData.append('file', file, file.name)
      const params = {
        data: formData,
        ccId: calibrationCenterData.id
      }
      const response = uploadData(params)
      response.then(() => {
        onSuccess()
      })
    },
    [calibrationCenterData.id, onSuccess, uploadData]
  )

  const handleUpload = () => {
    uploadFile(fileRef.current.files[0])
  }

  const handleClose = async () => {
    setImageUrl(null)
    const params = {
      ccId: calibrationCenterData.id
    }
    const deleteCCLogoResponse = await deleteCalibrationCenterLogo(params)
    if (deleteCCLogoResponse.status === 200) {
      onSuccess()
    }
  }

  useEffect(() => {
    setImageUrl(calibrationCenterData.image)
  }, [calibrationCenterData])

  return !imageUrl ? (
    isEdit ? (
      <AdasBox>
        <AdasBox
          sx={{
            height: '200px',
            border: '1px dotted black',
            display: 'flex',
            marginBottom: '20px'
          }}
        >
          {' '}
          <AdasButton
            component='label'
            sx={{
              fontSize: 12,
              margin: 'auto',
              display: 'flex',
              alignItems: 'center'
            }}
            startIcon={<FileUploadOutlinedIcon />}
          >
            <span>UPLOAD LOGO FILE - SVG, PNG, JPG</span>
            <input ref={fileRef} type='file' accept='image/*' hidden onChange={handleUpload} />
          </AdasButton>
        </AdasBox>
        {progress > 0 && !isSuccess && <AdasLinearProgress value={progress} />}
      </AdasBox>
    ) : (
      <CaradasLogoWrapper />
    )
  ) : (
    <AdasBox
      sx={{
        border: '1px solid black',
        padding: '.5rem',
        margin: '1.25rem 0'
      }}
    >
      {isEdit && <CloseIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={handleClose}></CloseIcon>}
      <AdasImage
        src={imageUrl}
        alt='dash'
        sx={{
          width: '100%',
          height: '200px',
          objectFit: 'contain'
        }}
      />
    </AdasBox>
  )
}

CalibrationCenterLogo.propTypes = { calibrationCenterData: PropTypes.object, isEdit: PropTypes.bool, onSuccess: PropTypes.func.isRequired }
