import PropTypes from 'prop-types'

import { Step, StepLabel, Stepper } from '@mui/material'

export const AdasStepper = ({ activeStep, sx = {}, ...props }) => {
  return <Stepper activeStep={activeStep} sx={sx} {...props} />
}

export const AdasStep = ({ sx = {}, ...props }) => {
  return <Step sx={sx} {...props} />
}

export const AdasStepLabel = ({ sx = {}, ...props }) => {
  return <StepLabel sx={sx} {...props} />
}

AdasStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  sx: PropTypes.object
}
