import { FormGroup } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasFormGroup = ({ sx = {}, children, ...props }) => {
  return (
    <FormGroup sx={sx} {...props}>
      {children}
    </FormGroup>
  )
}

AdasFormGroup.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node.isRequired
}
