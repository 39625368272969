import Snackbar from '@mui/material/Snackbar'
import PropTypes from 'prop-types'
import { useMessageStore } from '@caradasstore/MessageStore'
import CloseIcon from '@mui/icons-material/Close'
import { AdasBox, AdasButton } from '@components/wrapper-components'

export const CaradasSnackbar = ({ message, open, onClose, onUndo }) => {
  const storeMessages = useMessageStore((store) => store.messages)
  const clearMessage = useMessageStore((store) => store.clearMessage)

  const isSingleMessage = message
  const allMessages = isSingleMessage ? [message] : storeMessages

  return (
    <>
      {allMessages.length > 0 &&
        allMessages.map((message, index) => (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            key={index}
            open={isSingleMessage ? open : true}
            autoHideDuration={6000}
            onClose={isSingleMessage ? () => onClose : () => clearMessage(message.id)}
            message={isSingleMessage ? message : message.text}
            action={
              isSingleMessage ? (
                <>
                  <AdasBox>
                    <AdasButton color='inherit' size='small' onClick={onUndo}>
                      Undo
                    </AdasButton>
                    <CloseIcon onClick={() => onClose(false)} sx={{ float: 'right', cursor: 'pointer' }} fontSize='small'></CloseIcon>
                  </AdasBox>
                </>
              ) : null
            }
          />
        ))}
    </>
  )
}

CaradasSnackbar.propTypes = {
  type: PropTypes.oneOf(['single', 'other']),
  messages: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUndo: PropTypes.func
}
