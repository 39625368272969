import create from 'zustand'
import { persist } from 'zustand/middleware'

export const useAuthStore = create(
  persist(
    (set) => ({
      token: null,
      refreshToken: null,
      isLoggedIn: false,

      login: (token, refreshToken) => {
        set(() => ({
          token,
          refreshToken,
          isLoggedIn: true
        }))
      },

      logout: () => {
        set(() => ({
          token: null,
          refreshToken: null,
          isLoggedIn: false
        }))
      }
    }),
    {
      name: 'auth-state',
      storage: localStorage,
      getPartialState: (state) => ({
        token: state.token,
        refreshToken: state.refreshToken
      })
    }
  )
)
