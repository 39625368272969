import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { IdDisplay } from '@components/id-display'
import { getInvoiceScore, saveInvoiceScore } from '../../api/api.js'
import { useUserStore } from '../../store'
import { scoreValidations } from '../../utils/utils.js'

import { AdasBox, AdasButton, AdasDrawer, AdasPaper, AdasTextField, AdasTypography } from '@components/wrapper-components'
import { fieldLabel, groupLabel } from './ScoreDrawer.styles.js'
import theme from 'theme/theme.js'

const Box40 = ({ children, sx = {} }) => {
  const style = {
    ...sx,
    width: '40px',
    textOverflow: 'wrap'
  }
  return <AdasBox sx={style}>{children}</AdasBox>
}

const ScoreRow = ({ id, title, target = '10', value, handleChange, error, readOnly }) => {
  return (
    <AdasBox
      id={id + '_row'}
      sx={{
        display: 'flex',
        alignItems: 'center', // Vertical alignment
        justifyContent: 'space-between',
        marginBottom: '.5rem',
        width: '100%'
      }}
    >
      {/* Title on the left */}
      <AdasTypography sx={{ flex: 1 }}>{title}</AdasTypography>

      {/* Input or Value in the center, right-aligned */}
      {readOnly ? (
        <AdasTypography sx={{ flex: 1, textAlign: 'right' }}>{value}</AdasTypography>
      ) : (
        <AdasTextField
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          error={error}
          size='small'
          sx={{ width: '4rem', marginLeft: 'auto', marginRight: '1rem', textAlign: 'right' }} // Right-aligned input
          inputProps={{
            style: {
              padding: '0 10px',
              textAlign: 'right'
            },
            maxLength: 3
          }}
        />
      )}

      {/* Target score on the right */}
      <AdasTypography sx={{ minWidth: '3rem', textAlign: 'right' }}>{target}</AdasTypography>
    </AdasBox>
  )
}

const defaultScore = {
  preCalDocScore: ' ',
  preCalPhotoScore: ' ',
  prodCalScore: ' ',
  prodCalSrvCustScore: ' ',
  calDocScore: ' ',
  calPhotoScore: ' ',
  postDocScore: ' ',
  postPhotoScore: ' '
}

const defaultTouched = {
  notes: false,
  preCalDocScore: false,
  preCalPhotoScore: false,
  prodCalScore: false,
  prodCalSrvCustScore: false,
  calDocScore: false,
  calPhotoScore: false,
  postDocScore: false,
  postPhotoScore: false
}

export const ScoreDrawer = ({ invoiceId, referenceNumber, roNumber, isOpen, onClose, setIsScored }) => {
  const [score, setScore] = useState(defaultScore)
  const [auditorName, setAuditorName] = useState(null)
  const [auditDate, setAuditDate] = useState(null)
  const [auditCompleted, setAuditCompleted] = useState(false)
  const [notes, setNotes] = useState('')
  const [touched, setTouched] = useState(defaultTouched)
  const [valid, setValid] = useState({
    preCalDocScore: scoreValidations.preCalDocScore(score?.preCalDocScore),
    preCalPhotoScore: scoreValidations.preCalPhotoScore(score?.preCalPhotoScore),
    prodCalScore: scoreValidations.prodCalScore(score?.prodCalScore),
    prodCalSrvCustScore: scoreValidations.prodCalSrvCustScore(score?.prodCalSrvCustScore),
    calDocScore: scoreValidations.calDocScore(score?.calDocScore),
    calPhotoScore: scoreValidations.calPhotoScore(score?.calPhotoScore),
    postDocScore: scoreValidations.postDocScore(score?.postDocScore),
    postPhotoScore: scoreValidations.postPhotoScore(score?.postPhotoScore)
  })
  const currentUser = useUserStore((store) => store.currentUser)

  const everyItemValid = useMemo(() => {
    let allValid = true
    for (const value of Object.values(valid)) {
      if (!value) allValid = false
    }
    return allValid
  }, [valid])

  const someFieldTouched = useMemo(() => {
    let oneTouched = false
    for (const value of Object.values(touched)) {
      if (value) oneTouched = true
    }
    return oneTouched
  }, [touched])

  const scoreTotal = useMemo(() => {
    let total = 0
    for (const [key, value] of Object.entries(score)) {
      if (key !== 'notes' && value !== ' ') {
        total = total + parseInt(value)
      }
    }
    return total
  }, [score])

  const updateScoreFormData = useCallback((name, value) => {
    setScore((prev) => {
      const contact = {
        ...prev,
        [name]: value
      }
      return contact
    })
    setValid((prev) => {
      const valid = {
        ...prev
      }
      if (scoreValidations[name]) {
        valid[name] = scoreValidations[name](value)
      }
      return valid
    })
    setTouched((prev) => {
      return {
        ...prev,
        [name]: true
      }
    })
  }, [])

  const handleNoteChange = useCallback((e) => {
    setNotes(e.target.value)
    setTouched((prev) => {
      return {
        ...prev,
        notes: true
      }
    })
  }, [])

  const handleInputChange = useCallback(
    (e) => {
      let value = e.target.value
      value = value.replace(/\D/g, '')
      value = value === '' ? ' ' : value
      const name = e.target.name
      updateScoreFormData(name, value)
    },
    [updateScoreFormData]
  )

  const closeDrawer = useCallback(
    (saving = false, completed = false) => {
      if (saving) {
        const scoreSaveResponse = saveInvoiceScore({
          invoiceId: invoiceId,
          data: {
            ...score,
            notes: notes,
            completed: completed
          }
        })
        scoreSaveResponse.then((response) => {
          if (response.status === 200) {
            setIsScored(completed)
            onClose()
          } else {
            console.log('unable to save invoice score')
          }
        })
      } else {
        onClose()
      }
    },
    [invoiceId, notes, onClose, score, setIsScored]
  )

  useEffect(() => {
    if (isOpen && invoiceId) {
      const scoreResponse = getInvoiceScore({
        invoiceId: invoiceId
      })
      scoreResponse
        .then((response) => {
          if (response.status === 200 && response.data) {
            setAuditDate(response.data.lastModifiedDate)
            setAuditorName(response.data?.user?.name)
            setNotes(response.data.notes)
            setAuditCompleted(response.data.completed)
            if (response.data.items) {
              for (const scoreItem of Object.values(response.data.items)) {
                updateScoreFormData(scoreItem.category, scoreItem.score + '')
              }
            } else {
              setScore(defaultScore)
              setTouched(defaultTouched)
            }
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [invoiceId, isOpen, updateScoreFormData])

  return (
    <AdasDrawer anchor={'right'} open={isOpen} onClose={() => closeDrawer()}>
      <AdasBox
        sx={{
          padding: '25px 24px'
        }}
      >
        <AdasBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1.5rem' }}>
          <AdasTypography component='span' variant='h6'>
            Scorecard
          </AdasTypography>
          <AdasButton
            buttonType='iconButton'
            sx={{
              padding: '2px',
              cursor: 'pointer',
              color: theme.palette.info.main
            }}
            onClick={() => closeDrawer()}
          >
            <CloseIcon fontSize='small' />
          </AdasButton>
        </AdasBox>
        <AdasBox sx={{ marginBottom: '1rem', marginTop: '-1.5rem', color: 'grey' }}>
          <IdDisplay itemId={referenceNumber} roNumber={roNumber} itemType='Invoice' />
        </AdasBox>
        <AdasBox
          sx={{
            width: '100%',
            display: 'flex'
          }}
          mb={1}
        >
          <AdasTypography sx={fieldLabel}>Auditor Name : </AdasTypography>
          <AdasTypography ml={0.5}>{auditorName ? auditorName : currentUser.name}</AdasTypography>
        </AdasBox>
        <AdasBox
          sx={{
            width: '100%',
            display: 'flex'
          }}
        >
          <AdasTypography sx={fieldLabel}>Date Audited : </AdasTypography>
          <AdasTypography ml={0.5}>{auditDate ? new Date(auditDate).toLocaleDateString() : new Date().toLocaleDateString()}</AdasTypography>
        </AdasBox>
        <AdasPaper
          elevation={0}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            padding: '1rem',
            marginTop: '1rem'
          }}
        >
          <AdasBox id='headerRow' sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {/* Empty space for alignment with the title on the left */}
            <AdasBox sx={{ flex: 1 }} />

            {/* Current Score Header */}
            <AdasBox sx={{ minWidth: '4rem', marginRight: '1rem' }}>
              <AdasTypography sx={fieldLabel}>Current </AdasTypography>
            </AdasBox>

            {/* Target Score Header */}
            <AdasBox sx={{ minWidth: '3rem', textAlign: 'right' }}>
              <AdasTypography sx={fieldLabel}>Target</AdasTypography>
            </AdasBox>
          </AdasBox>

          <AdasTypography sx={groupLabel}>Precalibration Score</AdasTypography>
          <ScoreRow
            id='preCalDocScore'
            title='Documentation'
            handleChange={handleInputChange}
            value={score.preCalDocScore}
            error={touched.preCalDocScore && !valid.preCalDocScore}
            readOnly={!currentUser?.is_admin}
          />
          <ScoreRow
            id='preCalPhotoScore'
            title='Photos'
            handleChange={handleInputChange}
            value={score.preCalPhotoScore}
            error={touched.preCalPhotoScore && !valid.preCalPhotoScore}
            readOnly={!currentUser?.is_admin}
          />
          <AdasTypography sx={groupLabel}>Products</AdasTypography>
          <ScoreRow
            id='prodCalScore'
            title='Calibrations'
            handleChange={handleInputChange}
            value={score.prodCalScore}
            error={touched.prodCalScore && !valid.prodCalScore}
            readOnly={!currentUser?.is_admin}
          />
          <ScoreRow
            id='prodCalSrvCustScore'
            title='Service/Custom'
            handleChange={handleInputChange}
            value={score.prodCalSrvCustScore}
            error={touched.prodCalSrvCustScore && !valid.prodCalSrvCustScore}
            readOnly={!currentUser?.is_admin}
          />
          <AdasTypography sx={groupLabel}>Calibration</AdasTypography>
          <ScoreRow
            id='calDocScore'
            title='Documentation'
            target='20'
            handleChange={handleInputChange}
            value={score.calDocScore}
            error={touched.calDocScore && !valid.calDocScore}
            readOnly={!currentUser?.is_admin}
          />
          <ScoreRow
            id='calPhotoScore'
            title='Photos'
            target='20'
            handleChange={handleInputChange}
            value={score.calPhotoScore}
            error={touched.calPhotoScore && !valid.calPhotoScore}
            readOnly={!currentUser?.is_admin}
          />
          <AdasTypography sx={groupLabel}>Post Calibration</AdasTypography>
          <ScoreRow
            id='postDocScore'
            title='Documentation'
            handleChange={handleInputChange}
            value={score.postDocScore}
            error={touched.postDocScore && !valid.postDocScore}
            readOnly={!currentUser?.is_admin}
          />
          <ScoreRow
            id='postPhotoScore'
            title='Photos'
            handleChange={handleInputChange}
            value={score.postPhotoScore}
            error={touched.postPhotoScore && !valid.postPhotoScore}
            readOnly={!currentUser?.is_admin}
          />
          <AdasBox
            id='totalsRow'
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              marginBottom: '.25rem',
              borderTop: '1px solid black',
              paddingTop: '.75rem',
              paddingLeft: '1rem',
              marginTop: '.75rem',
              width: '100%'
            }}
          >
            <AdasTypography sx={groupLabel}>Total</AdasTypography>
            <Box40 sx={{ width: '55px', marginRight: '1rem' }}>{scoreTotal}</Box40>
            <Box40 sx={{ marginRight: '.5rem' }}>100</Box40>
          </AdasBox>
        </AdasPaper>
        <AdasTextField
          id='notes'
          name='notes'
          label='Notes'
          multiline
          rows={4}
          value={notes}
          onChange={handleNoteChange}
          placeholder='Type additional notes here...'
          sx={{ width: '100%', marginTop: '2rem' }}
          disabled={!currentUser?.is_admin}
        />
        <AdasBox
          id='buttonArea'
          sx={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse'
          }}
        >
          {currentUser?.is_admin ? (
            <AdasButton
              variant='contained'
              aria-label={auditCompleted ? 'Resubmit' : 'Submit'}
              color='primary'
              onClick={() => closeDrawer(true, true)}
              disabled={!(everyItemValid && someFieldTouched)}
            >
              {auditCompleted ? 'Resubmit' : 'Submit'}
            </AdasButton>
          ) : (
            <AdasButton variant='contained' aria-label='Done' color='primary' onClick={() => closeDrawer()}>
              Done
            </AdasButton>
          )}
          {!auditCompleted && currentUser?.is_admin && (
            <AdasButton
              variant='outlined'
              aria-label='Back'
              sx={{ marginRight: '1rem' }}
              disabled={!someFieldTouched}
              onClick={() => closeDrawer(true, false)}
            >
              Save and Exit
            </AdasButton>
          )}
        </AdasBox>
      </AdasBox>
    </AdasDrawer>
  )
}

ScoreDrawer.propTypes = {
  invoiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roNumber: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setIsScored: PropTypes.func
}

ScoreRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  target: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  readOnly: PropTypes.bool
}

Box40.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
}
