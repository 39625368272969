import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

export const AdasTextField = ({ disabled, innerRef, multiline, rows, ...props }) => {
  const textFieldProps = {
    ...props,
    disabled: disabled,
    multiline: multiline || false,
    rows: multiline ? rows || 3 : undefined,
    ref: innerRef ? innerRef : null
  }

  return <TextField {...textFieldProps} />
}

AdasTextField.propTypes = {
  disabled: PropTypes.bool,
  innerRef: PropTypes.object,
  multiline: PropTypes.bool,
  rows: PropTypes.number
}
