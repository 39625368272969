import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'

import { AdasBox, AdasButton, AdasMenu } from '@components/wrapper-components'

export const DropdownActionButtons = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const buttonRef = useRef(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (action) => {
    action()
    handleClose()
  }

  const buttonWidth = buttonRef.current ? buttonRef.current.offsetWidth : null

  return (
    <AdasBox>
      <AdasButton innerRef={buttonRef} aria-controls='simple-menu' aria-haspopup='true' variant='contained' onClick={handleClick}>
        Actions <KeyboardArrowDownOutlined />
      </AdasButton>
      <AdasMenu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: {
            minWidth: buttonWidth // Set the minimum width of the menu
          }
        }}
        options={options}
        handleMenuItemClick={handleMenuItemClick}
      />
    </AdasBox>
  )
}

DropdownActionButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      visible: PropTypes.bool.isRequired
    })
  ).isRequired
}
