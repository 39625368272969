import { ClientForm } from '@components/client'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classes from './VehicleInfo.module.css'

import {
  getClients,
  requestCreateWO,
  requestSafetySystems,
  updateQuote,
  updateWorkOrder,
  getInsuranceCompanies,
  getWorkOrderByReferenceNumber,
  getInvoiceByReferenceNumber,
  getQuoteByReferenceNumber,
  getAttachmentAppointmentEstimate,
  useSubmitFileAttachment,
  getReferralSourceList
} from '../../api/api'

import { VehicleInfoDetails } from '@components/vehicle-info-details'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { useCenterStore } from '@caradasstore/CenterStore'
import { CarImageHolder } from '@components/car-image-holder'
import { VinInput } from '@components/vin'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCheckbox,
  AdasContainer,
  AdasDrawer,
  AdasFormControl,
  AdasFormControlLabel,
  AdasFormGroup,
  AdasFormLabel,
  AdasInputLabel,
  AdasPaper,
  AdasRadio,
  AdasRadioGroup,
  AdasSelect,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import { useHistory, useLocation } from 'react-router-dom'
import { getQuoteSummaryUrl, getWorkOrderSummaryUrl, PARAM_NAMES, REDIRECT_URLS } from '../../constants'
import { useScrollTop } from '../../utils/useScrollTop'
import { sortByName } from '../../utils/utils'
import { CaradasSnackbar } from '@components/snackbar'
import { GENERIC_MESSAGES } from '../../constants'
import { StatusEnum } from '../../enums/enums'
import { Logger } from '../../logger'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { ATTACHMENT_TYPE_MAP, MIME_TYPES_MAP } from 'variable-maps'
import { VehicleInfoManuallPage } from './VehicleInfoManuallPage'
import theme from 'theme/theme'

export const VehicleInfoPage = (props) => {
  useScrollTop()
  const location = useLocation()
  const referralSourceCode = location.state?.referral
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const { fromWorkOrder = false, fromInvoice = false, fromQuote = false, isNewWorkOrder } = props
  const [referralSourceList, setReferralSourceList] = useState([])
  const [showReferralField, setShowReferralField] = useState(!!referralSourceCode)
  const [isAddClient, setIsAddClient] = useState(false)
  const [vehicleData, setVehicleData] = useState({})
  const [displayDetails, setDisplayDetails] = useState(false)
  const [isDrawOpen, setIsDrawOpen] = useState(false)
  const [customerPay, setCustomerPay] = useState(false)
  const [safetySystemsItems, setSafetySystemsItems] = useState({})
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [manualOverRideVin, setManualOverRideVin] = useState(false)
  const [insuranceCompanies, setInsuranceCompanies] = useState([])
  const [clients, setClients] = useState([])
  const [preCalRepairEstimateScan, setPreCalRepairEstimateScan] = useState(null)
  const [formInputValues, setFormInputValues] = useState({
    client: '',
    roNumber: '',
    vin: '',
    serviceType: 'STANDARD',
    insurance_company_id: '',
    appointment_id: '',
    referral_source_code: referralSourceCode || null,
    vehicleYear: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleTrim: ''
  })
  const [workOrderData, setWorkOrderData] = useState({}) //Object holds Quote/WorkOrder/Invoice data
  const prePopulateVin = isNewWorkOrder && (location.state?.prepopulateVin || location.state?.vin)
  const history = useHistory()
  const { uploadData } = useSubmitFileAttachment()

  const [isVinServiceDown, setIsVinServiceDown] = useState(false)

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const previousSafetySystemRef = useRef([])
  const ccId = useCenterStore((store) => store.ccId)
  const setLoading = useLoadingStore((store) => store.setLoading)
  useEffect(() => {
    if (location.state?.appointment_id) {
      setFormInputValues((prevState) => ({
        ...prevState,
        roNumber: location.state?.aro || '',
        appointment_id: location.state?.appointment_id || ''
      }))
    }
  }, [location.state?.appointment_id, setFormInputValues])
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (fromQuote || fromInvoice || fromWorkOrder) {
          setLoading(true)
        }
        if (fromQuote) {
          const quoteResponse = await getQuoteByReferenceNumber({
            referenceNumber: referenceNumber
          })
          if (quoteResponse?.status === 200) {
            quoteResponse.data.quote.safetySystemsItems = quoteResponse.data.safetySystemsItems || []
            setWorkOrderData(quoteResponse.data?.quote)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
        if (fromInvoice) {
          let response = await getInvoiceByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            if (!response.data?.invoiceDto) {
              redirectToForbiddenPage()
              return
            }
            response.data.invoiceDto.safetySystemsItems = response.data.safetySystemsItems || []
            setWorkOrderData(response.data.invoiceDto)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
        if (fromWorkOrder) {
          let response = await getWorkOrderByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            //Url Tampared i.e invoice/quote record tried to open in workorder path
            if (StatusEnum[response.data?.workOrder?.status] === StatusEnum.MOVED) {
              redirectToForbiddenPage()
              return
            }
            response.data.workOrder.safetySystemsItems = response.data.safetySystemsItems || []
            setWorkOrderData(response.data.workOrder)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            referenceNumber
          }
        })
      } finally {
        setLoading(false)
      }
    }
    if (!(isNewWorkOrder && location.state?.prepopulateVin) && referenceNumber) fetchData()
  }, [referenceNumber])

  useEffect(() => {
    // if not a new workorder, we need to get data from location to render vehicle info
    if (!isNewWorkOrder) {
      const clientId = workOrderData.client?.id

      const updateValues = {
        client: clientId,
        roNumber: workOrderData.repair_order_num,
        insurance_company_id: workOrderData?.insurance_company?.id,
        vin: workOrderData?.vehicle?.vin || ''
      }
      setFormInputValues(updateValues)

      const existingVehicleData = {
        ...workOrderData.vehicle,
        color: workOrderData.vehicle?.exterior_color,
        imageUrl: workOrderData.vehicle?.image_url,
        safetySystemsItems: workOrderData.safetySystemsItems || []
      }
      setDisplayDetails(true)
      setVehicleData(existingVehicleData)
    }
  }, [
    workOrderData?.client?.id,
    workOrderData?.repair_order_num,
    workOrderData?.safetySystemsItems,
    workOrderData?.vehicle,
    workOrderData?.insurance_company_dto?.id,
    isNewWorkOrder
  ])

  const title = props.title

  const isEnabled = useMemo(() => {
    const clientValid = !!formInputValues.client

    const vinValid =
      (!!formInputValues.vin && !manualOverRideVin && !isVinServiceDown) ||
      (manualOverRideVin &&
        formInputValues.vehicleYear &&
        formInputValues.vehicleMake &&
        formInputValues.vehicleModel &&
        formInputValues.vehicleTrim)

    const roNumberValid = customerPay || !!formInputValues.roNumber

    return vinValid && clientValid && roNumberValid
  }, [
    customerPay,
    formInputValues.client,
    formInputValues.vin,
    formInputValues.roNumber,
    formInputValues.vehicleYear,
    formInputValues.vehicleMake,
    formInputValues.vehicleModel,
    formInputValues.vehicleTrim,
    isVinServiceDown
  ])

  const redirectWorkOrderSummary = useCallback(
    async (data) => {
      if (fromQuote) {
        history.push(getQuoteSummaryUrl(data?.quote?.reference_number ?? data?.reference_number))
      } else {
        history.push(getWorkOrderSummaryUrl(data?.workOrder?.reference_number ?? data?.reference_number))
      }
    },
    [fromInvoice, fromQuote, history]
  )

  const changeHandler = useCallback(
    (e) => {
      setFormInputValues((prevValues) => {
        return { ...prevValues, [e.target.name]: e.target.value }
      })
    },
    [setFormInputValues]
  )

  const handleShowReferralField = useCallback(
    (e) => {
      setShowReferralField(e.target.value === 'true')
      setFormInputValues((prev) => ({ ...prev, referral_source_code: null }))
    },
    [setShowReferralField, setFormInputValues]
  )

  const handleCustomerPay = useCallback((e) => {
    const value = /true/.test(e.target.value)
    const roNumber = value ? 'Customer-Pay' : ''
    setCustomerPay(value)
    setFormInputValues((prevValues) => {
      return { ...prevValues, roNumber: roNumber }
    })
  }, [])

  const openSafetySystem = useCallback(() => {
    requestSafetySystems({})
      .then((response) => {
        if (response.status === 200) {
          const allSafetySystem = {}
          Object.keys(response.data).forEach((item) => {
            allSafetySystem[item] = {
              name: response.data[item],
              checked: false
            }
          })
          if (vehicleData && vehicleData.safetySystemsItems && vehicleData.safetySystemsItems.length > 0) {
            vehicleData.safetySystemsItems.forEach((safety) => {
              if (allSafetySystem[safety.name]) {
                allSafetySystem[safety.name].checked = true
              }
            })
          }
          setIsDrawOpen(true)
          setSafetySystemsItems(allSafetySystem)
        }
      })
      .catch((err) => {
        console.log('ERROR ', err)
      })
  }, [vehicleData])

  const uploadFile = useCallback(
    async (file, type, id) => {
      const formData = new FormData()
      formData.append('file', file, file.name)
      let attachmentType = ATTACHMENT_TYPE_MAP[type]
      const params = {
        data: formData,
        workorderId: id,
        type: 'workorders',
        attachmentType
      }

      const response = uploadData(params)
      response.then(() => {})
    },
    [uploadData]
  )

  useEffect(() => {
    const fetchFileData = async (value) => {
      try {
        const response = await getAttachmentAppointmentEstimate(ccId, value.appointment_id)
        const fileFromResponse = new File([response.data], value.name, {
          type: MIME_TYPES_MAP[`.${value.file_extension}`] // Ensure the correct MIME type is used
        })

        setPreCalRepairEstimateScan(fileFromResponse)
      } catch (error) {
        Logger.error({ message: error.message, payload: { value } })
      }
    }

    if (location.state?.recentEstimate?.id) {
      fetchFileData(location.state?.recentEstimate)
    }
  }, [location.state?.recentEstimate?.id])

  const submitHandler = useCallback(async () => {
    setLoading(true)
    const { color, imageUrl, fuel, ...vehicle } = vehicleData
    const workOrderRequestData = {
      referral_source_code: formInputValues.referral_source_code,
      client_id: formInputValues.client,
      calibration_center_id: ccId,
      repair_order_num: formInputValues.roNumber,
      quote: fromQuote,
      type: formInputValues.serviceType,
      insurance_company_id: formInputValues.insurance_company_id,
      vehicle: {
        vin: formInputValues.vin,
        calibration_center_id: ccId,
        exterior_color: color,
        image_url: imageUrl,
        fuel_capacity_gal: fuel,
        safetySystemsItems:
          vehicleData.safetySystemsItems?.map((item) => {
            return item.name
          }) || [],
        year: isVinServiceDown && formInputValues.vehicleYear ? formInputValues.vehicleYear : vehicle.year,
        make: isVinServiceDown && formInputValues.vehicleMake ? formInputValues.vehicleMake : vehicle.make,
        model: isVinServiceDown && formInputValues.vehicleModel ? formInputValues.vehicleModel : vehicle.model,
        trim: isVinServiceDown && formInputValues.vehicleTrim ? formInputValues.vehicleTrim : vehicle.trim,
        ...vehicle
      },
      appointment_id: formInputValues.appointment_id
    }
    try {
      const response = await requestCreateWO({ data: workOrderRequestData })
      if (response.status === 201) {
        const id = response.data.workOrder.id
        if (preCalRepairEstimateScan) {
          uploadFile(preCalRepairEstimateScan, 'preCalRepairEstimateScan', id)
        }

        setLoading(false)
        redirectWorkOrderSummary(response.data)
      }
    } catch (err) {
      Logger.error({ message: err, payload: { file: 'VehicleInfoPage/submitHandler' }, workOrderRequestData })
    } finally {
      setLoading(false)
    }
  }, [
    ccId,
    formInputValues.client,
    formInputValues.roNumber,
    formInputValues.serviceType,
    formInputValues.vin,
    fromQuote,
    redirectWorkOrderSummary,
    vehicleData,
    formInputValues.vehicleYear,
    formInputValues.vehicleMake,
    formInputValues.vehicleModel,
    formInputValues.vehicleTrim
  ])

  const handleSafetySystemChange = useCallback(
    (e) => {
      const selectSystem = safetySystemsItems[e.target.defaultValue]
      selectSystem.checked = e.target.checked
      setSafetySystemsItems((prevValues) => {
        const updateValues = {
          ...prevValues,
          [e.target.defaultValue]: selectSystem
        }
        return updateValues
      })
    },
    [safetySystemsItems]
  )

  const handleCloseSafetySystemEdit = useCallback(() => {
    const newSafetySystem = Object.keys(safetySystemsItems)
      .map((safety) => {
        if (safetySystemsItems[safety].checked) {
          const obj = {
            name: safety,
            description: safetySystemsItems[safety].name
          }
          return obj
        } else {
          return null
        }
      })
      .filter(Boolean)

    previousSafetySystemRef.current = vehicleData.safetySystemsItems.map((item) => {
      if (item.description) {
        const obj = {
          name: item.name,
          description: item.description
        }
        return obj
      } else {
        return null
      }
    })

    setVehicleData((prevValues) => {
      const updateValues = {
        ...prevValues,
        safetySystemsItems: newSafetySystem
      }
      return updateValues
    })
    setIsDrawOpen(false)
    const sortedPreviousSafetySystemRef = _.sortBy(previousSafetySystemRef.current, 'description')
    if (!_.isEqual(newSafetySystem, sortedPreviousSafetySystemRef)) {
      setOpenSnackBar(true)
    }
  }, [safetySystemsItems, vehicleData?.safetySystemsItems])

  const undoSafetySystemHandler = useCallback(() => {
    setVehicleData((prevState) => {
      const data = {
        ...prevState,
        safetySystemsItems: previousSafetySystemRef.current
      }
      return data
    })
    setOpenSnackBar(false)
  }, [])

  const updateVehicleInfo = async () => {
    // eslint-disable-next-line no-unused-vars
    const { safetySystemsItems, color, imageUrl, fuel, ...vehicle } = vehicleData
    if (fromQuote) {
      const submitData = {
        id: workOrderData.id,
        client_id: formInputValues.client,
        calibration_center_id: ccId,
        repair_order_num: formInputValues.roNumber,
        insurance_company_id: formInputValues.insurance_company_id,
        quote: fromQuote,
        vehicle: {
          vin: formInputValues.vin,
          calibration_center_id: ccId,
          exterior_color: color,
          image_url: imageUrl,
          fuel_capacity_gal: fuel,
          safetySystemsItems: safetySystemsItems,
          ...vehicle
        }
      }

      try {
        const response = await updateQuote({
          data: submitData,
          quoteId: submitData.id
        })

        if (response.status === 200) {
          redirectWorkOrderSummary(response.data)
        }
      } catch (err) {
        Logger.error({ message: err, payload: { file: 'VehicleInfoPage/updateVehicleInfo/fromQuote', submitData } })
      }
    } else {
      const data = {
        workorder_id: workOrderData.id,
        client_id: formInputValues.client,
        repair_order_num: formInputValues.roNumber,
        insurance_company_id: formInputValues.insurance_company_id,
        safetySystems: safetySystemsItems.map((item) => {
          return item.name
        })
      }

      try {
        const response = await updateWorkOrder({
          data: data
        })

        if (response.status === 200) {
          history.goBack()
        }
      } catch (err) {
        Logger.error({ message: err, payload: { file: 'VehicleInfoPage/updateVehicleInfo', data } })
      }
    }
  }

  const updateVehicleDetails = useCallback(
    (props) => {
      setDisplayDetails(true)
      setVehicleData({ ...props.data, safetySystemsItems: props.data?.safetySystems })
      setFormInputValues((prevValues) => {
        const updatedValues = {
          ...prevValues,
          vin: props.vin
        }
        return updatedValues
      })
    },
    [setDisplayDetails, setVehicleData, setFormInputValues]
  )

  const autocompleteHandler = useCallback(
    (event) => {
      setFormInputValues((prevValues) => {
        const updatedValues = {
          ...prevValues,
          [event.target.name]: event.target.value || ''
        }
        return updatedValues
      })
    },
    [setFormInputValues]
  )

  const handleVehicleManuallChange = (eventOrValue) => {
    if (eventOrValue && eventOrValue.target) {
      const { name, value } = eventOrValue.target
      setFormInputValues((prev) => ({
        ...prev,
        [name]: value
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const insuranceResponse = await getInsuranceCompanies()
        if (insuranceResponse?.data) {
          setInsuranceCompanies(insuranceResponse.data)
        }
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'NewQuote', method: 'useEffect/getInsuranceCompanies' } })
      }
    }

    fetchData()
  }, [])

  const clearVehicleFileds = () => {
    setFormInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleTrim: ''
      }
      return updatedValues
    })
  }

  useEffect(() => {
    if (isNewWorkOrder && isVinServiceDown) {
      clearVehicleFileds()
      setManualOverRideVin(false)
    }
  }, [isNewWorkOrder, isVinServiceDown])

  useEffect(() => {
    clearVehicleFileds()
  }, [manualOverRideVin])

  const fetchClient = useCallback(async () => {
    const clientListResponse = await getClients({ ccid: ccId })
    if (clientListResponse.status === 200) {
      setClients(clientListResponse.data.sort(sortByName))
    }
  }, [ccId, setClients])

  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  useEffect(() => {
    const fetchReferralSource = async () => {
      const referralListResponse = await getReferralSourceList(ccId)
      if (referralListResponse.status === 200) {
        const formattedReferralList = referralListResponse.data
          .sort(sortByName)
          .map((referralObj) => ({ id: referralObj.code, value: referralObj.code }))
        setReferralSourceList(formattedReferralList)
      }
    }

    fetchReferralSource()
  }, [])

  const disableEdit = workOrderData ? StatusEnum[workOrderData.status] === StatusEnum.CLOSED || fromInvoice : false

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        elevation={0}
        sx={{
          padding: '1.25rem',
          marginTop: '1.25rem',
          marginBottom: '20px'
        }}
      >
        {isAddClient && (
          <AdasDrawer anchor={'right'} open={isAddClient} onClose={() => setIsAddClient(false)}>
            <AdasBox className='drawer-title-container'>
              <AdasTypography variant='h6'>Client Info</AdasTypography>
              <AdasButton
                buttonType='iconButton'
                sx={{
                  padding: '2px',
                  cursor: 'pointer',
                  color: theme.palette.info.main
                }}
                onClick={() => setIsAddClient(false)}
              >
                <CloseIcon fontSize='small' />
              </AdasButton>
            </AdasBox>
            <ClientForm onSaveSuccess={fetchClient} clibrationCenterId={Number(ccId)} close={() => setIsAddClient(false)} />
          </AdasDrawer>
        )}
        <AdasDrawer anchor={'right'} open={isDrawOpen} onClose={handleCloseSafetySystemEdit}>
          <AdasBox className='drawer-title-container'>
            <AdasTypography variant='h6'>Installed Safety Systems</AdasTypography>
            <AdasButton
              buttonType='iconButton'
              sx={{
                padding: '2px',
                cursor: 'pointer',
                color: theme.palette.info.main
              }}
              onClick={handleCloseSafetySystemEdit}
            >
              <CloseIcon fontSize='small' />
            </AdasButton>
          </AdasBox>
          <AdasFormGroup className={classes.drawer}>
            {Object.keys(safetySystemsItems).map((safety, index) => {
              return (
                <AdasFormControlLabel
                  className={classes.description}
                  key={index}
                  control={
                    <AdasCheckbox
                      label={safety}
                      checked={safetySystemsItems[safety].checked}
                      onChange={handleSafetySystemChange}
                      value={safety}
                    ></AdasCheckbox>
                  }
                  label={safetySystemsItems[safety].name}
                />
              )
            })}
          </AdasFormGroup>
        </AdasDrawer>
        <AdasBox>
          <h1 style={{ fontSize: '28px', fontWeight: '400' }}>{title}</h1>
          <AdasFormControl fullWidth>
            <AdasButtonGroup sx={{ boxShadow: 'none' }} variant='contained' aria-label='outlined primary button group'>
              <AdasInputLabel id='clients-label'>Select Client</AdasInputLabel>
              <AdasSelect
                label='Select Client'
                labelId='clients-label'
                name='client'
                value={formInputValues.client}
                onChange={autocompleteHandler}
                disabled={disableEdit}
                options={clients || []}
                sx={{
                  maxHeight: '100px',
                  overflowY: 'auto',
                  width: '90%',
                  textAlign: 'left',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  '.MuiOutlinedInput-root': {
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px'
                  }
                }}
              />
              <AdasButton
                onClick={() => setIsAddClient(true)}
                disabled={disableEdit}
                sx={{
                  width: '10%',
                  height: '56px',
                  borderRadius: '0',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px'
                }}
                variant='contained'
              >
                <AddIcon />
              </AdasButton>
            </AdasButtonGroup>
            <AdasBox sx={{ margin: '.5rem 1rem', textAlign: 'left' }}>
              <AdasFormLabel id='repair-order-label' align='left'>
                Repair Order
              </AdasFormLabel>
              <AdasRadioGroup
                row
                options={[
                  { label: 'RO Provided', value: false },
                  { label: 'Customer Pay', value: true }
                ]}
                disabled={disableEdit}
                name='clientPay'
                value={customerPay}
                onChange={handleCustomerPay}
              />
            </AdasBox>

            <AdasTextField
              id='outlined-basic'
              label='RO #'
              variant='outlined'
              sx={{
                paddingTop: '5px'
              }}
              className={classes.formField}
              disabled={customerPay || disableEdit}
              name='roNumber'
              onChange={changeHandler}
              value={formInputValues.roNumber}
            />
          </AdasFormControl>
          <AdasFormControl fullWidth sx={{ marginTop: '20px' }}>
            <AdasInputLabel id='insurance_label'>Insurance Company</AdasInputLabel>
            <AdasSelect
              labelId='insurance_label'
              id='insurance_company_id'
              name='insurance_company_id'
              label='Insurance Company'
              disabled={disableEdit}
              value={formInputValues?.insurance_company_id || ''}
              onChange={autocompleteHandler}
              options={insuranceCompanies || []}
              sx={{
                width: '100%',
                textAlign: 'left'
              }}
            />
          </AdasFormControl>
          {isNewWorkOrder
            ? [
                <>
                  <AdasBox sx={{ margin: '1rem 1rem', textAlign: 'left' }}>
                    <AdasFormLabel id='referral-source-label' align='left'>
                      Do you have a referral source?
                    </AdasFormLabel>
                    <AdasRadioGroup
                      row
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                      ]}
                      disabled={!!referralSourceCode}
                      name='referral-radio-group'
                      value={showReferralField}
                      onChange={handleShowReferralField}
                    />
                  </AdasBox>
                  {showReferralField && (
                    <AdasFormControl fullWidth>
                      <AdasInputLabel id='referral_label'>Referral Source</AdasInputLabel>
                      <AdasSelect
                        disabled={!!referralSourceCode}
                        labelId='referral_label'
                        id='referral_label'
                        name='referral_source_code'
                        label='Referral Source'
                        value={formInputValues.referral_source_code}
                        onChange={autocompleteHandler}
                        options={referralSourceList}
                        sx={{
                          width: '100%',
                          textAlign: 'left'
                        }}
                      />
                    </AdasFormControl>
                  )}
                  <AdasBox sx={{ margin: '1.25rem 1rem' }} key='radio-box'>
                    <AdasFormControl fullWidth>
                      <AdasFormLabel align='left' id='work-type-label' sx={{ textAlign: 'left', alignContent: 'left' }}>
                        Type
                      </AdasFormLabel>
                      <AdasRadioGroup
                        row
                        options={[
                          { label: 'Standard', value: 'STANDARD' },
                          { label: 'Service Only', value: 'SERVICE_ONLY' }
                        ]}
                        name='serviceType'
                        value={formInputValues.serviceType}
                        onChange={changeHandler}
                      >
                        <AdasFormControlLabel value='standard' control={<AdasRadio />} label='Standard' />
                        <AdasFormControlLabel value='serviceOnly' control={<AdasRadio />} label='Service Only' />
                      </AdasRadioGroup>
                    </AdasFormControl>
                  </AdasBox>
                  <AdasFormLabel
                    key='s1'
                    // align="left"
                    sx={{ textAlign: 'left', marginLeft: '.5rem' }}
                    margin='4px'
                  >
                    Vehicle Details
                  </AdasFormLabel>
                  <VinInput
                    key='vinInput'
                    updateVehicleInfo={updateVehicleDetails}
                    prepopulateVin={prePopulateVin}
                    vinExistCheck={true}
                    setIsVinServiceDown={setIsVinServiceDown}
                  />
                </>
              ]
            : null}
          {!isVinServiceDown && (
            <>
              {displayDetails ? (
                <VehicleInfoDetails data={vehicleData} openDraw={openSafetySystem} disabled={disableEdit} />
              ) : (
                <CarImageHolder />
              )}
            </>
          )}
          {isNewWorkOrder && isVinServiceDown && (
            <>
              <AdasBox sx={{ ml: '1rem', mt: '0.5rem' }}>
                <AdasFormLabel key='s1' sx={{ fontWeight: '400', color: '#000000', fontStyle: 'italic' }}>
                  If VIN results in an error message, select Manually Enter Vehicle Details below
                </AdasFormLabel>
              </AdasBox>
              <VehicleInfoManuallPage
                formInputValues={formInputValues}
                setFormInputValues={setFormInputValues}
                handleVehicleManuallChange={handleVehicleManuallChange}
                manualOverRideVin={manualOverRideVin}
                setManualOverRideVin={setManualOverRideVin}
              />
            </>
          )}
        </AdasBox>
        <AdasBox className={classes.submit}>
          {isNewWorkOrder
            ? [
                <AdasButton
                  key='1'
                  sx={{ marginRight: '20px' }}
                  variant='outlined'
                  aria-label='Cancel'
                  color='primary'
                  onClick={() => history.goBack()}
                >
                  CANCEL
                </AdasButton>,
                <AdasButton
                  disabled={!isEnabled}
                  key='2'
                  variant='contained'
                  aria-label='Create Work Order'
                  color='primary'
                  isDebounceEnabled={true}
                  onClick={submitHandler}
                >
                  CREATE WORK ORDER
                </AdasButton>
              ]
            : [
                <AdasButton
                  key='1'
                  sx={{ float: 'left' }}
                  variant='outlined'
                  aria-label='Cancel'
                  color='primary'
                  onClick={() => history.goBack()}
                >
                  Cancel
                </AdasButton>,
                <AdasButton
                  key='2'
                  sx={{ float: 'right' }}
                  variant='contained'
                  aria-label='Cancel'
                  color='primary'
                  isDebounceEnabled={true}
                  onClick={updateVehicleInfo}
                  disabled={disableEdit || !isEnabled}
                >
                  Update
                </AdasButton>
              ]}
        </AdasBox>
        <AdasBox>
          <CaradasSnackbar
            open={openSnackBar}
            message={GENERIC_MESSAGES.SAFETY_SYSTEMS_UPDATED}
            onClose={setOpenSnackBar}
            onUndo={undoSafetySystemHandler}
          />
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}

VehicleInfoPage.propTypes = {
  title: PropTypes.string,
  fromInvoice: PropTypes.bool,
  fromQuote: PropTypes.bool,
  isNewWorkOrder: PropTypes.bool
}
