import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AdasBox, AdasSelect } from '@components/wrapper-components'

import { StatusEnum, StatusColorConfig } from '../../enums/enums'

export const SupportStatusInfo = ({ value = '', sx, type = 'EDIT', disabled = false, onChange }) => {
  const displayColor = StatusColorConfig[value.toLocaleUpperCase()]

  const statusOptions = useMemo(() => {
    let options = [StatusEnum.OPEN, StatusEnum.CLOSED, StatusEnum.NEW]

    if (value?.toLocaleUpperCase() === 'OPEN' || value?.toLocaleUpperCase() === 'CLOSED') {
      options = options.filter((status) => status !== StatusEnum.NEW)
    }

    return options
  }, [value])

  const defaultStyle = {
    backgroundColor: displayColor,
    borderRadius: '4px',
    padding: '0',
    fontSize: '14px',
    height: '24px'
  }

  return (
    <>
      {type === 'EDIT' ? (
        <AdasSelect
          sx={{ ...sx, ...defaultStyle }}
          value={StatusEnum[value] || value}
          name='support_status'
          disabled={disabled}
          options={statusOptions}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <AdasBox sx={{ ...sx, ...defaultStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {StatusEnum[value] || value}
        </AdasBox>
      )}
    </>
  )
}

SupportStatusInfo.propTypes = {
  value: PropTypes.string,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['VIEW', 'EDIT'])
}
