import { Modal } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasModal = ({ open, onClose, children, sx = {}, ...rest }) => {
  return (
    <Modal sx={sx} open={open} onClose={onClose} {...rest}>
      {children}
    </Modal>
  )
}

AdasModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object
}
