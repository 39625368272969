import createStore from 'zustand'
import persist from '../utils/persist'

export const useAvailabilityStore = createStore(
  persist(
    {
      key: 'currentAvailability' // Key for persistence
    },
    (set) => ({
      availabilityDetail: {}, // Initial state for availability details

      // Method to set new availability details
      setAvailability: (newAvailability) => {
        // Ensure the structure of the input is maintained
        console.log('Setting new availability: ', newAvailability)
        set(() => ({
          availabilityDetail: Array.isArray(newAvailability)
            ? [...newAvailability] // Set array of availability data
            : { ...newAvailability } // Handle other types if needed
        }))
      }
    })
  )
)
