import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PropTypes from 'prop-types'
import { AdasBox, AdasButton, AdasModal, AdasPaper, AdasTypography } from '@components/wrapper-components'

export const SuccessModal = ({ open, requestNumber, handleClose }) => {
  return (
    <AdasModal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <AdasPaper
        variant='outlined'
        sx={{
          position: 'relative',
          padding: '20px',
          minHeight: '200px',
          minWidth: '450px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '3%'
        }}
      >
        <CheckCircleOutlineIcon
          sx={{
            color: '#09AF00',
            alignSelf: 'center',
            fontSize: '25px'
          }}
        />
        <AdasTypography
          variant='body1'
          sx={{
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '20px',
            letterSpacing: '0.15000000596046448px',
            textAlign: 'center',
            marginTop: '10px',
            color: '#09AF00'
          }}
        >
          <strong>Request #{requestNumber} submitted</strong>
        </AdasTypography>
        <AdasTypography
          variant='body1'
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '0.15000000596046448px',
            textAlign: 'center',
            marginTop: '20px'
          }}
        >
          Please ensure all documents are attached to the <br />
          <strong>Quote/WO/Invoice </strong> for a quicker resolution.
        </AdasTypography>
        <AdasBox sx={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
          <AdasButton onClick={handleClose} variant='contained'>
            CLOSE
          </AdasButton>
        </AdasBox>
      </AdasPaper>
    </AdasModal>
  )
}

SuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}
