import { useRef } from 'react'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Logger } from 'logger'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { AdasBox, AdasCard, AdasTypography, AdasTextField, AdasButton } from '@components/wrapper-components'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import theme from 'theme/theme'
import { useCenterStore, useOrganisationStore } from '@caradasstore'
import { getEmbeddedCode } from 'api/api'
import { toPng } from 'html-to-image'
import QRCode from 'qrcode.react'
const BookingLink = () => {
  const organisationId = useOrganisationStore((store) => store.organisationId)
  const organisationDetails = useOrganisationStore((state) => state.organisationDetail)
  const ccId = useCenterStore((store) => store.ccId)
  const [embeddedCode, setEmbeddedCode] = useState()
  const [directLink, setDirectLink] = useState()
  const fetchEmbeddedCode = async () => {
    const embResponse = await getEmbeddedCode({ ccId, organisationId })
    if (embResponse?.status === 200) {
      setEmbeddedCode(embResponse.data)
    } else {
      Logger.error({
        message: 'failed to fetch embedded data',
        payload: { file: 'bookingLink', method: 'getEmbeddedCode' }
      })
    }
  }

  useEffect(() => {
    if (ccId && organisationId) {
      setDirectLink(organisationDetails?.direct_link || 'https://www.schedule.caradasplus')
      fetchEmbeddedCode(ccId, organisationId)
    }
  }, [ccId, organisationId])

  const qrCodeRef = useRef(null)
  const handleDownloadImage = () => {
    if (qrCodeRef.current === null) {
      return
    }

    toPng(qrCodeRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = 'qrcode.png'
        link.click()
      })
      .catch((err) => {
        console.error('Could not generate image', err)
      })
  }
  const handleCopyImage = () => {
    if (qrCodeRef.current === null) {
      return
    }

    toPng(qrCodeRef.current)
      .then((dataUrl) => {
        fetch(dataUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const item = new ClipboardItem({ 'image/png': blob })
            navigator.clipboard.write([item])
          })
      })
      .catch((err) => {
        console.error('Could not copy image', err)
      })
  }

  return (
    <AdasCard
      sx={{
        minHeight: '90vh'
      }}
      className='adas-card'
    >
      <AdasBox mb={4}>
        <AdasTypography variant='body2' mb={2} color={theme.typography.territoryColor}>
          Direct Link
        </AdasTypography>
        <AdasTextField
          fullWidth
          value={directLink}
          InputProps={{
            readOnly: true
          }}
          variant='outlined'
        />
        <CopyToClipboard text={directLink}>
          <AdasButton startIcon={<ContentCopyIcon />} sx={{ marginTop: 1, float: 'right' }}>
            Copy Link
          </AdasButton>
        </CopyToClipboard>
      </AdasBox>

      <AdasBox mt={6}>
        <AdasTypography variant='body2' mb={2} color={theme.typography.territoryColor}>
          QR Code
        </AdasTypography>

        <AdasBox ref={qrCodeRef} mt={1} padding={2} width='fit-content' sx={{ border: '1px solid #0000000f', borderRadius: 4 }}>
          <QRCode value={directLink} size={128} />
        </AdasBox>

        <AdasBox sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2}>
          <AdasButton startIcon={<ContentCopyIcon />} onClick={handleCopyImage}>
            Copy Image
          </AdasButton>
          <AdasButton startIcon={<FileDownloadOutlinedIcon />} component='a' onClick={handleDownloadImage}>
            Download Image
          </AdasButton>
        </AdasBox>
      </AdasBox>
      <AdasBox mt={6}>
        <AdasTypography variant='body2' mb={2} color={theme.typography.territoryColor}>
          Embed Your Client
        </AdasTypography>
        <AdasTextField
          fullWidth
          multiline
          minRows={5}
          value={embeddedCode}
          InputProps={{
            readOnly: true
          }}
          variant='outlined'
        />
        <AdasTypography mb={2} fontSize='small' color={theme.typography.shaded} style={{ marginTop: 2, marginLeft: 2 }}>
          Copy code above to embed Scheduler in your website
        </AdasTypography>
        <CopyToClipboard text={embeddedCode}>
          <AdasButton startIcon={<ContentCopyIcon />} sx={{ float: 'right' }}>
            Copy Code
          </AdasButton>
        </CopyToClipboard>
      </AdasBox>
    </AdasCard>
  )
}

BookingLink.propTypes = {
  directLink: PropTypes.string.isRequired,
  qrCodeSrc: PropTypes.string.isRequired,
  embedCode: PropTypes.string.isRequired
}

export default BookingLink
