import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import PropTypes from 'prop-types'

export const AdasLocalizationProvider = ({ children, adapterLocale = 'en-us' }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
      {children}
    </LocalizationProvider>
  )
}

AdasLocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  adapterLocale: PropTypes.string
}
