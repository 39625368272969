import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { useUserStore } from '@caradasstore/UserStore'
import { ItemPerPageSelector } from '@components/common'
import { EmailForm } from '@components/email'
import { QueueItem } from '@components/queue-item'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCheckbox,
  AdasContainer,
  AdasFormControlLabel,
  AdasPagination,
  AdasPaper,
  AdasStack,
  AdasTextField,
  AdasImage
} from '@components/wrapper-components'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SearchIcon from '@mui/icons-material/Search'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  closeInvoiceManual,
  getFinanciallyConnected,
  getInvoiceCsvByDateRange,
  getInvoiceEmails,
  getInvoicePdf,
  getInvoicelist,
  sendToQuickBook
} from '../../../api/api'
import QuickBookEnabled from '../../../assets/images/quickbook-enabled.svg'
import { BUTTONS_DISPLAY_LABEL, DATE_FORMATS, GENERIC_MESSAGES, getInvoiceSummaryUrl } from '../../../constants'
import { scrollPageTop, useScrollTop } from '../../../utils/useScrollTop'
import { formatDate, isTech } from '../../../utils/utils'
import { Logger } from '../../../logger'
import { CircularProgress } from '@mui/material'
import { StatusEnum, WorkFlowTypesEnum } from 'enums/enums'
import { DatePickerModal } from '@components/common/modal'
import './InvoiceQueuePage.css'

export const InvoiceQueuePage = () => {
  useScrollTop()
  const [invoiceList, setInvoiceList] = useState([])
  const [selectedInvoices, setSelectedInvoices] = useState({})
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const statusFilter = queryParams.get('status') || 'OPEN'
  const [pageParams, setPageParams] = useState({
    pageNo: 0,
    totalPages: 0,
    search: '',
    pageSize: 25,
    status: statusFilter
  })
  const [invoiceClosedTrigger, setInvoiceClosedTrigger] = useState(0)
  const [isEmail, setIsEmail] = useState(false)
  const [emailContacts, setEmailContacts] = useState({})
  const [invoicePdf, setInvoicePdf] = useState()
  const [isConnected, setIsConnected] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [pdfLoader, setPdfLoader] = useState(false)
  const [openDatePickerModal, setOpenDatePickerModal] = useState(false)
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const ccId = useCenterStore((store) => store.ccId)
  const user = useUserStore((store) => store.currentUser)

  const history = useHistory()
  const redirectToInvoiceSummary = useCallback(
    (data) => {
      history.push(getInvoiceSummaryUrl(data?.reference_number), { status: pageParams.status })
    },
    [history, pageParams.status]
  )

  const canCloseInvoice = useMemo(() => {
    if (isTech(user)) return false
    const keys = Object.keys(selectedInvoices)
    if (keys.length > 0) {
      const selectedAndClosed = invoiceList.filter((invoice) => {
        return keys.includes(invoice.id + '') && StatusEnum[invoice.status] === StatusEnum.CLOSED
      })
      return selectedAndClosed.length === 0
    }

    return false
  }, [selectedInvoices, user, invoiceList])

  const toggleHandler = useCallback(
    (e) => {
      history.replace({
        pathname: window.location.pathname,
        search: `?status=${e.target.value}`
      })

      setPageParams((prev) => ({
        ...prev,
        status: e.target.value,
        pageNo: 0 // Reset to first page on filter change
      }))
    },
    [history]
  )

  const paginationHandler = useCallback((event, page) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageNo: page - 1
      }
      return updated
    })
  }, [])

  const pageSizeHandler = useCallback((event) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageSize: event.target.value,
        pageNo: 0
      }
      return updated
    })
  }, [])

  const sendQuickBookHandler = useCallback(async () => {
    const invoiceIds = Object.keys(selectedInvoices)
    setLoading(true)
    const quickbookResponse = await sendToQuickBook({
      invoiceIds
    })
    setLoading(false)
    if (quickbookResponse?.status === 200) {
      setInvoiceClosedTrigger(invoiceClosedTrigger + 1)
      setSelectedInvoices({})
    }
  }, [invoiceClosedTrigger, selectedInvoices, setLoading])

  useEffect(() => {
    setSelectedInvoices({})
    const fetchInvoiceList = async () => {
      setLoading(true)
      try {
        const response = await getInvoicelist({
          pageNo: pageParams.pageNo,
          pageSize: pageParams.pageSize,
          status: pageParams.status,
          ccid: ccId,
          search: pageParams.search,
          sortBy: pageParams.status === 'CLOSED' ? 'closedAt' : 'createdAt'
        })
        setLoading(false)
        if (response.status === 200) {
          setInvoiceList(response.data.invoices)
          setPageParams((prev) => ({
            ...prev,
            totalPages: response.data.totalPages
          }))
          scrollPageTop()
        }
      } catch (error) {
        Logger.error({ message: error, payload: { pageParams, ccId } })
      } finally {
        setLoading(false)
      }
    }
    fetchInvoiceList()
  }, [ccId, pageParams.pageNo, pageParams.pageSize, pageParams.status, pageParams.search, setLoading, invoiceClosedTrigger])

  useEffect(() => {
    const getIsFinanciallyConnected = async () => {
      const response = await getFinanciallyConnected({
        ccid: centerDetail.id
      })
      if (response.status === 200) {
        setIsConnected(response.data)
      }
    }
    getIsFinanciallyConnected()
  }, [centerDetail.id])

  const emailInvoiceHandler = useCallback(async () => {
    const invoiceId = Object.keys(selectedInvoices)[0]
    const params = {
      invoiceId
    }
    try {
      const emailContactResponse = await getInvoiceEmails(params)
      if (emailContactResponse.status === 200) {
        setIsEmail(true)
        setEmailContacts(emailContactResponse.data)
      }
      const invoicePdfResponse = await getInvoicePdf({
        invoiceId
      })
      if (invoicePdfResponse?.status === 200) {
        const fileValue = new File([invoicePdfResponse.data], `invoice_${selectedInvoices[invoiceId]}.pdf`)
        fileValue.id = invoiceId
        setInvoicePdf(fileValue)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { InvoiceId: invoiceId, path: 'InvoicePage/emailInvoiceHandler' } })
    }
  }, [selectedInvoices, setLoading])

  const viewPdfHandler = useCallback(async (invoices) => {
    Object.keys(invoices).map(async (invoiceId) => {
      try {
        setPdfLoader(true)
        const invoicePdfResponse = await getInvoicePdf({
          invoiceId: invoiceId
        })
        if (invoicePdfResponse.status === 200) {
          const fileValue = new Blob([invoicePdfResponse.data], { type: 'application/pdf' }, `invoice_${selectedInvoices[invoiceId]}.pdf`)
          fileValue.id = invoiceId
          const fileURL = URL.createObjectURL(fileValue)
          const pdfWindow = window.open()
          if (pdfWindow === null) {
            alert('Pop-up was blocked by your browser! Please allow pop-ups for caradasplus.com to view Invoice.')
          } else {
            pdfWindow.location.href = fileURL
          }
        }
      } catch (error) {
        Logger.error({ message: error, payload: { invoiceId, path: 'InvoiceQueuePage/viewPdfHandler' } })
      } finally {
        setPdfLoader(false)
      }
    })
  }, [])

  const checkboxHandler = useCallback((e, data) => {
    if (e.target.checked === true) {
      setSelectedInvoices((prev) => {
        const updated = {
          ...prev,
          [data.id]: data.reference_number
        }
        return updated
      })
    } else {
      setSelectedInvoices((prev) => {
        // eslint-disable-next-line no-unused-vars
        const { [data.id]: removedId, ...updated } = prev
        return updated
      })
    }
  }, [])

  const selectAll = useCallback((e, data) => {
    if (e.target.checked === true) {
      const selectedIn = {}
      data.forEach((invoice) => {
        selectedIn[invoice.id] = invoice.reference_number
      })
      setSelectedInvoices(selectedIn)
    } else {
      setSelectedInvoices(() => {
        const updated = {}
        return updated
      })
    }
  }, [])

  const downloadCsvByDate = async (startDate, endDate) => {
    const status = pageParams.status === 'OPEN,CLOSED' ? '' : pageParams.status
    const csvResponse = await getInvoiceCsvByDateRange({
      startDate,
      endDate,
      ccId,
      status
    })

    if (csvResponse.status === 200) {
      const fileName = `caradasplus-invoices-export-${formatDate(startDate, DATE_FORMATS.ISO_DATE_STRING)}-to-${formatDate(endDate, DATE_FORMATS.ISO_DATE_STRING)}.csv`
      const fileValue = new Blob([csvResponse.data], { type: 'application/csv' }, fileName)
      const fileURL = URL.createObjectURL(fileValue)
      const tempLink = document.createElement('a')
      tempLink.href = fileURL
      tempLink.setAttribute('download', fileName)
      tempLink.click()
      setOpenDatePickerModal(false)
    }
  }

  const searchInputHandler = useCallback((e) => {
    setSearchInput(e.target.value)
  }, [])

  useEffect(() => {
    const performSearch = () => {
      setPageParams((prev) => {
        const updated = {
          ...prev,
          search: searchInput
        }
        return updated
      })
    }

    // Set a delay to perform the search 500ms after typing stops
    const timeoutId = setTimeout(performSearch, 1000)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const closeInvoiceHandler = useCallback(async () => {
    const invoiceIds = Object.keys(selectedInvoices)
    const closeInvoicesReponse = await closeInvoiceManual({
      invoiceIds
    })
    if (closeInvoicesReponse.status === 200) {
      setInvoiceClosedTrigger(invoiceClosedTrigger + 1)
      setSelectedInvoices({})
    }
  }, [invoiceClosedTrigger, selectedInvoices])

  return (
    <AdasContainer maxWidth='lg'>
      <EmailForm
        invoicePdf={invoicePdf}
        isOpen={isEmail}
        contacts={emailContacts}
        emailData={{ id: Object.keys(selectedInvoices)[0] }}
        onClose={() => setIsEmail(false)}
      />
      <AdasBox
        sx={{
          overflow: 'auto',
          fontSize: '14px',
          margin: '10px 0px',
          paddingTop: '6px'
        }}
      >
        <span className='AdasButtonGroupParent'>
          <AdasButtonGroup className='AdasButtonGroup' variant='contained' aria-label='outlined primary button group'>
            <AdasTextField
              sx={{
                width: '100%',
                borderRadius: '0px',
                backgroundColor: 'white'
              }}
              onChange={searchInputHandler}
              id='search'
              name='search'
              label='Search'
              variant='outlined'
              type={'text'}
              value={searchInput}
              fullWidth={true}
              size='small'
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
                sx: {
                  borderRadius: '0'
                },
                placeholder: 'Filter Invoices'
              }}
            />
          </AdasButtonGroup>
        </span>
        <span style={{ float: 'right' }}>
          <AdasBox>
            <AdasButtonGroup>
              <AdasButton
                value='OPEN,CLOSED'
                onClick={toggleHandler}
                variant={pageParams.status === 'OPEN,CLOSED' ? 'contained' : 'outlined'}
              >
                ALL
              </AdasButton>
              <AdasButton value='OPEN' onClick={toggleHandler} variant={pageParams.status === 'OPEN' ? 'contained' : 'outlined'}>
                OPEN
              </AdasButton>
              <AdasButton value='CLOSED' onClick={toggleHandler} variant={pageParams.status === 'CLOSED' ? 'contained' : 'outlined'}>
                CLOSED
              </AdasButton>
            </AdasButtonGroup>
          </AdasBox>
        </span>
      </AdasBox>
      <AdasBox>
        <AdasPaper
          elevation={0}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            padding: '.75rem',
            overflow: 'auto',
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', md: 'center' }
          }}
        >
          <AdasBox
            sx={{
              display: 'flex',
              marginLeft: '1.25rem',
              marginBottom: { xs: '10px', md: '0' }
            }}
          >
            {pageParams.status === 'OPEN' && (
              <AdasFormControlLabel
                control={
                  <AdasCheckbox
                    checked={Object.keys(selectedInvoices).length > 0}
                    onChange={(e) =>
                      selectAll(
                        e,
                        invoiceList.map((invoice) => {
                          return { id: invoice.id, reference_number: invoice.reference_number }
                        })
                      )
                    }
                    name='radio-buttons'
                  />
                }
                label={`${Object.keys(selectedInvoices).length} selected`}
              />
            )}
          </AdasBox>
          <AdasBox
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
              whiteSpace: 'nowrap',
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              gap: { xs: '10px', md: '0' }
            }}
          >
            {isConnected ? (
              <AdasButton
                sx={{ marginRight: { xs: '0', md: '5px' }, display: 'inline-flex', height: '100%' }}
                variant='outlined'
                disabled={!canCloseInvoice}
                isDebounceEnabled={true}
                startIcon={<AdasImage src={QuickBookEnabled} alt='quickbook' />}
                onClick={sendQuickBookHandler}
              >
                SEND TO QBO
              </AdasButton>
            ) : (
              <AdasButton
                sx={{ marginRight: { xs: '0', md: '5px' }, display: 'inline-flex', height: '100%' }}
                variant='outlined'
                disabled={!canCloseInvoice}
                isDebounceEnabled={true}
                startIcon={<TableChartOutlinedIcon />}
                onClick={closeInvoiceHandler}
              >
                CLOSE INVOICE
              </AdasButton>
            )}
            <AdasButton
              sx={{ marginRight: { xs: '0', md: '5px' }, display: 'inline-flex', height: '100%' }}
              variant='outlined'
              disabled={!(Object.keys(selectedInvoices).length === 1)}
              isDebounceEnabled={true}
              startIcon={pdfLoader ? <CircularProgress color='inherit' size={20} /> : <DownloadOutlinedIcon />}
              onClick={() => viewPdfHandler(selectedInvoices)}
            >
              View PDF
            </AdasButton>
            <AdasButton
              sx={{ marginRight: { xs: '0', md: '30px' }, display: 'inline-flex', height: '100%' }}
              variant='outlined'
              isDebounceEnabled={true}
              disabled={!(Object.keys(selectedInvoices).length === 1)}
              startIcon={<EmailOutlinedIcon />}
              onClick={emailInvoiceHandler}
            >
              Email
            </AdasButton>
            <AdasButton
              sx={{ marginLeft: { xs: '0', md: '30px' }, marginRight: { xs: '0', md: '5px' }, display: 'inline-flex', height: '100%' }}
              variant='outlined'
              disabled={Object.keys(selectedInvoices).length > 0}
              startIcon={<TableChartOutlinedIcon />}
              onClick={() => setOpenDatePickerModal(true)}
            >
              EXPORT CSV
            </AdasButton>
          </AdasBox>
        </AdasPaper>
      </AdasBox>
      <AdasBox>
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '.5rem' }}>
          <ul>
            {invoiceList.length > 0 &&
              invoiceList.map((item) => {
                return (
                  <QueueItem
                    itemType={WorkFlowTypesEnum.INVOICE}
                    key={item.id}
                    item={item}
                    isOptionEnabled={true}
                    isSelected={!!selectedInvoices[item.id]}
                    onClickItem={redirectToInvoiceSummary}
                    radioChanged={(e) => checkboxHandler(e, item)}
                  />
                )
              })}
            {invoiceList.length === 0 && (
              <li>
                No results
                {pageParams.totalPages > 1 && ' on this page. Go to next page to search more invoices.'}
              </li>
            )}
          </ul>
        </AdasPaper>
      </AdasBox>
      <AdasPaper
        elevation={0}
        sx={{
          padding: '20px',
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', md: 'center' },
          gap: { xs: '10px', md: '0' }
        }}
      >
        <AdasBox
          sx={{
            width: { xs: '50%', md: '20%' },
            marginBottom: { xs: '10px', md: '0' }
          }}
        >
          <ItemPerPageSelector itemPerPageList={[25, 50, 100, 200]} pageSizeHandler={pageSizeHandler} pageSize={pageParams.pageSize} />
        </AdasBox>
        <AdasBox
          sx={{
            width: { xs: '100%', md: '80%' },
            display: 'flex',
            justifyContent: { xs: 'flex-start', md: 'flex-end' }
          }}
        >
          <AdasStack spacing={2}>
            <AdasPagination onChange={paginationHandler} page={pageParams.pageNo + 1} count={pageParams.totalPages} shape='rounded' />
          </AdasStack>
        </AdasBox>
      </AdasPaper>
      <DatePickerModal
        dateRangeLimitWarning={GENERIC_MESSAGES.EXPORT_INVOICE_CSV_DATE_RANGE_WARNING}
        title={GENERIC_MESSAGES.EXPORT_INVOICE_CSV}
        message={GENERIC_MESSAGES.INVOICE_EXTRACT}
        open={openDatePickerModal}
        handleClose={() => setOpenDatePickerModal(false)}
        confirmLabel={BUTTONS_DISPLAY_LABEL.CREATE}
        handleSubmit={downloadCsvByDate}
      />
    </AdasContainer>
  )
}
