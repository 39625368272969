import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'

import { SearchOrAddData } from '@components/common'
import { AdasPaper, AdasTable } from '@components/wrapper-components'
import { PRODUCT_LIST_HEADERS, SERVICE_LIST_HEADERS, TABLE_HEADER_STYLE } from '../../../constants'
import { useUserStore } from '../../../store'

export const ProductList = ({ products = [], productTypeName, onAddProduct, onSelectProduct }) => {
  const [searchInput, setSearchInput] = useState('')
  const [productsDisplayed, setProductsDisplayed] = useState(products)
  const [sort, setSort] = useState('ASC')
  const initialLoad = useRef(true)
  const user = useUserStore((store) => store.currentUser)
  const displayCategory = productTypeName === 'Calibrations'
  const columns = displayCategory ? PRODUCT_LIST_HEADERS : SERVICE_LIST_HEADERS

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value)
    setProductsDisplayed(() => {
      const list = products.filter((product) => product.name.toLowerCase().includes(e.target.value.toLowerCase()))
      if (sort === 'ASC') {
        return list
      } else {
        return reverseArray(list)
      }
    })
  }

  const clickSortHandler = (id) => {
    initialLoad.current = false
    if (id === 'name') setSort(sort === 'ASC' ? 'DESC' : 'ASC')
  }

  useEffect(() => {
    if (!initialLoad.current) {
      setProductsDisplayed((prevList) => {
        const resultList = reverseArray(prevList)
        return resultList
      })
    }
  }, [sort])

  useEffect(() => {
    setProductsDisplayed(products)
  }, [products])

  const isTechUser = useMemo(() => {
    if (user) {
      return !(user.is_admin || user.is_manager || user.is_owner)
    }
    return false
  }, [user])

  const reverseArray = (array) => {
    const result = array.map(array.pop, [...array])
    return result
  }

  return (
    <>
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        <SearchOrAddData
          variant={isTechUser ? 'withAddButtonDisabled' : 'withAddButton'}
          searchInput={searchInput}
          searchInputHandler={searchInputHandler}
          onAddClick={() => onAddProduct(true)}
        />
        {products.length > 0 && (
          <AdasTable
            containerProps={{ sx: { backgroundColor: '#ffff' } }}
            tableProps={{
              sx: {
                marginTop: '20px',
                '.MuiTableCell-root': { border: 'none', padding: '2px 10px' },
                overflowX: 'scroll'
              },
              'aria-label': 'products table'
            }}
            columns={columns}
            sortField='name'
            sortDirection={sort}
            onSort={clickSortHandler}
            headerStyle={TABLE_HEADER_STYLE}
            data={productsDisplayed}
            onSelect={onSelectProduct}
          />
        )}
      </AdasPaper>
    </>
  )
}

ProductList.propTypes = {
  products: PropTypes.array,
  productTypeName: PropTypes.string,
  onAddProduct: PropTypes.func,
  onSelectProduct: PropTypes.func.isRequired
}
