import { AdasBox, AdasCircularProgress } from '@components/wrapper-components'
import theme from '../../theme/theme'

const defaultStyles = {
  color: '#fff',
  backgroundColor: theme.palette.background.shaded,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const AdasLoader = ({ sx = {} }) => {
  return (
    <AdasBox
      sx={{
        ...defaultStyles,
        ...sx
      }}
    >
      <AdasCircularProgress color='inherit' />
    </AdasBox>
  )
}
