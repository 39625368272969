import { CaradasPlusLogoWrapper } from '@components/logo-wrapper'
import { PasswordValidation } from '@components/validation'
import {
  AdasBox,
  AdasButton,
  AdasContainer,
  AdasInputAdornment,
  AdasLink,
  AdasPaper,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useTheme } from '@mui/material'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { passwordResetAuth, passwordResetTokenValidation } from '../../api/api'

export const ResetPasswordForm = () => {
  const theme = useTheme()
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [newPassword, setNewPassword] = useState('')
  const queryObj = qs.parse(window.location.search)
  const token = queryObj ? queryObj.token : null
  const history = useHistory()
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState(true)

  const handleValidationChange = (isValid) => {
    setIsPasswordValid(isValid)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setNewPassword(value)
  }

  const submitHandler = (event) => {
    event.preventDefault()
    const params = {
      password: newPassword,
      token: token
    }
    const passwordResetResponse = passwordResetAuth(params)
    passwordResetResponse
      .then((response) => {
        if (response.status === 200) {
          setIsPasswordResetSuccess(() => true)
        } else {
          setIsPasswordResetSuccess(() => false)
        }
      })
      .catch(() => {
        setIsPasswordResetSuccess(() => false)
      })
  }

  useEffect(() => {
    const params = {
      token: token
    }
    const validTokenResponse = passwordResetTokenValidation(params)
    validTokenResponse.then((response) => {
      if (response.status === 200) {
        setIsTokenValid(() => response.data)
      } else {
        setIsTokenValid(() => false)
      }
    })
  }, [isTokenValid, token])

  const login = () => {
    history.push('/authenticate')
  }

  return (
    <AdasBox
      sx={{
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
        bgcolor: `${theme.palette.background.lightGray}`,
        height: `${theme.palette.fullScreenHeight}`
      }}
    >
      <CaradasPlusLogoWrapper>
        <AdasContainer maxWidth='lg' sx={{ padding: '16px' }}>
          <AdasPaper elevation={0} sx={{ padding: '20px' }}>
            <AdasBox id='resetForm'>
              <AdasTypography variant='h5' paddingBottom='1rem'>
                Password Reset{' '}
              </AdasTypography>
              {!isTokenValid ? (
                <AdasBox sx={{ display: 'flex', flexDirection: 'column' }}>
                  <AdasBox>
                    <AdasTypography>
                      This password reset request is invalid.
                      <br /> <br />
                      Please return to the sign in page and select &quot;Forgot password?&quot; to generate a new request.
                    </AdasTypography>
                  </AdasBox>
                  <AdasLink
                    href='#'
                    onClick={login}
                    tabIndex={0}
                    underline='none'
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: theme.padding.smallPadding }}
                  >
                    <AdasTypography>Back to Sign In</AdasTypography>
                  </AdasLink>
                </AdasBox>
              ) : !isPasswordResetSuccess ? (
                <form>
                  <AdasTextField
                    placeholder='Password'
                    type={showPassword ? 'text' : 'password'}
                    id='password'
                    label='Password'
                    required
                    sx={{ width: '100%', paddingBottom: '1rem' }}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <AdasInputAdornment position='end'>
                          <AdasButton
                            buttonType='iconButton'
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(evt) => evt.preventDefault()}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </AdasButton>
                        </AdasInputAdornment>
                      )
                    }}
                  />
                  <PasswordValidation password={newPassword} onValidationChange={handleValidationChange} />
                  <AdasBox>
                    <AdasLink
                      href='#'
                      onClick={login}
                      variant='body2'
                      tabIndex={0}
                      underline='none'
                      sx={{
                        float: 'left',
                        display: 'flex',
                        paddingTop: '1rem'
                      }}
                    >
                      <AdasTypography>Back to Sign In</AdasTypography>
                    </AdasLink>
                  </AdasBox>
                  <AdasBox>
                    <AdasButton
                      variant='contained'
                      aria-label='login'
                      color='primary'
                      type='submit'
                      disabled={!isPasswordValid}
                      sx={{ width: '100%', marginTop: '1rem' }}
                      onClick={submitHandler}
                    >
                      Submit
                    </AdasButton>
                  </AdasBox>
                </form>
              ) : (
                <AdasBox sx={{ display: 'flex', flexDirection: 'column' }}>
                  <AdasBox>
                    <AdasTypography>Your new password has been set successfully </AdasTypography>
                  </AdasBox>
                  <AdasLink
                    href='#'
                    onClick={login}
                    tabIndex={0}
                    underline='none'
                    sx={{
                      float: 'left',
                      paddingTop: '1rem'
                    }}
                  >
                    <AdasTypography>Back to Sign In</AdasTypography>
                  </AdasLink>
                </AdasBox>
              )}
            </AdasBox>
          </AdasPaper>
        </AdasContainer>
      </CaradasPlusLogoWrapper>
    </AdasBox>
  )
}
