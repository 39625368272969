import { Tab, Tabs } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

export const AdasTabs = ({ tabs, sx = {}, value, onChange, ...props }) => {
  const theme = useTheme()
  const defaultFontWeight = sx.fontWeight || theme.typography.fontWeight500
  return (
    <Tabs sx={sx} value={value} onChange={onChange} {...props}>
      {tabs.map((tab, index) => (
        <Tab sx={{ fontWeight: defaultFontWeight, ...sx }} key={`${index}_tab`} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  )
}

AdasTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  sx: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func
}
