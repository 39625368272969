// messageModule.js
import createStore from 'zustand'

export const useMessageStore = createStore((set) => ({
  messages: [],
  nextMessageId: 0,
  addMessage: (newMessage) => {
    set((state) => ({
      messages: [...state.messages, { ...newMessage, id: state.nextMessageId }],
      nextMessageId: state.nextMessageId + 1
    }))
  },
  clearMessage: (messageId) => {
    set((state) => ({
      messages: state.messages.filter((message) => message.id !== messageId)
    }))
  },
  clearAllMessages: () => {
    set(() => ({ messages: [] }))
  }
}))
