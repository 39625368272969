import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { CustomItemForm, ItemList } from '@components/custom-item'
import { AdasBox, AdasButton, AdasDrawer, AdasTypography } from '@components/wrapper-components'
import { getCustomItemList } from '../../../api/api'
import { currencyFormatter } from '../../../utils/utils'
import { Logger } from '../../../logger'
import theme from 'theme/theme'
export const CustomItem = (props) => {
  const { existingCustomItemList = [], workOrderData, onSaveCustomItem, customItemTotal, disabled = false, type } = props
  const [customItemList, setCustomItemList] = useState([])
  const [isAddCustomItem, setIsAddCustomItem] = useState(false)
  const [existingCustomItem, setExistingCustomItem] = useState()
  useEffect(() => {
    setCustomItemList(existingCustomItemList)
  }, [existingCustomItemList])
  const addCustomItemHandler = () => {
    setExistingCustomItem()
    setIsAddCustomItem(true)
  }

  const onClickEditHandler = (props) => {
    setExistingCustomItem(props)
    setIsAddCustomItem(true)
  }

  const onSaveSuccessHandler = async () => {
    try {
      setIsAddCustomItem(false)
      const customItemListResponse = await getCustomItemList({
        type: type,
        id: workOrderData.id
      })

      if (customItemListResponse.status === 200) {
        onSaveCustomItem(customItemListResponse.data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { type, id: workOrderData.id } })
    }
  }
  return (
    <AdasBox sx={{ margin: '20px' }}>
      {isAddCustomItem && (
        <AdasDrawer anchor={'right'} open={isAddCustomItem} onClose={() => setIsAddCustomItem(false)}>
          <AdasBox className='drawer-title-container'>
            <AdasTypography variant='h6'>Custom Work Items & Credits</AdasTypography>
            <AdasButton
              buttonType='iconButton'
              sx={{
                padding: '2px',
                cursor: 'pointer',
                color: theme.palette.info.main
              }}
              onClick={() => setIsAddCustomItem(false)}
            >
              <CloseIcon fontSize='small' />
            </AdasButton>
          </AdasBox>
          <CustomItemForm
            type={type}
            cancel={() => setIsAddCustomItem(false)}
            isNew={!existingCustomItem}
            workOrderData={workOrderData}
            customItemData={existingCustomItem}
            onSaveSuccess={onSaveSuccessHandler}
          />
        </AdasDrawer>
      )}
      <AdasTypography component='span'>
        <AdasTypography component='b' fontWeight='bold'>
          Custom Charges & Discounts
        </AdasTypography>
      </AdasTypography>
      <AdasTypography component='span' sx={{ float: 'right' }}>
        <AdasTypography component='b' fontWeight='bold'>
          {currencyFormatter(customItemTotal)}
        </AdasTypography>
      </AdasTypography>
      <ItemList
        itemList={customItemList}
        onClickEdit={(props) => onClickEditHandler(props)}
        name='additional items'
        isCustomItem={true}
        disabled={disabled}
      />
      <AdasBox sx={{ textAlign: 'end' }}>
        <AdasButton startIcon={<AddIcon />} sx={{}} onClick={addCustomItemHandler} disabled={disabled}>
          Add New Item
        </AdasButton>
      </AdasBox>
    </AdasBox>
  )
}

CustomItem.propTypes = {
  type: PropTypes.string,
  existingCustomItemList: PropTypes.array,
  workOrderData: PropTypes.object,
  onSaveCustomItem: PropTypes.func,
  customItemTotal: PropTypes.number,
  disabled: PropTypes.bool
}
