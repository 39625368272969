import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useRef } from 'react'

import { AdasBox, AdasButton } from '@components/wrapper-components'
import { useMessageStore } from '../../store'
import { MIME_TYPES_MAP } from '../../variable-maps'

export const AddFileButton = ({ setAdditionalDocuments, disabled = false }) => {
  const theme = useTheme()
  const fileRef = useRef(null)
  const addMessage = useMessageStore((store) => store.addMessage)

  const handleUpload = () => {
    const file = fileRef.current.files[0]
    if (!file) {
      return
    }

    const suppoprtedFileTypes = Object.values(MIME_TYPES_MAP)
    if (!suppoprtedFileTypes.includes(file.type)) {
      addMessage({ text: 'File format not supported!' })
      return
    }

    setAdditionalDocuments((prev) => {
      prev.push(fileRef.current.files[0])
      const data = [...prev]
      return data
    })
  }

  return (
    <AdasBox
      sx={{
        flexDirection: 'column',
        height: '222px',
        width: '203px',
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: '4px',
        display: 'flex',
        margin: '16px auto'
      }}
    >
      {' '}
      <AdasButton
        component='label'
        sx={{
          textTransform: 'none',
          fontSize: 12,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
        disabled={disabled}
        startIcon={<FileUploadOutlinedIcon sx={{ width: '30px', height: '30px' }} />}
      >
        <AdasBox sx={{ fontSize: '12px', textAlign: 'center', marginTop: '16px' }}>UPLOAD ADDITIONAL DOCUMENT</AdasBox>
        <AdasBox
          sx={{
            fontSize: '10px',
            color: theme.palette.text.secondary,
            textAlign: 'center',
            marginTop: '10px'
          }}
        >
          (.pdf, .docx, .doc, .png, .jpeg, .txt)
        </AdasBox>
        <input ref={fileRef} type='file' hidden onChange={handleUpload} accept='.jpg, .jpeg, .png, .pdf, .doc, .docx, .txt' />
      </AdasButton>
    </AdasBox>
  )
}

AddFileButton.propTypes = {
  setAdditionalDocuments: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
