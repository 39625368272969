import { Popover } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasPopover = ({ anchorEl, anchorOrigin, transformOrigin, open, onClose, ...props }) => {
  return (
    <Popover anchorEl={anchorEl} open={open} onClose={onClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} {...props} />
  )
}

AdasPopover.propTypes = {
  anchorEl: PropTypes.object,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}
