import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import React from 'react'

const defaultStyles = {
  variant: 'elevation'
}

// eslint-disable-next-line react/display-name
export const AdasPaper = React.forwardRef(({ children, sx = {}, variant = defaultStyles.variant, ...rest }, ref) => {
  return (
    <Paper
      ref={ref}
      sx={{ ...defaultStyles.sx, ...sx }} // Merge default styles with incoming sx; user-defined styles will override
      variant={variant}
      {...rest}
    >
      {children}
    </Paper>
  )
})

AdasPaper.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['elevation', 'outlined'])
}
