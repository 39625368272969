import createStore from 'zustand'

export const useLoadingStore = createStore((set) => ({
  loading: false,
  setLoading: (isLoading) => {
    set(() => ({
      loading: isLoading
    }))
  }
}))
