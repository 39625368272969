import { AuthForm } from '@components/auth'
import { homePageStyles } from '../home-page-styles'
export const AuthPage = () => {
  return (
    <>
      {homePageStyles}
      <AuthForm />
    </>
  )
}
