import { GlobalStyles } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasGlobalStyles = ({ styles, ...props }) => {
  return <GlobalStyles styles={styles} {...props} />
}

AdasGlobalStyles.propTypes = {
  styles: PropTypes.object.isRequired
}
