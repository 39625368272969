import { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'

import { useCenterStore } from '@caradasstore/CenterStore'
import { useMessageStore } from '@caradasstore/MessageStore'
import { VinScannerModal } from '@components/vin'
import { AdasBox, AdasButton, AdasDivider, AdasInputAdornment, AdasTextField } from '@components/wrapper-components'
import { requestVehicleInfo, requestVinDataForQuotesWOInvoicesByVin } from '../../../api/api'
import { validations } from '../../../utils/utils'
import classes from '../shared/VehicleInfo.module.css'
import { ConfirmModal } from '@components/common'
import { BUTTONS_DISPLAY_LABEL, GENERIC_MESSAGES, REDIRECT_URLS } from '../../../constants'
import { useHistory } from 'react-router-dom'
import { StatusEnum } from 'enums/enums'

const VIN_ERRORS = [
  'Error: EXCEPTION: Cannot invoke "java.util.Collection.toArray()" because "c" is null',
  'Service unavailable:',
  'VIN decoding service was unable to find',
  'Unable to process response from service',
  'Invalid Data Exception:'
]

export const VinInput = (props) => {
  const {
    updateVehicleInfo,
    updateVINSearchList,
    shouldFetchOnlyVinList = false,
    prepopulateVin,
    isScan = true,
    vinExistCheck = false,
    setIsVinServiceDown
  } = props

  const history = useHistory()
  const clearAllMessages = useMessageStore((store) => store.clearAllMessages)
  const addMessage = useMessageStore((store) => store.addMessage)
  const ccId = useCenterStore((store) => store.ccId)
  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState({ vin: true })
  const [touched, setTouched] = useState({ vin: false })
  const [loading, setLoading] = useState(false)
  const [showDuplicateWarning, setShowDuplicateWarning] = useState(false)
  const [formInputValues, setFormInputValues] = useState({
    vin: prepopulateVin || ''
  })

  useEffect(() => {
    if (prepopulateVin) {
      populateVehicleInfo(prepopulateVin)
    }
  }, [prepopulateVin])

  const vinManualInputHandler = (e) => {
    const value = e.target.value
    setFormInputValues((prev) => ({
      ...prev,
      vin: value
    }))

    // Validate VIN and set touched state
    setValid({ vin: validations.vin(value) || shouldFetchOnlyVinList })
    setTouched({ vin: true })

    // Clear vehicle info if VIN is cleared
    if (value.length === 0 && !shouldFetchOnlyVinList) {
      //fetch only vin list then no need to update vehicle info
      updateVehicleInfo({ vin: '', data: {} }) // Call to clear vehicle fields
    }
  }

  const manualRef = useRef()
  const vinRef = useRef()

  const vinClickHandler = () => {
    setOpen(true)
  }

  const closeModalFunc = () => {
    setOpen(false)
    manualRef.current.focus()
  }

  const vinSuccessHandler = (props) => {
    updateVehicleInfo(props)
    setFormInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        vin: props.vin
      }
      return updatedValues
    })
    setValid({ vin: validations.vin(props.vin) })
    setOpen(false)
  }

  const populateVehicleInfo = (vin) => {
    if (shouldFetchOnlyVinList) {
      setFormInputValues((prev) => {
        const updated = {
          ...prev,
          vin
        }
        return updated
      })

      setValid({ vin: validations.vin(vin) })
      fetchVinList(vin)
      return
    } else {
      setLoading(true)
      const vehicleInfoRequest = requestVehicleInfo({
        vin: vin
      })
      vehicleInfoRequest
        .then((response) => {
          if (response && response.status === 200) {
            if (vinExistCheck) {
              fetchVinList(vin)
            }
            const { color_ext, doors, engine, make, model, vehicle_type, year, trim, image_url, safetySystems, fuel_capacity_gal } =
              response.data

            const vehicle = {
              year: year,
              make: make,
              model: model,
              color: color_ext,
              type: vehicle_type,
              engine: engine,
              doors: doors,
              trim: trim,
              imageUrl: image_url,
              safetySystems: safetySystems,
              fuel: fuel_capacity_gal,
              vin
            }

            vinSuccessHandler({
              data: vehicle,
              vin
            })
            setIsVinServiceDown && setIsVinServiceDown(false)
          } else {
            setIsVinServiceDown && setIsVinServiceDown(true)
          }
        })
        .catch((err) => {
          clearAllMessages()
          setValid({ vin: false })
          setTouched({ vin: true })
          console.log('ERROR ', err.message)
          const errorMessage = extractErrorMessagefromError(err)
          if (setIsVinServiceDown) {
            const hasError = VIN_ERRORS?.some((pattern) => VIN_ERRORS?.includes(pattern))
            if (hasError) {
              setIsVinServiceDown(true)
              updateVehicleInfo({ vin: vin, data: {} })
            }
          }
          addMessage({
            text: errorMessage
          })
        })
        .finally(() => setLoading(false))
    }
  }
  const extractMessage = (text) => {
    if (typeof text !== 'string' || !text.trim()) {
      return null
    }

    const messageMatch = text.match(/"message":"(.*?)"/)

    if (messageMatch && messageMatch[1]) {
      return messageMatch[1].replace('Bad arguments sent: ', '')
    }

    return null
  }

  const extractErrorMessagefromError = (error) => {
    const errorMessage =
      error.code === 'ERR_NETWORK'
        ? 'Network Error: Trouble connecting to our servers. Check your internet connection and try again.'
        : `Error: ${error?.response?.data?.message || error?.response?.data || error.message}`
    const extractError = extractMessage(errorMessage)
    return extractError ? errorMessage : errorMessage
  }

  const fetchVinList = (vin) => {
    setLoading(true)
    const vehicleInfoRequest = requestVinDataForQuotesWOInvoicesByVin({
      vin: vin,
      ccid: ccId
    })
    vehicleInfoRequest
      .then((response) => {
        if (response && response.status === 200) {
          if (!shouldFetchOnlyVinList && vinExistCheck) {
            if (response?.data?.length) {
              const openRecords = response.data.some((data) => data.status === StatusEnum.OPEN.toLocaleUpperCase())
              if (openRecords) {
                setShowDuplicateWarning(true)
              }
            }
          } else {
            updateVINSearchList({
              vin: vin,
              vinList: response.data
            })
          }
        }
      })
      .catch((err) => {
        console.log('ERROR ', err.message)
      })
      .finally(() => setLoading(false))
  }

  const handleCloseWarning = useCallback(() => {
    setShowDuplicateWarning(false)
  }, [setShowDuplicateWarning])

  const handleContinueWarning = useCallback(() => {
    history.push(REDIRECT_URLS.VIN_SEARCH, { vin: formInputValues.vin })
  }, [history, formInputValues.vin])

  return (
    <AdasBox>
      <AdasTextField
        fullWidth
        key={'vin'}
        id='outlined-basic'
        sx={{
          paddingTop: '5px'
        }}
        label='VIN'
        variant='outlined'
        onChange={vinManualInputHandler}
        className={classes.formField}
        name='vin'
        value={formInputValues.vin}
        inputRef={manualRef}
        innerRef={vinRef}
        error={!valid.vin && touched.vin}
        inputProps={{
          maxLength: 17
        }}
        helperText={!valid.vin && touched.vin && formInputValues.vin.length === 17 ? 'VIN is not valid, please try again.' : ''}
        InputProps={{
          endAdornment: (
            <AdasInputAdornment position='start' sx={{ color: '#0b7ef3' }}>
              <AdasButton
                onClick={() => populateVehicleInfo(formInputValues.vin)}
                disabled={formInputValues.vin.length !== 17 || loading}
                variant='link'
                sx={{ textTransform: 'none' }}
              >
                {loading ? <CircularProgress size={'1.5rem'} /> : 'Find VIN'}
              </AdasButton>
              {isScan && (
                <>
                  <AdasDivider orientation='vertical' flexItem />
                  <DocumentScannerIcon onClick={vinClickHandler} /> Scan
                </>
              )}
            </AdasInputAdornment>
          ),
          sx: {
            paddingRight: !isScan ? 0 : '' // Removes the right padding
          }
        }}
      />
      {open && <VinScannerModal open={open} onClose={closeModalFunc} setOpen={setOpen} vinSuccessHandler={populateVehicleInfo} />}
      <ConfirmModal
        open={showDuplicateWarning}
        handleClose={handleCloseWarning}
        handleContinue={handleContinueWarning}
        title={GENERIC_MESSAGES.VIN_MATCH_FOUND_TITLE}
        message={GENERIC_MESSAGES.VIN_MATCH_FOUND_MESSAGE}
        confirmLabel={BUTTONS_DISPLAY_LABEL.VIEW_RESULTS}
      />
    </AdasBox>
  )
}

VinInput.propTypes = {
  updateVehicleInfo: PropTypes.func,
  updateVINSearchList: PropTypes.func,
  shouldFetchOnlyVinList: PropTypes.bool,
  prepopulateVin: PropTypes.string,
  vinExistCheck: PropTypes.bool
}
