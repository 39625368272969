import createStore from 'zustand'
import persist from '../utils/persist'

export const useCenterStore = createStore(
  persist(
    {
      key: 'currentCalibrationCenter'
    },
    (set) => ({
      ccId: 0,
      centerDetail: {},
      userList: [],
      setCenterDetail: (newCenter) => {
        set(() => ({ centerDetail: { ...newCenter } }))
        set(() => ({ ccId: newCenter.id }))
      },
      setCcId: (newId) => {
        set(() => ({
          ccId: newId
        }))
      },
      setUserList: (newUsers) => {
        set(() => ({
          userList: newUsers
        }))
      }
    })
  )
)
