import { Avatar } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasAvatar = ({ sx = { bgcolor: 'primary', width: 'sm' }, src, alt = 'Avatar image', ...rest }) => {
  return <Avatar sx={sx} src={src} alt={alt} {...rest} />
}

AdasAvatar.propTypes = {
  sx: PropTypes.object,
  src: PropTypes.string,
  alt: PropTypes.string
}
