import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'

export const AdasCircularProgress = ({ color = 'primary' }) => {
  return <CircularProgress color={color} />
}

AdasCircularProgress.propTypes = {
  color: PropTypes.string
}
