import PropTypes from 'prop-types'

import { AdasButton, AdasButtonGroup, AdasTextField } from '@components/wrapper-components'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'

export const SearchOrAddData = ({
  searchInput,
  searchInputHandler,
  searchInputPlaceholder,
  onAddClick,
  searchPlaceholder = 'Search',
  variant = 'withAddButton', // 'withAddButton' or 'withoutAddButton' or 'withAddButtonDisabled'
  sx
}) => {
  const showAddButton = variant === 'withAddButton' || variant === 'withAddButtonDisabled'
  const isButtonDisabled = variant === 'withAddButtonDisabled'

  return (
    <AdasButtonGroup sx={{ boxShadow: 'none', width: '100%', ...sx }} variant='contained' aria-label='outlined primary button group'>
      <AdasTextField
        sx={{ width: showAddButton ? '90%' : '100%', borderRadius: '0px' }}
        onChange={searchInputHandler}
        id='search'
        name='search'
        label={searchPlaceholder}
        variant='outlined'
        type='text'
        value={searchInput}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          startAdornment: <SearchIcon />,
          sx: { borderRadius: '0' },
          placeholder: searchInputPlaceholder
        }}
      />
      {showAddButton && (
        <AdasButton
          onClick={onAddClick}
          disabled={isButtonDisabled}
          sx={{ width: '10%', maxHeight: '100px', borderRadius: '0' }}
          variant='contained'
        >
          <AddIcon />
        </AdasButton>
      )}
    </AdasButtonGroup>
  )
}

SearchOrAddData.propTypes = {
  searchInput: PropTypes.string.isRequired,
  searchInputHandler: PropTypes.func.isRequired,
  searchInputPlaceholder: PropTypes.string,
  onAddClick: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  variant: PropTypes.oneOf(['withAddButton', 'withoutAddButton', 'withAddButtonDisabled'])
}
