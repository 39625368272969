import { Logger } from '../../../logger'
import { InvoiceSettings } from './invoice-settings'
import { QuickBookForm } from './quick-book'
import { useEffect, useState, useCallback } from 'react'
import { AdasCard } from '@components/wrapper-components'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useUserStore } from '@caradasstore/UserStore'
import {
  getFinanciallyConnected,
  getCalibrationCenter,
  quickbooksConnect,
  postFinancialDisconnect,
  createCalibrationCenter,
  updateQBOItemizedForCalibrationCenter,
  updateQBOClassNameForCalibrationCenter
} from 'api/api'
import { ConfirmModal } from '@components/common'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { GENERIC_MESSAGES, BUTTONS_DISPLAY_LABEL, LIMITS } from '../../../constants'
import { AdasTypography, AdasBox } from '@components/wrapper-components'
import { validations } from 'utils/utils'

export const FinancialSettings = () => {
  const [isQuickBooksConnected, setIsQuickBooksConnected] = useState(false)
  const [cc, setCC] = useState()
  const [optInLineItems, setOptInLineItems] = useState(false)
  const [tempOptInLineItems, setTempOptInLineItems] = useState(false)
  const [optInQR, setOptInQR] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [adminCheckFailureMessage, SetAdminCheckFailureMessage] = useState(null)
  const [qboClassName, setQboClassName] = useState('')
  const [qboClassNameErrorMessage, setQboClassNameErrorMessage] = useState(null)

  const centerDetail = useCenterStore((store) => store.centerDetail)
  const currentUser = useUserStore((store) => store.currentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const ccId = centerDetail.id

  // Fetching Data
  useEffect(() => {
    const getIsFinanciallyConnected = async () => {
      const response = await getFinanciallyConnected({
        ccid: ccId
      })
      if (response.status === 200) {
        setIsQuickBooksConnected(response.data)
      }
    }
    getIsFinanciallyConnected()
  }, [ccId])

  useEffect(() => {
    const fetchCC = async () => {
      try {
        const response = await getCalibrationCenter({
          ccid: ccId
        })
        setCC(response.data)
        setOptInQR(!!response.data.is_include_qr)
        setOptInLineItems(!!response.data.qbo_itemized_invoice)
        setQboClassName(response.data.qbo_class_name || '')
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId } })
      }
    }

    fetchCC()
  }, [ccId])

  // Handlers
  const connectToQuickBooks = useCallback(async () => {
    const response = await quickbooksConnect({ id: ccId })
    if (response.status === 200) {
      window.location.replace(response.data)
    }
  }, [ccId])

  const disconnectFromQuickBooks = useCallback(() => {
    const disconnectFromQB = async () => {
      const response = await postFinancialDisconnect({
        ccid: ccId
      })
      if (response.status === 200) {
        setIsQuickBooksConnected(false)
      }
    }
    disconnectFromQB()
  }, [ccId])

  const handleQROptIn = async (e) => {
    try {
      const value = e.target.value === 'Yes'
      setOptInQR(value)
      const response = await createCalibrationCenter({ data: { ...cc, is_include_qr: value } })
      setCC(response.data)
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId } })
    }
  }

  const handleLineItemsOptIn = async (e) => {
    try {
      const value = e.target.value === 'Yes'
      setTempOptInLineItems(value)
      setShowModal(true)
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId } })
    }
  }

  const handleQboClassNameChange = async (e) => {
    const value = e.target.value

    if (value.length <= LIMITS.QBO_CLASS_NAME_SIZE) {
      setQboClassNameErrorMessage(validations.qboClassName(value) ? null : 'Alphabets only')
      setQboClassName(value)
    }
  }

  const updateQboClassName = async () => {
    try {
      if (!validations.qboClassName(qboClassName)) {
        const initQboClassName = cc.qbo_class_name || ''
        setQboClassName(initQboClassName)
      } else {
        await updateQBOClassNameForCalibrationCenter(ccId, qboClassName)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId, qboClassName } })
    } finally {
      setQboClassNameErrorMessage(null)
    }
  }

  const modalContinue = useCallback(
    (code) => {
      const updateQBOItemized = async () => {
        try {
          setLoading(true)
          await updateQBOItemizedForCalibrationCenter(ccId, tempOptInLineItems)
        } catch (error) {
          Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId } })
        } finally {
          setLoading(false)
        }
      }

      if (code === '2180') {
        updateQBOItemized()
        setOptInLineItems(tempOptInLineItems)
        SetAdminCheckFailureMessage(null)
        setShowModal(false)
      } else {
        SetAdminCheckFailureMessage(GENERIC_MESSAGES.INCORRECT_ADMIN_CODE)
        setShowModal(true)
      }
    },
    [ccId, tempOptInLineItems]
  )

  return (
    <>
      <AdasTypography component='span' fontWeight='bold' sx={{ marginTop: '20px' }} variant='h6'>
        Financial Settings
      </AdasTypography>
      <AdasCard
        sx={{
          margin: '17px 0px',
          padding: '16px 16px'
        }}
      >
        <QuickBookForm
          isConnected={isQuickBooksConnected}
          optInLineItems={optInLineItems}
          isAdminUser={currentUser && currentUser.is_admin}
          qboClassName={qboClassName}
          qboClassNameErrorMessage={qboClassNameErrorMessage}
          connectToQuickBooks={connectToQuickBooks}
          disconnectFromQuickBooks={disconnectFromQuickBooks}
          handleLineItemsOptIn={handleLineItemsOptIn}
          handleQboClassNameChange={handleQboClassNameChange}
          updateQboClassName={updateQboClassName}
        />
        <InvoiceSettings optInQR={optInQR} handleQROptIn={handleQROptIn} />

        <ConfirmModal
          open={showModal}
          handleClose={() => {
            SetAdminCheckFailureMessage(null)
            setShowModal(false)
          }}
          handleContinue={modalContinue}
          title={GENERIC_MESSAGES.OPT_IN_LINE_ITEMS_TITLE}
          adminCheckFailureMessage={adminCheckFailureMessage}
          message={
            <AdasBox sx={{ letterSpacing: '0.15px', marginTop: '10px', marginBottom: '18px', maxWidth: '450px', textAlign: 'center' }}>
              <AdasBox>
                <AdasTypography component='span' fontWeight='bold' sx={{ marginLeft: '-12px' }}>
                  {'YES = '}
                </AdasTypography>
                <AdasTypography component='span'>{GENERIC_MESSAGES.OPT_IN_LINE_ITEMS_YES_MESSAGE}</AdasTypography>
              </AdasBox>

              <AdasBox sx={{ marginY: '1rem' }}>
                <AdasTypography component='span' fontWeight='bold'>
                  {'NO = '}
                </AdasTypography>
                <AdasTypography component='span'>{GENERIC_MESSAGES.OPT_IN_LINE_ITEMS_NO_MESSAGE}</AdasTypography>
              </AdasBox>

              <AdasTypography>Enter system admin code to confirm</AdasTypography>
            </AdasBox>
          }
          confirmLabel={BUTTONS_DISPLAY_LABEL.CONFIRM}
          adminCheck={true}
        />
      </AdasCard>
    </>
  )
}
