import { FormControl } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasFormControl = ({ sx = {}, children, ...rest }) => {
  return (
    <FormControl sx={sx} {...rest}>
      {children}
    </FormControl>
  )
}

AdasFormControl.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node.isRequired
}
