import { List } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasList = ({ children, ...props }) => {
  return <List {...props}>{children}</List>
}

AdasList.propTypes = {
  children: PropTypes.node.isRequired
}
