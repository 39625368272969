import { useHistory } from 'react-router-dom'
import { AdasBox, AdasButton, AdasContainer, AdasTypography } from '@components/wrapper-components'
import { useCallback } from 'react'
import { useMessageStore } from '../../store'

export const ForbiddenPage = () => {
  const history = useHistory()
  const clearAllMessages = useMessageStore((store) => store.clearAllMessages)

  const handleRefresh = useCallback(() => {
    clearAllMessages()
    history.replace('/')
  }, [history])

  return (
    <AdasContainer maxWidth='sm' sx={{ marginTop: '50px' }}>
      <AdasBox textAlign='center' p={3}>
        <AdasTypography variant='h4' gutterBottom>
          Access Denied
        </AdasTypography>
        <AdasTypography gutterBottom>
          {
            "You don't have permission to access this page. \n\n If you believe this is an error, please refresh your web browser and try again. If this issue persists please contact your administrator or support team."
          }
        </AdasTypography>
        <AdasButton variant='contained' color='primary' onClick={handleRefresh} sx={{ marginTop: '20px' }}>
          Home
        </AdasButton>
      </AdasBox>
    </AdasContainer>
  )
}
