import { useEffect } from 'react'
import { AdasContainer, AdasPaper } from '@components/wrapper-components'
import Iframe from 'react-iframe'
import { useLoadingStore } from '@caradasstore/LoadingStore'
export const HealthCheckPage = () => {
  const setLoading = useLoadingStore((store) => store.setLoading)
  useEffect(() => {
    setLoading(true)

    const timeoutId = setTimeout(() => {
      setLoading(false)
      console.error('Iframe load timed out')
    }, 15000)

    return () => clearTimeout(timeoutId)
  }, [setLoading])
  return (
    <AdasContainer maxWidth='lg' styles={{ height: '100vh' }}>
      <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', height: '100vh' }}>
        <Iframe
          url='https://health.caradasplus.com/'
          position='static'
          width='100%'
          id='myId'
          className='myClassname'
          height='100%'
          onLoad={() => setLoading(false)}
        />
      </AdasPaper>
    </AdasContainer>
  )
}
