import PropTypes from 'prop-types'
import { useState } from 'react'

import { AdasBox, AdasButton, AdasTextField } from '@components/wrapper-components'
import { updateUser } from '../../../../api/api'
import { phoneNumberFormat } from '../../../../utils/utils'

export const PhoneNumberForm = ({ user, close, onSaveSuccess }) => {
  const [newPhone, setNewPhone] = useState(user.phone ? user.phone : '')

  const handleInputChange = (e) => {
    const value = phoneNumberFormat(e.target.value)
    setNewPhone(value)
  }

  const handleSavePhoneNumber = () => {
    const data = { ...user, phone: newPhone }
    const updateUserResponse = updateUser({
      data: data
    })
    updateUserResponse.then((response) => {
      if (response.status === 200) {
        close()
        onSaveSuccess(data)
      }
    })
  }

  return (
    <AdasBox sx={{ marginTop: '1rem' }}>
      <AdasTextField
        placeholder='Phone'
        type='text'
        id='phone'
        label='Phone'
        sx={{ width: '100%', paddingBottom: '.5rem' }}
        onChange={handleInputChange}
        value={newPhone}
      />
      <AdasBox
        sx={{
          margin: '20px 0px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={close}>
          Cancel
        </AdasButton>
        <AdasButton
          type='submit'
          disabled={newPhone.length < 14}
          variant='contained'
          aria-label='save'
          color='primary'
          sx={{ marginLeft: '.5rem' }}
          onClick={handleSavePhoneNumber}
        >
          Save
        </AdasButton>
      </AdasBox>
    </AdasBox>
  )
}

PhoneNumberForm.propTypes = { user: PropTypes.object, close: PropTypes.func.isRequired, onSaveSuccess: PropTypes.func.isRequired }
