import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'

import { AdasChip } from '@components/wrapper-components'

export const StatusInfo = ({ label, color, sx, isDiscount = false }) => {
  const theme = useTheme()
  const displayColor = color === 'primary' ? theme.palette.background.highlight : theme.palette.secondary.main.light
  const defaultStyle = {
    backgroundColor: isDiscount ? theme.palette.error.light : displayColor,
    borderRadius: '4px'
  }

  return <AdasChip label={isDiscount ? `(${label})` : label} sx={[defaultStyle, sx]} size='small' />
}

StatusInfo.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']).isRequired,
  sx: PropTypes.object,
  isDiscount: PropTypes.bool
}
