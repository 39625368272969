import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasTooltip = ({ title, children }) => {
  return <Tooltip title={title}>{children}</Tooltip>
}

AdasTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
