import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'

export const AdasChip = ({ label, sx = {}, color, size = 'medium' }) => {
  return <Chip label={label} sx={sx} color={color} size={size} />
}

AdasChip.propTypes = {
  label: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium'])
}
