import { useAuthStore } from '@caradasstore/AuthStore'
import axios from 'axios'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useCenterStore, useLoadingStore, useMessageStore, useOrganisationStore } from '../store'
import { purge } from '../utils/persist'
import { axiosSecure, refreshAuth } from './api'
import { REDIRECT_URLS } from '../constants'

export const AuthFailureInterceptor = () => {
  const interceptorId = useRef(null)
  const authCtx = useAuthStore()
  const history = useHistory()
  const setLoading = useLoadingStore((store) => store.setLoading)
  const addMessage = useMessageStore((store) => store.addMessage)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const setCcId = useCenterStore((store) => store.setCcId)
  const setOrganisationDetail = useOrganisationStore((store) => store.setOrganisationDetail)
  useEffect(() => {
    interceptorId.current = axiosSecure.interceptors.response.use(undefined, async (error) => {
      const originalRequest = error.config
      if (error.response?.status === 401) {
        try {
          // refreshAxios uses different axios instance
          const authRefreshResponse = await refreshAuth({})
          if (authRefreshResponse.status === 200) {
            const { token, refreshToken } = authRefreshResponse.data
            authCtx.login(token, refreshToken)
            originalRequest.headers.Authorization = `Bearer ${token}`
            return axiosSecure(originalRequest)
          }
          throw new Error('unable to refresh token')
        } catch (error) {
          authCtx.logout()
          setCenterDetail({})
          setCcId({})
          setOrganisationDetail({})
          purge()
          history.push('/authenticate')
        }
      } else if (error.response?.status === 403) {
        history.push(REDIRECT_URLS.FORBIDDEN_PAGE)
      } else if (originalRequest?.customErrorHandling) {
        setLoading(false)
        return Promise.reject(error)
      } else {
        const errorMessage =
          error.code === 'ERR_NETWORK'
            ? 'Network Error: Trouble connecting to our servers. Check your internet connection and try again.'
            : `Error: ${error?.response?.data?.message || error?.response?.data || error.message}`
        const extractError = extractMessage(errorMessage)
        addMessage({
          text: extractError ? extractError : errorMessage
        })
        setLoading(false)
      }
    })

    return () => {
      axios.interceptors.response.eject(interceptorId.current)
    }
  }, [addMessage, authCtx, history, setLoading])

  return null
}

const extractMessage = (text) => {
  if (typeof text !== 'string' || !text.trim()) {
    return null
  }

  const messageMatch = text.match(/"message":"(.*?)"/)

  if (messageMatch && messageMatch[1]) {
    return messageMatch[1].replace('Bad arguments sent: ', '')
  }

  return null
}
