import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import { PhotoInput } from '@components/photo'
import { sortByDate } from '../../../utils/utils'
import { OperationType } from '../../../enums/enums'
import { Accordion, AccordionSummary, AccordionDetails } from '../../common/accordion-elements/AccordionElements'
import { GENERIC_MESSAGES } from '../../../constants'
import { AdasBox, AdasTypography } from '@components/wrapper-components'

const leftColumnStyle = {
  width: '50%',
  paddingRight: '10px'
}
const rightColumnStyle = {
  width: '50%',
  paddingLeft: '10px'
}

const commonTextStyle = {
  lineHeight: '266%',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  padding: '8px 0'
}

const titleStyle = {
  ...commonTextStyle,
  fontWeight: '700',
  fontSize: '14px',
  color: 'rgba(26, 27, 31, 0.87)'
}

const subTitleStyle = {
  ...commonTextStyle,
  fontSize: '12px',
  fontWeight: '400',
  color: 'background: rgba(26, 27, 31, 0.6)'
}

export const MultiPhotoSection = ({
  title,
  addText = 'ADD ADDITIONAL PHOTO',
  photoInfoArray = [],
  workOrderId,
  refresh,
  disabled,
  imageLocation,
  labelList,
  accordionExpandedDefault = true,
  maxPhotos,
  isMultiUpload = false
}) => {
  const [images, setImages] = useState([])
  const [allLabels, setAllLabels] = useState(labelList)
  const uploadedMessage = images.length === 1 ? GENERIC_MESSAGES.PHOTO_UPLOADED : GENERIC_MESSAGES.PHOTOS_UPLOADED
  const hideNewPhoto = maxPhotos && images.length >= maxPhotos

  useEffect(() => {
    setImages([])
    let existingLabels = []

    photoInfoArray?.forEach((photoInfo) => {
      if (photoInfo.label) {
        existingLabels.push(photoInfo.label)
      }
      setImages((prev) => {
        prev.push(photoInfo)
        const photos = [...prev]
        return photos
      })
    })

    setAllLabels((prev) => Array.from(new Set([...prev, ...existingLabels])))
  }, [photoInfoArray])

  const refreshMultiPhotos = useCallback(
    (refreshedData) => {
      if (refreshedData) {
        if (refreshedData.operation === OperationType.RETAKE) {
          setImages((prevState) => {
            const updatedPhotos = prevState.map((photo) =>
              photo.id === refreshedData.deletedAttachmentId
                ? {
                    ...photo,
                    ...refreshedData.attachmentDetail,
                    isModalAlreadyOpened: true,
                    img_rotation: refreshedData.imgRotation || refreshedData.img_rotation
                  }
                : photo
            )

            // If the item doesn't exist, add a new one
            if (!prevState.some((photo) => photo.id === refreshedData.deletedAttachmentId) && refreshedData.attachmentDetail) {
              const newPhoto = {
                ...refreshedData.attachmentDetail,
                img_rotation: refreshedData.imgRotation || refreshedData.img_rotation
              }

              updatedPhotos.push(newPhoto)
            }
            return updatedPhotos
          })
        } else {
          setImages((prevState) =>
            prevState
              .map((photo) =>
                photo.id === refreshedData.attachmentId
                  ? refreshedData.operation === OperationType.ROTATE
                    ? { ...photo, img_rotation: refreshedData.img_rotation }
                    : null
                  : photo
              )
              .filter(Boolean)
          )
        }
      }
      refresh(refreshedData)
    },
    [refresh]
  )

  return (
    <AdasBox sx={{ marginTop: '10px', marginBottom: '10px' }}>
      <Accordion key={1} sx={{ background: '#FFFFFF' }} defaultExpanded={accordionExpandedDefault}>
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header' sx={{ background: '#FFFFFF' }}>
          <AdasBox sx={{ flex: '1' }}>
            <AdasTypography sx={titleStyle}>{title}</AdasTypography>
          </AdasBox>
          <AdasBox sx={{ flex: '1', textAlign: 'left' }}>
            <AdasTypography sx={subTitleStyle}>{`${images.length} ${uploadedMessage}`}</AdasTypography>
          </AdasBox>
        </AccordionSummary>
        <AccordionDetails>
          <AdasBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {images
              .sort((a, b) => sortByDate(a, b))
              .map((photo, index) => (
                <AdasBox key={index + photo?.id} sx={index % 2 === 1 ? { ...rightColumnStyle } : { ...leftColumnStyle }}>
                  <PhotoInput
                    labelText={''}
                    imageLocation={imageLocation}
                    imageId={photo?.id}
                    deleteOnRetake={true}
                    allowDelete={true}
                    workorderId={workOrderId}
                    onUpdate={refreshMultiPhotos}
                    label={photo?.label}
                    labelList={labelList}
                    disabled={disabled}
                    img_rotation={photo?.img_rotation}
                    isModalAlreadyOpened={photo?.isModalAlreadyOpened}
                    title={title}
                    maxPhotos={maxPhotos}
                    isMultiUpload={isMultiUpload}
                  />
                </AdasBox>
              ))}
            {!hideNewPhoto && (
              <AdasBox sx={{ width: '50%' }}>
                <PhotoInput
                  labelText={addText}
                  imageLocation={imageLocation}
                  workorderId={workOrderId}
                  onUpdate={refreshMultiPhotos}
                  labelList={allLabels}
                  disabled={disabled}
                  maxPhotos={images && images.length ? maxPhotos - images.length : maxPhotos}
                  isMultiUpload={isMultiUpload}
                />
              </AdasBox>
            )}
          </AdasBox>
          {maxPhotos && (
            <AdasTypography sx={{ ...subTitleStyle, padding: '0px' }}>
              {GENERIC_MESSAGES.MAX_POTOS}: {maxPhotos}
            </AdasTypography>
          )}
        </AccordionDetails>
      </Accordion>
    </AdasBox>
  )
}

MultiPhotoSection.propTypes = {
  title: PropTypes.string,
  addText: PropTypes.string,
  photoInfoArray: PropTypes.array,
  workOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresh: PropTypes.func,
  disabled: PropTypes.bool,
  imageLocation: PropTypes.string,
  labelList: PropTypes.array,
  accordionExpandedDefault: PropTypes.bool,
  maxPhotos: PropTypes.number
}
