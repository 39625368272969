import { Redirect, Route } from 'react-router-dom'

import { useAuthStore } from '@caradasstore/AuthStore'

export const AuthRoute = ({ component: Component }) => {
  const authCtx = useAuthStore()
  const isAuthenticated = authCtx.isLoggedIn

  return <Route path='/authenticate' exact render={() => (isAuthenticated ? <Redirect to='/' /> : <Component />)} />
}
