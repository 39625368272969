import PropTypes from 'prop-types'

import { AdasFormControl, AdasInputLabel, AdasSelect } from '@components/wrapper-components'

const DEFAULT_ITEMS_PER_PAGE = [10, 25, 50, 100, 200, 500]

export const ItemPerPageSelector = ({ pageSize, pageSizeHandler, itemPerPageList = DEFAULT_ITEMS_PER_PAGE }) => {
  return (
    <AdasFormControl fullWidth>
      <AdasInputLabel id='itemPerPage'>Items per Page</AdasInputLabel>
      <AdasSelect
        labelId='selectItemPerPage'
        id='selectItems'
        value={pageSize}
        label='Items per Page'
        onChange={pageSizeHandler}
        options={itemPerPageList || []}
      />
    </AdasFormControl>
  )
}

ItemPerPageSelector.propTypes = {
  pageSize: PropTypes.number.isRequired,
  pageSizeHandler: PropTypes.func.isRequired,
  itemPerPageList: PropTypes.array
}
