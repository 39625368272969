import { AdasTypography, AdasGrid } from '@components/wrapper-components'
import theme from 'theme/theme'

export const PreferencesFormRow = ({ children, title }) => {
  return (
    <AdasGrid
      item
      xs={12}
      display='flex'
      justifyContent='space-between'
      flexDirection={{ xs: 'column', sm: 'row' }}
      gap={3}
      alignItems={{ xs: 'left', sm: 'center' }}
    >
      <AdasGrid xs={12} sm={8}>
        <AdasTypography color={theme.typography.shaded} variant='body2'>
          {title}
        </AdasTypography>
      </AdasGrid>
      <AdasGrid xs={12} sm={4} width='100%'>
        {children}
      </AdasGrid>
    </AdasGrid>
  )
}
