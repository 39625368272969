import { useState, useCallback, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { getClientInvoicePhoto } from '../../../api/api'
import { sortByDate } from '../../../utils/utils'
import { PhotoInput } from '@components/photo'
import { AdasBox, AdasButton, AdasContainer, AdasPaper, AdasTypography } from '@components/wrapper-components'
import { PHOTO_LABELS } from '../../../constants'
import { FieldConfigurations, FieldNames } from '../../../enums/enums'
import { PHOTO_SUMMARY_FIELD_MAP } from '../../../variable-maps'

export const ClientInvoicePhotoPage = () => {
  const history = useHistory()
  const [dataUrls, setDataUrls] = useState({
    dashImageUrl: {},
    dashPostCalibrationImageUrl: {},
    weightImageUrl1: {},
    weightImageUrl2: {},
    driverFrontImageUrl: {},
    driverRearImageUrl: {},
    passengerFrontImageUrl: {},
    passengerRearImageUrl: {},
    driverFrontWheelImageUrl: {},
    driverRearWheelImageUrl: {},
    passengerFrontWheelImageUrl: {},
    passengerRearWheelImageUrl: {},
    alignmentImageUrl: {},
    alignmentImageUrl2: {}
  })

  const [additionalPhotos, setAdditionalPhotos] = useState([])
  const [frontCameraPhotos, setFrontCameraPhotos] = useState([])
  const [rearCameraPhotos, setRearCameraPhotos] = useState([])
  const [surroundViewPhotos, setSurroundViewPhotos] = useState([])
  const [blindSpotPhotos, setBlindSpotPhotos] = useState([])
  const [frontRadarCameraPhotos, setFrontRadarCameraPhotos] = useState([])

  const leftColumnStyle = {
    width: '50%',
    paddingRight: '10px'
  }
  const rightColumnStyle = {
    width: '50%',
    paddingLeft: '10px'
  }
  const classes = {
    subHeading: {
      fontWeight: '700 !important',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '266%',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: 'rgba(26, 27, 31, 0.87)',
      padding: '10px 0px'
    }
  }

  const location = useLocation()
  const workOrderData = location.state

  const requestPhotoSummary = useCallback(async (params) => {
    const response = await getClientInvoicePhoto(params)
    if (response.status === 200) {
      const photoSummaryData = response.data
      Object.keys(PHOTO_SUMMARY_FIELD_MAP).forEach((key) => {
        if (photoSummaryData[key]) {
          const imageItemDetail = photoSummaryData[key]
          setDataUrls((prevState) => {
            const imageUrls = {
              ...prevState,
              [PHOTO_SUMMARY_FIELD_MAP[key]]: imageItemDetail
            }
            return imageUrls
          })
        }
      })
      if (photoSummaryData.additionalPhotos != null) {
        setAdditionalPhotos([])
        photoSummaryData.additionalPhotos.forEach((doc) => {
          setAdditionalPhotos((prev) => {
            prev.push(doc)
            const photos = [...prev]
            return photos
          })
        })
      }
      if (photoSummaryData.frontCameraPhotos != null) {
        setFrontCameraPhotos([])
        photoSummaryData.frontCameraPhotos.forEach((doc) => {
          setFrontCameraPhotos((prev) => {
            prev.push(doc)
            const photos = [...prev]
            return photos
          })
        })
      }
      if (photoSummaryData.blindSpotMonitoringPhotos != null) {
        setBlindSpotPhotos([])
        photoSummaryData.blindSpotMonitoringPhotos.forEach((doc) => {
          setBlindSpotPhotos((prev) => {
            prev.push(doc)
            const photos = [...prev]
            return photos
          })
        })
      }
      if (photoSummaryData.surroundViewCameraPhotos != null) {
        setSurroundViewPhotos([])
        photoSummaryData.surroundViewCameraPhotos.forEach((doc) => {
          setSurroundViewPhotos((prev) => {
            prev.push(doc)
            const photos = [...prev]
            return photos
          })
        })
      }
      if (photoSummaryData.rearCameraPhotos != null) {
        setRearCameraPhotos([])
        photoSummaryData.rearCameraPhotos.forEach((doc) => {
          setRearCameraPhotos((prev) => {
            prev.push(doc)
            const photos = [...prev]
            return photos
          })
        })
      }
      if (photoSummaryData.frontRadarCameraPhotos != null) {
        setFrontRadarCameraPhotos([])
        photoSummaryData.frontRadarCameraPhotos.forEach((doc) => {
          setFrontRadarCameraPhotos((prev) => {
            prev.push(doc)
            const photos = [...prev]
            return photos
          })
        })
      }
    }
  }, [])

  const refresh = useCallback(() => {
    const params = {
      token: workOrderData.tempToken,
      invoiceId: workOrderData.id
    }
    requestPhotoSummary(params)
  }, [requestPhotoSummary, workOrderData.id, workOrderData.tempToken])

  useEffect(() => {
    refresh()
  }, [refresh])

  const generatePhotoInputProps = (fieldLocation, labelText, isClientInvoice, title) => {
    const fieldData = dataUrls[fieldLocation] || {}

    return {
      labelText,
      imageLocation: fieldLocation,
      workorderId: workOrderData.id,
      onUpdate: refresh,
      properties: fieldData,
      clientAccessData: { token: workOrderData.tempToken, invoiceId: workOrderData.id },
      imageId: fieldData.id || '',
      img_rotation: fieldData.img_rotation || 0,
      title: title || FieldConfigurations[fieldLocation]?.title,
      isClientInvoice: isClientInvoice
    }
  }

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper elevation={0} sx={{ padding: '20px' }}>
        <AdasBox>
          <h2>Photos</h2>
        </AdasBox>
        <AdasBox>
          <AdasPaper
            variant='outlined'
            sx={{
              padding: '0px 20px',
              marginBottom: '20px'
            }}
          >
            <AdasTypography sx={classes.subHeading}>DASHBOARD</AdasTypography>
            <AdasBox sx={{ display: 'flex' }}>
              <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                {dataUrls[FieldNames.DASH_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DASH_IMAGE_URL, 'DASH PRE CALIBRATION', true)} />
                )}
              </AdasBox>
              <AdasBox sx={{ width: '50%' }}>
                {dataUrls[FieldNames.DASH_POST_CALIBRATION_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DASH_POST_CALIBRATION_IMAGE_URL, 'DASH POST CALIBRATION', true)} />
                )}
              </AdasBox>
            </AdasBox>
          </AdasPaper>
        </AdasBox>
        {dataUrls[FieldNames.WEIGHT_IMAGE_URL_1]?.id || dataUrls[FieldNames.WEIGHT_IMAGE_URL_2]?.id ? (
          <AdasBox sx={{ marginBottom: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>FUEL WEIGHT COMPENSATION</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  {dataUrls[FieldNames.WEIGHT_IMAGE_URL_1]?.id && (
                    <PhotoInput {...generatePhotoInputProps(FieldNames.WEIGHT_IMAGE_URL_1, 'ADD PHOTO OF WEIGHT', true)} />
                  )}
                </AdasBox>
                <AdasBox sx={{ width: '50%' }}>
                  {dataUrls[FieldNames.WEIGHT_IMAGE_URL_2]?.id && (
                    <PhotoInput {...generatePhotoInputProps(FieldNames.WEIGHT_IMAGE_URL_2, 'ADD PHOTO OF WEIGHT', true)} />
                  )}
                </AdasBox>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        <AdasBox>
          <AdasPaper
            variant='outlined'
            sx={{
              padding: '0px 20px',
              marginBottom: '20px'
            }}
          >
            <AdasTypography sx={classes.subHeading}>FOUR CORNERS</AdasTypography>
            <AdasBox sx={{ display: 'flex' }}>
              <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                {dataUrls[FieldNames.DRIVER_FRONT_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_FRONT_IMAGE_URL, 'DRIVER_SIDE FRONT CORNER', true)} />
                )}
                {dataUrls[FieldNames.DRIVER_REAR_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_REAR_IMAGE_URL, 'DRIVER_SIDE REAR CORNER', true)} />
                )}
              </AdasBox>
              <AdasBox sx={{ width: '50%' }}>
                {dataUrls[FieldNames.PASSENGER_FRONT_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_FRONT_IMAGE_URL, 'PASSENGER_SIDE FRONT CORNER', true)} />
                )}
                {dataUrls[FieldNames.PASSENGER_REAR_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_REAR_IMAGE_URL, 'PASSENGER_SIDE REAR CORNER', true)} />
                )}
              </AdasBox>
            </AdasBox>
          </AdasPaper>

          <AdasPaper
            variant='outlined'
            sx={{
              padding: '0px 20px',
              marginBottom: '20px'
            }}
          >
            <AdasTypography sx={classes.subHeading}>TIRE PRESSURE</AdasTypography>
            <AdasBox sx={{ display: 'flex' }}>
              <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                {dataUrls[FieldNames.DRIVER_FRONT_WHEEL_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_FRONT_WHEEL_IMAGE_URL, 'LT FRONT WHEEL', true)} />
                )}
                {dataUrls[FieldNames.DRIVER_REAR_WHEEL_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_REAR_WHEEL_IMAGE_URL, 'LT REAR WHEEL', true)} />
                )}
              </AdasBox>
              <AdasBox sx={{ width: '50%' }}>
                {dataUrls[FieldNames.PASSENGER_FRONT_WHEEL_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_FRONT_WHEEL_IMAGE_URL, 'RT FRONT WHEEL', true)} />
                )}
                {dataUrls[FieldNames.PASSENGER_REAR_WHEEL_IMAGE_URL]?.id && (
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_REAR_WHEEL_IMAGE_URL, 'LT REAR WHEEL', true)} />
                )}
              </AdasBox>
            </AdasBox>
          </AdasPaper>
        </AdasBox>
        {dataUrls[FieldNames.ALIGNMENT_IMAGE_URL]?.id || dataUrls[FieldNames.ALIGNMENT_IMAGE_URL_2]?.id ? (
          <AdasBox sx={{ marginTop: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>WHEEL ALIGNMENT</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  {dataUrls[FieldNames.ALIGNMENT_IMAGE_URL]?.id && (
                    <PhotoInput {...generatePhotoInputProps(FieldNames.ALIGNMENT_IMAGE_URL, 'ADD PHOTO OF ALIGNMENT', true)} />
                  )}
                </AdasBox>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  {dataUrls[FieldNames.ALIGNMENT_IMAGE_URL_2]?.id && (
                    <PhotoInput {...generatePhotoInputProps(FieldNames.ALIGNMENT_IMAGE_URL_2, 'ADD PHOTO OF ALIGNMENT', true)} />
                  )}
                </AdasBox>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        {frontCameraPhotos && frontCameraPhotos.length > 0 ? (
          <AdasBox sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>{PHOTO_LABELS.FRONT_CAMERA}</AdasTypography>
              <AdasBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {frontCameraPhotos
                  .sort((a, b) => sortByDate(a, b))
                  .map((photo, index) => {
                    return (
                      <AdasBox key={index} sx={index % 2 === 1 ? { ...rightColumnStyle } : { ...leftColumnStyle }}>
                        <PhotoInput
                          labelText={''}
                          imageLocation={`frontCameraPhotos`}
                          isClientInvoice={true}
                          workorderId={workOrderData.id}
                          onUpdate={refresh}
                          imageId={photo.id}
                          label={photo.label}
                          img_rotation={photo.img_rotation}
                          clientAccessData={{ token: workOrderData.tempToken, invoiceId: workOrderData.id }}
                        />
                      </AdasBox>
                    )
                  })}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        {rearCameraPhotos && rearCameraPhotos.length > 0 ? (
          <AdasBox sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>{PHOTO_LABELS.REAR_CAMERA}</AdasTypography>
              <AdasBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {rearCameraPhotos
                  .sort((a, b) => sortByDate(a, b))
                  .map((photo, index) => {
                    return (
                      <AdasBox key={index} sx={index % 2 === 1 ? { ...rightColumnStyle } : { ...leftColumnStyle }}>
                        <PhotoInput
                          labelText={''}
                          imageLocation={`rearCameraPhotos`}
                          isClientInvoice={true}
                          workorderId={workOrderData.id}
                          onUpdate={refresh}
                          imageId={photo.id}
                          label={photo.label}
                          img_rotation={photo.img_rotation}
                          clientAccessData={{ token: workOrderData.tempToken, invoiceId: workOrderData.id }}
                        />
                      </AdasBox>
                    )
                  })}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        {surroundViewPhotos && surroundViewPhotos.length > 0 ? (
          <AdasBox sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>{PHOTO_LABELS.SURROUND_VIEW_CAMERA}</AdasTypography>
              <AdasBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {surroundViewPhotos
                  .sort((a, b) => sortByDate(a, b))
                  .map((photo, index) => {
                    return (
                      <AdasBox key={index} sx={index % 2 === 1 ? { ...rightColumnStyle } : { ...leftColumnStyle }}>
                        <PhotoInput
                          labelText={''}
                          imageLocation={`surroundViewPhotos`}
                          isClientInvoice={true}
                          workorderId={workOrderData.id}
                          onUpdate={refresh}
                          imageId={photo.id}
                          label={photo.label}
                          img_rotation={photo.img_rotation}
                          clientAccessData={{ token: workOrderData.tempToken, invoiceId: workOrderData.id }}
                        />
                      </AdasBox>
                    )
                  })}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        {blindSpotPhotos && blindSpotPhotos.length > 0 ? (
          <AdasBox sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>{PHOTO_LABELS.BLIND_SPOT_MONITORING}</AdasTypography>
              <AdasBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {blindSpotPhotos
                  .sort((a, b) => sortByDate(a, b))
                  .map((photo, index) => {
                    return (
                      <AdasBox key={index} sx={index % 2 === 1 ? { ...rightColumnStyle } : { ...leftColumnStyle }}>
                        <PhotoInput
                          labelText={''}
                          imageLocation={`blindSpotPhotos`}
                          isClientInvoice={true}
                          workorderId={workOrderData.id}
                          onUpdate={refresh}
                          imageId={photo.id}
                          label={photo.label}
                          img_rotation={photo.img_rotation}
                          clientAccessData={{ token: workOrderData.tempToken, invoiceId: workOrderData.id }}
                        />
                      </AdasBox>
                    )
                  })}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        {frontRadarCameraPhotos && frontRadarCameraPhotos.length > 0 ? (
          <AdasBox sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>{PHOTO_LABELS.FRONT_RADAR}</AdasTypography>
              <AdasBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {frontRadarCameraPhotos
                  .sort((a, b) => sortByDate(a, b))
                  .map((photo, index) => {
                    return (
                      <AdasBox key={index} sx={index % 2 === 1 ? { ...rightColumnStyle } : { ...leftColumnStyle }}>
                        <PhotoInput
                          labelText={''}
                          imageLocation={FieldNames.FRONT_RADAR_CAMERA_PHOTOS}
                          isClientInvoice={true}
                          workorderId={workOrderData.id}
                          onUpdate={refresh}
                          imageId={photo.id}
                          label={photo.label}
                          img_rotation={photo.img_rotation}
                          clientAccessData={{ token: workOrderData.tempToken, invoiceId: workOrderData.id }}
                        />
                      </AdasBox>
                    )
                  })}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        {additionalPhotos && additionalPhotos.length > 0 ? (
          <AdasBox sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>{PHOTO_LABELS.CALIBRATION_SERVICE_PHOTOS_1}</AdasTypography>
              <AdasBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {additionalPhotos
                  .sort((a, b) => sortByDate(a, b))
                  .map((photo, index) => {
                    return (
                      <AdasBox key={index} sx={index % 2 === 1 ? { ...rightColumnStyle } : { ...leftColumnStyle }}>
                        <PhotoInput
                          labelText={''}
                          imageLocation={FieldNames.ADDITIONAL_PHOTOS}
                          isClientInvoice={true}
                          workorderId={workOrderData.id}
                          onUpdate={refresh}
                          imageId={photo.id}
                          label={photo.label}
                          img_rotation={photo.img_rotation}
                          clientAccessData={{ token: workOrderData.tempToken, invoiceId: workOrderData.id }}
                        />
                      </AdasBox>
                    )
                  })}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        ) : null}
        <AdasBox sx={{ overflow: 'auto' }}>
          <AdasButton sx={{ float: 'right' }} variant='outlined' aria-label='goBack' color='primary' onClick={() => history.goBack()}>
            Return To Summary
          </AdasButton>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
