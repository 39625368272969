import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const AdasBox = forwardRef(({ children, ...props }, ref) => {
  return (
    <Box ref={ref} {...props}>
      {children}
    </Box>
  )
})

AdasBox.propTypes = {
  children: PropTypes.node
}
