import CloseIcon from '@mui/icons-material/Close'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'

import { AdasBox, AdasButton, AdasLinearProgress, AdasTypography } from '@components/wrapper-components'
import { FilePresentOutlined } from '@mui/icons-material'
import { useSubmitFileAttachment } from '../../api/api'
import { useMessageStore } from '../../store'
import { MIME_TYPES_MAP } from '../../variable-maps'

export const QuoteAttachment = (props) => {
  const { title, existingData, onChange, name, label } = props
  const [data, setData] = useState(null)
  const displayData = data ? data : existingData
  const fileRef = useRef(null)
  const { progress, isSuccess } = useSubmitFileAttachment()
  const addMessage = useMessageStore((store) => store.addMessage)
  const theme = useTheme()

  const handleUpload = () => {
    const file = fileRef.current.files[0]
    if (!file) {
      return
    }

    const suppoprtedFileTypes = Object.values(MIME_TYPES_MAP)
    if (!suppoprtedFileTypes.includes(file.type)) {
      addMessage({ text: 'File format not supported!' })
      return
    }

    setData(fileRef.current.files[0])
    onChange({
      data: fileRef.current.files[0],
      name
    })
  }

  const handleClose = async () => {
    setData(null)
    onChange({
      data: '',
      name
    })
  }

  return !displayData ? (
    <AdasBox
      sx={{
        display: 'flex',
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: '4px',
        background: theme.palette.background.default,
        padding: '16px',
        margin: '16px 0px',
        minHeight: '92px',
        alignItems: 'center'
      }}
    >
      {' '}
      <AdasButton
        component='label'
        sx={{
          fontSize: 12,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center'
        }}
        startIcon={<FileUploadOutlinedIcon />}
      >
        <span>{title}</span>
        <input ref={fileRef} type='file' accept='.jpg, .jpeg, .png, .pdf, .doc, .docx, .txt' hidden onChange={handleUpload} />
      </AdasButton>
    </AdasBox>
  ) : (
    <AdasBox
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        background: theme.palette.background.default,
        padding: '16px',
        margin: '16px 0px',
        flexDirection: 'column'
      }}
    >
      <AdasBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <AdasBox sx={{ display: 'flex', alignItems: 'center' }}>
          <FilePresentOutlined sx={{ color: '#CC3838' }}></FilePresentOutlined>
          <AdasTypography component='span' sx={{ marginLeft: '10px' }}>
            <a target='_blank' rel='noopener noreferrer' href={URL.createObjectURL(existingData || data)}>
              {existingData?.name || data.name}
            </a>
          </AdasTypography>
          <AdasTypography component='span' sx={{ marginLeft: '10px' }}>
            <AdasTypography color={theme.palette.text.secondary}>{(displayData.size / 1000000).toFixed(2)} MB</AdasTypography>
          </AdasTypography>
        </AdasBox>
        <CloseIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={handleClose}></CloseIcon>
        {!!progress && !isSuccess && <AdasLinearProgress value={progress} />}
      </AdasBox>
      <AdasBox
        sx={{
          marginLeft: '4px',
          paddingTop: '10px'
        }}
      >
        <AdasTypography color={theme.palette.text.secondary}>{label}</AdasTypography>
      </AdasBox>
    </AdasBox>
  )
}

QuoteAttachment.propTypes = {
  title: PropTypes.string,
  existingData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
}
