import { BarcodeReader } from 'dynamsoft-javascript-barcode'
import PropTypes from 'prop-types'
import React from 'react'

import { VideoDecode } from '@components/vin'
import '../shared/dbr'

export class DynamScanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bShowScanner: true,
      bShowImgDecode: false
    }
  }
  async componentDidMount() {
    try {
      await BarcodeReader.loadWasm()
    } catch (ex) {
      let errMsg
      if (ex.message.includes('network connection error')) {
        errMsg =
          'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.'
      } else {
        errMsg = ex.message || ex
      }
      console.error(errMsg)
      alert(errMsg)
    }
  }

  render() {
    return (
      <VideoDecode
        setVinNumber={this.props.setVinNumber}
        switchToManual={this.props.switchToManual}
        closeModalFunc={this.props.closeModalFunc}
      ></VideoDecode>
    )
  }
}

DynamScanner.propTypes = {
  setVinNumber: PropTypes.func.isRequired,
  switchToManual: PropTypes.func.isRequired,
  closeModalFunc: PropTypes.func.isRequired
}
