export const MIME_TYPES_MAP = {
  '.pdf': 'application/pdf',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.txt': 'text/plain',
  '.png': 'image/png',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg'
}

export const ATTACHMENT_TYPE_MAP = {
  dashImageUrl: 'DASH_PRE',
  dashPostCalibrationImageUrl: 'DASH_POST',
  weightImageUrl1: 'FUEL_WT_COMP_1',
  weightImageUrl2: 'FUEL_WT_COMP_2',
  driverFrontImageUrl: 'EXT_FL',
  driverRearImageUrl: 'EXT_RL',
  passengerFrontImageUrl: 'EXT_FR',
  passengerRearImageUrl: 'EXT_RR',
  driverFrontWheelImageUrl: 'TIRE_FL',
  driverRearWheelImageUrl: 'TIRE_RL',
  passengerFrontWheelImageUrl: 'TIRE_FR',
  passengerRearWheelImageUrl: 'TIRE_RR',
  alignmentImageUrl: 'THRUST_ANGLE_ALIGN',
  alignmentImageUrl2: 'THRUST_ANGLE_ALIGN_2',
  preCalReportScan: 'DOC_PRECAL_SCAN_RPT',
  preCalRepairEstimateScan: 'DOC_PRECAL_REP',
  wheelAlignmentReport: 'DOC_WHEEL_ALGN',
  calibrationReport: 'DOC_CAL_REPORT',
  adasThinkReport: 'DOC_ADAS_THINK',
  additionalPhotos: 'ADDITIONAL_IMG',
  calRequiredReport: 'DOC_CAL_REQUIRED',
  wheelAlignmentScan: 'DOC_WHEEL_ALGN',
  oemProcedureScan: 'DOC_OEM_PROCEDURE',
  partsInvoice: 'DOC_PART_INV',
  postTestScanReport: 'DOC_POST_TST_SCN_RPT',
  frontCamera: 'FRONT_CAMERA',
  rearCamera: 'REAR_CAMERA',
  surroundViewCamera: 'SURROUND_VIEW_CAMERA',
  blindSpotCamera: 'BLIND_SPOT_CAMERA',
  frontRadarCamera: 'FRONT_RADAR_CAMERA',
  docWorkOrder: 'DOC_WORKORDER'
}

export const CALIBRATION_FIELD_MAP = {
  additionalCalPhoto: 'additionalPhotos',
  calibrationReport: 'calibrationReport'
}

export const POST_CALIBRATION_FIELD_MAP = {
  odo_image_id: 'dashPostCalibrationImageUrl',
  cal_req_report_doc: 'calibrationRequiredReport',
  post_test_scan_report_doc: 'postTestScanReport'
}

export const PRE_CALIBRATION_FIELD_MAP = {
  photo_dash_id: 'dashImageUrl',
  photo_weight1: 'weightImageUrl1',
  photo_weight2: 'weightImageUrl2',
  photo_corner_fl: 'driverFrontImageUrl',
  photo_corner_rl: 'driverRearImageUrl',
  photo_corner_fr: 'passengerFrontImageUrl',
  photo_corner_rr: 'passengerRearImageUrl',
  photo_tire_fl: 'driverFrontWheelImageUrl',
  photo_tire_rl: 'driverRearWheelImageUrl',
  photo_tire_fr: 'passengerFrontWheelImageUrl',
  photo_tire_rr: 'passengerRearWheelImageUrl',
  photo_wheel_align: 'alignmentImageUrl',
  photo_wheel_align2: 'alignmentImageUrl2',
  precal_scan_rpt_doc: 'preCalReportScan',
  col_repair_doc: 'preCalRepairEstimateScan',
  cal_req_report_doc: 'calRequiredReport',
  wheel_align_doc: 'wheelAlignmentScan',
  oem_procedure_doc: 'oemProcedureScan'
}

export const PHOTO_SUMMARY_FIELD_MAP = {
  dashPreId: 'dashImageUrl',
  dashPostId: 'dashPostCalibrationImageUrl',
  fuelWeightComp1Id: 'weightImageUrl1',
  fuelWeightComp2Id: 'weightImageUrl2',
  cornerDrFId: 'driverFrontImageUrl',
  cornerDrRId: 'driverRearImageUrl',
  cornerPaFId: 'passengerFrontImageUrl',
  cornerPaRId: 'passengerRearImageUrl',
  tireDrFId: 'driverFrontWheelImageUrl',
  tireDrRId: 'driverRearWheelImageUrl',
  tirePaFId: 'passengerFrontWheelImageUrl',
  tirePaRId: 'passengerRearWheelImageUrl',
  thrustAngleAlignId: 'alignmentImageUrl',
  thrustAngleAlign2Id: 'alignmentImageUrl2'
}

export const PHOTO_DISPLAY_LABEL_MAP = {
  dashImageUrl: 'Dash Pre Calibration',
  dashPostCalibrationImageUrl: 'Dash Post Calibration',
  weightImageUrl1: 'Weight Compensation Added',
  weightImageUrl2: 'Additional Weight Photo (Optional)',
  driverFrontImageUrl: 'Driver Side Front Corner',
  driverRearImageUrl: 'Driver Side Rear Corner',
  passengerFrontImageUrl: 'Passenger Side Front Corner',
  passengerRearImageUrl: 'Passenger Side Rear Corner',
  driverFrontWheelImageUrl: 'Driver Side Front Wheel',
  driverRearWheelImageUrl: 'Driver Side Rear Wheel',
  passengerFrontWheelImageUrl: 'Passenger Side Front Wheel',
  passengerRearWheelImageUrl: 'Passenger Side Rear Wheel',
  alignmentImageUrl: 'Wheel Alignment',
  alignmentImageUrl2: 'Wheel Alignment'
}
