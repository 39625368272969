import PropTypes from 'prop-types'
import { AdasBox } from '@components/wrapper-components'
import { WORK_ORDER_TYPE } from 'constants'

export const IdDisplay = ({ itemId, roNumber, itemType, serviceOnly }) => {
  return (
    <AdasBox
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'flex-start', md: 'center' }
      }}
    >
      <AdasBox
        sx={{
          display: 'inline',
          marginRight: { xs: '0', md: '.2rem' }
        }}
      >
        <span>{itemType} #</span>
        {itemId}
      </AdasBox>
      <AdasBox
        sx={{
          display: 'inline',
          marginLeft: { xs: '0', md: '.2rem' },
          marginRight: { xs: '0', md: '.2rem' }
        }}
      >
        <AdasBox sx={{ display: { xs: 'none', md: 'inline' }, marginRight: { xs: '0', md: '.2rem' } }}>|</AdasBox>
        RO #{roNumber}
      </AdasBox>
      {serviceOnly !== undefined && (
        <AdasBox
          sx={{
            display: 'inline',
            marginLeft: { xs: '0', md: '.2rem' }
          }}
        >
          <AdasBox sx={{ display: { xs: 'none', md: 'inline' }, marginRight: { xs: '0', md: '.2rem' } }}>|</AdasBox>
          {WORK_ORDER_TYPE[serviceOnly] || ''}
        </AdasBox>
      )}
    </AdasBox>
  )
}

IdDisplay.propTypes = {
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  roNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  itemType: PropTypes.string.isRequired,
  sx: PropTypes.object
}
