import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import PropTypes from 'prop-types'
import { AdasLocalizationProvider } from '../wrapper-components/AdasLocalizationProvider'
import 'dayjs/locale/en-gb'

dayjs.extend(utc)
dayjs.extend(timezone)

const defaultStyles = {
  format: 'MM/dd/yyyy'
}

export const SchedulerDatePicker = ({
  disabled,
  sx = {},
  format = defaultStyles.format,
  disableFuture,
  disablePast,
  label,
  value,
  onChange,
  ...rest
}) => {
  const handleDateChange = (newValue) => {
    // Adjust selected date to keep the exact local date
    const localDate = dayjs(newValue).format(format) // Stay in local date
    const utcDate = dayjs(newValue).utc().format() // Convert to UTC

    // Call onChange with adjusted local date
    onChange(localDate) // Store in UTC or local as per your requirement
  }

  return (
    <AdasLocalizationProvider>
      <DatePicker
        disabled={disabled}
        label={label}
        sx={sx}
        format={format}
        disableFuture={disableFuture}
        disablePast={disablePast}
        value={value ? dayjs(value, format) : null}
        onChange={handleDateChange}
        {...rest}
      />
    </AdasLocalizationProvider>
  )
}

SchedulerDatePicker.propTypes = {
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  format: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func
}
