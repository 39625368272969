import { ItemPerPageSelector, SearchOrAddData } from '@components/common'
import { UserInfo } from '@components/user'
import {
  AdasBox,
  AdasButton,
  AdasContainer,
  AdasDrawer,
  AdasPagination,
  AdasPaper,
  AdasStack,
  AdasTable
} from '@components/wrapper-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getAllUsers, getCalibrationCenter } from '../../../api/api'
import { GLOBAL_DIRECTORY_HEADER, TABLE_HEADER_STYLE } from '../../../constants'
import { scrollPageTop } from '../../../utils/useScrollTop'
import { ccidFormatter, downloadAsFile, processCenterRoles, roundToGreatestInteger } from '../../../utils/utils'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useUserStore } from '@caradasstore/UserStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { Logger } from '../../../logger'

export const ManageUsersListPage = () => {
  const MAX_PAGE_SIZE = 500
  const [searchInput, setSearchInput] = useState('')
  const [users, setUsers] = useState([])
  const [isUserInfo, setIsUserInfo] = useState(false)
  const [userData, setUserData] = useState({})
  const [userCenters, setUserCenters] = useState([])
  const [pageNo, setPageNo] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(MAX_PAGE_SIZE)
  const [refreshNow, setRefeshNow] = useState(1)
  const [sort, setSort] = useState('ASC')
  const [sortField, setSortField] = useState('ccid')
  const currentUser = useUserStore((store) => store.currentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const history = useHistory()

  const paginationHandler = useCallback((_event, page) => {
    setPageNo(page - 1)
    scrollPageTop()
  }, [])

  const pageSizeHandler = useCallback((event) => {
    setPageNo(0)
    setPageSize(event.target.value)
    scrollPageTop()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const ccListResponse = await getAllUsers({
          pageNo: 0,
          pageSize: 500
        })
        setLoading(false)
        if (ccListResponse.status === 200) {
          const users = ccListResponse.data.userDisplayDtos.reduce((accum, user) => {
            const [role, centers] = processCenterRoles(user) // getting multiple cc for a single user
            const userData = {
              name: user.name || '',
              role: role || '',
              is_active: user.is_active || false,
              sourceUser: user,
              centers: centers
            }

            if (centers.length === 0) {
              accum.push({
                ...userData,
                role: role === 'Admin' ? '-- All --' : '-- None --',
                ccid: ccidFormatter(user.calibrationCenterId || ''),
                calibrationCenterId: user.calibrationCenterId
              })
            } else {
              centers.forEach((center) => {
                accum.push({
                  ...userData,
                  center: center.name || '',
                  ccid: ccidFormatter(center.id),
                  calibrationCenterId: center.id
                })
              })
            }

            return accum
          }, [])

          setTotalCount(users.length)
          setUsers(users)
          scrollPageTop()
        }
      } catch (error) {
        setLoading(false)
        Logger.error({ message: error, payload: { file: 'ManageUsersListPage', method: 'getAllUsers' } })
      }
    }

    fetchData()
  }, [refreshNow])

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value)
  }

  const userInfoSelect = (content) => {
    setUserCenters(content.centers)
    setUserData(content.sourceUser)
    setIsUserInfo(true)
  }

  const switChCalibrationCenter = async (content) => {
    const ccResponse = await getCalibrationCenter({
      ccid: content.calibrationCenterId
    })
    if (ccResponse.status === 200) {
      setCenterDetail(ccResponse.data)
      history.push('/')
    }
  }

  const userListSelectionHandler = (content, columnName) => {
    if (columnName === 'name') {
      userInfoSelect(content)
    } else if (columnName === 'center') {
      switChCalibrationCenter(content)
    }
  }

  const clickSortHandler = useCallback(
    (field) => {
      if (sortField === field) {
        setSort(sort === 'ASC' ? 'DESC' : 'ASC')
      } else {
        setSort('ASC')
        setSortField(field)
      }
    },
    [sort, sortField]
  )

  const userDisplayList = useMemo(() => {
    let filteredSortedList = users
    if (searchInput) {
      filteredSortedList = users.filter((user) => {
        const search = searchInput.toLowerCase()
        return (
          user.name?.toLowerCase().includes(search) ||
          user.ccid?.toLowerCase().includes(search) ||
          user.role?.toLowerCase().includes(search) ||
          user.center?.toLowerCase().includes(search)
        )
      })
    }
    filteredSortedList = filteredSortedList.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sort === 'ASC' ? -1 : 1
      }
      if (a[sortField] > b[sortField]) {
        return sort === 'ASC' ? 1 : -1
      }
      return 0
    })

    const startIndex = pageNo * pageSize
    const endIndex = startIndex + pageSize
    return filteredSortedList.slice(startIndex, endIndex)
  }, [users, searchInput, sort, sortField, pageNo, pageSize])

  const downloadUsers = useCallback((info, activeOnly = false) => {
    const users = activeOnly ? info.filter((user) => user.is_active) : info
    let userExport = users.map((user, index) => [
      index,
      user.ccid,
      '"' + user.center + '"',
      '"' + user.name + '"',
      user.sourceUser.email,
      user.role + '\n'
    ])
    userExport = [['index', 'ccid', 'center', 'name', 'email', 'role\n'], ...userExport]
    const fileName = 'manageUserExport.csv'
    const value = new Blob(userExport, { type: 'text/plain' })
    downloadAsFile(value, fileName)
  }, [])

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
        key='table'
      >
        <SearchOrAddData
          searchInput={searchInput}
          searchInputHandler={searchInputHandler}
          searchPlaceholder='Search'
          variant='withoutAddButton'
          searchInputPlaceholder='CC #, CC Name, User Name, Role'
          sx={{ marginBottom: '2rem' }}
        />

        {isUserInfo && (
          <AdasDrawer anchor={'right'} open={isUserInfo} onClose={() => setIsUserInfo(false)}>
            <UserInfo
              allowDelete={false}
              updateClientListHandler={() => {
                setRefeshNow(refreshNow + 1)
              }}
              userInfo={userData}
              closeDrawer={() => setIsUserInfo(false)}
              global={!currentUser.is_admin}
              userCenters={userCenters}
            />
          </AdasDrawer>
        )}

        {users.length > 0 && [
          <AdasTable
            containerProps={{ component: AdasPaper, sx: { paddingBottom: '1rem' } }}
            tableProps={{
              sx: {
                marginTop: '20px',
                '.MuiTableCell-root': { border: 'none', padding: '2px 10px' },
                overflowX: 'scroll'
              },
              'aria-label': 'global table'
            }}
            key='tableContainer'
            columns={GLOBAL_DIRECTORY_HEADER}
            sortField={sortField}
            sortDirection={sort}
            onSort={clickSortHandler}
            headerStyle={TABLE_HEADER_STYLE}
            data={userDisplayList}
            onSelect={userListSelectionHandler}
          />,
          <AdasBox sx={{ margin: '20px 0px', textAlign: 'end' }} key='buttonArea'>
            <AdasButton
              key='exportFiltered'
              variant='contained'
              disabled={!searchInput && pageSize === MAX_PAGE_SIZE}
              aria-label='Export List'
              color='primary'
              onClick={() => downloadUsers(userDisplayList)}
              sx={{ marginRight: '1rem' }}
            >
              Export Filtered List
            </AdasButton>
            <AdasButton
              key='exportAll'
              variant='contained'
              aria-label='Export List'
              color='primary'
              onClick={() => downloadUsers(users, true)}
            >
              Export List
            </AdasButton>
          </AdasBox>
        ]}
      </AdasPaper>
      <AdasPaper
        elevation={0}
        sx={{
          padding: '20px',
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex'
        }}
      >
        <AdasBox sx={{ width: '20%' }}>
          <ItemPerPageSelector pageSizeHandler={pageSizeHandler} pageSize={pageSize} />
        </AdasBox>
        <AdasBox sx={{ width: '80%', display: 'flex', justifyContent: 'end' }}>
          <AdasStack spacing={2}>
            <AdasPagination onChange={paginationHandler} count={roundToGreatestInteger(totalCount / pageSize)} shape='rounded' />
          </AdasStack>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
