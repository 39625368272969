import { useEffect } from 'react'
import parse from 'html-react-parser'
import CloseIcon from '@mui/icons-material/Close'

import { AdasBox, AdasTypography, AdasContainer, AdasButton } from '@components/wrapper-components'
import { InfoPopover } from '@components/popover'
import './broadcastBar.css'
import { useNotificationSubscription } from '@hooks'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useBroadcastStore } from '@caradasstore/BroadcastStore'
import { getActiveBroadcast } from 'api/api'
import { htmlParseOptions } from '../../utils/utils'
import theme from 'theme/theme'

export const BroadcastBar = () => {
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const broadcastmessage = useBroadcastStore((store) => store.broadCastMessage)
  const setBroadcastMessage = useBroadcastStore((store) => store.setBroadcastMessage)

  useNotificationSubscription(centerDetail?.id)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const broadcastResponse = await getActiveBroadcast()
        if (broadcastResponse?.data) {
          setBroadcastMessage(broadcastResponse.data)
        } else {
          setBroadcastMessage(null)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  const clearBroadcastMessage = () => setBroadcastMessage(null)

  return (
    broadcastmessage?.message && (
      <AdasContainer maxWidth='lg' sx={{ marginBottom: '20px' }}>
        <AdasBox className={`notification-message-section ${broadcastmessage.type === 'CRITICAL' ? 'critical' : ''}`}>
          <AdasBox className='notification-message-content-title'>
            <InfoPopover
              iconColor={broadcastmessage.type === 'CRITICAL' ? '#D22A23' : '#001B3F'}
              ariaLabel='Information on default'
              enableClick={false}
            />
            <AdasTypography className='notification-subject'>{broadcastmessage.subject}</AdasTypography>
            <AdasButton
              buttonType='iconButton'
              sx={{
                padding: '2px',
                cursor: 'pointer',
                marginRight: '10px',
                color: theme.palette.info.main
              }}
              onClick={clearBroadcastMessage}
            >
              <CloseIcon fontSize='small' />
            </AdasButton>
          </AdasBox>
          <AdasBox className='notification-message'>{parse(broadcastmessage.message, htmlParseOptions)}</AdasBox>
        </AdasBox>
      </AdasContainer>
    )
  )
}
