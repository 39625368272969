import CloseIcon from '@mui/icons-material/Close'
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useSubmitFileAttachment, removeAttachment, getFile } from '../../../api/api'
import { MIME_TYPES_MAP, ATTACHMENT_TYPE_MAP } from '../../../variable-maps'
import { AdasTypography, AdasLinearProgress, AdasButton, AdasBox } from '@components/wrapper-components'
import { useMessageStore } from '../../../store'
import { Logger } from '../../../logger'
import { AdasLoader } from '@components/adas-loader'

const BYTES_IN_MB = 1000000

export const FileUploadLongCard = (props) => {
  const theme = useTheme()
  const {
    typeLabel,
    title,
    workOrderData,
    existingData,
    removeFile,
    additionalDocument,
    additionalDocumentHandler,
    isClientInvoice = false,
    disabled = false,
    type = 'workorders'
  } = props
  const [data, setData] = useState(null)
  const [fileURL, setFileURL] = useState('')
  const displayData = data ? data : existingData
  const fileRef = useRef(null)
  const { progress, uploadData, isSuccess, attachmentId, resetIsSuccess } = useSubmitFileAttachment()
  const addMessage = useMessageStore((store) => store.addMessage)
  const [loading, setLoading] = useState(false)

  const handleUpload = () => {
    const file = fileRef.current.files[0]
    if (!file) {
      return
    }
    const suppoprtedFileTypes = Object.values(MIME_TYPES_MAP)
    if (!suppoprtedFileTypes.includes(file.type)) {
      addMessage({ text: 'File format not supported!' })
      return
    }
    if (additionalDocument) {
      additionalDocumentHandler(file)
    }
    setData(file)
  }

  const truncateFileName = useCallback((fileName) => {
    if (fileName.length <= 80) return fileName
    const extStart = fileName.lastIndexOf('.')
    let ext = fileName.slice(extStart, fileName.length)
    let name = fileName.slice(0, 80 - ext.length)
    return name + ext
  }, [])

  const uploadFile = async (file, docType) => {
    const formData = new FormData()
    formData.append('file', file, truncateFileName(file.name))

    let attachmentType = ATTACHMENT_TYPE_MAP[docType]
    if (additionalDocument) {
      attachmentType = 'DOC_WORKORDER'
    }
    const params = {
      data: formData,
      workorderId: workOrderData.id,
      attachmentType,
      type,
      onFailed: () => setData(null)
    }
    const response = uploadData(params)
    response.then(() => {}).catch(() => setData(null))
  }

  const handleClose = async () => {
    if (!disabled) {
      const params = {
        workorderId: workOrderData.id,
        attachmentId: existingData ? existingData.id : attachmentId
      }
      const removeResponse = await removeAttachment(params)
      if (removeResponse.status === 200) {
        if (existingData || additionalDocument) {
          removeFile()
        }
        setData(null)
      }
    }
  }

  const createUrl = (data) => {
    const extIndex = data.name.lastIndexOf('.')
    const fileExt = data.name.slice(extIndex, data.name.length)
    const fileBlob = new Blob([data], {
      type: MIME_TYPES_MAP[fileExt.toLowerCase()] || 'application/octet-stream'
    })
    return URL.createObjectURL(fileBlob)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (existingData) {
        try {
          setLoading(true)
          const response = await getFile({
            attachmentId: existingData.id
          })
          const fileValue = new File([response.data], existingData.name)
          fileValue.id = existingData.id
          displayData.size = fileValue.size
          const url = createUrl(fileValue)
          setFileURL(url)
        } catch (error) {
          Logger.error({ message: error, payload: { existingData } })
        } finally {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      URL.revokeObjectURL(fileURL)
    }
    /* eslint-disable */
  }, [existingData])

  useEffect(() => {
    if (data) {
      uploadFile(data, typeLabel)
      setFileURL(createUrl(data))

      return () => {
        URL.revokeObjectURL(fileURL)
      }
    } else {
      resetIsSuccess()
    }
    /* eslint-disable */
  }, [data])

  useEffect(() => {
    if (additionalDocument && !additionalDocument.removed) {
      setData(additionalDocument)
    } else {
      resetIsSuccess()
    }
    /* eslint-disable */
  }, [additionalDocument])

  return !displayData ? (
    !isClientInvoice && (
      <AdasBox
        sx={{
          height: '92px',
          border: `1px dashed ${theme.palette.divider}`,
          borderRadius: '4px',
          display: 'flex',
          margin: '16px 0px'
        }}
      >
        {' '}
        <AdasButton
          component='label'
          sx={{
            fontSize: 12,
            margin: 'auto',
            display: 'flex',
            alignItems: 'center'
          }}
          startIcon={<FileUploadOutlinedIcon />}
          disabled={disabled}
        >
          <AdasTypography sx={{ fontSize: 12 }}>{title}</AdasTypography>
          <input ref={fileRef} type='file' hidden onChange={handleUpload} accept='.jpg, .jpeg, .png, .pdf, .doc, .docx, .txt' />
        </AdasButton>
      </AdasBox>
    )
  ) : loading ? (
    <AdasLoader
      sx={{
        borderRadius: '4px',
        margin: '16px 0px',
        py: '16px'
      }}
    />
  ) : (
    <AdasBox
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        background: theme.palette.background.default,
        padding: '16px',
        margin: '16px 0px',
        flexDirection: 'column'
      }}
    >
      <AdasBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <FilePresentOutlinedIcon sx={{ color: '#CC3838' }}></FilePresentOutlinedIcon>
        <AdasTypography component='span' sx={{ marginLeft: '10px' }}>
          <a target='_blank' rel='noopener noreferrer' href={fileURL}>
            {existingData?.name || data?.name}
          </a>
        </AdasTypography>
        <AdasTypography component='span' sx={{ marginLeft: '10px' }}>
          <AdasTypography color={theme.palette.text.secondary}>{(displayData.size / BYTES_IN_MB).toFixed(2)} MB</AdasTypography>
        </AdasTypography>
        <AdasBox sx={{ marginLeft: 'auto' }}>
          {!isClientInvoice && (
            <CloseIcon
              sx={{
                float: 'right',
                color: disabled ? 'grey' : theme.palette.action.active.light,
                cursor: disabled ? 'not-allowed' : 'pointer'
              }}
              onClick={handleClose}
            ></CloseIcon>
          )}
        </AdasBox>
      </AdasBox>
      {!existingData && (
        <AdasBox sx={{ marginLeft: '0px', marginTop: '10px', width: '100%' }}>
          {!!progress && !isSuccess && <AdasLinearProgress value={progress} />}
        </AdasBox>
      )}
      <AdasBox
        sx={{
          marginLeft: '4px',
          paddingTop: '10px'
        }}
      >
        <AdasTypography color={theme.palette.text.secondary}>{props.label}</AdasTypography>
      </AdasBox>
    </AdasBox>
  )
}

FileUploadLongCard.propTypes = {
  typeLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  workOrderData: PropTypes.object.isRequired,
  existingData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  removeFile: PropTypes.func.isRequired,
  additionalDocument: PropTypes.object,
  additionalDocumentHandler: PropTypes.func,
  isClientInvoice: PropTypes.bool,
  disabled: PropTypes.bool
}
