import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

export const AdasSearchMenu = ({ options = [], value, onChange, disabled, ...rest }) => {
  return (
    <TextField select value={value} onChange={onChange} disabled={disabled} sx={rest.sx} {...rest}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

AdasSearchMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      name: PropTypes.node.isRequired
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
