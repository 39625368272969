import { useState, useCallback, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import parse from 'html-react-parser'

import {
  AdasBox,
  AdasGrid,
  AdasButton,
  AdasCard,
  AdasContainer,
  AdasDateTimePicker,
  AdasFormLabel,
  AdasRadioGroup,
  AdasRichTextEditor,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import { useUserStore } from '@caradasstore/UserStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { BUTTONS_DISPLAY_LABEL, DATE_FORMATS, GENERIC_MESSAGES, RadioGroup_Constants } from '../../constants'
import './BroadcastMessagePage.css'
import dayjs from 'dayjs'
import { ConfirmModal } from '@components/common'
import { sendBroadcastNotification, updateBroadcastNotification, getActiveBroadcast, endBroadcast } from '../../api/api'
import { Logger } from '../../logger'
import theme from 'theme/theme'
import { StatusInfo } from '@components/status-info'
import { InfoPopover } from '@components/popover'
import { htmlParseOptions } from '../../utils/utils'

export const BroadcastMessagePage = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showEndBroadcastModal, setShowEndBroadcastModal] = useState(false)
  const [messageId, setMessageId] = useState()
  const [messageContent, setMessageContent] = useState('')
  const [subject, setSubject] = useState('')
  const [isMessageVisible, setIsMessageVisible] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [adminCheckFailureMessage, SetAdminCheckFailureMessage] = useState(null)
  const currentUser = useUserStore((store) => store.currentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const isRichTextEditorEmpty = (content) => {
    const text = content.replace(/<[^>]*>/g, '')
    const trimmedText = text.trim()
    return trimmedText.length === 0
  }
  const validateForm = useCallback(() => {
    if (!errorMessage && selectedType && subject && messageContent && !isRichTextEditorEmpty(messageContent)) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [selectedType, subject, messageContent, errorMessage])

  useEffect(() => {
    validateForm()
  }, [selectedType, subject, messageContent, errorMessage])

  const handleTypeChange = useCallback((event) => {
    setSelectedType(event.target.value)
  }, [])

  const handleEndBroadCast = useCallback(async () => {
    try {
      await endBroadcast(messageId)
      setShowEndBroadcastModal(false)
      setMessageId('')
      setMessageContent('')
      setSubject('')
      setSelectedType('')
      setStartDate()
      setEndDate()
      setIsFormValid(false)
      setIsEditMode(false)
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'handleEndBroadCast', messageId } })
    }
  }, [messageId])

  const handleClearForm = useCallback(async () => {
    setShowEndBroadcastModal(false)
    setMessageContent('')
    setSubject('')
    setSelectedType('')
    setStartDate()
    setEndDate()
    setErrorMessage('')
    setIsFormValid(false)
  })

  const handleCancelForm = () => {
    setIsEditMode(false)
  }

  const modalContinue = useCallback(
    (code) => {
      const sendNotification = async () => {
        try {
          setLoading(true)
          const notificationData = {
            message: messageContent,
            subject: subject,
            type: selectedType,
            start_at: startDate?.toISOString() || null,
            end_at: endDate?.toISOString() || null,
            created_by: currentUser.id,
            created_at: dayjs().toISOString()
          }

          const sendNotificationData = await sendBroadcastNotification(notificationData)
          if (sendNotificationData) {
            setMessageId(sendNotificationData.data.id)
          }
        } catch (error) {
          Logger.error({ message: error, payload: { file: 'broadcastmessage', message: messageContent } })
        } finally {
          setLoading(false)
        }
      }

      const updateNotification = async () => {
        try {
          setLoading(true)
          const notificationData = {
            id: messageId,
            message: messageContent,
            subject: subject,
            type: selectedType,
            start_at: startDate?.toISOString() || null,
            end_at: endDate?.toISOString() || null,
            created_by: currentUser.id,
            created_at: dayjs().toISOString()
          }

          const sendNotificationData = await updateBroadcastNotification({ broadcastId: messageId, data: notificationData })
          if (sendNotificationData) {
            setMessageId(sendNotificationData.data.id)
          }
        } catch (error) {
          Logger.error({ message: error, payload: { file: 'broadcastmessage', message: messageContent } })
        } finally {
          setLoading(false)
          setIsEditMode(false)
        }
      }

      if (code === '2180') {
        if (messageId) {
          updateNotification()
        } else {
          sendNotification()
        }
        SetAdminCheckFailureMessage(null)
        setShowModal(false)
      } else {
        SetAdminCheckFailureMessage(GENERIC_MESSAGES.INCORRECT_ADMIN_CODE)
        setShowModal(true)
      }
    },
    [isFormValid, messageId, messageContent, subject, selectedType, currentUser]
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const broadcastResponse = await getActiveBroadcast()
        if (broadcastResponse?.data) {
          const broadcastData = broadcastResponse?.data
          setMessageId(broadcastData.id)
          setSelectedType(broadcastData.type)
          setMessageContent(broadcastData.message)
          setSubject(broadcastData.subject)
          setStartDate(broadcastData.start_at ? dayjs(broadcastData.start_at) : null)
          setEndDate(broadcastData.end_at ? dayjs(broadcastData.end_at) : null)
        }
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'broadcastmessage' } })
      }
    }

    fetchData()
  }, [])

  const handleConfirmModal = useCallback(() => setShowModal(true))
  const handleBroadcastEndModal = useCallback(() => setShowEndBroadcastModal(true))

  const handleStartDateChange = useCallback(
    (newDate) => {
      if (endDate && dayjs(newDate).isAfter(dayjs(endDate))) {
        setErrorMessage('Start date must be less than end date')
      } else if (endDate && dayjs(newDate).isSame(dayjs(endDate))) {
        setErrorMessage('Start date and end date should not be equal')
      } else {
        setErrorMessage('')
      }
      setStartDate(newDate)
    },
    [endDate]
  )

  const handleEndDateChange = useCallback(
    (newDate) => {
      if (startDate && dayjs(newDate).isBefore(dayjs(startDate))) {
        setErrorMessage('End date must be greater than start date')
      } else if (startDate && dayjs(newDate).isSame(dayjs(startDate))) {
        setErrorMessage('Start date and end date should not be equal')
      } else {
        setErrorMessage('')
      }
      setEndDate(newDate)
    },
    [startDate]
  )

  const handleSubjectChange = (event) => {
    setSubject(event.target.value)
  }

  const handleEditorChange = useCallback((content) => {
    setMessageContent(content)
  }, [])

  const handleButtonClick = useCallback(() => {
    setIsMessageVisible(true) // Set visibility to true when viewing message
  }, [messageContent])

  const handleEditClick = useCallback(() => {
    setIsEditMode(true)
  }, [])

  return (
    <AdasContainer maxWidth='lg' sx={{ paddingBottom: '10px' }}>
      <AdasCard
        sx={{
          margin: '17px 0px',
          padding: '16px 22px'
        }}
      >
        <AdasGrid container spacing={2}>
          <AdasGrid container item xs={12} justifyContent='space-between' alignItems='flex-start'>
            <AdasBox sx={{ margin: '.6rem 0', textAlign: 'left' }}>
              <AdasFormLabel id='type-label' align='left' sx={{ fontWeight: '600', color: 'rgba(0, 0, 0, 0.87)' }}>
                Type
              </AdasFormLabel>
              <AdasRadioGroup
                disabled={!isEditMode && !!messageId}
                options={RadioGroup_Constants.map((item) => ({
                  value: item.value,
                  label: item.label
                }))}
                row
                name='type'
                value={selectedType}
                onChange={handleTypeChange}
              />
            </AdasBox>
            {messageId && <StatusInfo color={'primary'} label={'Active'} sx={{ marginTop: '.6rem' }} />}
          </AdasGrid>
          <AdasGrid item xs={12}>
            <AdasTextField
              name='subject'
              sx={{ marginBottom: '20px', marginTop: '10px' }}
              id='subject'
              label='Subject'
              variant='outlined'
              fullWidth={true}
              disabled={!isEditMode && !!messageId}
              InputLabelProps={{
                shrink: true
              }}
              value={subject}
              onChange={handleSubjectChange}
            />
          </AdasGrid>
          <AdasGrid item xs={12}>
            <AdasRichTextEditor disabled={!isEditMode && messageId} value={messageContent} onChange={handleEditorChange} />
          </AdasGrid>

          <AdasGrid item xs={12} sx={{ marginTop: '10px' }}>
            <AdasButton
              disabled={!isEditMode && !!messageId}
              onClick={handleButtonClick}
              variant='outlined'
              aria-label='view'
              color='primary'
              sx={{ marginRight: '10px' }}
            >
              Preview Message
            </AdasButton>
          </AdasGrid>

          {isMessageVisible && (
            <AdasGrid item xs={12} sx={{ marginTop: '10px' }}>
              <AdasBox className={`notification-message-section ${selectedType === 'CRITICAL' ? 'critical' : ''}`}>
                <AdasBox className='notification-message-content-title'>
                  <InfoPopover
                    iconColor={selectedType === 'CRITICAL' ? '#D22A23' : '#001B3F'}
                    ariaLabel='Information on default'
                    enableClick={false}
                  />
                  <AdasTypography className='notification-subject'>{subject}</AdasTypography>
                  <CloseIcon
                    className='notification-close'
                    fontSize='small'
                    onClick={() => setIsMessageVisible(!isMessageVisible)}
                  ></CloseIcon>
                </AdasBox>
                <AdasBox className='notification-message'>{parse(messageContent, htmlParseOptions)}</AdasBox>
              </AdasBox>
            </AdasGrid>
          )}

          <AdasGrid item xs={12} sm={6} md={6} lg={4} sx={{ marginTop: '10px' }}>
            <AdasDateTimePicker
              disabled={!isEditMode && !!messageId}
              label='Start Date and Time'
              variant='outlined'
              name='start_date'
              disableFuture={false}
              value={startDate}
              onChange={handleStartDateChange}
              format={DATE_FORMATS.ISO_DATE_TIME_SHORT_STRING}
              sx={{ width: '100%' }}
            />
            {errorMessage && (
              <AdasTypography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  letterSpacing: '0.15000000596046448px',
                  textAlign: 'left',
                  mt: '10px',
                  ml: '5px',
                  color: theme.palette.error.main
                }}
              >
                {errorMessage}
              </AdasTypography>
            )}
          </AdasGrid>
          <AdasGrid item xs={12} sm={6} md={6} lg={4} sx={{ marginTop: '10px' }}>
            <AdasDateTimePicker
              disabled={!isEditMode && !!messageId}
              label='End Date and Time'
              variant='outlined'
              name='end_date'
              disableFuture={false}
              value={endDate}
              onChange={handleEndDateChange}
              format={DATE_FORMATS.ISO_DATE_TIME_SHORT_STRING}
              sx={{ width: '100%' }}
            />
          </AdasGrid>
        </AdasGrid>
        <AdasBox sx={{ overflow: 'auto', marginTop: '20px' }}>
          <AdasBox sx={{ float: 'right' }}>
            {messageId ? (
              <>
                {!isEditMode ? (
                  <>
                    <AdasButton variant='outlined' aria-label='edit' color='primary' sx={{ marginRight: '10px' }} onClick={handleEditClick}>
                      Edit
                    </AdasButton>
                    <AdasButton
                      onClick={handleBroadcastEndModal}
                      variant='contained'
                      type='submit'
                      aria-label='end'
                      color='primary'
                      disabled={!isFormValid} // Disable button if form is not valid
                    >
                      END BROADCAST
                    </AdasButton>
                  </>
                ) : (
                  <>
                    <AdasButton
                      variant='outlined'
                      aria-label='cancel'
                      color='primary'
                      sx={{ marginRight: '10px' }}
                      onClick={handleCancelForm}
                    >
                      Cancel
                    </AdasButton>
                    <AdasButton
                      onClick={handleConfirmModal}
                      variant='contained'
                      type='submit'
                      aria-label='update'
                      color='primary'
                      sx={{ marginRight: '10px' }}
                      disabled={!isFormValid}
                    >
                      Update Broadcast
                    </AdasButton>
                  </>
                )}
              </>
            ) : (
              <>
                <AdasButton variant='outlined' aria-label='cancel' color='primary' sx={{ marginRight: '10px' }} onClick={handleClearForm}>
                  Clear
                </AdasButton>
                <AdasButton
                  onClick={handleConfirmModal}
                  variant='contained'
                  type='submit'
                  aria-label='save'
                  color='primary'
                  disabled={!isFormValid} // Disable button if form is not valid
                >
                  START BROADCAST
                </AdasButton>
              </>
            )}
          </AdasBox>
        </AdasBox>
      </AdasCard>
      <ConfirmModal
        open={showModal}
        handleClose={() => {
          SetAdminCheckFailureMessage(null)
          setShowModal(false)
        }}
        handleContinue={modalContinue}
        title={GENERIC_MESSAGES.START_BROADCAST_TITLE}
        adminCheckFailureMessage={adminCheckFailureMessage}
        message={
          <AdasTypography variant='body1' className='custom-message'>
            {GENERIC_MESSAGES.BROADCAST_INFO_MESSAGE}
          </AdasTypography>
        }
        confirmLabel={BUTTONS_DISPLAY_LABEL.CONFIRM}
        adminCheck={true}
      />
      <ConfirmModal
        open={showEndBroadcastModal}
        handleClose={() => setShowEndBroadcastModal(false)}
        handleContinue={handleEndBroadCast}
        title={GENERIC_MESSAGES.END_BROADCAST_TITLE}
        message={GENERIC_MESSAGES.END_BROADCAST_MESSAGE}
        confirmLabel={BUTTONS_DISPLAY_LABEL.YES}
      />
    </AdasContainer>
  )
}
