import { InputLabel } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasInputLabel = ({ children, ...props }) => {
  return <InputLabel {...props}>{children}</InputLabel>
}

AdasInputLabel.propTypes = {
  children: PropTypes.node.isRequired
}
