import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

import { AdasBox, AdasButton, AdasContainer, AdasDrawer, AdasPaper, AdasTabs, AdasTypography } from '@components/wrapper-components'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { ProductForm, ProductInfo, ProductList } from '@components/product'
import { Logger } from '../../logger'
import { getCalibrationList, getServiceList } from '../../api/api'
import theme from 'theme/theme'

export const ProductsPage = () => {
  const [calibrationList, setCalibrationList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [currentTab, setCurrentTab] = React.useState('calibrations')
  const [isProductInfo, setIsProductInfo] = useState(false)
  const [isAddProduct, setIsAddProduct] = useState(false)
  const [productData, setProductData] = useState({})
  const ccId = useCenterStore((store) => store.ccId)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const tabs = [
    { label: 'Calibrations', value: 'calibrations' },
    { label: 'Services', value: 'services' }
  ]

  const fetchServices = async () => {
    const serviceListResponse = await getServiceList({
      ccid: ccId
    })
    if (serviceListResponse.status === 200) {
      setServiceList(serviceListResponse.data.sort(sortByName))
    }
  }

  const fetchCalibrations = async () => {
    const calibrationListResponse = await getCalibrationList({
      ccid: ccId
    })
    if (calibrationListResponse.status === 200) {
      setCalibrationList(calibrationListResponse.data.sort(sortByName))
    }
  }

  const changeTab = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const selectProduct = (product) => {
    setProductData(product)
    setIsProductInfo(true)
  }

  const updateProductList = () => {
    const fetchProductList = currentTab === 'calibrations' ? fetchCalibrations : fetchServices
    fetchProductList()
    setIsAddProduct(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        await fetchCalibrations()
        await fetchServices()
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'ProductsPage' } })
      } finally {
        setLoading(false)
      }
    }

    fetchData()
    /* eslint-disable */
  }, [])

  const sortByName = (a, b) => {
    const name1 = a.name.toUpperCase()
    const name2 = b.name.toUpperCase()

    let comparison = 0

    if (name1 > name2) {
      comparison = 1
    } else if (name1 < name2) {
      comparison = -1
    }
    return comparison
  }

  return (
    <AdasContainer maxWidth='lg' sx={{ paddingBottom: '10px' }}>
      {isProductInfo && (
        <AdasDrawer anchor={'right'} open={isProductInfo} onClose={() => setIsProductInfo(false)}>
          <AdasBox>
            <ProductInfo
              product={productData}
              productTypeName={currentTab}
              closeDrawer={() => setIsProductInfo(false)}
              onSaveSuccess={updateProductList}
            />
          </AdasBox>
        </AdasDrawer>
      )}
      {isAddProduct && (
        <AdasDrawer anchor={'right'} open={isAddProduct} onClose={() => setIsAddProduct(false)}>
          <AdasBox className='drawer-title-container'>
            <AdasTypography variant='h6' component='span'>
              Product Info
            </AdasTypography>
            <AdasButton
              buttonType='iconButton'
              sx={{
                padding: '2px',
                cursor: 'pointer',
                color: theme.palette.info.main
              }}
              onClick={() => setIsAddProduct(false)}
            >
              <CloseIcon fontSize='small' />
            </AdasButton>
          </AdasBox>
          <ProductForm
            productData={{ calibration_center_id: ccId }}
            productTypeName={currentTab}
            onSaveSuccess={updateProductList}
            close={() => setIsAddProduct(false)}
          />
        </AdasDrawer>
      )}
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        <AdasTabs tabs={tabs} value={currentTab} onChange={changeTab} aria-label='product listing tab set' />
        {currentTab === 'calibrations' && (
          <ProductList
            products={calibrationList}
            productTypeName='Calibrations'
            onAddProduct={setIsAddProduct}
            onSelectProduct={selectProduct}
          />
        )}
        {currentTab === 'services' && (
          <ProductList products={serviceList} productTypeName='Services' onAddProduct={setIsAddProduct} onSelectProduct={selectProduct} />
        )}
      </AdasPaper>
    </AdasContainer>
  )
}
