import './QuickBookForm.css'
import theme from 'theme/theme'
import { QuickBooksIcon } from './QuickBooksIcon'
import { AdasGrid, AdasButton, AdasTypography, AdasButtonGroup, AdasBox, AdasTextField } from '@components/wrapper-components'
import { InfoPopover } from '@components/popover'

export const QuickBookForm = ({
  isConnected,
  optInLineItems,
  isAdminUser,
  qboClassName,
  qboClassNameErrorMessage,
  connectToQuickBooks,
  disconnectFromQuickBooks,
  handleLineItemsOptIn,
  handleQboClassNameChange,
  updateQboClassName
}) => {
  return (
    <AdasBox>
      <AdasTypography sx={{ fontWeight: '600', fontSize: '.875rem' }} variant='h6'>
        QuickBooks Settings
      </AdasTypography>
      <AdasGrid container spacing={2} alignItems='center'>
        <AdasGrid item xs={12} sm={8} md={6} container alignItems='center' className='inline-content'>
          <QuickBooksIcon inactive={!isConnected} />
          <AdasTypography align='center' sx={{ marginLeft: '.5rem', fontSize: '0.875rem' }}>
            {'QuickBooks Online Status: '}
            {
              <AdasTypography component='span' fontWeight='bold' color={isConnected ? theme.palette.success.main : 'error'}>
                {isConnected ? 'Connected' : 'Disconnected'}
              </AdasTypography>
            }
          </AdasTypography>
        </AdasGrid>
        <AdasGrid item xs={12} sm={4} md={6} container justifyContent='flex-end' alignItems='center' style={{ textAlign: 'right' }}>
          {isConnected ? (
            <AdasButton
              sx={{ width: { xs: '100%', sm: 'auto' }, minWidth: '100px' }}
              variant='contained'
              onClick={disconnectFromQuickBooks}
            >
              Disconnect
            </AdasButton>
          ) : (
            <AdasButton sx={{ width: { xs: '100%', sm: 'auto' }, minWidth: '100px' }} variant='contained' onClick={connectToQuickBooks}>
              Connect
            </AdasButton>
          )}
        </AdasGrid>
      </AdasGrid>

      {isAdminUser ? (
        <AdasGrid container alignItems='center' sx={{ marginTop: '15px' }}>
          <AdasGrid item xs={12} sm={8} md={6}>
            <AdasTypography sx={{ fontSize: '.875rem' }}>Include Line Items on QBO Invoice</AdasTypography>
          </AdasGrid>
          <AdasGrid item xs={12} sm={4} md={6} container justifyContent='flex-end' alignItems='center'>
            <AdasButtonGroup
              type='toggleButton'
              value={optInLineItems ? 'Yes' : 'No'}
              exclusive
              onChange={handleLineItemsOptIn}
              aria-label='opt-in'
              name='optIn'
              sx={{ width: { xs: '100%', sm: 'auto' }, mt: { xs: '10px', sm: 0 } }}
            >
              <AdasButton
                buttonType='toggleButton'
                value='Yes'
                color='primary'
                aria-label='Yes'
                sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
              >
                Yes
              </AdasButton>
              <AdasButton
                buttonType='toggleButton'
                value='No'
                color='primary'
                aria-label='No'
                sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
              >
                No
              </AdasButton>
            </AdasButtonGroup>
          </AdasGrid>
        </AdasGrid>
      ) : null}

      <AdasGrid container alignItems='center' sx={{ marginTop: '15px' }}>
        <AdasGrid item xs={12} sm={9}>
          <AdasTypography component='span' sx={{ fontSize: '.875rem' }}>
            QBO Class Name (Class Tracking must be enabled in QBO Plus)
          </AdasTypography>
          <InfoPopover
            ariaLabel='QBO Class Name description'
            enableClick={true}
            // variant='top-right'
            sx={{ width: '275px', padding: '0px' }}
            text={
              <AdasBox sx={{ padding: '0.5rem' }}>
                <AdasTypography sx={{ fontSize: '0.875rem' }}>
                  For QBO Plus users only. Class name will be appended after store number for easier identification.
                </AdasTypography>

                <AdasTypography sx={{ marginTop: '1rem', fontSize: '0.875rem' }}>Example: 01234-StoreName</AdasTypography>
              </AdasBox>
            }
          />
        </AdasGrid>
        <AdasGrid item xs={12} sm={3} container justifyContent='flex-end' alignItems='center'>
          <AdasTextField
            size='small'
            id='qboClassName'
            name='qboClassName'
            type={'text'}
            value={qboClassName}
            onChange={handleQboClassNameChange}
            onBlur={updateQboClassName}
            sx={{ borderRadius: '0px', maxWidth: '165px' }}
            error={!!qboClassNameErrorMessage}
            helperText={qboClassNameErrorMessage}
          />
        </AdasGrid>
      </AdasGrid>
    </AdasBox>
  )
}
