import { FinancialSettings } from '@components/settings/financial'
import { SchedulerSettings } from '@components/settings/scheduler'
import { useUserStore } from '@caradasstore/UserStore'
import { AdasBox } from '@components/wrapper-components'

export const GeneralSettings = () => {
  const currentUser = useUserStore((store) => store.currentUser)
  const isAdminUser = currentUser && currentUser.is_admin
  return (
    <AdasBox sx={{ marginTop: '10px' }}>
      <FinancialSettings />
      {isAdminUser && <SchedulerSettings />}
    </AdasBox>
  )
}
