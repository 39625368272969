import { ConfirmModal } from '@components/common'
import { GENERIC_MESSAGES, BUTTONS_DISPLAY_LABEL } from '../../../constants'
import { removeAttachment, uploadImageAttachment, updateAttachmentRotation, getImageThumbnail } from '../../../api/api'
import { AdasBox, AdasButton, AdasTypography, AdasModal, AdasImage } from '@components/wrapper-components'
import { AdasLoader } from '@components/adas-loader'
import { Rotate90DegreesCwOutlined } from '@mui/icons-material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { useCallback, useEffect, useRef, useState } from 'react'
import { OperationType } from '../../../enums/enums'
import { ATTACHMENT_TYPE_MAP } from '../../../variable-maps'
import './PhotoViewer.css'

export const PhotoViewer = (props) => {
  const {
    imageUrl,
    imageLocation,
    workorderId,
    deleteOnRetake = true,
    onUpdate,
    imageId,
    disabled = false,
    img_rotation = 0,
    isOpen,
    closeModal,
    title,
    allowDelete = false
  } = props

  const fileRef = useRef(null)
  const [fullImageUrl, setFullImageUrl] = useState(imageUrl)
  const [fullImageId, setFullImageId] = useState(imageId)
  const [currentRotation, setCurrentRotation] = useState(img_rotation)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [deleteAttachmentId, setDeleteAttachment] = useState(null)
  const [loading, setLoading] = useState(false)

  const deleteAttachment = async (attachmentId) => {
    const currentImageLocation = imageLocation
    try {
      await removeAttachment({ attachmentId: attachmentId })

      const updatedData = {
        operation: OperationType.DELETE,
        imageLocation: currentImageLocation,
        attachmentId: attachmentId
      }

      closeModal()
      setFullImageId(null)
      setCurrentRotation(0)
      setFullImageUrl(null)

      onUpdate(updatedData)
    } catch (error) {
      console.error('Error deleting image:', error)
    }
  }

  const handleDelete = async () => {
    if (deleteAttachmentId) {
      setShowConfirmModal(false)
      deleteAttachment(deleteAttachmentId)
    }
  }

  const deleteWithConfirmation = (deleteImageId) => {
    setDeleteAttachment(deleteImageId)
    setShowConfirmModal(true)
  }

  const handleUpload = async (ref) => {
    const file = ref?.current?.files?.[0]
    if (file) {
      setCurrentRotation(0)
      setLoading(true)
      setFullImageUrl(null)
      const formData = new FormData()
      formData.append('file', file, file.name)
      let attachmentType = ATTACHMENT_TYPE_MAP[imageLocation]
      const params = {
        data: formData,
        workorderId: workorderId,
        type: attachmentType
      }
      try {
        if (deleteOnRetake && fullImageId) {
          await removeAttachment({ attachmentId: fullImageId })
        }

        const response = await uploadImageAttachment(params)
        if (response?.data) {
          setFullImageUrl(URL.createObjectURL(file))
          setFullImageId(response.data.id)

          const updatedData = {
            operation: OperationType.RETAKE,
            attachmentId: response.data.id,
            img_rotation: 0,
            imageLocation: imageLocation,
            attachmentDetail: response.data,
            deletedAttachmentId: fullImageId
          }
          onUpdate(updatedData)
          setLoading(false)
        } else {
          console.error('Upload server problem. Please try again after some time')
          setLoading(false)
        }
      } catch (error) {
        console.error('Error uploading image:', error)
        setLoading(false)
      }
    }
  }

  const handleRotate = () => {
    setCurrentRotation((prevRotation) => (prevRotation + 90) % 360)
    const updatedRotation = (currentRotation + 90) % 360

    const savingParams = {
      workorderId: workorderId,
      rotation: updatedRotation,
      attachmentId: fullImageId
    }

    const response = updateAttachmentRotation(savingParams)
    response.then(() => {
      const updatedData = {
        operation: OperationType.ROTATE,
        img_rotation: updatedRotation,
        imageLocation: imageLocation,
        attachmentId: fullImageId
      }
      onUpdate(updatedData)
    })
  }

  const requestFullImage = useCallback(async () => {
    const requestFullImageResponse = getImageThumbnail({
      attachmentId: fullImageId
    })
    requestFullImageResponse
      .then((response) => {
        if (response.status === 200 && response.data) {
          setFullImageUrl(URL.createObjectURL(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [fullImageId])

  useEffect(() => {
    setFullImageId(imageId)
  }, [imageId])

  useEffect(() => {
    if (isOpen) {
      requestFullImage()
    }
  }, [isOpen, requestFullImage])

  return (
    <>
      <AdasModal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby='responsive-modal-title'
        aria-describedby='responsive-modal-description'
        className='modalContainer'
      >
        <AdasBox className='modalContent'>
          <AdasTypography id='responsive-modal-title' component='h2' className='title'>
            {title}
          </AdasTypography>
          <AdasBox className='imageContainer'>
            {loading ? (
              <AdasLoader />
            ) : (
              <AdasImage
                src={fullImageUrl}
                alt='Modal'
                className='image'
                sx={{
                  width: `${currentRotation === 90 || currentRotation === 270 ? '100%' : '100%'}`,
                  transform: `rotate(${currentRotation}deg)`
                }}
              />
            )}
          </AdasBox>
          <AdasBox className='buttonSection'>
            <AdasBox className='buttonContainer'>
              <AdasButton component='label' startIcon={<PhotoCameraOutlinedIcon />} disabled={disabled}>
                <AdasTypography variant='button'>RETAKE PHOTO</AdasTypography>
                <input ref={fileRef} type='file' hidden accept='image/*' onChange={() => handleUpload(fileRef)} />
              </AdasButton>
              {allowDelete && !disabled && (
                <AdasButton buttonType='iconButton' color='primary' onClick={() => deleteWithConfirmation(imageId)} size='small'>
                  <DeleteOutlineOutlinedIcon
                    fontSize='small'
                    style={{
                      marginRight: '6px'
                    }}
                  />
                  <AdasTypography variant='button'>DELETE</AdasTypography>
                </AdasButton>
              )}
              {!disabled && (
                <AdasButton buttonType='iconButton' color='primary' onClick={handleRotate} size='small'>
                  <Rotate90DegreesCwOutlined
                    fontSize='small'
                    style={{
                      transform: `rotate(${currentRotation}deg)`,
                      marginRight: '6px'
                    }}
                  />
                  <AdasTypography variant='button'>ROTATE</AdasTypography>
                </AdasButton>
              )}
            </AdasBox>
            <AdasBox className='doneButtonContainer'>
              <AdasButton onClick={closeModal} variant='contained' color='primary'>
                Done
              </AdasButton>
            </AdasBox>
          </AdasBox>
        </AdasBox>
      </AdasModal>
      {showConfirmModal && (
        <ConfirmModal
          cancelLabel={BUTTONS_DISPLAY_LABEL.CANCEL}
          confirmLabel={BUTTONS_DISPLAY_LABEL.DELETE}
          open={showConfirmModal}
          title={GENERIC_MESSAGES.DELETE_PHOTO}
          message={GENERIC_MESSAGES.CONFIRM_DELETE_PHOTO}
          handleClose={() => setShowConfirmModal(false)}
          handleContinue={handleDelete}
        />
      )}
    </>
  )
}
