import PropTypes from 'prop-types'

import { AdasBox, AdasContainer, AdasImage } from '@components/wrapper-components'
import CaradasLogo from '../../assets/images/logo-caradas.png'
import classes from './logoWrapper.module.css'

export const CaradasLogoWrapper = ({ size, children, image }) => {
  return (
    <AdasContainer maxWidth={size ? size : 'false'}>
      <AdasBox className={classes.logo}>
        <AdasImage src={image ? image : CaradasLogo} alt='logo' sx={{ objectFit: 'contain', marginBottom: '2rem' }} />
        {children}
      </AdasBox>
    </AdasContainer>
  )
}

CaradasLogoWrapper.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.string
}
