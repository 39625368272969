import { Box } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasImage = ({ sx, src, alt, ...rest }) => {
  return <Box component='img' sx={sx} src={src} alt={alt} {...rest} />
}

AdasImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  sx: PropTypes.object
}
