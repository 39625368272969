import { ButtonGroup, ToggleButtonGroup } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasButtonGroup = ({ sx = {}, type = 'button', ...props }) => {
  const getButtonGroupComponent = () => {
    switch (type) {
      case 'button':
        return <ButtonGroup sx={sx} {...props} />
      case 'toggleButton':
        return <ToggleButtonGroup sx={sx} {...props} />
      default:
        return null
    }
  }

  return getButtonGroupComponent()
}

AdasButtonGroup.propTypes = {
  sx: PropTypes.object,
  type: PropTypes.oneOf(['button', 'toggleButton'])
}
