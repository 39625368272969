import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types'

export const AdasStack = ({ children, spacing = 2, direction = 'column', sx = {}, ...rest }) => {
  return (
    <Stack spacing={spacing} direction={direction} sx={sx} {...rest}>
      {children}
    </Stack>
  )
}

AdasStack.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  direction: PropTypes.oneOf(['column', 'column-reverse', 'row', 'row-reverse']),
  sx: PropTypes.object
}
