import { Radio } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasRadio = (props) => {
  const { checked, disabled = false, inputProps = {}, onChange, ...rest } = props
  return <Radio inputProps={inputProps} checked={checked} disabled={disabled} onChange={onChange} {...rest} />
}

AdasRadio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  onChange: PropTypes.func
}
