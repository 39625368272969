import Popper from '@mui/material/Popper'
import PropTypes from 'prop-types'

export const AdasPopper = ({ anchorEl, open, placement, children, className }) => {
  return (
    <Popper anchorEl={anchorEl} open={open} placement={placement} className={className}>
      {children}
    </Popper>
  )
}

AdasPopper.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.oneOf([null])]),
  open: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf([
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start'
  ]),
  children: PropTypes.node.isRequired
}
