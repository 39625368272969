import { FaqList } from '@components/faq'
import { AdasBox, AdasContainer, AdasLink, AdasPaper, AdasTextField, AdasTypography, AdasImage } from '@components/wrapper-components'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import CarADAS from '../../assets/images/logo-carADASplus.svg'

export const SupportPage = () => {
  const [searchInput, setSearchInput] = useState('')
  const history = useHistory()
  const searchInputHandler = (e) => {
    setSearchInput(e.target.value)
  }

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        elevation={0}
        sx={{
          padding: '1,25rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <AdasBox sx={{ width: '18%', padding: '1rem 0 0' }}>
          <AdasImage src={CarADAS} alt='Caradas Plus Logo' />
        </AdasBox>
        <AdasTypography variant='h4' component='h1' sx={{ margin: '1rem 0 1rem' }}>
          Contact Car ADAS+ Support
        </AdasTypography>
        <AdasBox sx={{ borderTop: '2px solid blue', width: '50%', margin: '.5rem' }} />
        <AdasTypography sx={{ margin: '.5rem' }}>
          Reach out to us directly regarding any technical issues you may be facing. We’ve got your back.
        </AdasTypography>
        <AdasBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px', margin: '20px 0px' }}>
          <AdasBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AdasLink onClick={() => history.push('/support/support-request')} sx={{ cursor: 'pointer' }}>
              <BuildOutlinedIcon fontSize='large' />
            </AdasLink>
            <AdasTypography variant='h5' component='h2' color='common.black'>
              Support
            </AdasTypography>
            <AdasLink onClick={() => history.push('/support/support-request')} sx={{ cursor: 'pointer', margin: '.5rem .25rem 2.5rem' }}>
              Submit Request
            </AdasLink>
          </AdasBox>
          <AdasBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AdasLink href='mailto:support@caradas.com'>
              <MailOutlineIcon fontSize='large' />
            </AdasLink>
            <AdasTypography variant='h5' component='h2' color='common.black'>
              Email Us
            </AdasTypography>
            <AdasLink href='mailto:support@caradas.com' sx={{ margin: '.5rem .25rem 2.5rem' }}>
              support@caradas.com
            </AdasLink>
          </AdasBox>
        </AdasBox>

        <AdasBox
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'row'
          }}
        >
          <AdasContainer sx={{ width: '100%', marginBottom: '2rem' }}>
            <AdasTypography variant='h5' component='h2' color='common.black' sx={{ marginBottom: '.5rem' }}>
              Frequently Asked Questions:
            </AdasTypography>
            <AdasTextField
              sx={{ width: '60%', borderRadius: '0px', marginBottom: '.5rem' }}
              onChange={searchInputHandler}
              id='search'
              name='search'
              label='Search'
              variant='outlined'
              size='small'
              type={'text'}
              value={searchInput}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
                sx: { borderRadius: '0' }
              }}
            />
            <FaqList filter={searchInput} />
          </AdasContainer>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
