import InputMask from 'react-input-mask'
import { TextField } from '@mui/material'

export const AdasPhoneField = ({ value, onChange, isValid, label, name, disabled }) => {
  return (
    <InputMask
      mask='(999) 999-9999'
      value={value}
      onChange={onChange}
      alwaysShowMask={false} // Don't show the mask until user starts typing
      disabled={disabled}
    >
      {() => (
        <TextField
          label={label}
          fullWidth
          name={name}
          disabled={disabled}
          error={!isValid}
          helperText={!isValid ? 'Phone number must be in the format (123) 456-7890' : ''}
        />
      )}
    </InputMask>
  )
}
