import React from 'react'

export class QuickBookButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: false
    }
  }

  componentDidMount() {
    this.init()
  }

  async init() {
    let script1 = document.createElement('script')
    script1.src = 'https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js'
    script1.type = 'text/javascript'
    script1.onload = () => {
      let script2 = document.createElement('script')
      script2.src = 'https://js.appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js'
      script2.type = 'text/javascript'
      script2.onload = () => {
        let script3 = document.createElement('script')
        script3.innerHTML = `
            intuit.ipp.anywhere.setup({
              grantUrl: 'http://www.mycompany.com/HelloWorld/RequestTokenServlet',
              datasources: {
                  quickbooks : true,
                  payments : true
              },
                paymentOptions:{
                  intuitReferred : true
              }
            });
          `
        script3.type = 'text/javascript'

        document.getElementsByTagName('head')[0].appendChild(script3)
      }
      document.getElementsByTagName('head')[0].appendChild(script2)
    }
    document.getElementsByTagName('head')[0].appendChild(script1)
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: '<ipp:connectToIntuit></ipp:connectToIntuit>' }}></div>
  }
}
