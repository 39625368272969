import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

export const AdasAccordion = ({ defaultExpanded, elevation = 0, disableGutters = false, square = false, ...props }) => (
  <Accordion defaultExpanded={defaultExpanded} disableGutters={disableGutters} elevation={elevation} square={square} {...props} />
)

AdasAccordion.propTypes = {
  defaultExpanded: PropTypes.bool,
  elevation: PropTypes.number,
  disableGutters: PropTypes.bool,
  square: PropTypes.bool
}

export const AdasAccordionSummary = ({ expandIcon = <ExpandMoreIcon />, ...props }) => (
  <AccordionSummary expandIcon={expandIcon} {...props} />
)

AdasAccordionSummary.propTypes = {
  expandIcon: PropTypes.node
}

export const AdasAccordionDetails = styled(AccordionDetails)(({ theme, padding, bordertop }) => ({
  padding: padding || theme.spacing(2),
  borderTop: bordertop
}))

AdasAccordionDetails.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderTop: PropTypes.string
}
