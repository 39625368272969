import { SvgIcon } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasSvgIcon = (props) => {
  return <SvgIcon {...props} />
}

AdasSvgIcon.propTypes = {
  children: PropTypes.node.isRequired
}
