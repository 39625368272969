import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AdasBox, AdasButton, AdasLink } from '@components/wrapper-components'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { FilePresentOutlined } from '@mui/icons-material'
import { MIME_TYPES_MAP } from 'variable-maps'
import { getAttachmentAppointmentEstimate } from 'api/api'
import { useCenterStore } from '@caradasstore/CenterStore'
import { Logger } from 'logger'

// Helper function to check if the file has an image extension
const isImageFile = (extension) => {
  const imageExtensions = ['png', 'jpg', 'jpeg', 'gif']
  return imageExtensions.includes(extension?.toLowerCase())
}

// Generates a file URL based on file data and its extension
const generateFileUrl = (fileData) => {
  const extIndex = fileData.name.lastIndexOf('.')
  const fileExtension = fileData.name.slice(extIndex)
  const fileBlob = new Blob([fileData], {
    type: MIME_TYPES_MAP[fileExtension.toLowerCase()] || 'application/octet-stream'
  })
  return URL.createObjectURL(fileBlob)
}

export const AppointmentAttachment = ({ name, value, onChange, title, label, onDelete, isViewOnly = false }) => {
  const ccId = useCenterStore((store) => store.ccId)
  const MIME_TYPES = Object.keys(MIME_TYPES_MAP).join(',')
  const [fileURL, setFileURL] = useState('')

  const isFileFromResponse = value && typeof value === 'object' && value?.id && !(value instanceof File)

  useEffect(() => {
    const fetchFileData = async () => {
      try {
        const response = await getAttachmentAppointmentEstimate(ccId, value.appointment_id)
        const fileFromResponse = new File([response.data], value.name)
        fileFromResponse.id = value.id
        const fileUrl = generateFileUrl(fileFromResponse)
        setFileURL(fileUrl)
      } catch (error) {
        Logger.error({ message: error.message, payload: { value } })
      }
    }

    if (value?.id) {
      fetchFileData()
    }

    return () => {
      if (fileURL) {
        URL.revokeObjectURL(fileURL)
      }
    }
  }, [value?.id])

  return (
    <AdasBox
      display='column'
      alignItems='center'
      justifyContent='center'
      mt={2}
      py={2}
      border='1px solid #0000001f'
      borderRadius='4px'
      position='relative'
    >
      {/* File upload button for new uploads */}
      {!isFileFromResponse && !(value instanceof File) && (
        <AdasButton
          component='label'
          sx={{
            fontSize: 12,
            margin: 'auto',
            display: 'flex',
            alignItems: 'center'
          }}
          startIcon={<FileUploadOutlinedIcon />}
        >
          <span>{title}</span>
          <input accept={MIME_TYPES} style={{ display: 'none' }} id={`upload-${name}`} type='file' name={name} onChange={onChange} />
        </AdasButton>
      )}

      {/* Display file from the response */}
      {isFileFromResponse && (
        <>
          <AdasBox display='flex' flexDirection='column' alignItems='center'>
            {isImageFile(value.file_extension) ? (
              <img src={fileURL} alt={value.name} style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
            ) : (
              <FilePresentOutlined sx={{ color: '#CC3838', fontSize: 40 }} />
            )}
            <AdasLink
              sx={{
                fontSize: '14px',
                textDecoration: 'none',
                color: '#0077cc',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center'
              }}
              target='_blank'
              rel='noopener noreferrer'
              href={fileURL}
            >
              {value.name}
            </AdasLink>
          </AdasBox>
          {!isViewOnly && (
            <AdasButton
              component='label'
              sx={{
                fontSize: 12,
                margin: 'auto',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center'
              }}
              startIcon={<FileUploadOutlinedIcon />}
            >
              <span>Change estimate</span>
              <input accept={MIME_TYPES} style={{ display: 'none' }} id={`upload-${name}`} type='file' name={name} onChange={onChange} />
            </AdasButton>
          )}
        </>
      )}

      {/* Render uploaded file */}
      {value && value instanceof File && (
        <>
          <AdasBox display='flex' flexDirection='column' alignItems='center'>
            {isImageFile(value.name.split('.').pop()) ? (
              <img src={URL.createObjectURL(value)} alt={value.name} style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
            ) : (
              <FilePresentOutlined sx={{ color: '#CC3838', fontSize: 40 }} />
            )}
            <AdasLink
              sx={{
                fontSize: '14px',
                textDecoration: 'none',
                color: '#0077cc',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center'
              }}
              target='_blank'
              rel='noopener noreferrer'
              href={URL.createObjectURL(value)}
            >
              {value.name} ({(value.size / 1024 / 1024).toFixed(2)} MB)
            </AdasLink>
          </AdasBox>
          <AdasButton
            component='label'
            sx={{
              fontSize: 12,
              margin: 'auto',
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center'
            }}
            startIcon={<FileUploadOutlinedIcon />}
          >
            <span>Change estimate</span>
            <input accept={MIME_TYPES} style={{ display: 'none' }} id={`upload-${name}`} type='file' name={name} onChange={onChange} />
          </AdasButton>
        </>
      )}
    </AdasBox>
  )
}

AppointmentAttachment.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool
}
