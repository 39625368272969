import LinearProgress from '@mui/material/LinearProgress'
import PropTypes from 'prop-types'

const defaultStyles = {
  color: 'primary',
  variant: 'determinate'
}

export const AdasLinearProgress = ({ value, variant = defaultStyles.variant, color = defaultStyles.color, ...rest }) => {
  return <LinearProgress variant={variant} value={value} color={color} {...rest} />
}

AdasLinearProgress.propTypes = {
  value: PropTypes.number,
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'buffer', 'query']),
  color: PropTypes.oneOf(['primary', 'secondary'])
}
