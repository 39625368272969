import PropTypes from 'prop-types'

import { AdasBox, AdasButton, AdasContainer, AdasTypography } from '@components/wrapper-components'
import { Logger } from 'logger'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    Logger.error(error, 'ErrorBoundary')
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Logger.error(error, errorInfo)
    this.props.history.push('/')
  }

  render() {
    if (this.state.hasError) {
      return (
        <AdasContainer maxWidth='sm' sx={{ marginTop: '50px' }}>
          <AdasBox textAlign='center' p={3}>
            <AdasTypography variant='h4' gutterBottom>
              Oops! Something went wrong.
            </AdasTypography>
            <AdasTypography gutterBottom>{"We're sorry for the inconvenience. Please try refreshing the page."}</AdasTypography>
            <AdasButton variant='contained' color='primary' onClick={() => window.location.reload()} sx={{ marginTop: '20px' }}>
              Refresh Page
            </AdasButton>
          </AdasBox>
        </AdasContainer>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

export default withRouter(ErrorBoundary)
