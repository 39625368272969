import { Radio, RadioGroup } from '@mui/material'
import PropTypes from 'prop-types'

import { AdasFormControlLabel } from './AdasFormControlLabel'

export const AdasRadioGroup = ({ options, value, onChange, disabled, ...rest }) => {
  return (
    <RadioGroup value={value} onChange={onChange} {...rest}>
      {options.map((option, i) => (
        <AdasFormControlLabel
          disabled={disabled}
          key={`${option.label}_${i}`}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  )
}

AdasRadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func
}
