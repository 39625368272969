import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getDocuments, getQuoteByReferenceNumber } from '../../../api/api'
import { FileUpload } from '@components/file-upload'
import { useHistory } from 'react-router-dom'
import { AddFileButton } from '@components/buttons'
import { FILE_UPLOAD } from '../../../constants'
import { AdasGrid, AdasPaper, AdasContainer, AdasButton, AdasBox } from '@components/wrapper-components'
import { useScrollTop } from '../../../utils/useScrollTop'
import { PARAM_NAMES } from '../../../constants'

export const QuoteDocument = () => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const history = useHistory()
  const [workOrderData, setWorkOrderData] = useState({})
  const [documents, setDocuments] = useState({
    collisionRepairEstimate: '',
    calibrationRequiredReport: '',
    partsInvoice: ''
  })

  const [additionalDocuments, setAdditionalDocuments] = useState([])
  const [loadedAdditionalDocs, setLoadedAdditionalDocs] = useState([])

  const removeFileHandler = (fileName) => {
    if (fileName) {
      setDocuments((prevState) => {
        const updatedDocuments = {
          ...prevState,
          [fileName]: null
        }
        return updatedDocuments
      })
    }
  }

  const removeLoadedAdditionalFileHandler = (index) => {
    setLoadedAdditionalDocs((prev) => {
      prev[index].removed = true
      const data = [...prev]
      return data
    })
  }

  useEffect(() => {
    const requestDocuments = async (params) => {
      const response = await getDocuments({ type: 'quotes', id: params.workorderId })
      if (response.status === 200) {
        const documentSummaryData = response.data
        Object.keys(documents).forEach((key) => {
          if (documentSummaryData[key]) {
            setDocuments((prevState) => {
              const updatedDocuments = {
                ...prevState,
                [key]: documentSummaryData[key]
              }
              return updatedDocuments
            })
          }
        })
        if (documentSummaryData.additionalDocuments != null) {
          let loadedAdditionalDocs = []
          documentSummaryData.additionalDocuments.forEach((doc) => {
            loadedAdditionalDocs.push(doc)
            if (loadedAdditionalDocs.length > 0) {
              setLoadedAdditionalDocs(() => {
                const data = [...loadedAdditionalDocs]
                return data
              })
            }
          })
        }
      }
    }
    if (workOrderData.id) {
      const params = {
        workorderId: workOrderData.id
      }
      requestDocuments(params)
    }
    /* eslint-disable */
  }, [workOrderData.id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const quoteResponse = await getQuoteByReferenceNumber({
          referenceNumber: referenceNumber
        })
        if (quoteResponse?.status === 200) {
          setWorkOrderData(quoteResponse.data?.quote)
        }
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            referenceNumber
          }
        })
      }
    }
    if (referenceNumber) fetchData()
  }, [referenceNumber])

  return (
    <AdasContainer maxWidth='lg'>
      {workOrderData.id && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem' }}>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                marginBottom: '20px'
              }}
            >
              <p>DOCUMENTS</p>
              <AdasGrid container alignItems='center' spacing={2}>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('collisionRepairEstimate')}
                    existingData={documents.collisionRepairEstimate}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_COLLISION_REPAIR_ESTIMATE}
                    label={FILE_UPLOAD.LABEL.COLLISION_REPAIR_ESTIMATE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PRE_CALIBRATION_REPAIR_ESTIMATE_SCAN}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('calibrationRequiredReport')}
                    existingData={documents.calibrationRequiredReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_CALIBRATION_REQUIRED_REPORT}
                    label={FILE_UPLOAD.LABEL.CALIBRATION_REQUIRED_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.CALIBRATION_REQUIRED_REPORT}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    removeFile={() => removeFileHandler('partsInvoice')}
                    existingData={documents.partsInvoice}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_PARTS_INVOICE}
                    label={FILE_UPLOAD.LABEL.PARTS_INVOICE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PARTS_INVOICE}
                  />
                </AdasGrid>
                {loadedAdditionalDocs.map((doc, index) => {
                  if (doc && !doc.removed) {
                    return (
                      <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                        <FileUpload
                          key={index}
                          removeFile={() => removeLoadedAdditionalFileHandler(index)}
                          existingData={doc}
                          workOrderData={workOrderData}
                          title={`custom_document_${index}`}
                          label={FILE_UPLOAD.LABEL.ADDITIONAL_DOCUMENT}
                          typeLabel={FILE_UPLOAD.TYPE_LABEL.ADDITIONAL_DOCUMENT}
                        />
                      </AdasGrid>
                    )
                  } else {
                    return null
                  }
                })}
                {additionalDocuments.map((doc, index) => {
                  if (doc && !doc.removed) {
                    return (
                      <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                        <FileUpload
                          key={index}
                          removeFile={() => removeAdditionalFileHandler(index)}
                          additionalDocument={doc}
                          workOrderData={workOrderData}
                          title={`custom_document_${index}`}
                          label={FILE_UPLOAD.LABEL.ADDITIONAL_DOCUMENT}
                          typeLabel={FILE_UPLOAD.TYPE_LABEL.ADDITIONAL_DOCUMENT}
                        />
                      </AdasGrid>
                    )
                  } else {
                    return null
                  }
                })}
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <AddFileButton setAdditionalDocuments={setAdditionalDocuments} />
                </AdasGrid>
              </AdasGrid>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasButton variant='contained' aria-label='DONE' color='primary' onClick={() => history.goBack()}>
              DONE
            </AdasButton>
          </AdasBox>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}
