import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { ItemPerPageSelector } from '@components/common'
import { EmailForm } from '@components/email'
import { QueueItem } from '@components/queue-item'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasContainer,
  AdasPagination,
  AdasPaper,
  AdasStack,
  AdasTextField
} from '@components/wrapper-components'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getQuoteEmails, getQuotePdf, getQuotelist } from '../../../api/api'
import { REDIRECT_URLS, getQuoteSummaryUrl } from '../../../constants'
import { scrollPageTop, useScrollTop } from '../../../utils/useScrollTop'
import { Logger } from '../../../logger'

export const QuotePage = () => {
  useScrollTop()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const statusFilter = queryParams.get('status') || 'OPEN'
  const [quoteList, setQuoteList] = useState([])
  const [selectedQuote, setSelectedQuote] = useState()
  const [selectedQuoteReferenceNumber, setSelectedQuoteReferenceNumber] = useState()
  const [pageParams, setPageParams] = useState({
    pageNo: 0,
    totalPages: 0,
    pageSize: 25,
    status: statusFilter,
    search: ''
  })
  const [isEmail, setIsEmail] = useState(false)
  const [emailContacts, setEmailContacts] = useState({})
  const [quotePdf, setQuotePdf] = useState()
  const [searchInput, setSearchInput] = useState('')
  const [pdfLoader, setPdfLoader] = useState(false)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const ccId = useCenterStore((store) => store.ccId)
  const history = useHistory()

  useEffect(() => {
    const performSearch = () => {
      setPageParams((prev) => {
        const updated = {
          ...prev,
          search: searchInput
        }
        return updated
      })
    }

    // Set a delay to perform the search 500ms after typing stops
    const timeoutId = setTimeout(performSearch, 1000)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const redirectToNewQuote = () => {
    history.push(REDIRECT_URLS.NEW_QUOTE)

    // Scroll to top of the page
    scrollPageTop()
  }

  const handleClick = (data) => {
    history.push(getQuoteSummaryUrl(data?.reference_number))
  }

  const handleSearchChange = useCallback((e) => {
    setSearchInput(e.target.value)
  }, [])

  const toggleHandler = useCallback(
    (e) => {
      history.replace({
        pathname: window.location.pathname,
        search: `?status=${e.target.value}`
      })

      setPageParams((prev) => ({
        ...prev,
        status: e.target.value,
        pageNo: 0 // Reset to first page on filter change
      }))
    },
    [history]
  )

  const paginationHandler = useCallback((event, page) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageNo: page - 1
      }
      return updated
    })
  }, [])

  const pageSizeHandler = useCallback((event) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageSize: event.target.value,
        pageNo: 0
      }
      return updated
    })
  }, [])

  useEffect(() => {
    const quoteListResponse = getQuotelist({
      pageNo: pageParams.pageNo,
      pageSize: pageParams.pageSize,
      status: pageParams.status,
      ccid: ccId,
      search: pageParams.search
    })
    setLoading(true)
    quoteListResponse.then((response) => {
      if (response?.status === 200) {
        setQuoteList(response.data.quotes)
        setPageParams((prev) => {
          const updated = {
            ...prev,
            totalPages: response.data.totalPages
          }
          return updated
        })
        scrollPageTop()
      }
      setLoading(false)
    })
  }, [pageParams.pageNo, pageParams.pageSize, pageParams.status, pageParams.search, ccId, setLoading])

  const emailQuoteHandler = useCallback(async () => {
    const params = {
      quoteId: selectedQuote
    }
    try {
      const emailContactResponse = await getQuoteEmails(params)
      if (emailContactResponse.status === 200) {
        setIsEmail(true)
        setEmailContacts(emailContactResponse.data)
      }
      const quotePdfResponse = await getQuotePdf({
        quoteId: selectedQuote
      })
      if (quotePdfResponse.status === 200) {
        const fileValue = new File([quotePdfResponse.data], `quote_${selectedQuoteReferenceNumber}.pdf`)
        fileValue.id = selectedQuote
        setQuotePdf(fileValue)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { QuoteId: selectedQuote, path: 'QuotePage/emailQuoteHandler' } })
    }
  }, [selectedQuote, selectedQuoteReferenceNumber])

  const viewPdfHandler = async () => {
    try {
      setPdfLoader(true)
      const quotePdfResponse = await getQuotePdf({
        quoteId: selectedQuote
      })
      if (quotePdfResponse.status === 200) {
        const fileValue = new Blob([quotePdfResponse.data], { type: 'application/pdf' }, `quote_${selectedQuoteReferenceNumber}.pdf`)
        fileValue.id = selectedQuote
        const fileURL = URL.createObjectURL(fileValue)
        const pdfWindow = window.open()
        if (pdfWindow === null) {
          alert('Pop-up was blocked by your browser! Please allow pop-ups for caradasplus.com to view Quote.')
        } else {
          pdfWindow.location.href = fileURL
        }
      }
    } catch (error) {
      Logger.error({ message: error, payload: { QuoteId: selectedQuote, path: 'QuotePage/viewPdfHandler' } })
    } finally {
      setPdfLoader(false)
    }
  }

  return (
    <AdasContainer maxWidth='lg'>
      <EmailForm
        quotePdf={quotePdf}
        isOpen={isEmail}
        contacts={emailContacts}
        emailData={{ id: selectedQuote }}
        onClose={() => setIsEmail(false)}
      />
      <AdasBox
        sx={{
          overflow: 'auto',
          fontSize: '14px',
          margin: '.5rem 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <AdasButton key='2' variant='contained' aria-label='Create Quote' color='primary' onClick={redirectToNewQuote}>
          New Quote
        </AdasButton>

        <AdasButtonGroup>
          <AdasButton value='OPEN,CLOSED' onClick={toggleHandler} variant={pageParams.status === 'OPEN,CLOSED' ? 'contained' : 'outlined'}>
            ALL
          </AdasButton>
          <AdasButton value='OPEN' onClick={toggleHandler} variant={pageParams.status === 'OPEN' ? 'contained' : 'outlined'}>
            OPEN
          </AdasButton>
          <AdasButton value='CLOSED' onClick={toggleHandler} variant={pageParams.status === 'CLOSED' ? 'contained' : 'outlined'}>
            CLOSED
          </AdasButton>
        </AdasButtonGroup>
      </AdasBox>

      <AdasPaper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          padding: '.75rem',
          justifyContent: 'space-between'
        }}
      >
        <AdasTextField
          sx={{ borderRadius: '0px', width: '100%', mb: { xs: 1, md: 0.8 } }}
          onChange={handleSearchChange}
          id='search'
          name='search'
          label='Search'
          variant='outlined'
          size='small'
          type={'text'}
          value={searchInput}
          fullWidth={true}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            startAdornment: <SearchIcon />,
            sx: { borderRadius: '0' },
            placeholder: 'Filter Quotes'
          }}
        />

        <AdasBox sx={{ display: 'flex', justifyContent: 'flex-end', mt: { xs: 1, md: 0 }, maxHeight: '40px', marginLeft: '.5rem' }}>
          <AdasButton
            sx={{ marginRight: '.5rem', width: '130px' }}
            variant='outlined'
            disabled={!selectedQuote}
            isDebounceEnabled={true}
            startIcon={pdfLoader ? <CircularProgress color='inherit' size={20} /> : <DownloadOutlinedIcon />}
            onClick={viewPdfHandler}
          >
            View PDF
          </AdasButton>
          <AdasButton
            variant='outlined'
            isDebounceEnabled={true}
            disabled={!selectedQuote}
            startIcon={<EmailOutlinedIcon />}
            onClick={emailQuoteHandler}
          >
            Email
          </AdasButton>
        </AdasBox>
      </AdasPaper>
      <AdasBox>
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '.5rem' }}>
          <ul>
            {quoteList.length > 0 &&
              quoteList.map((item) => {
                return (
                  <QueueItem
                    item={item}
                    key={item.id}
                    isOptionEnabled={true}
                    isSelected={selectedQuote === item.id}
                    onClickItem={handleClick}
                    radioChanged={() => {
                      setSelectedQuote(item.id)
                      setSelectedQuoteReferenceNumber(item.reference_number)
                    }}
                  />
                )
              })}
            {quoteList.length === 0 && (
              <li>
                No quotes found
                {pageParams.totalPages > 1 && ' on this page. Go to next page to search more quotes.'}
              </li>
            )}
          </ul>
        </AdasPaper>
      </AdasBox>
      <AdasPaper elevation={0} sx={{ padding: '20px', marginTop: '20px', display: 'flex' }}>
        <AdasBox sx={{ width: '20%' }}>
          <ItemPerPageSelector itemPerPageList={[25, 50, 100, 200]} pageSizeHandler={pageSizeHandler} pageSize={pageParams.pageSize} />
        </AdasBox>
        <AdasBox sx={{ width: '80%', display: 'flex', justifyContent: 'end' }}>
          <AdasStack spacing={2}>
            <AdasPagination onChange={paginationHandler} page={pageParams.pageNo + 1} count={pageParams.totalPages} shape='rounded' />
          </AdasStack>
        </AdasBox>
      </AdasPaper>
      <AdasBox sx={{ margin: '20px 0px', textAlign: 'end' }}>
        <AdasButton key='2' variant='contained' aria-label='Create Work Order' color='primary' onClick={redirectToNewQuote}>
          New Quote
        </AdasButton>
      </AdasBox>
    </AdasContainer>
  )
}
