import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import PropTypes from 'prop-types'

import { AdasButton } from '@components/wrapper-components'

export const SortIndicator = ({ direction = 'ASC' }) => {
  return (
    <AdasButton buttonType='iconButton' size='small' sx={{ marginLeft: '.5rem' }}>
      {direction === 'ASC' ? <ArrowUpwardIcon fontSize='inherit' /> : <ArrowDownwardIcon fontSize='inherit' />}
    </AdasButton>
  )
}

SortIndicator.propTypes = {
  direction: PropTypes.oneOf(['ASC', 'DESC'])
}
