import { FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasFormControlLabel = ({ label, control, ...rest }) => {
  return <FormControlLabel label={label} control={control} {...rest} />
}

AdasFormControlLabel.propTypes = {
  label: PropTypes.node.isRequired,
  control: PropTypes.node.isRequired
}
