import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export const AdasTimePicker = ({ availability, onChange, type, index, error, minutesStep = 15, ampm = false, ...props }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TimePicker
      fullWidth
      value={availability ? dayjs(availability, 'HH:mm') : null}
      onChange={(newValue) => {
        const formattedValue = newValue ? newValue.format('HH:mm') : ''
        onChange(index, type, formattedValue)
      }}
      minutesStep={minutesStep}
      ampm={ampm}
      timeSteps={{ minutes: minutesStep }}
      componentsProps={{
        actionBar: { actions: [] },
        textField: {
          InputProps: {
            sx: {
              height: { xs: '35px', sm: '40px' }, // Responsive height
              fontSize: { xs: '14px', md: '16px' }, // Larger font size for visibility
              '& .MuiInputBase-input': {
                textAlign: 'center', // Ensure time is centered
                letterSpacing: '0.05em' // Slight spacing for clarity
              },
              '& .MuiSvgIcon-root': {
                fontSize: { xs: '16px', sm: '18px', md: '20px' } // Responsive icon size
              }
            }
          },
          error: error
        }
      }}
      {...props}
    />
  </LocalizationProvider>
)
