import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Divider } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { AdsCustomModal } from '@components/wrapper-components'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { AdasBox, AdasContainer, AdasTypography, AdasButton, AdasGrid, AdasTimePicker } from '@components/wrapper-components'
import theme from 'theme/theme'
import { useHistory } from 'react-router-dom'
import { StickyFooter } from '@components/sticky-footer'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useCenterStore, useOrganisationStore, useUserStore } from '@caradasstore'
import { getScheduleAvailability, updateScheduleAvailability } from '../../../api/api'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { Logger } from '../../../logger'
import { WeekdaysEnum } from 'enums/enums'
import Dayjs from 'dayjs'
import { FormHelperText } from '@mui/material'
import { useDeviceQueries } from '@hooks/useDeviceQueries'

const ScheduleAvailability = (props) => {
  const { hasUnsavedChanges, setHasUnsavedChanges } = props
  const [availabilities, setAvailabilities] = useState([])
  const [showUpdatedPopup, setShowUpdatedPopup] = useState(false)
  const currentUser = useUserStore((store) => store.currentUser)
  const ccId = useCenterStore((store) => store.ccId)
  const organisationId = useOrganisationStore((store) => store.organisationId)
  const organisationDetail = useOrganisationStore((store) => store.organisationDetail)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const history = useHistory()
  const initialAvailabilitiesRef = useRef([])
  const { isMobile } = useDeviceQueries()

  const timezoneDisplay = organisationDetail?.timezone_at_name || ''

  const handleAddTimeSlot = (id) => {
    const updatedAvailability = availabilities.map((availability) =>
      availability.id === id
        ? {
            ...availability,
            start: '08:00',
            end: '17:00',
            isDayUnAvailable: false
          }
        : availability
    )
    setAvailabilities(updatedAvailability)
  }

  const handleRemoveTimeSlot = (id) => {
    const updatedAvailability = availabilities.map((availability) =>
      availability.id === id
        ? {
            ...availability,
            start: '00:00',
            end: '00:00',
            isDayUnAvailable: true
          }
        : availability
    )
    setAvailabilities(updatedAvailability)
  }

  const [timeErrors, setTimeErrors] = useState({})

  const handleTimeChange = (id, type, value) => {
    const updatedAvailability = availabilities.map((availability) => {
      if (availability.id === id) {
        const start = type === 'start' ? value : availability.start
        const end = type === 'end' ? value : availability.end

        // Create a copy of the error state
        const newErrors = { ...timeErrors }

        // Ensure the start time is less than the end time
        if (Dayjs(start, 'HH:mm').isBefore(Dayjs(end, 'HH:mm'))) {
          // Clear error if the time is valid
          delete newErrors[id]
          setTimeErrors(newErrors)
          return {
            ...availability,
            [type]: value
          }
        } else {
          // Set an error message
          newErrors[id] = 'Start time must be less than the end time.'
          setTimeErrors(newErrors)
          return availability // Keep the previous values if invalid
        }
      }
      return availability
    })

    setAvailabilities(updatedAvailability)
  }

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        setLoading(true)
        const response = await getScheduleAvailability({ ccId, organisation_id: organisationId })
        const fetchedAvailability = response?.data

        if (fetchedAvailability && fetchedAvailability.length > 0) {
          const transformedAvailability = transformAvailability(fetchedAvailability)
          setAvailabilities(transformedAvailability)
          initialAvailabilitiesRef.current = transformedAvailability
        }
      } catch (error) {
        Logger.error({
          message: error,
          payload: { file: 'scheduleAvailability', method: 'fetchAvailability' }
        })
      } finally {
        setLoading(false)
      }
    }

    if (ccId && organisationId) fetchAvailability()
  }, [ccId, organisationId])

  const transformAvailability = (fetchedAvailability) => {
    return fetchedAvailability
      .map((dayAvailability) => {
        const start = formatTime(dayAvailability.start_time)
        const end = formatTime(dayAvailability.end_time)

        return {
          start,
          end,
          isDayUnAvailable: start === '00:00' && end === '00:00',
          ...dayAvailability
        }
      })
      .sort((a, b) => WeekdaysEnum[a.day] - WeekdaysEnum[b.day])
  }

  const formatTime = (timeArray) => {
    return `${timeArray[0].toString().padStart(2, '0')}:${timeArray[1].toString().padStart(2, '0')}`
  }

  const description = 'Set your weekly available time slots here. This will repeat every week.'

  const timePickerProps = {
    InputLabelProps: { shrink: true },
    inputProps: {
      min: '08:00',
      max: '17:00',
      step: 900,
      style: { padding: '12px', color: '#1a1b1f99' }
    },
    variant: 'outlined'
  }

  const prepareAvailabilityData = () => {
    return availabilities.map((availability) => ({
      id: availability.id,
      organisation_id: organisationId,
      day: availability.day?.toUpperCase(),
      start_time: availability.start || '00:00',
      end_time: availability.end || '00:00',
      updated_by: currentUser.id
    }))
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const organisationsAvailability = prepareAvailabilityData()

      const data = {
        ccId,
        organisation_id: organisationId,
        organisationsAvailability
      }

      const response = await updateScheduleAvailability(data)
      const updatedAvailabilities = response?.data
      setShowUpdatedPopup(true)
      if (updatedAvailabilities && updatedAvailabilities.length > 0) {
        const transformedAvailability = transformAvailability(updatedAvailabilities)
        setAvailabilities(transformedAvailability)
        initialAvailabilitiesRef.current = transformedAvailability
        setHasUnsavedChanges(false)
      }
      setTimeout(() => {
        setShowUpdatedPopup(false)
      }, 3000)
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'scheduleAvailability', method: 'handleSubmit' }
      })
    } finally {
      setLoading(false)
    }
  }

  const handleBackToCalendar = () => {
    history.push({
      pathname: '/scheduler'
    })
  }

  useEffect(() => {
    const isModified = JSON.stringify(initialAvailabilitiesRef.current) !== JSON.stringify(availabilities)
    setHasUnsavedChanges(isModified)
  }, [availabilities, setHasUnsavedChanges])

  return (
    <AdasContainer maxWidth='lg'>
      <AdasBox sx={{ minHeight: '75vh' }}>
        <AdasBox my={3} display='flex' flexDirection='column' gap={3}>
          <AdasTypography fontSize='small' color={theme.typography.shaded}>
            {description}
          </AdasTypography>

          <AdasBox>
            <AdasTypography component='span' color={'#00000080'} variant='body2'>
              Store Time Zone: &nbsp;&nbsp;
            </AdasTypography>
            <AdasTypography component='span' variant='body2'>
              {timezoneDisplay}
            </AdasTypography>
          </AdasBox>
        </AdasBox>
        <AdasGrid item container direction='column' spacing={2} mt={2} xs={12}>
          {availabilities?.map((availability) => (
            <AdasGrid item key={availability.id} xs={12} style={{ marginTop: 20 }}>
              <AdasGrid
                item
                display='flex'
                alignItems={{ xs: 'left', sm: 'center' }}
                xs={12}
                justifyContent='space-between'
                flexDirection={{ xs: 'column', sm: 'row' }}
              >
                <AdasTypography variant='subtitle2' xs={3} mt={1.2} mb={{ xs: '0.75rem', sm: '' }}>
                  {availability.day?.toUpperCase()}
                </AdasTypography>
                {!availability?.isDayUnAvailable ? (
                  <AdasGrid item display='flex' alignItems={{ xs: 'left', sm: 'center' }} xs={9} gap={1}>
                    <AdasGrid item display='flex' alignItems='center' md={8} gap={2}>
                      <AdasTimePicker
                        availability={availability.start}
                        type={'start'}
                        index={availability.id}
                        onChange={handleTimeChange} // Directly pass the function without 'e.target.value'
                        ampm={true}
                        minutesStep={15}
                        {...timePickerProps}
                        sx={{ minWidth: { xs: '100px', sm: '120px' }, maxWidth: '150px' }}
                        error={!!timeErrors[availability.id]} // Show error if there is an error message
                      />
                      <AdasTypography xs={1} fontSize='small' color={theme.typography.shaded}>
                        To
                      </AdasTypography>
                      <AdasTimePicker
                        availability={availability.end}
                        type={'end'}
                        index={availability.id}
                        onChange={handleTimeChange} // Directly pass the function without 'e.target.value'
                        ampm={true}
                        minutesStep={15}
                        {...timePickerProps}
                        sx={{ minWidth: { xs: '100px', sm: '120px' }, maxWidth: '150px' }}
                        error={!!timeErrors[availability.id]} // Show error if there is an error message
                      />
                    </AdasGrid>
                    <AdasGrid item display='flex' alignItems={{ xs: 'left', sm: 'center' }}>
                      <AdasButton startIcon={<HighlightOffIcon />} onClick={() => handleRemoveTimeSlot(availability.id)}>
                        {!isMobile && <span>Remove</span>}
                      </AdasButton>
                    </AdasGrid>
                  </AdasGrid>
                ) : (
                  <AdasGrid item display='flex' alignItems='center' xs={9} gap={1}>
                    <AdasGrid item xs={5} md={8}>
                      <AdasTypography variant='subtitle1'>Unavailable</AdasTypography>
                    </AdasGrid>
                    <AdasGrid item xs={2} display='flex' alignItems='center'>
                      <AdasButton
                        startIcon={<AddCircleOutlineIcon style={{ marginLeft: 1 }} />}
                        onClick={() => handleAddTimeSlot(availability.id)}
                      >
                        Add
                      </AdasButton>
                    </AdasGrid>
                  </AdasGrid>
                )}
              </AdasGrid>
              <AdasGrid item display='flex' alignItems='center' justifyContent={'center'} mt={2}>
                {timeErrors[availability.id] && <FormHelperText error>{timeErrors[availability.id]}</FormHelperText>}
              </AdasGrid>
            </AdasGrid>
          ))}
        </AdasGrid>
      </AdasBox>
      <AdsCustomModal
        description='Your Schedule Availability selections have been updated successfully.'
        open={showUpdatedPopup}
        hideCloseIcon={true}
      >
        <AdasBox px={3} pt={3} display='flex' justifyContent='flex-end' gap={3}>
          <AdasButton variant='contained' textTransform='uppercase' color='primary' onClick={() => setShowUpdatedPopup(false)}>
            {'Okay'}
          </AdasButton>
        </AdasBox>
      </AdsCustomModal>
      <StickyFooter sxProps={{ backgroundColor: '#ffff' }}>
        <Divider sx={{ marginY: '20px', marginX: '-40px' }} />
        <AdasBox>
          <AdasButton startIcon={<ArrowBackIosNewIcon />} onClick={handleBackToCalendar}>
            {' '}
            back to calendar
          </AdasButton>
          <AdasButton
            sx={{ float: 'right' }}
            variant='contained'
            aria-label='Set up complete'
            color='primary'
            onClick={() => handleSubmit()}
            disabled={
              !availabilities || availabilities?.length === 0 || !hasUnsavedChanges || Object.keys(timeErrors).length > 0 // Disable if there are any errors
            }
          >
            Save
          </AdasButton>
        </AdasBox>
      </StickyFooter>
    </AdasContainer>
  )
}

ScheduleAvailability.propTypes = {
  initialAvailability: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    })
  ),
  setHasUnsavedChanges: PropTypes.func
}

export default ScheduleAvailability
