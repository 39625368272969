import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { formatDateOrReturn, formatTimeOrReturn } from './helper'

dayjs.extend(utc)
export const AppointmentPayload = ({ formValues, organisationDetail }) => {
  const timeZoneCode = organisationDetail.timezone_at || 'UTC' // Assuming the value is in format "-09:00"
  const appointmentPayload = {
    qwi_id: formValues.qwi_id || '',
    organisation_id: organisationDetail.id,
    status: formValues.status || 'SCHEDULED',
    client: {
      id: formValues?.clientId || null,
      full_name: formValues.fullName,
      business_name: formValues.businessName,
      business_phone: formValues.businessPhone,
      phone: formValues.phone,
      referral_source: formValues.referral,
      primary_email: formValues.primaryEmail,
      secondary_email: formValues.secondaryEmail,
      address: formValues.address,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zipCode
    },
    vehicle: {
      id: formValues?.vehicleId || null,
      model: formValues.vehicleModel,
      vin: formValues.vin,
      year: formValues.vehicleYear,
      make: formValues.vehicleMake,
      repair_order_num: formValues.aro
    },
    duration: organisationDetail?.appointment_duration,
    delivery_pickup: formValues.pickDeliver,
    appointment_date: formatDateOrReturn(formValues?.appointmentDate, formValues?.appointmentTime, timeZoneCode),
    start_time: formatTimeOrReturn(formValues?.appointmentDate, formValues?.appointmentTime, timeZoneCode), // Convert time to UTC with timezone
    assignee: formValues.assignee === 'null' ? null : formValues.assignee,
    notes: formValues.notes,
    timezone_at: organisationDetail.timezone_at || formValues.timezone_at,
    createdBy: {
      id: formValues.userId || null,
      name: formValues.userName || null
    },
    updatedBy: formValues.updatedBy || null,
    pickup_contact_name: formValues.pickupContact?.fullName || '',
    pickup_contact_phone: formValues.pickupContact?.phone || ''
  }

  return appointmentPayload
}

AppointmentPayload.propTypes = {
  formValues: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  organisationDetail: PropTypes.object.isRequired,
  timezone: PropTypes.string
}
