import { useCenterStore } from '@caradasstore/CenterStore'
import { useOrganisationStore } from '@caradasstore/OrganisationStore'
import { SchedularCalendarEvent } from '@components/scheduler/schedule-calendar-event'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCalendar,
  AdasDatePicker,
  AdasDrawer,
  AdasTypography,
  AdsCustomModal
} from '@components/wrapper-components'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { TextField } from '@mui/material'
import { getDateOverrides, getEvents, getScheduleAvailability } from 'api/api'
import { DATE_FORMATS } from 'constants'
import dayjs from 'dayjs'
import { WeekdaysEnum, weekdaysEnumToFCIndex } from 'enums/enums'
import { Logger } from 'logger'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { getTimeZoneByCode, mapEventsToCalendarFormat } from 'utils/utils'
import { AppointmentDetails } from './AppointmentDetails'
import './SchedulerCalendar.css'
import 'dayjs/locale/en-gb'
import { useDeviceQueries } from '@hooks/useDeviceQueries'
import { daysOfWeekMap } from 'utils/utils'
export const ScheduleCalendar = ({ initialView, refresh, setRefresh, openNewEventSlider }) => {
  const calendarRef = useRef(null)
  const ccId = useCenterStore((store) => store.ccId)
  const organisationId = useOrganisationStore((store) => store.organisationId)
  const organisationDetails = useOrganisationStore((state) => state.organisationDetail)
  const [view, setView] = useState(initialView)
  const [title, setTitle] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [isSliderOpen, setIsSliderOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedEventId, setSelectedEventId] = useState(null) // State to hold the selected event ID
  const [calendarHiddenDays, setCalendarHiddenDays] = useState([])
  const [unavailableDates, setUnavailableDates] = useState([])
  const { isMobile, isLandscape } = useDeviceQueries()
  const [businessHours, setBusinessHours] = useState()

  useEffect(() => {
    setView(isMobile && !isLandscape ? 'timeGridDay' : initialView) // for mobile and portrait then show day grid
  }, [isMobile, isLandscape, initialView])

  const updateTitle = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      setTitle(calendarApi.view.title)
    }
  }

  const handleViewChange = (event, nextView) => {
    if (nextView !== null) {
      updateTitle()
      fetchEvents(nextView)
      setView(nextView) // Fetch events for today
    }
  }

  const handleTodayClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.today()
      const today = calendarApi.getDate()
      setSelectedDate(new Date())
      updateTitle()
      fetchEvents(view, today) // Fetch events for today
    }
  }

  const handlePrevClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.prev()
      const newDate = calendarApi.getDate()
      setSelectedDate(newDate)
      updateTitle()
      fetchEvents(view, newDate) // Fetch events for the previous period
    }
  }

  const handleNextClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.next()
      const newDate = calendarApi.getDate()
      setSelectedDate(newDate)
      updateTitle()
      fetchEvents(view, newDate) // Fetch events for the next period
    }
  }

  const handleDateClick = () => {
    setIsDatePickerOpen(true)
  }

  const handleDateChange = (newDate) => {
    if (newDate && dayjs(newDate).isValid()) {
      setSelectedDate(newDate)
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi()
        calendarApi.gotoDate(dayjs(newDate).format(DATE_FORMATS.ISO_DATE))
        updateTitle()
      }
      const formattedDate = dayjs(newDate).format(DATE_FORMATS.ISO_DATE)
      setIsDatePickerOpen(false)
      fetchEvents(view, formattedDate) // Fetch events for the selected date
    }
  }

  const fetchEvents = async (viewMode, date) => {
    let startDate, endDate
    const referenceDate = date || selectedDate
    if (viewMode === 'timeGridDay') {
      startDate = dayjs(referenceDate).startOf('day').utc(false).format(DATE_FORMATS.ISO_DATE)
      endDate = dayjs(referenceDate).add(1, 'day').utc(false).format(DATE_FORMATS.ISO_DATE)
    } else if (viewMode === 'timeGridWeek') {
      startDate = dayjs(referenceDate).startOf('week').utc(false).format(DATE_FORMATS.ISO_DATE)
      endDate = dayjs(referenceDate).startOf('week').add(7, 'days').utc(false).format(DATE_FORMATS.ISO_DATE)
    }
    const params = {
      ccId,
      organisationId,
      startDate,
      endDate
    }

    try {
      const response = await getEvents(params)
      if (response && response.status === 200) {
        setSelectedEvent(mapEventsToCalendarFormat(response.data))
      }
    } catch (error) {
      console.error('Error fetching events:', error)
      Logger.error({
        message: error,
        payload: { file: 'scheduleCalendar', method: 'fetchEvents' }
      })
    }
  }

  const getFullCalenderHiddenDaysFromAvailability = (fetchedAvailability) => {
    return fetchedAvailability
      .filter((dayObj) => {
        const { start_time, end_time, day } = dayObj
        return (
          start_time[0] === 0 && start_time[1] === 0 && end_time[0] === 0 && end_time[1] === 0 && (day === 'SATURDAY' || day === 'SUNDAY')
        )
      })
      .map((dayObj) => {
        const dayEnumValue = WeekdaysEnum[dayObj.day]
        return weekdaysEnumToFCIndex[dayEnumValue]
      })
  }
  const mapBusinessHours = (data) => {
    return data
      .filter((day) => !(day.start_time[0] === 0 && day.end_time[0] === 0)) // Remove non-working days
      .map((day) => ({
        daysOfWeek: [daysOfWeekMap[day.day]], // Map day name to FullCalendar's daysOfWeek
        startTime: `${day.start_time[0].toString().padStart(2, '0')}:${day.start_time[1].toString().padStart(2, '0')}`, // Format start time
        endTime: `${day.end_time[0].toString().padStart(2, '0')}:${day.end_time[1].toString().padStart(2, '0')}` // Format end time
      }))
  }

  const fetchAndSetFullCalendarHiddenDays = async () => {
    try {
      const response = await getScheduleAvailability({ ccId, organisation_id: organisationId })
      const fetchedAvailability = response?.data
      if (fetchedAvailability && fetchedAvailability.length > 0) {
        setCalendarHiddenDays(getFullCalenderHiddenDaysFromAvailability(fetchedAvailability))
        setBusinessHours(mapBusinessHours(fetchedAvailability))
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'scheduleCalendar', method: 'fetchAndSetFullCalendarHiddenDays' }
      })
    }
  }

  const fetchDateOverrides = async () => {
    try {
      const response = await getDateOverrides({ ccId, organisation_id: organisationId })
      if (response) {
        const unavailableDates = response.data.filter((data) => data.is_unavailable)
        setUnavailableDates(unavailableDates.map((item) => item.overrides_date))
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'dateoverrides', method: 'fetchDateOverrides' }
      })
    }
  }

  const handleDayCellClassNames = (arg) => {
    if (unavailableDates.includes(dayjs(arg.date).utc(false).format(DATE_FORMATS.ISO_DATE))) {
      return ['date-unavailable'] // Apply the class if the date is unavailable
    }
    return []
  }

  useEffect(() => {
    if (ccId && organisationId) {
      fetchEvents(view)
      fetchAndSetFullCalendarHiddenDays()
      fetchDateOverrides()
    } // Initial fetch on mount
  }, [ccId, organisationId, refresh])

  const handleEventClick = (eventInfo) => {
    if (eventInfo?.event?.id) setSelectedEventId(eventInfo.event.id) // Set the selected event ID
    setIsSliderOpen(true) // Open the slider when an event is clicked
  }

  const isAllowEventSelection = (eventInfo) => {
    const eventDateTime = dayjs(eventInfo.startStr) // Event time in local time
    const startOfCurrentDate = dayjs().startOf('day') // start of the day (00:00)
    const futureLimit = startOfCurrentDate.add(organisationDetails?.max_time, 'minute')
    return eventDateTime.isAfter(startOfCurrentDate) && eventDateTime.isBefore(futureLimit)
  }

  const renderEventContent = (eventInfo) => {
    const { title, extendedProps } = eventInfo.event
    const { id, type, assignee, start, client, offset, vehicle, duration } = extendedProps
    // const timeText = dayjs(start).utc(false).format(DATE_FORMATS.ISO_TIME_STRING)
    const timeText = dayjs(start).tz(offset).format(DATE_FORMATS.ISO_TIME_STRING)
    const repair_order_num = (duration === 60 && !assignee?.full_name) || duration >= 90 ? vehicle.repair_order_num : ''
    return (
      <SchedularCalendarEvent
        timeText={timeText}
        type={type}
        title={title}
        id={id}
        assingeeName={assignee?.full_name || ''}
        isActive={assignee?.is_active || ''}
        fullName={client?.business_name || client?.full_name || ''}
        repair_order_num={repair_order_num}
      />
    )
  }

  const renderHeaderContent = (date) => {
    const currentDate = dayjs(date.date).utc(false) // Pass the ISO date directly
    const today = dayjs().utc(false) // Get today's date
    const isToday = date.isToday // Check if it's today
    const formattedDate = currentDate.format('ddd').toUpperCase() // Get the abbreviated weekday name
    const dayOfMonth = currentDate.format('D') // Get the day of the month

    return (
      <AdasBox className='header-date'>
        <AdasBox className='today-date'>
          <AdasTypography className={`weekday ${isToday ? 'active' : ''}`} variant='body2' component='span'>
            {formattedDate}
          </AdasTypography>
          {isToday ? (
            <AdasBox className='circle'>
              <AdasTypography className='day' variant='h6' component='span'>
                {dayOfMonth}
              </AdasTypography>
            </AdasBox>
          ) : (
            <AdasTypography variant='h6' sx={{ fontWeight: '500' }} component='span'>
              {dayOfMonth}
            </AdasTypography>
          )}
        </AdasBox>
      </AdasBox>
    )
  }

  return (
    <>
      <AdasBox
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'start', md: 'space-between' },
          mb: '12px',
          gap: { xs: '24px' }
        }}
      >
        {(!isMobile || isLandscape) && (
          <AdasButtonGroup type='toggleButton' value={view} exclusive aria-label='opt-in' onChange={handleViewChange} name='optIn'>
            <AdasButton
              buttonType='toggleButton'
              value='timeGridDay'
              color='primary'
              aria-label='day view'
              sx={{
                padding: { sm: '5px 10px' },
                flex: { xs: 1, sm: 'none' }
              }}
            >
              DAY
            </AdasButton>
            <AdasButton
              buttonType='toggleButton'
              value='timeGridWeek'
              color='primary'
              aria-label='week view'
              sx={{
                padding: { sm: '5px 10px' },
                flex: { xs: 1, sm: 'none' }
              }}
            >
              WEEK
            </AdasButton>
          </AdasButtonGroup>
        )}
        <AdasBox display='flex' alignItems='center' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          <AdasButton
            startIcon={<NavigateBeforeIcon />}
            onClick={handlePrevClick}
            aria-label='previous period'
            sx={{
              padding: { xs: '3px', sm: '6px' }
            }}
          />
          <AdasTypography
            variant='h6'
            component='span'
            color='primary'
            onClick={handleDateClick}
            style={{ cursor: 'pointer' }}
            sx={{
              fontSize: { xs: '14px', sm: '18px' },
              mx: { xs: '8px', sm: '12px' }
            }}
          >
            {title}
          </AdasTypography>
          <AdasButton
            startIcon={<NavigateNextIcon />}
            onClick={handleNextClick}
            aria-label='next period'
            sx={{
              padding: { xs: '3px', sm: '6px' }
            }}
          />
        </AdasBox>
        {!isMobile && (
          <AdasButton
            variant='outlined'
            aria-label='TODAY'
            color='primary'
            onClick={handleTodayClick}
            sx={{
              padding: { sm: '6px 12px' }
            }}
          >
            TODAY
          </AdasButton>
        )}
      </AdasBox>

      <AdasCalendar
        view={view}
        height={900}
        SchedularCalendarEvent={SchedularCalendarEvent}
        calendarRef={calendarRef}
        updateTitle={updateTitle}
        setSelectedEvent={setSelectedEvent}
        setIsSliderOpen={setIsSliderOpen}
        setSelectedEventId={setSelectedEventId}
        scrollTime={'08:00'}
        hiddenDays={calendarHiddenDays}
        eventContent={renderEventContent}
        events={selectedEvent}
        nowIndicator={true}
        slotHeight='48px'
        slotMinTime='00:00:00' // Set the minimum time to 8 AM
        slotMaxTime='24:00:00' // Set the maximum time to 5 PM
        allDaySlot={false} // Disable the all-day section
        timeZone={getTimeZoneByCode(organisationDetails.timezone_at)}
        eventClick={handleEventClick}
        selectable={true}
        select={openNewEventSlider}
        firstDay={1}
        selectOverlap={true}
        selectAllow={isAllowEventSelection}
        eventMaxStack={3}
        dayCellClassNames={handleDayCellClassNames}
        dayHeaderContent={renderHeaderContent}
        className='adas-calendar'
        businessHours={businessHours}
      />

      <AdsCustomModal title='Select Date' open={isDatePickerOpen} onClose={() => setIsDatePickerOpen(false)}>
        <AdasDatePicker
          label='Select Date'
          variant='outlined'
          name='add_date'
          value={dayjs(selectedDate).format(DATE_FORMATS.ISO_DATE_STRING)}
          renderInput={(params) => <TextField {...params} />}
          format={DATE_FORMATS.ISO_DATE_STRING}
          onChange={handleDateChange}
        />
      </AdsCustomModal>

      {isSliderOpen && (
        <AdasDrawer anchor={'right'} open={isSliderOpen} onClose={() => setIsSliderOpen(false)}>
          <AppointmentDetails cancel={() => setIsSliderOpen(false)} selectedEventId={selectedEventId} setRefresh={setRefresh} />
        </AdasDrawer>
      )}
    </>
  )
}

ScheduleCalendar.propTypes = {
  initialView: PropTypes.oneOf(['timeGridDay', 'timeGridWeek']).isRequired
}
