import Pagination from '@mui/material/Pagination'
import PropTypes from 'prop-types'

export const AdasPagination = ({ page, count, onChange, shape = 'rounded' }) => {
  return <Pagination shape={shape} count={count} page={page} onChange={onChange} />
}

AdasPagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  shape: PropTypes.oneOf(['rounded', 'standard'])
}
