import { Button, CircularProgress, IconButton, ToggleButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { forwardRef, useState } from 'react'
import { debounce } from 'utils/utils'

const buttonTypes = {
  button: Button,
  iconButton: IconButton,
  toggleButton: ToggleButton
}

// eslint-disable-next-line react/display-name
export const AdasButton = forwardRef(
  ({ children, onClick, disabled, innerRef, buttonType = 'button', debounceTime = 300, isDebounceEnabled = false, ...props }, ref) => {
    const theme = useTheme()
    const defaultWeight = props.fontWeight || theme.typography.fontWeight500
    const effectiveRef = innerRef || ref
    const ButtonComponent = buttonTypes[buttonType]
    const [loading, setLoading] = useState(false)

    // Debounced click handler
    const onClickWithDebounce = debounce(async (event) => {
      if (onClick) {
        setLoading(true)
        try {
          await onClick(event)
        } finally {
          setLoading(false)
        }
      }
    }, debounceTime)

    const onClickWithOutDebounce = onClick

    return (
      <ButtonComponent
        {...props}
        disabled={disabled || loading}
        startIcon={loading ? <CircularProgress color='primary' size={20} /> : props.startIcon}
        onClick={isDebounceEnabled ? onClickWithDebounce : onClickWithOutDebounce}
        ref={effectiveRef}
        sx={{ fontWeight: defaultWeight, ...props.sx }}
      >
        {children}
      </ButtonComponent>
    )
  }
)

AdasButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any
    })
  ]),
  disabled: PropTypes.bool,
  buttonType: PropTypes.oneOf(['button', 'iconButton', 'toggleButton']),
  debounceTime: PropTypes.number,
  isDebounceEnabled: PropTypes.bool
}
