import { ListItem } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasListItem = ({ sx = {}, ...props }) => {
  return <ListItem sx={sx} {...props} />
}

AdasListItem.propTypes = {
  sx: PropTypes.object
}
