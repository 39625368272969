import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import theme from '../../theme/theme'

const defaultStyles = {
  variant: 'body1',
  color: theme.typography.defaultColor
}

export const AdasTypography = ({ variant = defaultStyles.variant, color, children, sx, ...props }) => {
  return (
    <Typography variant={variant} color={color} sx={sx} {...props}>
      {children}
    </Typography>
  )
}

AdasTypography.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node
}
