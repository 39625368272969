import { Divider } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasDivider = ({ orientation, ...rest }) => {
  return <Divider orientation={orientation} {...rest} />
}

AdasDivider.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
}
