import PropTypes from 'prop-types'

import { AdasBox, AdasTypography, AdasImage } from '@components/wrapper-components'
import CarSkeleton from '../../assets/images/car.svg'

export const CarImageHolder = (props) => {
  const { showText = true } = props
  return (
    <AdasBox>
      <AdasImage sx={{ margin: '20px auto' }} src={CarSkeleton} alt='holder' />
      {showText && (
        <AdasBox
          sx={{
            height: '100px',
            display: 'flex',
            margin: '20px 0px',
            backgroundColor: 'rgba(26, 27, 31, 0.04)'
          }}
        >
          <AdasTypography
            component='span'
            sx={{
              fontSize: 16,
              margin: 'auto',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Enter Vin to fill vehicle details
          </AdasTypography>
        </AdasBox>
      )}
    </AdasBox>
  )
}

CarImageHolder.propTypes = {
  showText: PropTypes.bool
}
