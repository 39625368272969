import { ConfirmModal } from '@components/common'
import { GENERIC_MESSAGES, BUTTONS_DISPLAY_LABEL } from '../../../constants'
import { AdasBox, AdasButton, AdasTypography, AdasModal, AdasImage } from '@components/wrapper-components'
import { AdasLoader } from '@components/adas-loader'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Logger } from '../../../logger'
import './PhotoViewer.css'

export const PhotoViewerWithoutThumbnail = (props) => {
  const {
    parentId,
    fetchImage,
    imageDataObj,
    imageUrl,
    imageLocation,
    onUpdate,
    disabled = false,
    isOpen,
    closeModal,
    title,
    allowDelete = false
  } = props

  const fileRef = useRef(null)
  const [fullImageUrl, setFullImageUrl] = useState(imageUrl)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteAttachment = async () => {
    try {
      closeModal()
      setFullImageUrl(null)
      onUpdate(null, imageLocation, null)
    } catch (error) {
      console.error('Error deleting image:', error)
    }
  }

  const handleDelete = async () => {
    setShowConfirmModal(false)
    deleteAttachment()
  }

  const deleteWithConfirmation = () => {
    setShowConfirmModal(true)
  }

  const handleUpload = async (ref) => {
    const file = ref?.current?.files?.[0]
    if (file) {
      setLoading(true)
      setFullImageUrl(null)
      const formData = new FormData()
      formData.append('file', file, file.name)
      setFullImageUrl(URL.createObjectURL(file))
      onUpdate(formData, imageLocation, URL.createObjectURL(file))
      setLoading(false)
    }
  }

  const requestFullImage = useCallback(async () => {
    try {
      if (imageDataObj.id) {
        setLoading(true)
        const imageData = await fetchImage(imageDataObj.id)
        if (imageData.status === 200 && imageData.data) {
          const url = URL.createObjectURL(imageData.data)
          setFullImageUrl(url)
        }
      }
    } catch (error) {
      Logger.error({ message: error, payload: { path: 'PhotoViewer/requestFullImage', parentId, imageDataObj } })
    } finally {
      setLoading(false)
    }
  }, [imageDataObj, parentId])

  useEffect(() => {
    if (isOpen) {
      requestFullImage()
    }
  }, [isOpen, requestFullImage])

  return (
    <>
      <AdasModal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby='responsive-modal-title'
        aria-describedby='responsive-modal-description'
        className='modalContainer'
      >
        <AdasBox className='modalContent'>
          <AdasTypography id='responsive-modal-title' component='h2' className='title'>
            {title}
          </AdasTypography>
          <AdasBox className='imageContainer'>
            {loading ? (
              <AdasLoader />
            ) : (
              <AdasImage
                src={fullImageUrl}
                alt='Modal'
                className='image'
                sx={{
                  width: '100%'
                }}
              />
            )}
          </AdasBox>
          <AdasBox className='buttonSection'>
            <AdasBox className='buttonContainer'>
              <AdasButton component='label' startIcon={<PhotoCameraOutlinedIcon />} disabled={disabled}>
                <AdasTypography variant='button'>RETAKE PHOTO</AdasTypography>
                <input ref={fileRef} type='file' hidden accept='image/*' onChange={() => handleUpload(fileRef)} />
              </AdasButton>
              {allowDelete && !disabled && (
                <AdasButton buttonType='iconButton' color='primary' onClick={() => deleteWithConfirmation()} size='small'>
                  <DeleteOutlineOutlinedIcon
                    fontSize='small'
                    style={{
                      marginRight: '6px'
                    }}
                  />
                  <AdasTypography variant='button'>DELETE</AdasTypography>
                </AdasButton>
              )}
            </AdasBox>
            <AdasBox className='doneButtonContainer'>
              <AdasButton onClick={closeModal} variant='contained' color='primary'>
                Done
              </AdasButton>
            </AdasBox>
          </AdasBox>
        </AdasBox>
      </AdasModal>
      {showConfirmModal && (
        <ConfirmModal
          cancelLabel={BUTTONS_DISPLAY_LABEL.CANCEL}
          confirmLabel={BUTTONS_DISPLAY_LABEL.DELETE}
          open={showConfirmModal}
          title={GENERIC_MESSAGES.DELETE_PHOTO}
          message={GENERIC_MESSAGES.CONFIRM_DELETE_PHOTO}
          handleClose={() => setShowConfirmModal(false)}
          handleContinue={handleDelete}
        />
      )}
    </>
  )
}
