import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

import { AdasList, AdasListItem, AdasListItemIcon, AdasTypography } from '@components/wrapper-components'

const lowerCaseLetters = /[a-z]/g
const upperCaseLetters = /[A-Z]/g
const numbers = /[0-9]/g
const specialCharRegex = /^[A-Za-z0-9 ]+$/

const ValidationListItem = ({ text, success }) => {
  const theme = useTheme()
  const color = success ? theme.palette.success.light : theme.palette.text.secondary

  return (
    <AdasListItem sx={{ paddingBottom: 0, paddingTop: '.25rem', display: 'flex' }}>
      <AdasListItemIcon sx={{ paddingRight: '.5rem', minWidth: 'auto' }}>
        {success ? <CheckCircleIcon sx={{ color: color }} fontSize='small' /> : <HighlightOffIcon color={color} fontSize='small' />}
      </AdasListItemIcon>
      <AdasTypography variant='body2' color={color}>
        {text}
      </AdasTypography>
    </AdasListItem>
  )
}

export const PasswordValidation = ({ password, onValidationChange }) => {
  const hasLower = password.match(lowerCaseLetters)
  const hasUpper = password.match(upperCaseLetters)
  const hasNumbers = password.match(numbers)
  const hasSpecialChars = password.length > 0 && !password.match(specialCharRegex)
  const correctLength = password.length >= 8 && password.length <= 21
  const isPasswordValid = correctLength && hasLower && hasUpper && hasNumbers && hasSpecialChars

  useEffect(() => {
    onValidationChange(isPasswordValid)
  }, [isPasswordValid, onValidationChange])

  return (
    <>
      <AdasTypography variant='subtitle2' sx={{ display: 'flex' }}>
        Password Requirements
      </AdasTypography>
      <AdasList>
        <ValidationListItem text='At least 8 characters and less than 22' success={correctLength} />
        <ValidationListItem text='A lowercase letter' success={hasLower} />
        <ValidationListItem text='An uppercase letter' success={hasUpper} />
        <ValidationListItem text='A number' success={hasNumbers} />
        <ValidationListItem text='A special character (for exampel !, #, $, ?)' success={hasSpecialChars} />
      </AdasList>
    </>
  )
}

PasswordValidation.propTypes = {
  password: PropTypes.string.isRequired,
  onValidationChange: PropTypes.func.isRequired
}

ValidationListItem.propTypes = {
  text: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired
}
