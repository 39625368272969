import { AdasCssBaseline, AdasThemeProvider } from '@components/wrapper-components'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import ErrorBoundary from './components/error-boundary/ErrorBoundary'
// import theme from './theme/theme'
import { createTheme } from 'theme/src'
import './global.css'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AdasThemeProvider theme={createTheme({ responsiveFontSizes: true })}>
        <AdasCssBaseline />
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </AdasThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
)
