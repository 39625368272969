import theme from 'theme/theme'

export const WorkFlowTypesEnum = {
  INVOICE: 'Invoice',
  QUOTE: 'Quote',
  WORKORDER: 'WO'
}

export const StatusEnum = {
  ALL: 'All',
  NEW: 'New',
  OPEN: 'Open',
  CLOSED: 'Closed',
  BLOCKED: 'Blocked',
  MOVED: 'Moved',
  REVERTED: 'Reverted'
}

export const OperationType = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  ROTATE: 'ROTATE',
  RETAKE: 'RETAKE'
}

export const FieldNames = {
  DASH_IMAGE_URL: 'dashImageUrl',
  WEIGHT_IMAGE_URL_1: 'weightImageUrl1',
  WEIGHT_IMAGE_URL_2: 'weightImageUrl2',
  DRIVER_FRONT_IMAGE_URL: 'driverFrontImageUrl',
  DRIVER_REAR_IMAGE_URL: 'driverRearImageUrl',
  PASSENGER_FRONT_IMAGE_URL: 'passengerFrontImageUrl',
  PASSENGER_REAR_IMAGE_URL: 'passengerRearImageUrl',
  DRIVER_FRONT_WHEEL_IMAGE_URL: 'driverFrontWheelImageUrl',
  DRIVER_REAR_WHEEL_IMAGE_URL: 'driverRearWheelImageUrl',
  PASSENGER_FRONT_WHEEL_IMAGE_URL: 'passengerFrontWheelImageUrl',
  PASSENGER_REAR_WHEEL_IMAGE_URL: 'passengerRearWheelImageUrl',
  ALIGNMENT_IMAGE_URL: 'alignmentImageUrl',
  ALIGNMENT_IMAGE_URL_2: 'alignmentImageUrl2',
  PRE_CAL_REPORT_SCAN: 'preCalReportScan',
  PRE_CAL_REPAIR_ESTIMATE_SCAN: 'preCalRepairEstimateScan',
  CAL_REQUIRED_REPORT: 'calRequiredReport',
  WHEEL_ALIGNMENT_SCAN: 'wheelAlignmentScan',
  OEM_PROCEDURE_SCAN: 'oemProcedureScan',
  DASH_POST_CALIBRATION_IMAGE_URL: 'dashPostCalibrationImageUrl',
  CALIBRATION_REQUIRED_REPORT: 'calibrationRequiredReport',
  POST_TEST_SCAN_REPORT: 'postTestScanReport',
  ADDITIONAL_PHOTOS: 'additionalPhotos',
  FRONT_CAMERA_PHOTOS: 'frontCamera',
  REAR_CAMERA_PHOTOS: 'rearCamera',
  SURROUND_VIEW_PHOTOS: 'surroundViewCamera',
  BLIND_SPOT_PHOTOS: 'blindSpotCamera',
  FRONT_RADAR_CAMERA_PHOTOS: 'frontRadarCamera'
}

export const FieldConfigurations = {
  [FieldNames.DASH_IMAGE_URL]: {
    description: 'Dash Image',
    title: 'Dash Information',
    subTitle: ''
  },
  [FieldNames.WEIGHT_IMAGE_URL_1]: {
    description: 'Weight Image 1',
    title: '',
    subTitle: ''
  },
  [FieldNames.WEIGHT_IMAGE_URL_2]: {
    description: 'Weight Image 2',
    title: '',
    subTitle: ''
  },
  [FieldNames.DRIVER_FRONT_IMAGE_URL]: {
    description: 'Driver Front Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.DRIVER_REAR_IMAGE_URL]: {
    description: 'Driver Rear Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.PASSENGER_FRONT_IMAGE_URL]: {
    description: 'Passenger Front Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.PASSENGER_REAR_IMAGE_URL]: {
    description: 'Passenger Rear Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.DRIVER_FRONT_WHEEL_IMAGE_URL]: {
    description: 'Driver Front Wheel Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.DRIVER_REAR_WHEEL_IMAGE_URL]: {
    description: 'Driver Rear Wheel Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.PASSENGER_FRONT_WHEEL_IMAGE_URL]: {
    description: 'Passenger Front Wheel Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.PASSENGER_REAR_WHEEL_IMAGE_URL]: {
    description: 'Passenger Rear Wheel Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.ALIGNMENT_IMAGE_URL]: {
    description: 'Alignment Image',
    title: '',
    subTitle: ''
  },
  [FieldNames.PRE_CAL_REPORT_SCAN]: {
    id: '',
    description: 'Pre-Calibration Report Scan',
    title: '',
    subTitle: '',
    url: '',
    img_rotation: 0
  },
  [FieldNames.PRE_CAL_REPAIR_ESTIMATE_SCAN]: {
    id: '',
    description: 'Pre-Calibration Repair Estimate Scan',
    title: '',
    subTitle: '',
    url: '',
    img_rotation: 0
  },
  [FieldNames.CAL_REQUIRED_REPORT]: {
    description: 'Calibration Required Report',
    title: '',
    subTitle: ''
  },
  [FieldNames.WHEEL_ALIGNMENT_SCAN]: {
    description: 'Wheel Alignment Scan',
    title: '',
    subTitle: ''
  },
  [FieldNames.OEM_PROCEDURE_SCAN]: {
    description: 'OEM Procedure Scan',
    title: '',
    subTitle: ''
  }
}

export const StatusColorConfig = {
  primary: theme.palette.background.highlight,
  secondary: theme.palette.secondary.main.light,
  default: theme.palette.secondary.main.light,
  [StatusEnum.NEW.toLocaleUpperCase()]: '#B1D6FD',
  [StatusEnum.OPEN.toLocaleUpperCase()]: '#FFFACA',
  [StatusEnum.CLOSED.toLocaleUpperCase()]: '#96F7B8'
}

export const SupportRequestEnums = {
  CALIBRATION_DIAGNOSTIC: 'CALIBRATION_DIAGNOSTIC',
  CAR_ADASPLUS_IT: 'CAR_ADASPLUS_IT',
  QUOTE_WORKORDER_INVOICE: 'QUOTE_WORKORDER_INVOICE'
}

export const RaisedTypesEnum = {
  invoice: 'INVOICE',
  quote: 'QUOTE',
  workorder: 'WORKORDER'
}

export const ProblemAreaEnum = {
  DIAGNOSTIC_TROUBLE_CODE: 'DIAGNOSTIC_TROUBLE_CODE',
  FAILED_CALIBRATION: 'FAILED_CALIBRATION',
  OTHER: 'OTHER'
}

export const SupportRecordTypeEnums = {
  QUOTE_WORKORDER_INVOICE: 'QUOTE_WORKORDER_INVOICE',
  INFORMATION_REQUEST: 'INFORMATION_REQUEST'
}

export const BroadCastTypes = {
  CRITICAL: 'CRITICAL',
  INFO: 'INFO'
}

//For Display order
export const WeekdaysEnum = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
}

//FullcalendarDays start from 0
export const FullCalendarDaysEnum = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
}

export const weekdaysEnumToFCIndex = {
  [WeekdaysEnum.MONDAY]: FullCalendarDaysEnum.MONDAY,
  [WeekdaysEnum.TUESDAY]: FullCalendarDaysEnum.TUESDAY,
  [WeekdaysEnum.WEDNESDAY]: FullCalendarDaysEnum.WEDNESDAY,
  [WeekdaysEnum.THURSDAY]: FullCalendarDaysEnum.THURSDAY,
  [WeekdaysEnum.FRIDAY]: FullCalendarDaysEnum.FRIDAY,
  [WeekdaysEnum.SATURDAY]: FullCalendarDaysEnum.SATURDAY,
  [WeekdaysEnum.SUNDAY]: FullCalendarDaysEnum.SUNDAY
}
