import createStore from 'zustand'

export const useBroadcastStore = createStore((set) => ({
  broadCastMessage: null,
  setBroadcastMessage: (message) => {
    set(() => ({
      broadCastMessage: message
    }))
  }
}))
