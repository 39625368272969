import { FormLabel } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasFormLabel = ({ id, sx = {}, children, ...props }) => {
  return (
    <FormLabel id={id} sx={sx} {...props}>
      {children}
    </FormLabel>
  )
}

AdasFormLabel.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node.isRequired
}
