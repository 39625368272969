import { useCallback, useEffect, useRef } from 'react'
export const usePolling = (setRefresh, refresh, pollingIntervalMinutes = 1) => {
  const timeoutId = useRef(null)
  const componentMounted = useRef(false)
  const isVisible = useRef(true)
  const pollingInterval = pollingIntervalMinutes * 60 * 1000

  const startPolling = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      if (componentMounted.current && isVisible.current) {
        setRefresh((prev) => !prev)
        startPolling() // Continue polling
      }
    }, pollingInterval)
  }, [setRefresh, pollingInterval])

  useEffect(() => {
    componentMounted.current = true
    startPolling()

    const handleVisibilityChange = () => {
      isVisible.current = !document.hidden

      if (isVisible.current) {
        startPolling()
      } else {
        if (timeoutId.current) clearTimeout(timeoutId.current)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      componentMounted.current = false
      if (timeoutId.current) clearTimeout(timeoutId.current)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [startPolling])

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    startPolling()
  }, [refresh, startPolling])
}
