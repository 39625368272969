import { AppRoutes } from '@routes/app-route'
import { useAxiosSecure } from './api/api'

const App = () => {
  useAxiosSecure()
  return (
    <>
      <AppRoutes />
    </>
  )
}

export default App
