import { useEffect, useState } from 'react'
import { BACKEND_URL } from '../config'
import { useBroadcastStore } from '@caradasstore/BroadcastStore'
import { useAuthStore } from '@caradasstore/AuthStore'
import { getActiveBroadcast } from '../api/api'
import { Logger } from '../logger'

export const useNotificationSubscription = (ccId) => {
  const setBroadcastMessage = useBroadcastStore((store) => store.setBroadcastMessage)
  const authCtx = useAuthStore.getState()
  const token = authCtx.token
  const [trigger, setTrigger] = useState(false)
  let eventSource = null
  let timeoutId = null
  const TIMEOUT = 300000

  useEffect(() => {
    if (ccId) {
      const notificationSubscriptionUrl = `${BACKEND_URL}/v1/notifications/subscribe?ccId=${ccId}&token=${encodeURIComponent(token)}`
      eventSource = new EventSource(notificationSubscriptionUrl)

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data)
        setBroadcastMessage(data)
      }

      eventSource.onerror = () => {
        Logger.error({ message: 'Notification Subscription Error' })
        Logger.info({ message: 'Retrying event subscription in 5 minutes' })

        eventSource.close()
        eventSource = null // Clear the variable as the event source is closed

        // Retry in 5 mins
        timeoutId = setTimeout(async () => {
          // Fallback to fetch active notification
          try {
            const broadcastResponse = await getActiveBroadcast()
            if (broadcastResponse?.data) {
              setBroadcastMessage(broadcastResponse.data)
            } else {
              setBroadcastMessage(null)
            }
          } catch (error) {
            Logger.error({ message: error, payload: { path: 'useNotificationSubscription/getActiveBroadcast' } })
          }

          // trigger useEffect to again establish SSE connection
          setTrigger(!trigger)
        }, TIMEOUT)
      }

      // Cleanup function to close the EventSource and reset state
      return () => {
        if (eventSource) {
          eventSource.close()
          eventSource = null
        }

        if (timeoutId) {
          clearTimeout(timeoutId)
          timeoutId = null
        }
      }
    }
  }, [ccId, authCtx, token, trigger])
}
