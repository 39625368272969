import PropTypes from 'prop-types'

import { AdasButton, AdasButtonGroup, AdasInputLabel } from '@components/wrapper-components'

export const ToggleActive = ({ toggle, handleToggleActive, disabled = false }) => {
  return (
    <>
      <AdasInputLabel htmlFor='status'>Status</AdasInputLabel>
      <AdasButtonGroup
        type='toggleButton'
        value={toggle ? 'active' : 'inactive'}
        exclusive
        onChange={handleToggleActive}
        aria-label='status'
        name='status'
        sx={{ marginBottom: '20px' }}
        disabled={disabled}
      >
        <AdasButton buttonType='toggleButton' value='active' color='primary' aria-label='active' sx={{ padding: '5px 10px' }}>
          Active
        </AdasButton>
        <AdasButton buttonType='toggleButton' value='inactive' color='primary' aria-label='inactive' sx={{ padding: '5px 10px' }}>
          Inactive
        </AdasButton>
      </AdasButtonGroup>
    </>
  )
}

ToggleActive.propTypes = {
  toggle: PropTypes.bool.isRequired,
  handleToggleActive: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
