import { Backdrop } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasBackdrop = ({ open, sx, onClose, children, ...rest }) => {
  return (
    <Backdrop sx={sx} open={open} onClick={onClose} {...rest}>
      {children}
    </Backdrop>
  )
}

AdasBackdrop.propTypes = {
  open: PropTypes.bool,
  sx: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.node
}
