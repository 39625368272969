import PropTypes from 'prop-types'

import { AdasPaper } from '@components/wrapper-components'

export const StickyFooter = ({ children, sxProps = {} }) => {
  const stickyFooterStyles = {
    position: 'sticky',
    bottom: 0,
    width: 'inherit',
    zIndex: 100,
    padding: '20px 0',
    ...sxProps // Allow passing additional sx styles if needed
  }
  return (
    <AdasPaper sx={stickyFooterStyles} elevation={0}>
      {children}
    </AdasPaper>
  )
}

StickyFooter.propTypes = {
  children: PropTypes.node.isRequired
}
