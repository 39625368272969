import { EditCalibrationForm } from '@components/settings'
import { GeneralSettings } from '@components/settings/general'
import { UserQueue } from '@components/user'
import { AdasBox, AdasContainer, AdasPaper, AdasTabs } from '@components/wrapper-components'
import { useState } from 'react'

export const SettingsPage = () => {
  const [settingType, setSettingType] = useState('cc')
  const onTabChange = (e, value) => {
    setSettingType(value)
  }
  const tabs = [
    { label: 'Calibration Center Info', value: 'cc' },
    { label: 'Users', value: 'user' },
    { label: 'General', value: 'general' }
  ]

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        sx={{
          padding: '1.25rem 1.25rem',
          marginTop: '1.25rem',
          backgroundColor: 'white',
          marginBottom: '40px'
        }}
      >
        <AdasBox>
          <AdasTabs tabs={tabs} value={settingType} onChange={onTabChange} variant='scrollable' scrollButtons='auto' />
          {settingType === 'cc' && <EditCalibrationForm />}
          {settingType === 'user' && <UserQueue />}
          {settingType === 'general' && <GeneralSettings />}
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
