import { ResetPasswordForm } from '@components/auth'
import { homePageStyles } from '../home-page-styles'

export const ResetPasswordPage = () => {
  return (
    <>
      {homePageStyles}
      <ResetPasswordForm />
    </>
  )
}
