import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { PasswordValidation } from '@components/validation'
import { AdasBox, AdasButton, AdasInputAdornment, AdasTextField } from '@components/wrapper-components'
import { updateUserPassword } from '../../../../api/api'

export const PasswordForm = ({ user, close, onSaveSuccess }) => {
  const [newPassword, setNewPassword] = useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [enableSave, setEnableSave] = useState(false)

  const handleValidationChange = (isValid) => {
    setEnableSave(isValid)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setNewPassword(value)
  }

  const handleSavePassword = () => {
    const data = { ...user, password: newPassword }
    const updateUserResponse = updateUserPassword({
      data: data
    })
    updateUserResponse.then((response) => {
      if (response.status === 200) {
        close()
        onSaveSuccess(data)
      }
    })
  }

  return (
    <>
      <AdasTextField
        placeholder='Password'
        type={showPassword ? 'text' : 'password'}
        id='password'
        label='Password'
        sx={{ width: '100%', paddingBottom: '.5rem' }}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <AdasInputAdornment position='end'>
              <AdasButton
                buttonType='iconButton'
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(evt) => evt.preventDefault()}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </AdasButton>
            </AdasInputAdornment>
          )
        }}
      />
      <PasswordValidation password={newPassword} onValidationChange={handleValidationChange} />
      <AdasBox
        sx={{
          margin: '20px 0px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={close}>
          Cancel
        </AdasButton>
        <AdasButton
          type='submit'
          disabled={!enableSave}
          variant='contained'
          aria-label='save'
          color='primary'
          sx={{ marginLeft: '.5rem' }}
          onClick={handleSavePassword}
        >
          Save
        </AdasButton>
      </AdasBox>
    </>
  )
}

PasswordForm.propTypes = { user: PropTypes.object, close: PropTypes.func.isRequired, onSaveSuccess: PropTypes.func.isRequired }
