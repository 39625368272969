import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'

import { StatusInfo } from '@components/status-info'
import { AdasBox, AdasButton, AdasCard, AdasCardContent, AdasTypography } from '@components/wrapper-components'
import { currencyFormatter } from '../../../utils/utils'

export const ItemList = (props) => {
  const theme = useTheme()
  const { itemList, name, isCustomItem = false, disabled, onClickEdit } = props
  return (
    <AdasBox>
      {itemList.length > 0 ? (
        <AdasBox
          component='ul'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'auto',
            margin: '16px 10px'
          }}
        >
          {itemList.map((item, index) => {
            return (
              <li key={index}>
                <AdasCard
                  sx={{
                    margin: '10px',
                    width: '200px'
                  }}
                >
                  <AdasCardContent sx={{ paddingBottom: '5px' }}>
                    <StatusInfo
                      label={currencyFormatter(item.price)}
                      color='primary'
                      isDiscount={item.item_type === 'discount' ? true : false}
                    />
                    <AdasTypography
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical'
                      }}
                    >
                      {item.name}
                    </AdasTypography>
                    {isCustomItem && !disabled && (
                      <AdasBox sx={{ textAlign: 'end' }}>
                        <AdasButton
                          startIcon={<ModeEditOutlineOutlinedIcon />}
                          key='2'
                          aria-label='edit'
                          color='primary'
                          onClick={() => onClickEdit(item)}
                        >
                          Edit
                        </AdasButton>
                      </AdasBox>
                    )}
                  </AdasCardContent>
                </AdasCard>
              </li>
            )
          })}
        </AdasBox>
      ) : (
        <AdasBox
          sx={{
            height: '106px',
            border: `1px dashed ${theme.palette.divider}`,
            margin: '16px 0px',
            display: 'flex',
            borderRadius: '4px'
          }}
        >
          {' '}
          <AdasTypography sx={{ fontSize: 12, margin: 'auto' }}>NO {name.toUpperCase()} ADDED</AdasTypography>
        </AdasBox>
      )}
    </AdasBox>
  )
}

ItemList.propTypes = {
  itemList: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  isCustomItem: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickEdit: PropTypes.func
}
