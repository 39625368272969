import createStore from 'zustand'
import persist from '../utils/persist'

export const useOrganisationStore = createStore(
  persist(
    {
      key: 'currentOrganisation'
    },
    (set) => ({
      organisationId: 0,
      organisationDetail: {},
      setOrganisationDetail: (newOrganisation) => {
        set(() => ({ organisationDetail: { ...newOrganisation } }))
        set(() => ({ organisationId: newOrganisation.id }))
      },
      setOrganisationId: (newId) => {
        set(() => ({
          organisationId: newId
        }))
      }
    })
  )
)
