import { useCallback, useEffect, useMemo, useState } from 'react'
import { UsaStates } from 'usa-states'

import { useCenterStore } from '@caradasstore/CenterStore'
import { useUserStore } from '@caradasstore/UserStore'
import { CalibrationCenterLogo } from '@components/settings'
import dayjs from 'dayjs'
import { DATE_FORMATS } from '../../../constants'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCard,
  AdasDatePicker,
  AdasDivider,
  AdasSearchMenu,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { StatusInfo } from '@components/status-info'
import { useTheme } from '@mui/material/styles'
import { Logger } from '../../../logger'
import { createCalibrationCenter, getCalibrationCenter } from '../../../api/api'
import { ccidFormatter, formatDate, phoneNumberFormat } from '../../../utils/utils'

export const EditCalibrationForm = () => {
  const theme = useTheme()
  const user = useUserStore((store) => store.currentUser)
  const ccId = useCenterStore((store) => store.ccId)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const [isEdit, setIsEdit] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const tableCellStyle = {
    textAlign: 'left'
  }
  const tableLeftCellStyle = {
    paddingLeft: '5%'
  }
  const labelStyle = {
    marginTop: '10px',
    fontWeight: '600'
  }
  const [calibrationCenter, setCalibrationCenter] = useState({
    name: '',
    website: '',
    email: '',
    phone: '',
    shop_address: '',
    shop_city: '',
    shop_state: '',
    shop_zip: '',
    shop_country: '',
    group_number: '',
    group_name: '',
    opening_date: '',
    support_lead: '',
    pbi_account_id: ''
  })
  const usStates = new UsaStates()

  const handleInputChange = (e) => {
    const value = getValueBasedOnField(e.target.value, e.target.name)
    setCalibrationCenter((prev) => {
      const calibrationCenter = {
        ...prev,
        [e.target.name]: value
      }
      return calibrationCenter
    })
  }

  const isBtnDisabled = useMemo(() => {
    const REQUIRED_FIELDS = ['name', 'email', 'phone', 'shop_address', 'shop_city', 'shop_state', 'shop_zip', 'shop_country']
    for (const key in calibrationCenter) {
      if (REQUIRED_FIELDS.includes(key) && (!calibrationCenter[key] || calibrationCenter[key].trim() === '')) {
        return true
      }
    }
    return false
  }, [calibrationCenter])

  const getValueBasedOnField = (value, fieldName) => {
    let result
    switch (fieldName) {
      case 'phone':
        result = phoneNumberFormat(value)
        break
      case 'opening_date':
        result = formatDate(value, DATE_FORMATS.ISO_DATE_STRING)
        break
      case 'group_number':
        result = formatGroupNumber(value)
        break
      default:
        result = value
    }
    return result
  }

  const formatGroupNumber = (value) => {
    return value.replace(/\D/g, '')
  }

  const fetchCalibrationCenter = useCallback(async () => {
    try {
      const ccResponse = await getCalibrationCenter({
        ccid: centerDetail.id
      })
      if (ccResponse?.status === 200) {
        if (ccResponse.data.opening_date) {
          ccResponse.data.opening_date = new Date(ccResponse.data.opening_date).toISOString()
        }
        setCenterDetail(ccResponse.data)
        setCalibrationCenter(ccResponse.data)
        setIsActive(ccResponse.data.is_active)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { ccId } })
    }
  }, [setCenterDetail, centerDetail.id])

  useEffect(() => {
    setLoading(true)
    fetchCalibrationCenter().finally(() => setLoading(false))
  }, [fetchCalibrationCenter, isEdit])

  const handleCancel = () => {
    setCalibrationCenter(centerDetail)
    setIsEdit(false)
  }

  const handleSubmit = useCallback(async () => {
    const data = {
      ...centerDetail,
      ...calibrationCenter,
      group_name: calibrationCenter.group_name ? calibrationCenter.group_name.trim() : '',
      opening_date: formatDate(calibrationCenter.opening_date, DATE_FORMATS.ISO_DATE_TIME_STRING),
      is_active: isActive
    }

    const updateCCResponse = createCalibrationCenter({
      data
    })
    updateCCResponse.then((response) => {
      if (response?.status === 200) {
        setIsEdit(false)
        setCenterDetail(response.data)
      } else {
        setIsEdit(false)
      }
    })
  }, [centerDetail, calibrationCenter, isActive, setCenterDetail])

  const CalibrationCenterInfo = (props) => {
    const ccData = props?.data
    return (
      <AdasBox sx={{ backgroundColor: '#ffffff', padding: '16px' }}>
        <AdasTypography component={'div'} sx={{ overflow: 'auto' }}>
          <AdasTypography sx={{ float: 'left', fontSize: '1.25rem', fontWeight: theme.typography.fontWeight500 }} variant='subHeader'>
            {ccData.name}
          </AdasTypography>
          <AdasTypography sx={{ float: 'right', fontSize: '0.875rem', fontWeight: theme.typography.fontWeight500 }}>
            {ccidFormatter(ccId)}
          </AdasTypography>
        </AdasTypography>
        <AdasBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <AdasBox sx={{ display: 'flex', flexDirection: 'column' }}>
            <AdasTypography sx={labelStyle} variant='subtitle2'>
              Address
            </AdasTypography>
            <AdasTypography>{ccData.shop_address}</AdasTypography>
          </AdasBox>
          <StatusInfo label={isActive ? 'Active' : 'Inactive'} color={isActive ? 'primary' : 'secondary'} />
        </AdasBox>
        <AdasBox sx={{ display: 'flex' }}>
          <table style={{ width: '100%' }}>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <tbody>
              <tr>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    City
                  </AdasTypography>{' '}
                </th>
                <th style={tableCellStyle}>
                  {' '}
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    State
                  </AdasTypography>
                </th>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    Zip
                  </AdasTypography>{' '}
                </th>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    Country
                  </AdasTypography>{' '}
                </th>
              </tr>
              <tr>
                <td style={tableCellStyle}>{ccData.shop_city}</td>
                <td style={tableCellStyle}>{ccData.shop_state}</td>
                <td style={tableCellStyle}>{ccData.shop_zip}</td>
                <td style={tableCellStyle}>
                  <AdasTypography>{ccData.shop_country}</AdasTypography>
                </td>
              </tr>
            </tbody>
          </table>
        </AdasBox>
        <AdasTypography sx={labelStyle} variant='subtitle2'>
          Phone
        </AdasTypography>
        <AdasTypography>{phoneNumberFormat(ccData.phone)}</AdasTypography>
        <AdasTypography sx={labelStyle} variant='subtitle2'>
          Email
        </AdasTypography>
        <AdasTypography>
          <a href={`mailto:${ccData.email}`}>{ccData.email}</a>
        </AdasTypography>
        <AdasTypography sx={labelStyle} variant='subtitle2'>
          Website
        </AdasTypography>
        <AdasTypography>
          <a href={ccData.website}>{ccData.website}</a>
        </AdasTypography>
        <AdasDivider orientation='horizontal' flexItem sx={{ marginBottom: '10px', marginTop: '25px' }} />
        <AdasBox sx={{ display: 'flex' }}>
          <table style={{ width: '100%' }}>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <tbody>
              <tr>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    Open Date
                  </AdasTypography>
                </th>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    Group #
                  </AdasTypography>
                </th>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    Group Name
                  </AdasTypography>
                </th>
              </tr>
              <tr>
                <td style={ccData.opening_date ? tableCellStyle : tableLeftCellStyle}>
                  <AdasTypography>{formatDate(ccData.opening_date, DATE_FORMATS.ISO_DATE_STRING) || '-'}</AdasTypography>
                </td>
                <td style={ccData.group_number ? tableCellStyle : tableLeftCellStyle}>
                  <AdasTypography>{ccData.group_number || '-'}</AdasTypography>
                </td>
                <td style={ccData.group_name ? tableCellStyle : tableLeftCellStyle}>
                  <AdasTypography variant='body1'>{ccData.group_name || '-'}</AdasTypography>
                </td>
              </tr>
              <tr>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    Support Lead
                  </AdasTypography>
                </th>
                <th style={tableCellStyle}>
                  <AdasTypography sx={labelStyle} variant='subtitle2'>
                    PBI Account
                  </AdasTypography>
                </th>
              </tr>
              <tr>
                <td style={ccData.support_lead ? tableCellStyle : tableLeftCellStyle}>
                  <AdasTypography>{ccData.support_lead || '-'}</AdasTypography>
                </td>
                <td style={ccData.pbi_account_id ? tableCellStyle : tableLeftCellStyle}>
                  <AdasTypography>{ccData.pbi_account_id || '-'}</AdasTypography>
                </td>
              </tr>
            </tbody>
          </table>
        </AdasBox>
        <AdasBox sx={{ textAlign: 'end' }}>
          <AdasButton
            startIcon={<ModeEditOutlineOutlinedIcon />}
            key='2'
            aria-label='Cancel'
            color='primary'
            onClick={() => setIsEdit(true)}
          >
            Edit
          </AdasButton>
        </AdasBox>
      </AdasBox>
    )
  }

  return (
    <AdasCard
      sx={{
        margin: '17px 0px',
        padding: '16px 16px'
      }}
    >
      <CalibrationCenterLogo isEdit={isEdit} calibrationCenterData={centerDetail} onSuccess={fetchCalibrationCenter} />
      {isEdit ? (
        <AdasBox sx={{ backgroundColor: '#ffffff', padding: '16px' }}>
          <AdasTextField
            onChange={handleInputChange}
            name='name'
            sx={{ marginBottom: '20px', marginTop: '10px' }}
            id='name'
            label='Name'
            inputProps={{
              maxLength: 70
            }}
            variant='outlined'
            disabled={!user.is_admin}
            value={calibrationCenter.name}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
          />
          <AdasTextField
            onChange={handleInputChange}
            name='shop_address'
            sx={{ marginBottom: '20px' }}
            id='address'
            label='Address'
            variant='outlined'
            value={calibrationCenter.shop_address}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              maxLength: 55
            }}
          />
          <AdasBox sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
            <AdasTextField
              onChange={handleInputChange}
              name='shop_city'
              sx={{ marginRight: '16px' }}
              id='city'
              label='City'
              variant='outlined'
              value={calibrationCenter.shop_city}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
            />
            <AdasSearchMenu
              fullWidth
              id='select-state'
              select
              label='State'
              variant='outlined'
              onChange={handleInputChange}
              name='shop_state'
              value={calibrationCenter.shop_state}
              options={usStates?.states?.map((state) => ({
                value: state.abbreviation,
                name: state.name
              }))}
            />
          </AdasBox>
          <AdasTextField
            onChange={handleInputChange}
            name='shop_zip'
            sx={{ marginBottom: '20px' }}
            id='zip'
            label='Zip'
            variant='outlined'
            value={calibrationCenter.shop_zip}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              maxLength: 12
            }}
          />
          <AdasTextField
            onChange={handleInputChange}
            name='phone'
            sx={{ marginBottom: '20px' }}
            id='phone'
            label='Phone'
            variant='outlined'
            value={calibrationCenter.phone ? phoneNumberFormat(calibrationCenter.phone) : ''}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
          />
          <AdasTextField
            onChange={handleInputChange}
            name='email'
            sx={{ marginBottom: '20px' }}
            id='email'
            label='Email'
            variant='outlined'
            value={calibrationCenter.email}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              maxLength: 128
            }}
          />
          <AdasTextField
            onChange={handleInputChange}
            name='website'
            sx={{ marginBottom: '20px' }}
            id='name'
            label='Website (Optional)'
            variant='outlined'
            value={calibrationCenter.website}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              maxLength: 128
            }}
          />
          <AdasBox sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
            <AdasDatePicker
              label='Open Date (Optional)'
              variant='outlined'
              name='opening_date'
              disabled={!user.is_admin}
              sx={{ marginRight: '16px', flex: 1 }}
              value={formatDate(calibrationCenter.opening_date, DATE_FORMATS.ISO_DATE_STRING) ?? ''}
              format={DATE_FORMATS.ISO_DATE_STRING}
              onChange={(newDate) => {
                const now = dayjs(newDate, DATE_FORMATS.ISO_DATE_STRING, true)
                if (now.isValid()) {
                  let formattedDate = new Date(now.year(), now.month(), now.date())
                  return handleInputChange({ target: { name: 'opening_date', value: formattedDate } })
                }
                return handleInputChange({ target: { name: 'opening_date', value: null } })
              }}
            />
            <AdasTextField
              onChange={handleInputChange}
              disabled={!user.is_admin}
              name='pbi_account_id'
              id='pbi_account_id'
              label='PBI Account (Optional)'
              variant='outlined'
              value={calibrationCenter.pbi_account_id ?? ''}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              sx={{ flex: 1 }}
            />
          </AdasBox>
          <AdasBox sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
            <AdasTextField
              onChange={handleInputChange}
              disabled={!user.is_admin}
              name='support_lead'
              id='support_lead'
              label='Support Lead (Optional)'
              variant='outlined'
              value={calibrationCenter.support_lead}
              sx={{ flex: 1, marginRight: '16px' }}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
            />
          </AdasBox>
          <AdasBox sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
            <AdasTextField
              onChange={handleInputChange}
              disabled={!user.is_admin}
              name='group_number'
              id='group_number'
              label='Group # (Optional)'
              variant='outlined'
              value={calibrationCenter.group_number ?? ''}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 3,
                inputMode: 'numeric',
                pattern: '\\d*'
              }}
              sx={{ flex: 1 }}
            />
            <AdasTextField
              onChange={handleInputChange}
              disabled={!user.is_admin}
              name='group_name'
              id='group_name'
              label='Group Name (Optional)'
              variant='outlined'
              value={calibrationCenter.group_name ?? ''}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 30
              }}
              sx={{ flex: 1 }}
            />
          </AdasBox>
          {user.is_admin && (
            <AdasBox>
              <AdasButtonGroup variant='contained'>
                <AdasButton onClick={() => setIsActive(true)} variant={isActive ? 'contained' : 'outlined'}>
                  Active
                </AdasButton>
                <AdasButton onClick={() => setIsActive(false)} variant={!isActive ? 'contained' : 'outlined'}>
                  Inactive
                </AdasButton>
              </AdasButtonGroup>
            </AdasBox>
          )}
          <AdasBox sx={{ margin: '20px 0px', overflow: 'auto' }}>
            <AdasBox sx={{ float: 'right' }}>
              <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={handleCancel} sx={{ marginRight: '10px' }}>
                Cancel
              </AdasButton>
              <AdasButton
                variant='contained'
                type='submit'
                disabled={isBtnDisabled}
                aria-label='save'
                color='primary'
                onClick={handleSubmit}
              >
                Save
              </AdasButton>
            </AdasBox>
          </AdasBox>
        </AdasBox>
      ) : (
        <CalibrationCenterInfo data={calibrationCenter} />
      )}
    </AdasCard>
  )
}
