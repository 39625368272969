import { Card } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasCard = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>
}

AdasCard.propTypes = {
  children: PropTypes.node
}
