import AWS from 'aws-sdk'
import { AWS_ACCESS_KEY_ID, AWS_REGION, AWS_SECRET_ACCESS_KEY, LOG_GROUP, LOG_STREAM } from '../config'

// Uncomment the line below to enable logging with the AWS SDK
// AWS.config.logger = console

AWS.config.update({
  region: AWS_REGION,
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY
})

const isProduction = process.env.NODE_ENV === 'production'
const cloudwatchLogs = new AWS.CloudWatchLogs()

const sendToCloudWatch = ({ logLevel, message, payload }) => {
  // Define the log group name and log stream name
  const logGroupName = LOG_GROUP
  const logStreamName = LOG_STREAM

  // Create a log event with a timestamp and include payload in the message
  const logEvent = {
    timestamp: new Date().getTime(),
    message: `[${logLevel}] ${message} \n Payload: ${JSON.stringify(payload)}`
  }

  // Create a log event batch
  const logEvents = [logEvent]

  // Create a request object to put log events
  const params = {
    logGroupName,
    logStreamName,
    logEvents
  }

  // Send the log event batch to CloudWatch Logs
  cloudwatchLogs.putLogEvents(params, (err) => {
    if (err) {
      console.error('Error sending log to CloudWatch:', err)
    }
  })
}

export class Logger {
  static getFormattedTimestamp() {
    return new Date().toISOString()
  }

  static info({ message, payload, shouldSendToCloudWatch = isProduction }) {
    console.log(`%c[${this.getFormattedTimestamp()}] [INFO]:`, 'color: blue', message, payload)
    if (shouldSendToCloudWatch) {
      sendToCloudWatch({ logLevel: 'info', message, payload })
    }
  }

  static warn({ message, payload, shouldSendToCloudWatch = isProduction }) {
    console.warn(`%c[${this.getFormattedTimestamp()}] [WARN]:`, 'color: yellow', message, payload)
    if (shouldSendToCloudWatch) {
      sendToCloudWatch({ logLevel: 'warn', message, payload })
    }
  }

  static error({ message, payload, shouldSendToCloudWatch = isProduction }) {
    console.error(`%c[${this.getFormattedTimestamp()}] [ERROR]:`, 'color: red', message, payload)
    if (shouldSendToCloudWatch) {
      sendToCloudWatch({ logLevel: 'error', message, payload })
    }
  }
}
