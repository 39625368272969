import PropTypes from 'prop-types'

import { AdasInputAdornment, AdasTextField } from '@components/wrapper-components'

const CURRENCY_SYMBOL = '$'

export const CurrencyInput = (props) => {
  const onChangeHandler = (e) => {
    const value = e.target.value

    const newValue = value.replace(/[^0-9.]/g, '')
    const parts = newValue.split('.')
    if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 2)) {
      e.target.value = newValue
      props.onChange(e)
    }
  }

  const onBlurHandler = (e) => {
    if (e.target.value) {
      e.target.value = parseFloat(e.target.value).toFixed(2)
      props.onChange(e)
    }
  }

  return (
    <>
      <AdasTextField
        {...props}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        InputProps={{
          ...props.InputProps,
          startAdornment: <AdasInputAdornment position='start'>{CURRENCY_SYMBOL}</AdasInputAdornment>
        }}
      ></AdasTextField>
    </>
  )
}

CurrencyInput.propTypes = {
  onChange: PropTypes.func.isRequired
}
