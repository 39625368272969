import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'

export const AdasCheckbox = ({ label, checked, onChange, name, value, ...rest }) => {
  return <Checkbox label={label} name={name} checked={checked} onChange={onChange} value={value} {...rest} />
}

AdasCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string
}
