import { Link } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasLink = ({ children, href, ...props }) => (
  <Link href={href} {...props}>
    {children}
  </Link>
)

AdasLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string
}
