import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { StatusInfo } from '@components/status-info'
import {
  AdasBox,
  AdasButton,
  AdasCheckbox,
  AdasDrawer,
  AdasFormControlLabel,
  AdasFormGroup,
  AdasTypography
} from '@components/wrapper-components'
import { currencyFormatter } from '../../utils/utils'
import classes from './Drawers.module.css'
import theme from 'theme/theme'

export const Drawers = ({ isOpen, onClose, title, data }) => {
  const [itemList, setItemList] = useState([])
  const [displayedProducts, setDisplayedProducts] = useState([])

  useEffect(() => {
    if (data) {
      setItemList(data)
      setDisplayedProducts(
        Object.keys(data)
          .filter((key) => data[key].is_active || (!data[key].is_active && data[key].checked))
          .sort((key1, key2) => data[key1].name.localeCompare(data[key2].name))
      )
    }
  }, [data, setItemList, setDisplayedProducts])

  const handleChange = (event) => {
    const selectedItem = itemList[event.target.defaultValue]
    setItemList((prevValues) => {
      const updateValues = {
        ...prevValues,
        [event.target.defaultValue]: {
          ...selectedItem,
          checked: event.target.checked
        }
      }
      return updateValues
    })
  }
  const closeDrawers = () => {
    const list = Object.keys(data)
      .map((item) => {
        return itemList[item]
      })
      .filter((item) => item.checked)
    onClose(list)
  }
  const LabelComponent = (props) => {
    return (
      <AdasBox sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <AdasTypography sx={{ paddingRight: '5px' }}>{props.name}</AdasTypography>
        <StatusInfo label={currencyFormatter(props.price)} color='primary' />
      </AdasBox>
    )
  }

  return (
    itemList && (
      <AdasDrawer anchor={'right'} open={isOpen} onClose={closeDrawers}>
        <AdasBox className='drawer-title-container'>
          <AdasTypography variant='h6' sx={{ textTransform: 'capitalize' }}>
            {title}
          </AdasTypography>
          <AdasButton
            buttonType='iconButton'
            sx={{
              padding: '2px',
              cursor: 'pointer',
              color: theme.palette.info.main
            }}
            onClick={closeDrawers}
          >
            <CloseIcon fontSize='small' />
          </AdasButton>
        </AdasBox>
        <AdasFormGroup className={classes.drawer} sx={{ mr: '22px' }}>
          {displayedProducts.map((productName, index) => {
            return (
              <AdasBox key={index + productName} sx={{ my: '15px', ml: '16px', width: '100%' }}>
                <AdasFormControlLabel
                  labelPlacement='end'
                  sx={{ display: 'flex' }}
                  disableTypography={true}
                  className={classes.description}
                  key={productName}
                  control={
                    <AdasCheckbox
                      label={productName}
                      checked={itemList[productName]?.checked ? true : false}
                      onChange={handleChange}
                      value={productName}
                      sx={{ padding: '0', mr: '10px' }}
                    />
                  }
                  label={<LabelComponent name={data[productName].name} price={itemList[productName].price} />}
                ></AdasFormControlLabel>
              </AdasBox>
            )
          })}
        </AdasFormGroup>
      </AdasDrawer>
    )
  )
}

Drawers.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  data: PropTypes.object
}
