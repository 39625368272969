import PropTypes from 'prop-types'
import {
  AdasContainer,
  AdasPaper,
  AdasTypography,
  AdasBox,
  AdasLink,
  AdasRadioGroup,
  AdasSearchMenu,
  AdasTextField,
  AdasButton,
  AdasFormLabel,
  AdasImage
} from '@components/wrapper-components'
import { Autocomplete, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { InfoPopover } from '@components/popover'
import { useHistory } from 'react-router-dom'
import { GENERIC_MESSAGES, LABEL_DISPLAY, getInvoiceSummaryUrl, getQuoteSummaryUrl, getWorkOrderSummaryUrl } from '../../../constants'
import { DiagnosticTreeViewer } from '@components/diagnostic-tree-viewer'
import { getReferenceNumberBySearch, getSupportTicketsConstants } from '../../../api/api'
import { Logger } from '../../../logger'
import { useCenterStore } from '@caradasstore/CenterStore'
import { SupportRecordTypeEnums, SupportRequestEnums } from 'enums/enums'
import { useLocation } from 'react-router-dom'

export const SupportRequestPage = () => {
  const history = useHistory()
  const theme = useTheme()
  const location = useLocation()
  const ccId = useCenterStore((store) => store.ccId)

  const { supportType: type, requestType: reqType, selectedRecord: record, referenceNumber, enableButton } = location.state || {}
  const [isOpenDiagnosticTree, setIsOpenDiagnosticTree] = useState(false)
  const [supportType, setSupportType] = useState(type || '')
  const [requestType, setRequestType] = useState(reqType || '')
  const [selectedRecord, setSelectedRecord] = useState(record || '')
  const [recordList, setRecordList] = useState([])
  const [isDisableNextButton, setIsDisableNextButton] = useState(!enableButton)
  const [supportRequestConstants, setSupportRequestConstants] = useState({})
  const [searchRecord, setSearchRecord] = useState('')
  const [recordInput, setRecordInput] = useState(record?.reference_number || '')
  const [userOverride, setUserOverride] = useState(false)

  // Automatically select Calibration Diagnostic and QuoteWorkorderInvoice radio if referenceNumber exists for the first time
  useEffect(() => {
    if (referenceNumber && !userOverride) {
      if (supportType != SupportRequestEnums.CAR_ADASPLUS_IT) {
        setSupportType(SupportRequestEnums.CALIBRATION_DIAGNOSTIC)
        setRequestType(SupportRecordTypeEnums.QUOTE_WORKORDER_INVOICE)
      }
      setRecordInput(referenceNumber)
      setIsDisableNextButton(false) // Enable the Next button by default when referenceNumber exists
      if (recordList.length > 0) {
        setSelectedRecord(recordList[0]) // Auto-select the first record
      } else {
        setSelectedRecord('')
      }
    }
  }, [referenceNumber, userOverride, supportType, recordList])

  const handleNextButtonClick = () => {
    if (supportType === SupportRequestEnums.CAR_ADASPLUS_IT) {
      history.push('/support/create-support-request', { selectedRecord, requestType, supportType, isViewTicketRequest: false })
    }
    setIsOpenDiagnosticTree(true)
  }

  const handleCloseModal = () => {
    setIsOpenDiagnosticTree(false)
  }

  const handleSupportType = useCallback((e) => {
    const type = e.target.value
    setIsDisableNextButton(true)
    setSelectedRecord('')
    if (!referenceNumber) {
      setRecordList([])
    }
    setSupportType(type)
    if (type === SupportRequestEnums.CALIBRATION_DIAGNOSTIC) {
      setRequestType(SupportRecordTypeEnums.QUOTE_WORKORDER_INVOICE)
      if (referenceNumber) {
        setUserOverride(false)
      }
    } else {
      setRequestType('')
      setUserOverride(true)
    }
  }, [])

  const handleTicketNumberInputChange = useCallback((e, value) => {
    if (!value) {
      setRecordList([])
    }
    setRecordInput(value?.reference_number)
    setSelectedRecord(value)
    setIsDisableNextButton(!value)
    setUserOverride(true)
  }, [])

  const handleBlur = useCallback(() => {
    if (!selectedRecord) {
      setRecordInput('')
      setRecordList([])
    }
    setUserOverride(true)
  }, [selectedRecord])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const constants = await getSupportTicketsConstants()
        setSupportRequestConstants(constants.data)
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'api', method: 'getSupportTicketsConstants' } })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchRecord) {
          const listOfIds = await getReferenceNumberBySearch({
            ccId,
            search: searchRecord
          })

          return setRecordList(listOfIds.data)
        }
        setRecordList([])
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [ccId, searchRecord])

  const searchRecordInputHandler = useCallback((e) => {
    setRecordInput(e.target.value)
    setUserOverride(true)
  }, [])

  useEffect(() => {
    const performRecordSearchTrigger = () => {
      setSearchRecord(recordInput)
    }

    const timeoutId = setTimeout(performRecordSearchTrigger, 300)
    return () => clearTimeout(timeoutId)
  }, [recordInput])

  const handleRecordClick = useCallback(() => {
    if (selectedRecord.table_name === 'invoice') {
      history.push(getInvoiceSummaryUrl(selectedRecord.qwi_id))
    } else if (selectedRecord.table_name === 'workorder') {
      history.push(getWorkOrderSummaryUrl(selectedRecord.qwi_id))
    } else {
      history.push(getQuoteSummaryUrl(selectedRecord.qwi_id))
    }
  }, [searchRecord, selectedRecord])

  const classes = {
    subtitle: {
      fontWeight: 700,
      lineHeight: '21.98px',
      color: 'rgba(26, 27, 31, 0.87)',
      marginTop: '10px',
      marginBottom: '10px'
    },
    assistanceStyles: {
      display: 'flex',
      alignItems: 'center'
    },
    helpSectionStyle: {
      textDecoration: 'none',
      color: '#0077cc',
      fontWeight: 600,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingLeft: '50px',
      cursor: 'pointer'
    },
    paperContainer: {
      display: 'flex',
      flexDirection: 'column',

      justifyContent: 'space-between'
    },
    formLabel: {
      display: 'flex',
      fontSize: '14px',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#1A1B1FDB',
      fontWeight: 700
    }
  }

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '20px' }}>
        <AdasBox sx={{ minHeight: '74vh' }}>
          <AdasTypography sx={classes.subtitle}>{GENERIC_MESSAGES.CREATE_SUPPORT_REQUEST}</AdasTypography>
          <AdasBox sx={{ backgroundColor: '#FFFACA', minHeight: '70px', padding: '1%' }}>
            <AdasBox sx={classes.assistanceStyles}>
              <InfoPopover iconColor={'#001B3F'} ariaLabel='Information on default' enableClick={false} />
              <AdasTypography>{GENERIC_MESSAGES.SUPPORT_REQUESTING_ASSITANCE}</AdasTypography>
            </AdasBox>
            <AdasLink sx={classes.helpSectionStyle} rel='noopener noreferrer' onClick={() => history.push('/help')}>
              {LABEL_DISPLAY.HELP_SECTION_LABEL}
            </AdasLink>
          </AdasBox>
          <AdasBox
            variant='outlined'
            sx={{
              minHeight: '100px',
              marginTop: '20px',
              paddingTop: '10px'
            }}
          >
            <AdasBox sx={{ margin: '.6rem 1rem', textAlign: 'right' }}>
              <AdasFormLabel id='repair-order-label' align='right' sx={classes.formLabel}>
                {LABEL_DISPLAY.SUPPORT_TYPE_LABEL}
              </AdasFormLabel>
              {supportRequestConstants?.supportTypes && (
                <AdasRadioGroup
                  options={(supportRequestConstants?.supportTypes || []).map((item) => ({
                    value: item.slug,
                    label: item.type
                  }))}
                  row
                  name='supportType'
                  value={supportType}
                  onChange={handleSupportType}
                />
              )}
            </AdasBox>
          </AdasBox>
          {supportType !== undefined && (
            <AdasBox
              variant='outlined'
              sx={{
                minHeight: '100px',
                marginTop: '20px',
                paddingTop: '10px'
              }}
            >
              <AdasBox sx={{ margin: '.6rem 1rem', textAlign: 'left' }}>
                <AdasFormLabel id='repair-order-label' align='right' sx={classes.formLabel}>
                  {LABEL_DISPLAY.REQUEST_TYPE_LABEL}
                </AdasFormLabel>
                {supportType === SupportRequestEnums.CALIBRATION_DIAGNOSTIC && (
                  <AdasRadioGroup
                    options={(supportRequestConstants?.supportRecordTypes || [])
                      .filter((item) => item.slug !== SupportRecordTypeEnums.INFORMATION_REQUEST) //CAR-1627 hidden Information Request radio button
                      .map((item) => ({
                        value: item.slug,
                        label: item.recordType
                      }))}
                    row
                    name='requestType'
                    value={requestType}
                    onChange={(e) => {
                      setIsDisableNextButton(e.target.value === SupportRequestEnums.QUOTE_WORKORDER_INVOICE)
                      setRequestType(e.target.value)
                      if (referenceNumber && e.target.value === SupportRequestEnums.QUOTE_WORKORDER_INVOICE) {
                        setSelectedRecord(referenceNumber)
                        setUserOverride(false)
                      } else {
                        setSelectedRecord('')
                        setUserOverride(true)
                      }
                    }}
                  />
                )}
                {supportType === SupportRequestEnums.CAR_ADASPLUS_IT && (
                  <AdasSearchMenu
                    fullWidth
                    id='it-RequestType'
                    select
                    label='Type'
                    sx={{ width: '40%', marginTop: '20px' }}
                    variant='outlined'
                    onChange={(e) => {
                      setIsDisableNextButton(e.target.value === SupportRequestEnums.QUOTE_WORKORDER_INVOICE)
                      setRequestType(e.target.value)
                      if (referenceNumber && e.target.value === SupportRequestEnums.QUOTE_WORKORDER_INVOICE) {
                        setSelectedRecord(referenceNumber)
                        setUserOverride(false)
                      } else {
                        setSelectedRecord('')
                        setUserOverride(true)
                      }
                    }}
                    name='requestType'
                    value={requestType}
                    options={(supportRequestConstants?.supportItRequestTypes || []).map((item) => ({
                      value: item.slug,
                      name: item.itRequestType
                    }))}
                  />
                )}
              </AdasBox>
              {requestType === SupportRequestEnums.QUOTE_WORKORDER_INVOICE && (
                <AdasBox sx={{ margin: '.6rem 1rem', marginTop: '20px' }}>
                  <Autocomplete
                    id='record-search'
                    options={recordList}
                    getOptionLabel={(option) => option.reference_number}
                    value={selectedRecord || null}
                    isOptionEqualToValue={(option, value) => option.reference_number === value.reference_number}
                    onBlur={handleBlur}
                    onInput={searchRecordInputHandler}
                    onChange={handleTicketNumberInputChange}
                    sx={{ width: '40%' }}
                    renderInput={(params) => <AdasTextField {...params} label='Record #' variant='outlined' />}
                  />
                </AdasBox>
              )}
              {selectedRecord && (
                <AdasBox sx={{ padding: '16px', mb: '20px', justifyContent: 'space-between' }}>
                  <AdasBox sx={{ display: 'flex' }}>
                    <AdasBox sx={{ width: '30%', marginTop: '20px' }}>
                      <AdasTypography variant='subtitle2' sx={{ marginBottom: '20px' }}>
                        <AdasLink underline='none' sx={{ cursor: 'pointer' }} onClick={handleRecordClick}>
                          RECORD #{selectedRecord.reference_number}
                        </AdasLink>
                      </AdasTypography>
                      <AdasTypography sx={{ fontSize: '14px' }}>
                        {selectedRecord.year} {selectedRecord.make} {selectedRecord.model}
                      </AdasTypography>
                      <AdasTypography sx={{ fontSize: '13px', marginTop: '5px' }}>VIN: {selectedRecord.vin}</AdasTypography>
                    </AdasBox>
                    <AdasBox sx={{ width: '70%' }}>
                      <AdasImage src={selectedRecord.image_url} alt='vehicle' sx={{ maxHeight: '250px', maxWidth: '200px' }} />
                    </AdasBox>
                  </AdasBox>
                </AdasBox>
              )}
            </AdasBox>
          )}
        </AdasBox>
        <AdasBox
          sx={{
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'space-between'
          }}
        >
          <AdasButton sx={{ marginLeft: 'auto', marginRight: '1rem' }} onClick={() => history.replace('/support')}>
            CANCEL
          </AdasButton>
          <AdasButton variant='contained' disabled={isDisableNextButton} onClick={handleNextButtonClick}>
            NEXT
          </AdasButton>
        </AdasBox>
      </AdasPaper>
      <DiagnosticTreeViewer
        handleNextButtonClick={() => {
          handleCloseModal()
          history.push('/support/create-support-request', { selectedRecord, requestType, supportType, isViewTicketRequest: false })
        }}
        closeModal={handleCloseModal}
        isOpen={isOpenDiagnosticTree}
      />
    </AdasContainer>
  )
}

SupportRequestPage.propTypes = {
  title: PropTypes.string
}
