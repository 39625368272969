import { ListItemIcon } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasListItemIcon = ({ sx = {}, children, ...props }) => {
  return (
    <ListItemIcon sx={sx} {...props}>
      {children}
    </ListItemIcon>
  )
}

AdasListItemIcon.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node.isRequired
}
