import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useCenterStore } from '@caradasstore/CenterStore'
import { CarImageHolder } from '@components/car-image-holder'
import { ClientForm } from '@components/client'
import { QuoteAttachment } from '@components/quote'
import { VehicleInfoDetails } from '@components/vehicle-info-details'
import { VinInput } from '@components/vin'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasContainer,
  AdasDrawer,
  AdasFormControl,
  AdasFormLabel,
  AdasInputLabel,
  AdasPaper,
  AdasRadioGroup,
  AdasSelect,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import {
  getClients,
  requestCreateQuote,
  useSubmitFileAttachment,
  getInsuranceCompanies,
  getAttachmentAppointmentEstimate,
  getReferralSourceList
} from '../../../api/api'
import { VehicleInfoManuallPage } from '../../vehicle-info-page/VehicleInfoManuallPage'
import { getQuoteSummaryUrl } from '../../../constants'
import { sortByName } from '../../../utils/utils'
import { ATTACHMENT_TYPE_MAP, MIME_TYPES_MAP } from '../../../variable-maps'
import { Logger } from '../../../logger'
import classes from './VehicleInfo.module.css'
import theme from 'theme/theme'

export const NewQuote = (props) => {
  const [isAddClient, setIsAddClient] = useState(false)
  const [vehicleData, setVehicleData] = useState({})
  const [displayDetails, setDisplayDetails] = useState(false)
  const [customerPay, setCustomerPay] = useState(false)
  const [isVinAvailable, setIsVinAvailable] = useState(true)
  const [insuranceCompanies, setInsuranceCompanies] = useState([])
  const [clients, setClients] = useState([])
  const [documents, setDocuments] = useState({
    preCalRepairEstimateScan: '',
    calRequiredReport: ''
  })
  const location = useLocation()
  const referralSourceCode = location.state?.referral
  const prePopulateVin = location.state?.prepopulateVin || location.state?.vin
  const [referralSourceList, setReferralSourceList] = useState([])
  const [showReferralField, setShowReferralField] = useState(!!referralSourceCode)
  const [formInputValues, setFormInputValues] = useState({
    client: '',
    roNumber: '',
    serviceType: 'STANDARD',
    vin: '',
    vehicleYear: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleTrim: '',
    insurance_company_id: '',
    appointment_id: '',
    referral_source_code: referralSourceCode || null
  })
  const { uploadData } = useSubmitFileAttachment()
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const ccId = useCenterStore((store) => store.ccId)
  const isEnable = useMemo(() => {
    const vinValid =
      isVinAvailable === true
        ? !!formInputValues.vin && !!formInputValues.client
        : !!formInputValues.client &&
          !!formInputValues.vehicleYear &&
          !!formInputValues.vehicleMake &&
          !!formInputValues.vehicleModel &&
          !!formInputValues.vehicleTrim

    const roNumberValid = customerPay || formInputValues.roNumber
    return vinValid && roNumberValid
  }, [
    isVinAvailable,
    formInputValues.vin,
    formInputValues.client,
    formInputValues.vehicleYear,
    formInputValues.vehicleMake,
    formInputValues.vehicleModel,
    formInputValues.vehicleTrim,
    formInputValues.roNumber,
    customerPay
  ])

  const uploadFile = useCallback(
    async (file, type, id) => {
      const formData = new FormData()
      formData.append('file', file, file.name)
      let attachmentType = ATTACHMENT_TYPE_MAP[type]
      const params = {
        data: formData,
        workorderId: id,
        type: 'quotes',
        attachmentType
      }

      const response = uploadData(params)
      response.then(() => {})
    },
    [uploadData]
  )

  const title = props.title

  const history = useHistory()
  const redirectQuoteSummary = useCallback(
    (data) => {
      history.push(getQuoteSummaryUrl(data?.quote?.reference_number))
    },
    [history]
  )

  const documentChangeHandler = useCallback((props) => {
    setDocuments((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [props.name]: props.data
      }
      return updatedValues
    })
  }, [])

  const changeHandler = useCallback((e) => {
    const fieldValue = e.target.name === 'year' ? e.target.value.replace(/\D/g, '') : e.target.value
    setFormInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [e.target.name]: fieldValue
      }
      return updatedValues
    })
  }, [])

  const handleShowReferralField = useCallback(
    (e) => {
      setShowReferralField(e.target.value === 'true')
      setFormInputValues((prev) => ({ ...prev, referral_source_code: null }))
    },
    [setShowReferralField, setFormInputValues]
  )

  const handleCustomerPay = useCallback((e) => {
    const value = /true/.test(e.target.value)
    const roNumber = value ? 'Customer-Pay' : ''
    setCustomerPay(value)
    setFormInputValues((prevValues) => {
      return { ...prevValues, roNumber: roNumber }
    })
  }, [])

  const getVehicleInfo = useCallback(() => {
    if (isVinAvailable === true) {
      const { color, imageUrl, fuel, safetySystems, ...vehicle } = vehicleData
      return {
        vin: formInputValues.vin,
        calibration_center_id: ccId,
        exterior_color: color,
        image_url: imageUrl,
        fuel_capacity_gal: fuel,
        safetySystemsItems: safetySystems,
        ...vehicle
      }
    } else {
      const { vehicleYear, vehicleMake, vehicleModel, vehicleTrim } = formInputValues
      return {
        year: vehicleYear,
        make: vehicleMake,
        model: vehicleModel,
        trim: vehicleTrim,
        vin: ''
      }
    }
  }, [ccId, formInputValues, isVinAvailable, vehicleData])

  const submitHandler = useCallback(async () => {
    const quoteRequestData = {
      client_id: formInputValues.client,
      calibration_center_id: ccId,
      repair_order_num: formInputValues.roNumber,
      type: formInputValues.serviceType,
      insurance_company_id: formInputValues.insurance_company_id,
      appointment_id: formInputValues.appointment_id,
      vehicle: getVehicleInfo(),
      referral_source_code: formInputValues.referral_source_code
    }

    try {
      const response = await requestCreateQuote({ data: quoteRequestData })
      if (response.status === 201) {
        const id = response.data.quote.id
        Object.keys(documents).map((doc) => {
          if (documents[doc]) {
            uploadFile(documents[doc], doc, id)
          }
        })

        redirectQuoteSummary(response.data)
      }
    } catch (err) {
      Logger.error({ message: err, payload: { file: 'NewQuote', method: 'requestAuth', quoteRequestData } })
    }
  }, [
    ccId,
    documents,
    formInputValues.client,
    formInputValues.roNumber,
    formInputValues.serviceType,
    getVehicleInfo,
    redirectQuoteSummary,
    uploadFile
  ])

  const autocompleteHandler = useCallback((event) => {
    setFormInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [event.target.name]: event.target.value || ''
      }
      return updatedValues
    })
  }, [])

  const fetchClient = useCallback(async () => {
    const clientListResponse = await getClients({ ccid: centerDetail.id })
    if (clientListResponse.status === 200) {
      setClients(clientListResponse.data.sort(sortByName))
    }
  }, [centerDetail.id])

  const updateVehicleInfo = useCallback((props) => {
    setDisplayDetails(true)
    setVehicleData({ ...props.data, safetySystemsItems: props.data?.safetySystems })
    setFormInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        vin: props.vin
      }
      return updatedValues
    })
  }, [])

  useEffect(() => {
    if (location.state?.appointment_id) {
      setFormInputValues((prevState) => ({
        ...prevState,
        roNumber: location.state?.aro || '',
        appointment_id: location.state?.appointment_id || ''
      }))
    }
  }, [location.state?.appointment_id, setFormInputValues])

  useEffect(() => {
    const fetchFileData = async (value) => {
      try {
        const response = await getAttachmentAppointmentEstimate(ccId, value.appointment_id)
        const fileFromResponse = new File([response.data], value.name, {
          type: MIME_TYPES_MAP[`.${value.file_extension}`] // Ensure the correct MIME type is used
        })

        setDocuments((prevValues) => ({
          ...prevValues,
          preCalRepairEstimateScan: fileFromResponse
        }))
      } catch (error) {
        Logger.error({ message: error.message, payload: { value } })
      }
    }

    if (location.state?.recentEstimate?.id) {
      fetchFileData(location.state?.recentEstimate)
    }
  }, [location.state?.recentEstimate?.id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const insuranceResponse = await getInsuranceCompanies()
        if (insuranceResponse?.data) {
          setInsuranceCompanies(insuranceResponse.data)
        }
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'NewQuote', method: 'useEffect/getInsuranceCompanies' } })
      }
    }

    fetchData()
  }, [])

  const handleVinAvailableToggle = useCallback(
    (e) => {
      setIsVinAvailable(e.target.value === 'true')
      setDisplayDetails('')
      setVehicleData('')
      setFormInputValues((prevValues) => ({
        ...prevValues,
        vin: ''
      }))
    },
    [setFormInputValues]
  )

  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  useEffect(() => {
    const fetchReferralSource = async () => {
      const referralListResponse = await getReferralSourceList(centerDetail.id)
      if (referralListResponse.status === 200) {
        const formattedReferralList = referralListResponse.data
          .sort(sortByName)
          .map((referralObj) => ({ id: referralObj.code, value: referralObj.code }))
        setReferralSourceList(formattedReferralList)
      }
    }

    fetchReferralSource()
  }, [])

  const handleVehicleManuallChange = (eventOrValue) => {
    if (eventOrValue && eventOrValue.target) {
      const { name, value } = eventOrValue.target
      setFormInputValues((prev) => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const clearVehicleFileds = () => {
    setFormInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleTrim: ''
      }
      return updatedValues
    })
  }

  useEffect(() => {
    clearVehicleFileds()
  }, [isVinAvailable])

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '20px' }}>
        {isAddClient && (
          <AdasDrawer anchor={'right'} open={isAddClient} onClose={() => setIsAddClient(false)}>
            <AdasBox className='drawer-title-container'>
              <AdasTypography variant='h6'>Client Info</AdasTypography>
              <AdasButton
                buttonType='iconButton'
                sx={{
                  padding: '2px',
                  cursor: 'pointer',
                  color: theme.palette.info.main
                }}
                onClick={() => setIsAddClient(false)}
              >
                <CloseIcon fontSize='small' />
              </AdasButton>
            </AdasBox>
            <ClientForm onSaveSuccess={fetchClient} clibrationCenterId={Number(ccId)} close={() => setIsAddClient(false)} />
          </AdasDrawer>
        )}
        <AdasBox>
          <h1>{title}</h1>
          <AdasFormControl fullWidth>
            <AdasButtonGroup
              sx={{ boxShadow: 'none' }}
              className={classes.formField}
              variant='contained'
              aria-label='outlined primary button group'
            >
              <AdasInputLabel id='clients-label'>Select Client</AdasInputLabel>
              <AdasSelect
                label='Select Client'
                labelId='clients'
                id='client-select'
                name='client'
                value={formInputValues.client}
                onChange={autocompleteHandler}
                options={clients || []}
                sx={{
                  maxHeight: '100px',
                  overflowY: 'auto',
                  width: '90%',
                  textAlign: 'left',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  '.MuiOutlinedInput-root': {
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px'
                  }
                }}
              />
              <AdasButton
                onClick={() => setIsAddClient(true)}
                sx={{
                  width: '10%',
                  height: '56px',
                  borderRadius: '0',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px'
                }}
                variant='contained'
              >
                <AddIcon />
              </AdasButton>
            </AdasButtonGroup>
            <AdasBox sx={{ margin: '.5rem 1rem', textAlign: 'right' }}>
              <AdasFormLabel
                id='repair-order-label'
                align='right'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                Repair Order
              </AdasFormLabel>
              <AdasRadioGroup
                options={[
                  { label: 'RO Provided', value: false },
                  { label: 'Customer Pay', value: true }
                ]}
                row
                name='clientPay'
                value={customerPay}
                onChange={handleCustomerPay}
              />
            </AdasBox>
            <AdasTextField
              id='outlined-basic'
              label='RO #'
              variant='outlined'
              className={classes.formField}
              name='roNumber'
              onChange={changeHandler}
              value={formInputValues.roNumber}
              disabled={customerPay}
              inputProps={{
                maxLength: 12
              }}
            />
          </AdasFormControl>
          <AdasFormControl fullWidth sx={{ marginTop: '20px' }}>
            <AdasInputLabel id='insurance_label'>Insurance Company</AdasInputLabel>
            <AdasSelect
              labelId='insurance_label'
              id='insurance_company_id'
              name='insurance_company_id'
              label='Insurance Company'
              value={formInputValues.insurance_company_id}
              onChange={autocompleteHandler}
              options={insuranceCompanies || []}
              sx={{
                width: '100%',
                textAlign: 'left'
              }}
            />
          </AdasFormControl>
          <AdasBox sx={{ margin: '1rem 1rem', textAlign: 'left' }}>
            <AdasFormLabel id='referral-source-label' align='left'>
              Do you have a referral source?
            </AdasFormLabel>
            <AdasRadioGroup
              row
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]}
              disabled={!!referralSourceCode}
              name='referral-radio-group'
              value={showReferralField}
              onChange={handleShowReferralField}
            />
          </AdasBox>
          {showReferralField && (
            <AdasFormControl fullWidth>
              <AdasInputLabel id='referral_label'>Referral Source</AdasInputLabel>
              <AdasSelect
                disabled={!!referralSourceCode}
                labelId='referral_label'
                id='referral_label'
                name='referral_source_code'
                label='Referral Source'
                value={formInputValues.referral_source_code}
                onChange={autocompleteHandler}
                options={referralSourceList}
                sx={{
                  width: '100%',
                  textAlign: 'left'
                }}
              />
            </AdasFormControl>
          )}
          <AdasBox sx={{ margin: '1.25rem 1rem', textAlign: 'left' }}>
            <AdasFormLabel id='work-type-label' align='left'>
              Type
            </AdasFormLabel>
            <AdasRadioGroup
              row
              options={[
                { value: 'STANDARD', label: 'Standard' },
                { value: 'SERVICE_ONLY', label: 'Service Only' }
              ]}
              name='serviceType'
              value={formInputValues.serviceType}
              onChange={changeHandler}
            />
          </AdasBox>
          <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
            <AdasFormControl fullWidth sx={{ padding: '20px 0px' }}>
              <AdasFormLabel id='controlled-radio-buttons-group' sx={{ textAlign: 'left', alignContent: 'left' }}>
                Do you have a VIN ?
              </AdasFormLabel>
              <AdasRadioGroup
                row
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
                aria-labelledby='controlled-radio-buttons-group'
                name='isVinAvailable'
                value={isVinAvailable}
                onChange={handleVinAvailableToggle}
              />
            </AdasFormControl>
            {isVinAvailable ? (
              <AdasBox key='vin-section'>
                <VinInput updateVehicleInfo={updateVehicleInfo} isQuote={true} prepopulateVin={prePopulateVin} vinExistCheck={true} />
                {displayDetails ? <VehicleInfoDetails isQuote={true} data={vehicleData}></VehicleInfoDetails> : <CarImageHolder />}
              </AdasBox>
            ) : (
              <>
                <VehicleInfoManuallPage
                  formInputValues={formInputValues}
                  setFormInputValues={setFormInputValues}
                  handleVehicleManuallChange={handleVehicleManuallChange}
                  manualOverRideVin={!isVinAvailable}
                  isFromQuote={true}
                />
              </>
            )}
            <AdasBox>
              <AdasPaper
                elevation={0}
                variant='elevation'
                sx={{
                  marginBottom: '20px',
                  textAlign: 'left'
                }}
              >
                <p>Documents from Client</p>
                <QuoteAttachment
                  existingData={documents.preCalRepairEstimateScan}
                  name='preCalRepairEstimateScan'
                  onChange={documentChangeHandler}
                  title={'UPLOAD COLLISION REPAIR ESTIMATE (OPTIONAL)'}
                  label={'Collision Repair Estimate'}
                />
                <QuoteAttachment
                  name='calRequiredReport'
                  onChange={documentChangeHandler}
                  title={'UPLOAD CALIBRATIONS REQUIRED REPORT (OPTIONAL)'}
                  label={'Calibration Required Report'}
                />
              </AdasPaper>
            </AdasBox>
          </AdasPaper>
        </AdasBox>
        <AdasBox className={classes.submit}>
          <AdasButton
            key='1'
            sx={{ marginRight: '20px' }}
            variant='outlined'
            aria-label='Cancel'
            color='primary'
            onClick={() => history.goBack()}
          >
            CANCEL
          </AdasButton>
          <AdasButton
            disabled={!isEnable}
            key='2'
            variant='contained'
            aria-label='Create Work Order'
            color='primary'
            isDebounceEnabled={true}
            onClick={submitHandler}
          >
            CREATE QUOTE
          </AdasButton>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}

NewQuote.propTypes = {
  title: PropTypes.string
}
