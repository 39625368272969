import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles'
import { baseThemeOptions } from './base-theme-options'

export const createTheme = (config = { responsiveFontSizes: false }) => {
  let theme = createMuiTheme(baseThemeOptions)

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
