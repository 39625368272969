import createStore from 'zustand'
import persist from '../utils/persist'

export const useUserStore = createStore(
  persist(
    {
      key: 'currentUser',
      userEmail: ''
    },
    (set) => ({
      currentUser: null,
      setCurrentUser: (newCurrentUser) => {
        set(() => ({
          currentUser: newCurrentUser
        }))
      },
      setUserEmail: (newUserEmail) => {
        set(() => ({
          userEmail: newUserEmail
        }))
      }
    })
  )
)
