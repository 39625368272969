export const baseThemeOptions = {
  palette: {
    primary: { main: '#0071E3' },
    secondary: { main: '#8A8A8A' },
    background: {
      default: '#ffffff',
      highlight: '#96F7B8',
      shaded: '#1A1B1F80',
      lightGray: '#E1E9F0'
    },
    info: {
      main: '#001B3F',
      light: '#D7E2FF'
    },
    error: {
      main: '#BA1A1A',
      light: '#FFDAD6'
    },
    success: {
      main: '#6FBF73'
    },
    fullScreenHeight: '100vh'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
          fontFamily: 'Roboto, Arial, sans-serif'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 14,
    defaultColor: '#000000DE',
    secondaryColor: '#888d9a',
    territoryColor: '#212121',
    shaded: '#1a1b1fde',
    success: '#09AF00',
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeight500: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,

    body1: {
      fontSize: '1rem !important',
      fontWeight: 400,
      lineHeight: 1.5,
      [`@media (max-width:600px)`]: {
        fontSize: '0.875rem !important' // Adjust for mobile
      }
    },
    body2: {
      fontSize: '0.875rem !important',
      fontWeight: 400,
      lineHeight: 1.57,
      [`@media (max-width:600px)`]: {
        fontSize: '0.75rem !important' // Adjust for mobile
      }
    },
    subtitle1: {
      fontSize: '1rem !important',
      fontWeight: 500,
      lineHeight: 1.75,
      [`@media (max-width:600px)`]: {
        fontSize: '0.875rem !important' // Adjust for mobile
      }
    },
    subtitle2: {
      fontSize: '0.875rem !important',
      fontWeight: 500,
      lineHeight: 1.57,
      [`@media (max-width:600px)`]: {
        fontSize: '0.75rem !important' // Adjust for mobile
      }
    },
    subtitle3: {
      fontSize: '0.9375rem !important',
      fontWeight: 600,
      lineHeight: 1.73,
      [`@media (max-width:600px)`]: {
        fontSize: '0.8125rem !important' // Adjust for mobile
      }
    },
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: 1.375,
      [`@media (max-width:${1200}px)`]: {
        fontSize: '3rem' // Adjust for large screens
      },
      [`@media (max-width:${1000}px)`]: {
        fontSize: '2.5rem' // Adjust for medium screens
      },
      [`@media (max-width:${600}px)`]: {
        fontSize: '2rem' // Adjust for small screens (mobile)
      },
      [`@media (max-width:${0}px)`]: {
        fontSize: '1.75rem' // Adjust for extra small screens
      }
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.375,
      [`@media (max-width:${1000}px)`]: {
        fontSize: '2.5rem'
      },
      [`@media (max-width:${600}px)`]: {
        fontSize: '2rem'
      },
      [`@media (max-width:${0}px)`]: {
        fontSize: '1.5rem'
      }
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.375,
      [`@media (max-width:${1000}px)`]: {
        fontSize: '2rem'
      },
      [`@media (max-width:${600}px)`]: {
        fontSize: '1.75rem'
      },
      [`@media (max-width:${0}px)`]: {
        fontSize: '1.5rem'
      }
    },
    h4: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.375,
      [`@media (max-width:${1000}px)`]: {
        fontSize: '1.75rem'
      },
      [`@media (max-width:${600}px)`]: {
        fontSize: '1.5rem'
      },
      [`@media (max-width:${0}px)`]: {
        fontSize: '1.25rem'
      }
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.375,
      [`@media (max-width:${1000}px)`]: {
        fontSize: '1.25rem'
      },
      [`@media (max-width:${600}px)`]: {
        fontSize: '1.125rem'
      }
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: 1.375,
      [`@media (max-width:${1000}px)`]: {
        fontSize: '1.1rem'
      },
      [`@media (max-width:${600}px)`]: {
        fontSize: '1rem'
      }
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66,
      [`@media (max-width:${600}px)`]: {
        fontSize: '0.65rem'
      }
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    }
  },
  padding: {
    smallPadding: '1rem',
    mediumPadding: '2rem',
    largePadding: '3rem',
    extraSmallPadding: '0.5rem',
    extraLargePadding: '4rem',
    doublePadding: '2rem 2rem'
  }
}
