import { ListItemText } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasListItemText = ({ sx = {}, ...props }) => {
  return <ListItemText sx={sx} {...props} />
}

AdasListItemText.propTypes = {
  sx: PropTypes.object
}
