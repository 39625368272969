import { AdasBackdrop, AdasCircularProgress } from '@components/wrapper-components'
import { useLoadingStore } from '../../store'

export const LoadingBackdrop = () => {
  const loading = useLoadingStore((store) => store.loading)
  return (
    <AdasBackdrop sx={{ color: '#fff', zIndex: () => 9999 }} open={loading}>
      <AdasCircularProgress color='inherit' />
    </AdasBackdrop>
  )
}
