import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AdasBox,
  AdasGrid,
  AdasButton,
  AdasCard,
  AdasContainer,
  AdasImage,
  AdasSearchMenu,
  AdasTextField,
  AdasTypography,
  AdasLink
} from '@components/wrapper-components'
import { SuccessModal, ConfirmModal } from '@components/common'
import {
  BUTTONS_DISPLAY_LABEL,
  GENERIC_MESSAGES,
  getInvoiceSummaryUrl,
  getQuoteSummaryUrl,
  getWorkOrderSummaryUrl
} from '../../../constants'
import { useLocation, useHistory } from 'react-router-dom'
import {
  createSupportTicket,
  getSupportTicketsConstants,
  getSupportTicket,
  getReferenceNumberBySearch,
  updateSupportTicketResolution,
  updateTicketStatus,
  uploadSupportPhoto,
  getSupportPhoto,
  updateSupportTicketDetails
} from 'api/api'
import { Logger } from '../../../logger'
import { SupportStatusInfo } from '@components/status-info'
import { useMessageStore, useUserStore } from '../../../store'
import { AssignedUser } from '@components/assigned-user'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { ProblemAreaEnum, RaisedTypesEnum, StatusEnum, SupportRequestEnums, SupportRecordTypeEnums } from 'enums/enums'
import { PhotoInputWithoutThumbnail } from '@components/photo/photo-input-without-thumbnail'

export const CreateSupportRequestPage = () => {
  const location = useLocation()
  const { selectedRecord = {}, requestType = '', supportType = '' } = location?.state || {}
  const { ticketRequestId } = useParams()
  const isViewTicketRequest = ticketRequestId > 0
  const history = useHistory()
  const user = useUserStore((store) => store.currentUser)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showCancleConfirmModal, setShowCancleConfirmModal] = useState(false)
  const [vehicleDetails, setVehicleDetails] = useState(selectedRecord)
  const [supportImage, setSupportImage] = useState([])
  const [supportRequest, setSupportRequest] = useState({
    dtc: '',
    dtc_description: '',
    calibration_type: '',
    type: supportType,
    device: '',
    browser: '',
    problem_description: '',
    problem_area:
      supportType === SupportRequestEnums.CALIBRATION_DIAGNOSTIC && requestType === SupportRecordTypeEnums.INFORMATION_REQUEST
        ? ProblemAreaEnum.OTHER
        : ProblemAreaEnum.DIAGNOSTIC_TROUBLE_CODE,
    contact_user_id: user,
    it_request_type: '',
    resolution: '',
    notes: ''
  })
  const [createdTicketId, setCreatedTicketId] = useState(null)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [errors, setErrors] = useState({
    notes: false
  })

  const refs = { notes: useRef(null) }
  const ccId = useCenterStore((store) => store.ccId)
  const userList = useCenterStore((store) => store.userList)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const addMessage = useMessageStore((store) => store.addMessage)
  const [supportRequestConstants, setSupportRequestConstants] = useState({})
  const isClosed = useMemo(() => {
    return StatusEnum[supportRequest?.status?.toLocaleUpperCase()] === StatusEnum.CLOSED
  }, [supportRequest.status])

  const isAdminEditEnabled = useMemo(() => {
    // Ticket CAR-2351 enabling add edit permission to admin
    return user.is_admin
      ? StatusEnum[supportRequest?.status?.toLocaleUpperCase()] === StatusEnum.OPEN || !isViewTicketRequest
      : !isViewTicketRequest
  }, [supportRequest.status, user.is_admin, isViewTicketRequest, ticketRequestId])

  const isNew = useMemo(() => {
    return StatusEnum[supportRequest?.status?.toLocaleUpperCase()] === StatusEnum.NEW
  }, [supportRequest.status])

  const filteredUserList = useMemo(() => {
    if (!user.is_admin) {
      return userList.filter((userItem) => !userItem.is_admin)
    }
    return userList
  }, [userList])

  const MAX_LENGTH = 2000
  const MAX_LENGTH_DTC_DESC = 500
  const MAX_LENGTH_DTC_CODE = 40
  const MAX_LENGTH_PROBLEM_DESC = 500

  const handleInputChange = (e) => {
    const value = getValueBasedOnField(e.target.value, e.target.name)
    if (e.target.name === 'notes') {
      setErrors((prev) => ({ ...prev, notes: false }))
    }

    setSupportRequest((prev) => {
      const supportRequest = {
        ...prev,
        [e.target.name]: value
      }
      return supportRequest
    })
  }

  const getValueBasedOnField = (value, fieldName) => {
    let result
    let userSelected = ''
    switch (fieldName) {
      case 'contact_user_id':
        userSelected = filteredUserList.filter((user) => user.id === value)[0]
        result = userSelected
        break
      default:
        result = value
    }
    return result
  }

  const onCalibrationTypeChanged = (e) => {
    setSupportRequest((prev) => {
      const supportRequest = {
        ...prev,
        [e.target.name]: e.target.value
      }
      return supportRequest
    })
  }

  const handleCancel = useCallback(() => {
    if (user.is_admin) {
      return history.goBack()
    }

    setShowCancleConfirmModal(true)
  }, [])

  const handleDone = () => {
    history.replace('/support')
  }

  const handleGoBack = () => {
    history.replace('/support/support-request', { supportType, requestType, selectedRecord, enableButton: true })
  }

  const handleTicketStatusChange = async (value) => {
    try {
      setLoading(true)
      if (value === 'Closed') {
        const response = await getSupportTicket({ id: ticketRequestId })
        if (response?.status === 200) {
          if (!response?.data?.supportTicket?.notes) {
            setLoading(false)
            refs.notes.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            refs.notes.current.focus()
            setErrors((prev) => ({ ...prev, notes: supportRequest.notes ? false : true }))

            return addMessage({
              text: supportRequest.notes
                ? 'Please save the notes before closing the ticket.'
                : 'A Closing Note is mandatory. Please add and update the ticket before closing the ticket.'
            })
          }
        }
      }

      const response = await updateTicketStatus({
        id: ticketRequestId,
        status: value
      })

      setLoading(false)
      if (response.status === 200) {
        setSupportRequest((prev) => ({
          ...prev,
          status: value
        }))
      }
    } catch (error) {
      setLoading(false)
      Logger.error({
        message: error,
        payload: { file: 'CreateSupportRequestPage', method: 'handleTicketStatusChange' }
      })
    }
  }

  const handleAsisignedUserChange = (user) => {
    setSupportRequest((prev) => {
      const supportRequest = {
        ...prev,
        assigned_to: user
      }
      return supportRequest
    })
  }

  const handleUpdateModal = useCallback(() => {
    setOpenUpdateModal((prevState) => !prevState)
  }, [setOpenUpdateModal])

  const handleUpdate = useCallback(
    async (updateType) => {
      if (openUpdateModal) {
        handleUpdateModal()
      }

      let supportDetails = {
        problem_description: supportRequest.problem_description
      }

      if (supportRequest.type === SupportRequestEnums.CALIBRATION_DIAGNOSTIC) {
        supportDetails = {
          ...supportDetails,
          problem_area: supportRequest.problem_area,
          dtc: supportRequest.dtc,
          dtc_description: supportRequest.dtc_description,
          calibration_type: supportRequest.calibration_type
        }
      } else if (supportRequest.type === SupportRequestEnums.CAR_ADASPLUS_IT) {
        supportDetails = {
          ...supportDetails,
          device: supportRequest.device,
          browser: supportRequest.browser
        }
      }
      try {
        setLoading(true)
        const detailUpdateResponse = await updateSupportTicketDetails({ ticketId: supportRequest.id, data: supportDetails })
        if (detailUpdateResponse?.status === 200) {
          const response = await updateSupportTicketResolution({
            id: supportRequest.id,
            data: { resolution: supportRequest.resolution, notes: supportRequest.notes },
            updateType
          })
          if (response?.status === 200) {
            if (updateType === 'saveAndEmail') history.goBack()
          }
        }
      } catch (error) {
        addMessage({ text: 'Something went wrong! please try again.' })
        Logger.error({ message: error, payload: { file: 'createSupportRequestPage', method: 'Update support request', supportDetails } })
      } finally {
        setLoading(false)
      }
    },
    [supportRequest, updateSupportTicketResolution, history, handleUpdateModal, openUpdateModal]
  )

  const handleSave = useCallback(() => handleUpdate('saveOnly'), [handleUpdate])

  const handleContinueUpdateModal = useCallback(() => handleUpdate('saveAndEmail'), [handleUpdate])

  const handleSubmit = useCallback(async () => {
    let reqBody = ''
    setLoading(true)
    try {
      reqBody = {
        type: supportType,
        calibration_center_id: ccId,
        problem_description: supportRequest.problem_description,
        reference_number: selectedRecord.reference_number,
        qwi_id: selectedRecord.qwi_id,
        raised_stage: RaisedTypesEnum[selectedRecord.table_name],
        contact_user_id: supportRequest.contact_user_id.id
      }

      if (supportType === SupportRequestEnums.CALIBRATION_DIAGNOSTIC) {
        reqBody = {
          ...reqBody,
          record_type: requestType,
          problem_area: supportRequest.problem_area,
          dtc: supportRequest.dtc,
          dtc_description: supportRequest.dtc_description,
          calibration_type: supportRequest.calibration_type
        }
      } else if (supportType === SupportRequestEnums.CAR_ADASPLUS_IT) {
        reqBody = {
          ...reqBody,
          it_request_type: requestType,
          device: supportRequest.device,
          browser: supportRequest.browser
        }
      }

      const response = await createSupportTicket(reqBody)
      if (response?.status === 200) {
        await Promise.all(
          supportImage.map(async (image) => {
            try {
              if (image) {
                await uploadSupportPhoto({
                  file: image.file,
                  attachmentNo: image.attachment_number,
                  ticketId: response.data.supportTicket.id
                })
              }
            } catch (uploadError) {
              Logger.error({
                message: uploadError,
                payload: { file: 'createSupportRequestPage', method: 'handleSubmit/uploadSupportPhoto', reqBody }
              })
            }
          })
        )
        setCreatedTicketId(response?.data?.supportTicket?.ticket_number)
        setShowSuccessModal(true)
      }
    } catch (error) {
      addMessage({ text: 'Something went wrong! please try again.' })
      Logger.error({ message: error, payload: { file: 'createSupportRequestPage', method: 'create support request', reqBody } })
    } finally {
      setLoading(false)
    }
  }, [supportRequest, createSupportTicket, supportImage])

  const cancelConfirmationContinue = useCallback(() => {
    setShowCancleConfirmModal(false)
    history.replace('/support')
  }, [])

  const filteredSupportProblemAreas = useMemo(() => {
    if (supportType === SupportRequestEnums.CALIBRATION_DIAGNOSTIC && requestType === SupportRecordTypeEnums.INFORMATION_REQUEST) {
      return supportRequestConstants?.supportProblemAreas?.filter((problem) => problem.slug === ProblemAreaEnum.OTHER)
    } else return supportRequestConstants?.supportProblemAreas
  }, [requestType, supportType, supportRequestConstants?.supportProblemAreas])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const constants = await getSupportTicketsConstants()
        setSupportRequestConstants(constants.data)
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'createSupportRequestPage', method: 'useEffect/getSupportTicketsConstants' } })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (supportRequest.qwi_id) {
          const referenceResponse = await getReferenceNumberBySearch({
            ccId,
            search: supportRequest.reference_number
          })
          if (referenceResponse.data && referenceResponse.data?.length > 0) {
            const vehicle = referenceResponse.data.find((item) => item.reference_number === supportRequest.reference_number)
            setVehicleDetails(vehicle)
          }
        }
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'createSupportRequestPage', method: 'useEffect/getReferenceNumberBySearch' } })
      }
    }
    if (isViewTicketRequest && supportRequest.qwi_id) {
      fetchData()
    }
  }, [ccId, supportRequest.qwi_id])

  const isSubmitDisabled = useMemo(() => {
    const calibrationDiagnosticChecks =
      supportRequest.type === SupportRequestEnums.CALIBRATION_DIAGNOSTIC &&
      ((supportRequest.problem_area === ProblemAreaEnum.OTHER &&
        (!supportRequest.problem_description || !supportRequest.contact_user_id.id)) ||
        (supportRequest.problem_area === ProblemAreaEnum.DIAGNOSTIC_TROUBLE_CODE &&
          (!supportRequest.dtc ||
            !supportRequest.calibration_type ||
            !supportRequest.problem_description ||
            !supportRequest.contact_user_id.id)) ||
        (supportRequest.problem_area === ProblemAreaEnum.FAILED_CALIBRATION &&
          (!supportRequest.calibration_type || !supportRequest.problem_description || !supportRequest.contact_user_id.id)))

    const carAdasPlusItChecks =
      supportRequest.type === SupportRequestEnums.CAR_ADASPLUS_IT &&
      (!supportRequest.problem_description || !supportRequest.contact_user_id.id)

    return calibrationDiagnosticChecks || carAdasPlusItChecks
  }, [supportRequest])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await getSupportTicket({ id: ticketRequestId })
        setLoading(false)
        if (response?.status === 200) {
          setSupportRequest(response?.data?.supportTicket)
          setSupportImage(response?.data?.supportTicketPhoto)
        }
      } catch (error) {
        setLoading(false)
        Logger.error({
          message: error,
          payload: { file: 'createSupportRequestPage', method: 'useEffect/getSupportTicket', ticketRequestId }
        })
      }
    }
    if (ticketRequestId) {
      fetchData()
    }
  }, [ticketRequestId, supportRequest.status, setLoading])

  const fetchSupportImageById = useCallback(
    async (photoId) => {
      return await getSupportPhoto({ ticketId: ticketRequestId, photoId })
    },
    [ticketRequestId]
  )

  const updateSupportImage = useCallback((formData, imageLocation, imageUrl) => {
    setSupportImage((prev) => {
      const imageArr = [...prev]
      imageArr[imageLocation] = formData ? { file: formData, attachment_number: imageLocation, imageUrl } : null
      return imageArr
    })
  }, [])

  const handleRecordClick = useCallback(() => {
    const { table_name: tableName, reference_number: referenceNumber } = vehicleDetails
    if (tableName === 'invoice') {
      history.push(getInvoiceSummaryUrl(referenceNumber))
    } else if (tableName === 'workorder') {
      history.push(getWorkOrderSummaryUrl(referenceNumber))
    } else {
      history.push(getQuoteSummaryUrl(referenceNumber))
    }
  }, [vehicleDetails, history, getInvoiceSummaryUrl, getWorkOrderSummaryUrl, getQuoteSummaryUrl])

  return (
    <AdasContainer maxWidth='lg' sx={{ paddingBottom: '10px' }}>
      <AdasCard
        sx={{
          margin: '17px 0px',
          padding: '16px 16px'
        }}
      >
        <AdasBox sx={{ padding: '16px', marginBottom: '20px' }}>
          {isViewTicketRequest && (
            <AdasBox sx={{ display: 'flex', justifyContent: 'space-between', mb: '10px', alignItems: 'center' }}>
              <AdasBox sx={{ display: 'flex', color: '#1A1B1FDE' }}>
                <AdasTypography sx={{ fontSize: '14px', lineHeight: '20px', mr: '.5rem' }}>
                  Support #{supportRequest.ticket_number}
                </AdasTypography>
                <AdasTypography sx={{ fontSize: '14px', lineHeight: '20px' }}>|</AdasTypography>
                <AdasTypography sx={{ fontSize: '14px', ml: '.5rem', lineHeight: '20px' }}>
                  {isClosed
                    ? `Closed: ${supportRequest.closed_at ? new Date(supportRequest.closed_at).toLocaleDateString() : ''}`
                    : `Created: ${new Date(supportRequest.created_at).toLocaleDateString()}`}
                </AdasTypography>
              </AdasBox>
              <AdasBox sx={{ float: 'right' }}>
                {!user.is_admin ? (
                  <SupportStatusInfo
                    disabled={!user.is_admin}
                    type='VIEW'
                    onChange={handleTicketStatusChange}
                    value={supportRequest.status}
                    sx={{ ml: '10px', cursor: 'pointer', minWidth: '65px' }}
                  />
                ) : (
                  <AdasBox style={{ display: 'flex', alignItems: 'center' }}>
                    <SupportStatusInfo
                      disabled={!supportRequest.assigned_to}
                      onChange={handleTicketStatusChange}
                      value={supportRequest.status}
                      sx={{ mx: '10px', cursor: 'pointer' }}
                    />
                    <AdasTypography sx={{ fontSize: '14px', lineHeight: '20px', display: 'inLine', mr: '5px' }}>
                      Assigned to:
                    </AdasTypography>
                    <AssignedUser
                      type={'support'}
                      closedStatus={isClosed}
                      isAdminUsersOnly={true}
                      initialAssignee={supportRequest.assigned_to}
                      ticketNumber={supportRequest?.id}
                      isTicket={true}
                      onChange={handleAsisignedUserChange}
                    />
                  </AdasBox>
                )}
              </AdasBox>
            </AdasBox>
          )}
          {!user.is_admin && supportRequest.resolution && (
            <>
              <AdasTypography variant='subtitle2' sx={{ marginBottom: '20px' }}>
                RESOLUTIONS
              </AdasTypography>
              <AdasBox sx={{ backgroundColor: '#CBF8C880', padding: '20px', marginBottom: '20px' }}>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: 0 }}>
                  <li key='resolution'>
                    <AdasTypography sx={{ fontSize: '14px', lineHeight: '23px', fontWeight: 400 }}>
                      {supportRequest.resolution}
                    </AdasTypography>
                  </li>
                </ul>
              </AdasBox>
            </>
          )}
          <AdasTypography variant='subtitle2' sx={{ marginBottom: '20px' }}>
            DETAILS
          </AdasTypography>
          <AdasGrid container spacing={2}>
            {supportRequest.type === SupportRequestEnums.CAR_ADASPLUS_IT && (
              <>
                <AdasGrid item xs={12} sm={6} md={6} lg={6}>
                  <AdasSearchMenu
                    fullWidth
                    id='device'
                    select
                    label='Device (Optional)'
                    variant='outlined'
                    onChange={handleInputChange}
                    sx={{ marginRight: '15px', marginBottom: '20px' }}
                    name='device'
                    disabled={!isAdminEditEnabled}
                    value={supportRequest.device}
                    options={(supportRequestConstants?.supportDevices || []).map((item) => ({
                      value: item.slug,
                      name: item.device
                    }))}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={6} lg={6}>
                  <AdasSearchMenu
                    fullWidth
                    id='browser'
                    select
                    label='Web Browser (Optional)'
                    variant='outlined'
                    onChange={handleInputChange}
                    sx={{ marginRight: '15px', marginBottom: '20px' }}
                    name='browser'
                    disabled={!isAdminEditEnabled}
                    value={supportRequest.browser}
                    options={(supportRequestConstants?.supportBrowsers || []).map((item) => ({
                      value: item.slug,
                      name: item.browser
                    }))}
                  />
                </AdasGrid>
              </>
            )}
            {supportRequest.type === SupportRequestEnums.CALIBRATION_DIAGNOSTIC && (
              <>
                <AdasGrid item xs={12} sm={6} md={6} lg={6}>
                  <AdasSearchMenu
                    fullWidth
                    id='problem-area'
                    select
                    label='Problem Area'
                    variant='outlined'
                    onChange={handleInputChange}
                    sx={{ marginRight: '15px', marginBottom: '20px', bgcolor: isViewTicketRequest ? null : 'white' }}
                    name='problem_area'
                    disabled={!isAdminEditEnabled}
                    value={supportRequest.problem_area}
                    options={(filteredSupportProblemAreas || []).map((item) => ({
                      value: item.slug,
                      name: item.problemArea
                    }))}
                  />
                </AdasGrid>
                {supportRequest.problem_area !== 'OTHER' && (
                  <>
                    <AdasGrid item xs={12} sm={6} md={6} lg={6}>
                      <AdasTextField
                        onChange={handleInputChange}
                        name='dtc'
                        id='dtc'
                        label={
                          supportRequest.problem_area === ProblemAreaEnum.FAILED_CALIBRATION
                            ? 'Diagnostic Trouble Code (Optional)'
                            : 'Diagnostic Trouble Code'
                        }
                        helperText={`${MAX_LENGTH_DTC_CODE} characters max, ${MAX_LENGTH_DTC_CODE - supportRequest.dtc?.length || 0} remaining`}
                        variant='outlined'
                        value={supportRequest.dtc}
                        fullWidth={true}
                        disabled={!isAdminEditEnabled}
                        sx={{ bgcolor: isViewTicketRequest ? null : 'white' }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          maxLength: 40
                        }}
                      />
                    </AdasGrid>
                    <AdasGrid item xs={12}>
                      <AdasTextField
                        onChange={handleInputChange}
                        name='dtc_description'
                        sx={{ marginBottom: '20px', marginTop: '10px', bgcolor: isViewTicketRequest ? null : 'white' }}
                        id='dtc_description'
                        label='Code Description (Optional)'
                        helperText={`${MAX_LENGTH_DTC_DESC} characters max, ${MAX_LENGTH_DTC_DESC - supportRequest.dtc_description?.length || 0} remaining`}
                        variant='outlined'
                        value={supportRequest.dtc_description}
                        multiline={true}
                        rows={3}
                        fullWidth={true}
                        disabled={!isAdminEditEnabled}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          maxLength: MAX_LENGTH_DTC_DESC
                        }}
                      />
                    </AdasGrid>
                    <AdasGrid item xs={12}>
                      <AdasSearchMenu
                        fullWidth
                        id='calibration_type'
                        select
                        label='Calibration Type'
                        variant='outlined'
                        onChange={onCalibrationTypeChanged}
                        name='calibration_type'
                        sx={{ marginBottom: '20px', bgcolor: isViewTicketRequest ? null : 'white' }}
                        disabled={!isAdminEditEnabled}
                        value={supportRequest.calibration_type}
                        options={(supportRequestConstants?.calibrationCategoryTypes || []).map((item) => ({
                          value: item.slug,
                          name: item.name
                        }))}
                      />
                    </AdasGrid>
                  </>
                )}
              </>
            )}
            <AdasGrid item xs={12}>
              <AdasTextField
                onChange={handleInputChange}
                name='problem_description'
                id='problem_description'
                label='Problem Description'
                variant='outlined'
                sx={{ marginBottom: '20px', bgcolor: isViewTicketRequest ? null : 'white' }}
                helperText={`${MAX_LENGTH_PROBLEM_DESC} characters max, ${MAX_LENGTH_PROBLEM_DESC - supportRequest.problem_description?.length || 0} remaining`}
                value={supportRequest.problem_description}
                multiline={true}
                rows={3}
                fullWidth={true}
                disabled={!isAdminEditEnabled}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: MAX_LENGTH_PROBLEM_DESC
                }}
              />
            </AdasGrid>
          </AdasGrid>
        </AdasBox>
        <AdasBox sx={{ padding: '16px', mb: '20px', justifyContent: 'space-between' }}>
          <AdasTypography variant='subtitle2' sx={{ marginBottom: '20px' }}>
            SUPPORT PHOTOS
          </AdasTypography>
          <AdasBox sx={{ display: 'flex' }}>
            <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
              <PhotoInputWithoutThumbnail
                title='Support Photo'
                parentId={ticketRequestId}
                fetchImage={fetchSupportImageById}
                imageDataObj={supportImage[0]}
                labelText='ADD PHOTO'
                imageLocation='0'
                onUpdate={updateSupportImage}
                disabled={isViewTicketRequest}
                allowDelete={true}
              />
            </AdasBox>
            {(supportImage[0]?.attachment_number || supportImage[1]?.attachment_number) && (
              <AdasBox sx={{ width: '50%' }}>
                <PhotoInputWithoutThumbnail
                  title='Additional Support Photo'
                  parentId={ticketRequestId}
                  fetchImage={fetchSupportImageById}
                  imageLocation='1'
                  labelText='ADD ADDITIONAL PHOTO'
                  imageDataObj={supportImage[1]}
                  onUpdate={updateSupportImage}
                  disabled={isViewTicketRequest}
                  allowDelete={true}
                />
              </AdasBox>
            )}
          </AdasBox>
        </AdasBox>

        {vehicleDetails && vehicleDetails.reference_number && (
          <AdasBox sx={{ padding: '16px', mb: '20px', justifyContent: 'space-between' }}>
            <AdasBox sx={{ display: 'flex' }}>
              <AdasBox sx={{ width: '30%', marginTop: '20px' }}>
                <AdasTypography variant='subtitle2' sx={{ marginBottom: '20px' }}>
                  <AdasLink underline='none' sx={{ cursor: 'pointer' }} onClick={handleRecordClick}>
                    RECORD #{vehicleDetails.reference_number}
                  </AdasLink>
                </AdasTypography>
                <AdasTypography sx={{ fontSize: '14px' }}>
                  {vehicleDetails.year} {vehicleDetails.make} {vehicleDetails.model}
                </AdasTypography>
                <AdasTypography sx={{ fontSize: '13px', marginTop: '5px' }}>VIN: {vehicleDetails.vin}</AdasTypography>
              </AdasBox>
              <AdasBox sx={{ width: '70%' }}>
                <AdasImage src={vehicleDetails.image_url} alt='vehicle' sx={{ maxHeight: '250px', maxWidth: '200px' }} />
              </AdasBox>
            </AdasBox>
          </AdasBox>
        )}

        <AdasBox sx={{ padding: '16px' }}>
          <AdasTypography variant='subtitle2' sx={{ marginBottom: '20px' }}>
            CONTACT INFORMATION
          </AdasTypography>
          <AdasGrid container spacing={2}>
            <AdasGrid item xs={12}>
              <AdasSearchMenu
                fullWidth
                id='name'
                select
                label='Name'
                variant='outlined'
                onChange={handleInputChange}
                sx={{ width: '49%', bgcolor: isViewTicketRequest ? null : 'white' }}
                name='contact_user_id'
                disabled={isViewTicketRequest}
                value={supportRequest?.contact_user_id?.id}
                options={filteredUserList?.map((item) => ({
                  value: item.id,
                  name: item.name
                }))}
              />
            </AdasGrid>
            <AdasGrid item xs={12} sm={6} md={6} lg={6}>
              <AdasTextField
                fullWidth
                id='email'
                label='Email'
                variant='outlined'
                sx={{ marginRight: '15px' }}
                name='email'
                value={supportRequest.contact_user_id?.email}
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 28
                }}
              />
            </AdasGrid>
            <AdasGrid item xs={12} sm={6} md={6} lg={6}>
              <AdasTextField
                name='phone'
                id='phone'
                label='Phone'
                variant='outlined'
                value={supportRequest.contact_user_id?.phone}
                fullWidth={true}
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 28
                }}
              />
            </AdasGrid>
            {user.is_admin && supportRequest?.id && (
              <>
                <AdasGrid item xs={12} sm={12} md={12} lg={12}>
                  <AdasTextField
                    fullWidth
                    id='ccname'
                    label='CC Name'
                    variant='outlined'
                    sx={{ marginRight: '15px' }}
                    name='ccName'
                    value={supportRequest?.calibrationCenter?.name}
                    disabled={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </AdasGrid>
                <AdasGrid item xs={12}>
                  <AdasTextField
                    onChange={handleInputChange}
                    name='resolution'
                    id='resolution'
                    label='Resolution (Visible to Technician)'
                    variant='outlined'
                    sx={{ my: '20px', bgcolor: isClosed || isNew ? null : 'white' }}
                    disabled={isClosed || isNew}
                    helperText={`${MAX_LENGTH} characters max, ${MAX_LENGTH - supportRequest.resolution?.length || 0} remaining`}
                    value={supportRequest.resolution}
                    multiline={true}
                    rows={3}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      maxLength: MAX_LENGTH
                    }}
                  />
                </AdasGrid>
                <AdasGrid item xs={12}>
                  {/* clarify notes */}
                  <AdasTextField
                    onChange={handleInputChange}
                    name='notes'
                    id='notes'
                    label='Closing Notes (Visible to Admins Only)'
                    variant='outlined'
                    sx={{ bgcolor: isClosed || isNew ? null : 'white' }}
                    helperText={`${MAX_LENGTH} characters max, ${MAX_LENGTH - supportRequest.notes?.length || 0} remaining`}
                    value={supportRequest.notes}
                    disabled={isClosed || isNew}
                    multiline={true}
                    innerRef={refs.notes}
                    error={errors.notes}
                    rows={3}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      maxLength: MAX_LENGTH
                    }}
                  />
                </AdasGrid>
              </>
            )}
          </AdasGrid>
        </AdasBox>

        <AdasBox sx={{ margin: '20px 0px', px: '16px', overflow: 'auto' }}>
          <AdasBox sx={{ float: 'right' }}>
            {isViewTicketRequest ? (
              user.is_admin ? (
                <>
                  <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={handleCancel} sx={{ marginRight: '10px' }}>
                    {isClosed ? 'Go Back' : 'Cancel'}
                  </AdasButton>
                  <AdasButton
                    variant='contained'
                    type='submit'
                    disabled={isNew || isClosed || isSubmitDisabled}
                    aria-label='save'
                    color='primary'
                    sx={{ marginRight: '10px' }}
                    onClick={handleSave}
                  >
                    Save
                  </AdasButton>
                  <AdasButton
                    variant='contained'
                    type='submit'
                    disabled={isNew || isClosed || isSubmitDisabled}
                    aria-label='Save & Send Email'
                    color='primary'
                    onClick={handleUpdateModal}
                  >
                    Save & Send Email
                  </AdasButton>
                </>
              ) : (
                <AdasButton variant='contained' aria-label='cancel' color='primary' onClick={handleDone} sx={{ marginRight: '10px' }}>
                  DONE
                </AdasButton>
              )
            ) : (
              <>
                <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={handleCancel} sx={{ marginRight: '10px' }}>
                  Cancel
                </AdasButton>
                <AdasButton
                  variant='outlined'
                  type='submit'
                  disabled={isViewTicketRequest}
                  aria-label='goback'
                  onClick={handleGoBack}
                  sx={{ marginRight: '10px' }}
                >
                  GO BACK
                </AdasButton>
                <AdasButton
                  variant='contained'
                  type='submit'
                  disabled={isViewTicketRequest || isSubmitDisabled}
                  aria-label='save'
                  color='primary'
                  onClick={handleSubmit}
                >
                  SUBMIT REQUEST
                </AdasButton>
              </>
            )}
          </AdasBox>
        </AdasBox>

        {showSuccessModal && (
          <SuccessModal
            open={showSuccessModal}
            requestNumber={createdTicketId}
            handleClose={() => {
              setShowSuccessModal(false)
              history.replace('/support')
            }}
          />
        )}
        {showCancleConfirmModal && (
          <ConfirmModal
            open={showCancleConfirmModal}
            handleClose={() => {
              setShowCancleConfirmModal(false)
            }}
            handleContinue={cancelConfirmationContinue}
            title={GENERIC_MESSAGES.CANCEL_REQUEST_TITLE}
            message={GENERIC_MESSAGES.CONFIRM_CANCEL_REQUEST}
            cancelLabel={BUTTONS_DISPLAY_LABEL.NO}
            confirmLabel={BUTTONS_DISPLAY_LABEL.YES}
          />
        )}
        {openUpdateModal && (
          <ConfirmModal
            sx={{ width: '50%' }}
            open={openUpdateModal}
            handleClose={handleUpdateModal}
            handleContinue={handleContinueUpdateModal}
            title={GENERIC_MESSAGES.CONFIRM_UPDATE}
            message={
              <AdasTypography
                variant='body1'
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  letterSpacing: '0.15000000596046448px',
                  textAlign: 'left',
                  margin: '10px 0',
                  maxWidth: '450px'
                }}
              >
                {GENERIC_MESSAGES.SUPPORT_UPDATE_INFO}
              </AdasTypography>
            }
            cancelLabel={BUTTONS_DISPLAY_LABEL.NO}
            confirmLabel={BUTTONS_DISPLAY_LABEL.YES}
          />
        )}
      </AdasCard>
    </AdasContainer>
  )
}
