import PropTypes from 'prop-types'

import { AdasAvatar } from '@components/wrapper-components'

const initials = (name) => {
  if (name) {
    return name.split(' ').reduce((accumulator, currentValue) => accumulator + currentValue[0].toUpperCase(), '')
  }

  return '?'
}

export const CaradasAvatar = ({ user, size }) => {
  return (
    <AdasAvatar
      sx={{
        bgcolor: 'primary',
        width: size === 'sm' ? 24 : 40,
        height: size === 'sm' ? 24 : 40,
        fontSize: size === 'sm' ? '.75rem' : '1rem'
      }}
    >
      {user?.name && initials(user?.name).slice(0, 2)}
    </AdasAvatar>
  )
}

CaradasAvatar.propTypes = { user: PropTypes.object, size: PropTypes.string }
