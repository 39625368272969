import createStore from 'zustand'

export const useRevertedInvoice = createStore((set) => ({
  revertedInvoice: undefined,
  setRevertedInvoice: (rInvoice) => {
    set(() => ({
      revertedInvoice: rInvoice
    }))
  },
  resetRevertedInvoice: () => {
    set(() => ({
      revertedInvoice: undefined
    }))
  }
}))
