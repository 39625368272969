import styled from 'styled-components'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const StyledReactQuill = styled(ReactQuill)`
  .ql-tooltip {
    right: 0 !important;
    left: auto !important;
    top: 32px !important;
    padding: 10px;
    z-index: 1000;
  }
`

export const AdasRichTextEditor = ({ value, onChange, disabled, ...props }) => {
  return <StyledReactQuill value={value} readOnly={disabled} onChange={onChange} {...props} />
}
