import { useEffect } from 'react'

export const scrollPageTop = () => {
  if (document && document.getElementById('CaradasAppbar')) {
    document.getElementById('CaradasAppbar').scrollIntoView()
  }
}
export const useScrollTop = () => {
  useEffect(() => {
    scrollPageTop()
  }, [])
}
