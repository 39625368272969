import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { ContactForm } from '@components/client'
import { AdasBox, AdasButton, AdasCard, AdasTypography } from '@components/wrapper-components'
import { phoneNumberFormat } from '../../../utils/utils'

export const ContactInfo = (props) => {
  const { contact, onSaveSuccess, index, deleteContactHandler } = props
  const [updatedContact, setUpdatedContact] = useState(contact)
  const [isContactEdit, setIsContactEdit] = useState(false)
  const onSaveContactSuccessHandler = (props) => {
    setUpdatedContact(props)
    setIsContactEdit(false)
    onSaveSuccess()
  }
  const labelStyle = {
    margin: '8px 0px',
    fontWeight: '600'
  }
  return !isContactEdit ? (
    <AdasCard key={index} className='adas-card'>
      <AdasTypography sx={{ fontWeight: '600' }} variant='h6'>
        {updatedContact.name}
      </AdasTypography>
      <AdasTypography sx={labelStyle} variant='subtitle2'>
        Job Title
      </AdasTypography>
      <AdasTypography>{updatedContact.jobTitle}</AdasTypography>
      <AdasTypography sx={labelStyle} variant='subtitle2'>
        Phone
      </AdasTypography>
      <AdasTypography>
        <a href={`tel:${updatedContact.phone}`}>{phoneNumberFormat(updatedContact.phone)}</a>
      </AdasTypography>
      <AdasTypography sx={labelStyle} variant='subtitle2'>
        Email
      </AdasTypography>
      <AdasTypography>
        <a href={`mailto:${updatedContact.email}`}>{updatedContact.email}</a>
      </AdasTypography>
      <AdasBox sx={{ textAlign: 'end' }}>
        <AdasButton
          startIcon={<ModeEditOutlineOutlinedIcon />}
          key='2'
          aria-label='Cancel'
          color='primary'
          onClick={() => setIsContactEdit(true)}
        >
          Edit
        </AdasButton>
      </AdasBox>
    </AdasCard>
  ) : (
    <ContactForm
      key={index}
      index={index}
      deleteContactHandler={deleteContactHandler}
      contactData={updatedContact}
      cancel={() => setIsContactEdit(false)}
      onSaveSuccess={onSaveContactSuccessHandler}
    />
  )
}

ContactInfo.propTypes = {
  contact: PropTypes.object.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  index: PropTypes.number,
  deleteContactHandler: PropTypes.func
}
