import { Alert } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const AdasAlert = forwardRef(({ sx = {}, severity, ...props }, ref) => {
  return <Alert ref={ref} sx={sx} severity={severity} {...props} />
})

AdasAlert.propTypes = {
  sx: PropTypes.object,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning'])
}
