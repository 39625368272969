import { useTheme, useMediaQuery } from '@mui/material'

// Custom hook to export isMobile and isLandscape
export const useDeviceQueries = () => {
  const theme = useTheme()

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px) or (max-height: ${theme.breakpoints.values.sm}px)`)
  const isLandscape = useMediaQuery('(orientation: landscape)')

  return { isMobile, isLandscape }
}
