import { AdasGrid, AdasButtonGroup, AdasButton, AdasTypography, AdasBox, AdasImage } from '@components/wrapper-components'
import { InfoPopover } from '@components/popover'

export const InvoiceSettings = ({ optInQR, handleQROptIn }) => {
  return (
    <AdasBox sx={{ marginTop: '20px' }}>
      <AdasTypography sx={{ fontWeight: '600', fontSize: '.875rem' }} variant='h6'>
        Invoice Settings
      </AdasTypography>
      <AdasGrid container alignItems='center'>
        <AdasGrid item xs={12} sm={9}>
          <AdasTypography component='span' sx={{ fontSize: '.875rem' }}>
            Display Invoice QR code & URL on the Invoice PDF
          </AdasTypography>
          <InfoPopover
            ariaLabel='Sample Invoice with QR code & url'
            enableClick={true}
            // variant='top-right'
            sx={{ width: '150px', padding: '0px' }}
            text={<AdasImage src='sample_invoice.png' sx={{ width: '100%' }} />}
          />
        </AdasGrid>
        <AdasGrid item xs={12} sm={3} container justifyContent='flex-end' alignItems='center'>
          <AdasButtonGroup
            type='toggleButton'
            value={optInQR ? 'Yes' : 'No'}
            exclusive
            onChange={handleQROptIn}
            aria-label='opt-in'
            name='optIn'
            sx={{ width: { xs: '100%', sm: 'auto' }, mt: { xs: '10px', sm: 0 } }}
          >
            <AdasButton
              buttonType='toggleButton'
              value='Yes'
              color='primary'
              aria-label='Yes'
              sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
            >
              Yes
            </AdasButton>
            <AdasButton
              buttonType='toggleButton'
              value='No'
              color='primary'
              aria-label='No'
              sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
            >
              No
            </AdasButton>
          </AdasButtonGroup>
        </AdasGrid>
      </AdasGrid>
    </AdasBox>
  )
}
