import { CarImageHolder } from '@components/car-image-holder'
import { QueueItem } from '@components/queue-item'
import { VinInput } from '@components/vin'
import { AdasBox, AdasButton, AdasContainer, AdasPaper, AdasTypography } from '@components/wrapper-components'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  BUTTONS_DISPLAY_LABEL,
  GENERIC_MESSAGES,
  REDIRECT_URLS,
  getInvoiceSummaryUrl,
  getQuoteSummaryUrl,
  getWorkOrderSummaryUrl
} from '../../constants'
import { WorkFlowTypesEnum } from '../../enums/enums'
import { useLocation } from 'react-router-dom'
// import { useLoadingStore } from '@caradasstore/LoadingStore'

export const VinSearchPage = () => {
  const location = useLocation()
  const { vin: VinNumber } = location?.state || {}
  const [vinSearchList, setVinSearchList] = useState([])
  const [noRecordsFound, setNoRecordsFound] = useState(false)
  const [vin, setVin] = useState(VinNumber || '')
  const history = useHistory()
  // const setLoading = useLoadingStore((store) => store.setLoading)

  const handleClick = (item) => {
    if (item.table_name === 'invoice') {
      //'Invoice';
      history.push(getInvoiceSummaryUrl(item?.reference_number))
    } else if (item.table_name === 'workorder') {
      //'WO';
      history.push(getWorkOrderSummaryUrl(item?.reference_number))
    } else {
      //'Quote';
      history.push(getQuoteSummaryUrl(item?.reference_number))
    }
  }

  const getType = (item) => {
    if (item.table_name === 'workorder') return WorkFlowTypesEnum.WORKORDER
    else if (item.table_name === 'quote') return WorkFlowTypesEnum.QUOTE
    else if (item.table_name === 'invoice') return WorkFlowTypesEnum.INVOICE
    else return ''
  }
  const redirectTo = (redirectUrl) => () => {
    history.push(redirectUrl, { prepopulateVin: vin })
  }

  const updateVINSearchList = useCallback((fetchedResults) => {
    setVinSearchList([])
    setNoRecordsFound(false)
    if (fetchedResults?.vin) {
      setVin(fetchedResults.vin)
    }
    if (fetchedResults?.vinList && fetchedResults?.vinList?.length > 0) {
      setVinSearchList(fetchedResults.vinList)
    } else {
      setNoRecordsFound(true)
    }
  }, [])

  const classes = {
    title: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '21.98px',
      color: 'rgba(26, 27, 31, 0.87)'
    },
    subtitle: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '21.98px',
      color: 'rgba(26, 27, 31, 0.87)',
      marginTop: '10px',
      marginBottom: '10px'
    }
  }
  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper elevation={0} sx={{ padding: '1.5rem', marginTop: '1.25rem', marginBottom: '20px' }}>
        <AdasBox key='vin-section'>
          <AdasTypography sx={classes.subtitle}>{GENERIC_MESSAGES.SEARCH_ALL}</AdasTypography>
          <VinInput shouldFetchOnlyVinList={true} updateVINSearchList={updateVINSearchList} prepopulateVin={vin} />
          <AdasTypography sx={classes.subtitle}>{GENERIC_MESSAGES.VIN_MUST_MATCH}</AdasTypography>

          {vinSearchList.length === 0 && !noRecordsFound && <CarImageHolder showText={false} />}
        </AdasBox>
      </AdasPaper>
      {vinSearchList.length > 0 && (
        <AdasPaper elevation={0} sx={{ padding: '1.5rem', marginTop: '1.0rem', marginBottom: '20px' }}>
          <AdasTypography sx={classes.title}>{GENERIC_MESSAGES.SEARCH_RESULTS}</AdasTypography>
          <AdasBox sx={{ marginTop: '10px' }}>
            <AdasPaper elevation={0} sx={{ marginTop: '20px' }}>
              <ul>
                {vinSearchList
                  .filter((item) => {
                    return item
                  })
                  .map((item) => {
                    return <QueueItem key={item.id} itemType={getType(item)} item={item} onClickItem={handleClick} />
                  })}
              </ul>
            </AdasPaper>
          </AdasBox>
        </AdasPaper>
      )}
      {noRecordsFound && (
        <AdasPaper elevation={0} sx={{ padding: '1.5rem', marginTop: '1.0rem', marginBottom: '20px' }}>
          <AdasTypography sx={classes.title}>{GENERIC_MESSAGES.SEARCH_RESULTS}</AdasTypography>
          <AdasBox>
            <AdasPaper
              elevation={0}
              sx={{
                padding: '20px',
                marginTop: '10px',
                background: '#ffffff',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <AdasTypography variant='h6'>{GENERIC_MESSAGES.NO_RECORDS_FOUND}</AdasTypography>
              <AdasTypography sx={{ marginTop: '10px' }}>{GENERIC_MESSAGES.TRY_OTHER_VIN}</AdasTypography>
              <AdasTypography sx={{ marginTop: '20px' }}>{vin}</AdasTypography>
              <AdasBox sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <AdasButton
                  variant='contained'
                  color='primary'
                  sx={{ width: '100%', marginBottom: '10px' }}
                  onClick={redirectTo(REDIRECT_URLS.NEW_WORK_ORDER)}
                >
                  {BUTTONS_DISPLAY_LABEL.NEW_WORK_ORDER}
                </AdasButton>

                <AdasButton
                  variant='outlined'
                  color='secondary'
                  sx={{ width: '100%', backgroundColor: 'white' }}
                  onClick={redirectTo(REDIRECT_URLS.NEW_QUOTE)}
                >
                  {BUTTONS_DISPLAY_LABEL.NEW_QUOTE}
                </AdasButton>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}
