import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { FileUpload } from '@components/file-upload'
import { useScrollTop } from '../../utils/useScrollTop'
import { AddFileButton } from '@components/buttons'
import { getDocuments, getInvoiceByReferenceNumber, getWorkOrderByReferenceNumber } from '../../api/api'
import { FILE_UPLOAD, PARAM_NAMES, REDIRECT_URLS } from '../../constants'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { Logger } from '../../logger'
import {
  AdasGrid,
  AdasBox,
  AdasTypography,
  AdasPaper,
  AdasContainer,
  AdasButton,
  AdasLink,
  AdasDivider
} from '@components/wrapper-components'
import { StatusEnum } from 'enums/enums'
import { handleDownloadOEMStatementPDF } from 'utils/utils'
import { CircularProgress } from '@mui/material'

export const DocumentPage = (props) => {
  useScrollTop()
  const { fromInvoice = false } = props
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const history = useHistory()
  const [workOrderData, setWorkOrderData] = useState({}) //Object is filled with only required fields
  const setLoading = useLoadingStore((store) => store.setLoading)
  const loading = useLoadingStore((store) => store.loading)
  const [statementLoading, setStatementLoading] = useState(false)

  const classes = {
    subHeading: {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '266%',
      fontWeight: '700',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: 'rgba(26, 27, 31, 0.87)',
      padding: '10px 0'
    }
  }

  const [documents, setDocuments] = useState({
    preCalibrationReport: '',
    oemProcedure: '',
    wheelAlignmentReport: '',
    calibrationReport: '',
    postTestScanReport: '',
    collisionRepairEstimate: '',
    calibrationRequiredReport: '',
    partsInvoice: '',
    qwiOemPositionStatements: []
  })
  const [additionalDocuments, setAdditionalDocuments] = useState([])
  const [loadedAdditionalDocs, setLoadedAdditionalDocs] = useState([])

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const removeAdditionalFileHandler = useCallback((index) => {
    setAdditionalDocuments((prev) => {
      prev[index].removed = true
      const data = [...prev]
      return data
    })
  }, [])

  const removeLoadedAdditionalFileHandler = useCallback((index) => {
    setLoadedAdditionalDocs((prev) => {
      prev[index].removed = true
      const data = [...prev]
      return data
    })
  }, [])

  const removeFileHandler = useCallback((fileName) => {
    if (fileName) {
      setDocuments((prevState) => {
        const updatedDocuments = {
          ...prevState,
          [fileName]: null
        }
        return updatedDocuments
      })
    }
  }, [])

  useEffect(() => {
    const requestDocuments = async (params) => {
      try {
        setLoading(true)
        const response = await getDocuments({ type: 'workorders', id: workOrderData.id })
        if (response.status === 200) {
          const documentSummaryData = response.data
          Object.keys(documents).forEach((key) => {
            if (documentSummaryData[key]) {
              setDocuments((prevState) => {
                const updatedDocuments = {
                  ...prevState,
                  [key]: documentSummaryData[key]
                }
                return updatedDocuments
              })
            }
          })
          if (documentSummaryData.additionalDocuments != null) {
            let loadedAdditionalDocs = []
            for (const doc of documentSummaryData.additionalDocuments) {
              loadedAdditionalDocs.push(doc)
              if (loadedAdditionalDocs.length > 0) {
                setLoadedAdditionalDocs([...loadedAdditionalDocs])
              }
            }
          }
        }
      } catch (error) {
        Logger.error({ message: error, payload: { params, path: 'DocumentPage/requestDocuments' } })
      } finally {
        setLoading(false)
      }
    }
    if (workOrderData.id) {
      const params = {
        workorderId: workOrderData.id
      }
      requestDocuments(params)
    }
  }, [workOrderData.id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (fromInvoice) {
          let response = await getInvoiceByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            if (!response.data?.invoiceDto) {
              redirectToForbiddenPage()
              return
            }
            let commonWorkOrderData = {}
            commonWorkOrderData.id = response.data.workOrderDto.id //WorkOrder Id
            commonWorkOrderData.status = response.data.invoiceDto.status
            setWorkOrderData(commonWorkOrderData)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        } else {
          let response = await getWorkOrderByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            //Url Tampared i.e invoice/quote record tried to open in workorder path
            if (StatusEnum[response.data?.workOrder?.status] === StatusEnum.MOVED) {
              redirectToForbiddenPage()
              return
            }
            let commonWorkOrderData = {}
            commonWorkOrderData.id = response.data.workOrder.id
            commonWorkOrderData.status = response.data.workOrder.status
            setWorkOrderData(commonWorkOrderData)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            referenceNumber
          }
        })
      }
    }
    if (referenceNumber) fetchData()
  }, [referenceNumber])

  const handleOemStatementClick = useCallback(async (event) => {
    const id = event.target.id
    setStatementLoading(id)
    try {
      await handleDownloadOEMStatementPDF(id)
    } catch (error) {
      Logger.error({ message: error, payload: { id, path: 'ClientInvoiceDocumentPage/handleOemStatementClick' } })
    } finally {
      setStatementLoading(false)
    }
  }, [])

  const disableEdit = StatusEnum[workOrderData.status] === StatusEnum.CLOSED || fromInvoice
  return (
    <AdasContainer maxWidth='lg'>
      {!loading && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem' }}>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '20px',
                marginBottom: '20px'
              }}
            >
              <AdasTypography variant='overline' sx={classes.subHeading}>
                REPORTS
              </AdasTypography>
              <AdasGrid container alignItems='center' spacing={2}>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('preCalibrationReport')}
                    existingData={documents.preCalibrationReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_PRE_CALIBRATION_SCAN_REPORT}
                    label={FILE_UPLOAD.LABEL.PRE_CALIBRATION_SCAN_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PRE_CALIBRATION_SCAN_REPORT}
                    disabled={disableEdit}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('oemProcedure')}
                    existingData={documents.oemProcedure}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_OEM_PROCEDURE}
                    label={FILE_UPLOAD.LABEL.OEM_PROCEDURE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.OEM_PROCEDURE}
                    disabled={disableEdit}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('wheelAlignmentReport')}
                    existingData={documents.wheelAlignmentReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_WHEEL_ALIGNMENT_REPORT}
                    label={FILE_UPLOAD.LABEL.WHEEL_ALIGNMENT_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.WHEEL_ALIGNMENT_REPORT}
                    disabled={disableEdit}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('calibrationReport')}
                    existingData={documents.calibrationReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_CALIBRATION_REPORT}
                    label={FILE_UPLOAD.LABEL.CALIBRATION_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.CALIBRATION_REPORT}
                    disabled={disableEdit}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('postTestScanReport')}
                    existingData={documents.postTestScanReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_POST_TEST_DRIVE_SCAN_REPORT}
                    label={FILE_UPLOAD.LABEL.POST_TEST_DRIVE_SCAN_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.POST_TEST_DRIVE_SCAN_REPORT}
                    disabled={disableEdit}
                  />
                </AdasGrid>
              </AdasGrid>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '20px',
                marginBottom: '20px'
              }}
            >
              <AdasTypography variant='overline' sx={classes.subHeading}>
                ADDITIONAL DOCUMENTS
              </AdasTypography>
              <AdasGrid container spacing={2}>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('collisionRepairEstimate')}
                    existingData={documents.collisionRepairEstimate}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_COLLISION_REPAIR_ESTIMATE}
                    label={FILE_UPLOAD.LABEL.COLLISION_REPAIR_ESTIMATE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PRE_CALIBRATION_REPAIR_ESTIMATE_SCAN}
                    disabled={disableEdit}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('calibrationRequiredReport')}
                    existingData={documents.calibrationRequiredReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_CALIBRATION_REQUIRED_REPORT}
                    label={FILE_UPLOAD.LABEL.CALIBRATION_REQUIRED_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.CALIBRATION_REQUIRED_REPORT}
                    disabled={disableEdit}
                  />
                </AdasGrid>
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <FileUpload
                    type='workorders'
                    removeFile={() => removeFileHandler('partsInvoice')}
                    existingData={documents.partsInvoice}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_PARTS_INVOICE}
                    label={FILE_UPLOAD.LABEL.PARTS_INVOICE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PARTS_INVOICE}
                    disabled={disableEdit}
                  />
                </AdasGrid>
                {loadedAdditionalDocs.map((doc, index) => {
                  if (doc && !doc.removed) {
                    return (
                      <AdasGrid key={`file_${index}`} item xs={12} sm={6} md={4} lg={3}>
                        <FileUpload
                          key={index}
                          type='workorders'
                          removeFile={() => removeLoadedAdditionalFileHandler(index)}
                          existingData={doc}
                          workOrderData={workOrderData}
                          title={`custom_document_${index}`}
                          typeLabel={FILE_UPLOAD.TYPE_LABEL.ADDITIONAL_DOCUMENT}
                          label={FILE_UPLOAD.LABEL.ADDITIONAL_DOCUMENT}
                          disabled={disableEdit}
                        />
                      </AdasGrid>
                    )
                  } else {
                    return null
                  }
                })}
                {additionalDocuments.map((doc, index) => {
                  if (doc && !doc.removed) {
                    return (
                      <AdasGrid key={`file_${index}`} item xs={12} sm={6} md={4} lg={3}>
                        <FileUpload
                          type='workorders'
                          key={index}
                          removeFile={() => removeAdditionalFileHandler(index)}
                          additionalDocument={doc}
                          workOrderData={workOrderData}
                          title={`custom_document_${index}`}
                          typeLabel={FILE_UPLOAD.TYPE_LABEL.ADDITIONAL_DOCUMENT}
                          label={FILE_UPLOAD.LABEL.ADDITIONAL_DOCUMENT}
                          disabled={disableEdit}
                        />
                      </AdasGrid>
                    )
                  } else {
                    return null
                  }
                })}
                <AdasGrid item xs={12} sm={6} md={4} lg={3}>
                  <AddFileButton setAdditionalDocuments={setAdditionalDocuments} disabled={disableEdit} />
                </AdasGrid>
              </AdasGrid>
            </AdasPaper>
          </AdasBox>
          {documents?.qwiOemPositionStatements.length > 0 && (
            <>
              <AdasBox sx={{ mb: '20px' }}>
                <AdasBox>
                  <AdasTypography variant='overline' sx={classes.subHeading}>
                    OEM POSITION STATEMENT LINES
                  </AdasTypography>
                </AdasBox>
                {documents.qwiOemPositionStatements.map((data, index) => (
                  <AdasBox key={index} sx={{ display: 'flex', alignItems: 'center', mb: '10px' }}>
                    {statementLoading == data.oemPositionStatements.id && <CircularProgress size={15} sx={{ mr: '10px' }} />}
                    <AdasLink
                      key={index}
                      id={data.oemPositionStatements.id}
                      sx={{
                        fontSize: '14px',
                        textDecoration: 'none',
                        color: '#0077cc',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer'
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={handleOemStatementClick}
                    >
                      {data.oemPositionStatements.name}
                    </AdasLink>
                  </AdasBox>
                ))}
              </AdasBox>
              <AdasDivider />
            </>
          )}
          <AdasBox sx={{ display: 'flex', mt: '20px' }}>
            <AdasButton variant='contained' aria-label='DONE' color='primary' onClick={() => history.goBack()}>
              DONE
            </AdasButton>
          </AdasBox>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}

DocumentPage.propTypes = {
  fromInvoice: PropTypes.bool
}
