import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'

import { CarImageHolder } from '@components/car-image-holder'
import { LoadingBackdrop } from '@components/loader'
import {
  AdasBox,
  AdasButton,
  AdasContainer,
  AdasDivider,
  AdasPaper,
  AdasStack,
  AdasTypography,
  AdasImage
} from '@components/wrapper-components'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { getClientInvoice, getClientInvoiceMedia, getClientInvoicePdf, getClientInvoiceCOCPdf } from '../../../api/api'
import CaradasLogo from '../../../assets/images/logo-caradas.png'
import { useLoadingStore } from '../../../store'
import { currencyFormatter, phoneNumberFormat } from '../../../utils/utils'
import { REDIRECT_URLS } from '../../../constants'
import { Logger } from '../../../logger'

export const ClientInvoiceSummaryPage = () => {
  const [invoiceData, setInvoiceData] = useState()
  const location = useLocation()
  const [isError, setIsError] = useState(false)
  const [pdfLoader, setPdfLoader] = useState(false)
  const [cocLoader, setCocLoader] = useState(false)

  const vehicleData = invoiceData ? invoiceData.vehicle : {}
  const searchParams = new URLSearchParams(location.search)
  const invoiceId = searchParams.get('invoiceId')
  const tempToken = searchParams.get('token')

  const textDisplay = { textAlign: 'center' }

  const history = useHistory()
  const setLoading = useLoadingStore((store) => store.setLoading)

  const redirectDocuments = () => {
    history.push(REDIRECT_URLS.CLIENT_INVOICE_SUMMARY_DOCUMENT, { ...invoiceData, tempToken })
  }

  const redirectPhotos = () => {
    history.push(REDIRECT_URLS.CLIENT_INVOICE_SUMMARY_PHOTO, { ...invoiceData, tempToken })
  }

  const packagesTotal = useMemo(() => {
    return invoiceData && invoiceData.calibrations.length > 0
      ? invoiceData.calibrations.reduce((total, current) => total + current.calibration.price, 0)
      : 0
  }, [invoiceData])

  const servicesTotal = useMemo(() => {
    return invoiceData && invoiceData.services.length > 0
      ? invoiceData.services.reduce((total, current) => total + current.service.price, 0)
      : 0
  }, [invoiceData])

  const customItemTotal = useMemo(() => {
    return invoiceData && invoiceData.customItems.length > 0
      ? invoiceData.customItems.reduce((total, current) => {
          if (current.item_type === 'discount') {
            return total - current.price
          } else {
            return total + current.price
          }
        }, 0)
      : 0
  }, [invoiceData])

  const totalCost = packagesTotal + servicesTotal + customItemTotal

  useEffect(() => {
    const params = {
      invoiceId,
      token: tempToken
    }
    const invoiceResponse = getClientInvoice(params)
    invoiceResponse
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          data.invoiceDto.safetySystemsItems = response.data.safetySystemsItems
          data.invoiceDto.calibrations = response.data.workOrderCalibrations
          data.invoiceDto.services = response.data.workOrderServices
          data.invoiceDto.customItems = response.data.customItems

          setInvoiceData(data.invoiceDto)
        }
      })
      .catch(() => {
        setIsError(true)
      })
  }, [invoiceId, tempToken, history])

  const CalibrationCenterInfo = (props) => {
    const { calibrationCenter } = props
    return (
      <AdasPaper elevation={0} sx={{ padding: '20px' }}>
        <AdasBox sx={{ display: 'inline-flex' }}>
          <AdasTypography component='span' sx={{ width: '50%' }}>
            <AdasImage sx={{ margin: '0' }} alt='' src={calibrationCenter.image ? calibrationCenter.image : CaradasLogo} />
          </AdasTypography>
          <AdasBox>
            <AdasBox sx={{ fontWeight: '600' }}>{calibrationCenter.name}</AdasBox>
            <AdasBox>{phoneNumberFormat(calibrationCenter.phone)}</AdasBox>
            <a href={`mailto:${calibrationCenter.email}`}>{calibrationCenter.email}</a>
            <AdasBox>
              {calibrationCenter.shop_address}, {calibrationCenter.shop_city}, {calibrationCenter.shop_state}{' '}
            </AdasBox>
          </AdasBox>
        </AdasBox>
      </AdasPaper>
    )
  }

  const InvoiceItem = (props) => {
    const { name, price, itemType = 'credit' } = props
    return (
      <AdasBox sx={{ margin: '20px' }}>
        <AdasTypography component='span' sx={{ display: 'inline-flex' }}>
          {name}
        </AdasTypography>
        {itemType === 'credit' ? (
          <AdasTypography component='span' sx={{ float: 'right' }}>
            ${price.toFixed(2)}
          </AdasTypography>
        ) : (
          <AdasTypography component='span' sx={{ float: 'right', color: 'red' }}>
            (${price.toFixed(2)})
          </AdasTypography>
        )}
      </AdasBox>
    )
  }

  const ErrorMessage = () => {
    return (
      <AdasBox
        sx={{
          backgroundColor: '#E1E9F0',
          flexGrow: 1,
          overflow: 'auto',
          height: '100dvh',
          textAlign: 'center',
          fontSize: '30px'
        }}
      >
        <AdasBox sx={{ marginTop: '100px', color: 'red' }}>
          Invoice link has expired. Please request an updated invoice email from your calibration center.
        </AdasBox>
      </AdasBox>
    )
  }
  const displayPDFContent = (pdfContent, fileName) => {
    const fileValue = new Blob([pdfContent], { type: 'application/pdf' }, fileName)
    fileValue.id = invoiceId
    const fileURL = URL.createObjectURL(fileValue)
    const pdfWindow = window.open()
    if (pdfWindow === null) {
      alert('Pop-up was blocked by your browser! Please allow pop-ups for caradasplus.com to view Invoice.')
    } else {
      pdfWindow.location.href = fileURL
    }
  }

  const viewPdf = async () => {
    try {
      setPdfLoader(true)
      const invoicePdfResponse = await getClientInvoicePdf({
        invoiceId: invoiceId,
        token: tempToken
      })
      if (invoicePdfResponse.status === 200) {
        displayPDFContent(invoicePdfResponse.data, `invoice_${invoiceData.reference_number}.pdf`)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { invoiceId, path: 'ClientInvoiceSummaryPage/viewPdf' } })
    } finally {
      setPdfLoader(false)
    }
  }

  const viewCOCPdf = async () => {
    try {
      setCocLoader(true)
      const invoicePdfResponse = await getClientInvoiceCOCPdf({
        invoiceId: invoiceId,
        token: tempToken
      })
      setCocLoader(false)
      if (invoicePdfResponse.status === 200) {
        displayPDFContent(invoicePdfResponse.data, `certificate_${invoiceId}.pdf`)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { invoiceId, path: 'InvoiceSummaryPage/viewCOC' } })
    } finally {
      setCocLoader(false)
    }
  }

  const downloadMediaHandler = async () => {
    try {
      setLoading(true)
      const invoiceMediaResponse = await getClientInvoiceMedia({
        invoiceId: invoiceId,
        token: tempToken
      })
      setLoading(false)
      if (invoiceMediaResponse.status === 200) {
        const fileValue = new Blob([invoiceMediaResponse.data], { type: 'application/zip' }, `invoice_${invoiceData.reference_number}.zip`)
        fileValue.id = invoiceId
        const fileURL = URL.createObjectURL(fileValue)
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', `invoice_${invoiceData.reference_number}.zip`)
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      Logger.error({ message: error, payload: { invoiceId, path: 'InvoiceSummaryPage/download' } })
    } finally {
      setLoading(false)
    }
  }

  return !isError ? (
    invoiceData && (
      <AdasContainer maxWidth='lg'>
        <LoadingBackdrop />
        <CalibrationCenterInfo calibrationCenter={invoiceData.calibrationCenter} />
        <AdasPaper elevation={0} sx={{ padding: '20px', marginTop: '20px' }}>
          <AdasBox sx={{ marginBottom: '40px' }}>
            <AdasBox sx={{ overflow: 'hidden' }}>
              <AdasBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <AdasBox sx={{ display: 'flex' }}>
                  <AdasTypography>Invoice # </AdasTypography>
                  <AdasTypography>{invoiceData.reference_number}</AdasTypography>
                </AdasBox>
                <AdasBox sx={{ display: 'flex', float: 'right' }}>
                  <AdasTypography>RO # </AdasTypography>
                  <AdasTypography>{invoiceData.repair_order_num}</AdasTypography>
                </AdasBox>
              </AdasBox>
              <AdasBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <AdasBox sx={{ display: 'flex' }}>
                  <AdasTypography>Serviced By: </AdasTypography>
                  <AdasTypography>{invoiceData.assigned_to.name}</AdasTypography>
                  <AdasDivider orientation='vertical' variant='middle' flexItem sx={{ display: 'inline', margin: '0px 10px' }} />
                  <AdasTypography>{moment(invoiceData.created).format('L')}</AdasTypography>
                </AdasBox>
                <AdasTypography component='span' sx={{ float: 'right' }}>
                  {invoiceData.client.name}
                </AdasTypography>
              </AdasBox>
            </AdasBox>
            <AdasBox
              id='carInfo'
              sx={{
                display: 'flex',
                textAlign: 'center',
                marginTop: '20px'
              }}
            >
              <AdasTypography id='image' sx={{ height: '30%', width: '35%' }}>
                {vehicleData && vehicleData.image_url ? (
                  <AdasImage alt='' src={vehicleData.image_url} sx={{ margin: '0px 0px' }} />
                ) : (
                  <CarImageHolder showText={false} />
                )}
              </AdasTypography>
              <AdasBox component='span' sx={{ width: '35%', margin: 'auto' }}>
                <AdasTypography
                  sx={{
                    ...textDisplay,
                    fontSize: '18px',
                    fontWeight: '600'
                  }}
                >
                  {vehicleData ? `${vehicleData.year} ${vehicleData.make} ${vehicleData.model} ${vehicleData.trim}` : ''}
                </AdasTypography>
                <AdasTypography sx={{ ...textDisplay, fontSize: '18px' }}>VIN: {vehicleData.vin}</AdasTypography>
                <AdasTypography sx={{ ...textDisplay, fontSize: '20px', fontWeight: '500' }}>
                  Total Cost: {currencyFormatter(totalCost)}
                </AdasTypography>
              </AdasBox>
              <AdasBox
                component='span'
                sx={{
                  width: '30%',
                  float: 'right',
                  textAlign: 'right',
                  margin: 'auto'
                }}
              >
                <AdasButton
                  onClick={viewPdf}
                  startIcon={pdfLoader ? <CircularProgress color='inherit' size={20} /> : <DownloadOutlinedIcon />}
                  variant='contained'
                  sx={{ flex: 1, mb: '10px' }}
                >
                  VIEW INVOICE
                </AdasButton>
                <AdasButton
                  onClick={viewCOCPdf}
                  startIcon={cocLoader ? <CircularProgress color='inherit' size={20} /> : <DownloadOutlinedIcon />}
                  variant='contained'
                  sx={{ flex: 1, mb: '10px', ml: '10px' }}
                >
                  VIEW CERTIFICATE
                </AdasButton>
              </AdasBox>
            </AdasBox>
          </AdasBox>
          <AdasBox>
            <AdasPaper variant='outlined'>
              <AdasBox sx={{ margin: '20px' }}>
                <AdasTypography component='span' sx={{ display: 'inline-flex' }}>
                  <b>Description</b>
                </AdasTypography>
                <AdasTypography component='span' sx={{ float: 'right' }}>
                  <b>Amount</b>
                </AdasTypography>
              </AdasBox>
              {invoiceData.calibrations.length > 0 &&
                invoiceData.calibrations.map((item, index) => {
                  return (
                    <InvoiceItem key={`${item.calibration.name}${index}`} name={item.calibration.name} price={item.calibration.price} />
                  )
                })}
              {invoiceData.services.length > 0 &&
                invoiceData.services.map((item, index) => {
                  return <InvoiceItem key={`${item.service.name}${index}`} name={item.service.name} price={item.service.price} />
                })}
              {invoiceData.customItems.length > 0 &&
                invoiceData.customItems.map((item, index) => {
                  return <InvoiceItem key={`${item.name}${index}`} name={item.name} price={item.price} itemType={item.item_type} />
                })}
            </AdasPaper>
          </AdasBox>
          <AdasBox sx={{ margin: '10px 0px' }}>
            <AdasStack
              direction='row'
              spacing={2}
              divider={<AdasDivider orientation='vertical' flexItem sx={{ backgroundColor: '#0071E3' }} />}
            >
              <AdasButton startIcon={<PhotoCameraOutlinedIcon />} sx={{ width: '50%' }} onClick={redirectPhotos}>
                Photos
              </AdasButton>
              <AdasButton startIcon={<InsertDriveFileOutlinedIcon />} sx={{ width: '50%' }} onClick={redirectDocuments}>
                Documents
              </AdasButton>
            </AdasStack>
            <AdasDivider orientation='horizontal' flexItem sx={{ marginTop: '10px' }} />
          </AdasBox>
          <AdasBox sx={{ paddingBottom: '20px' }}>
            <AdasButton fullWidth variant='outlined' startIcon={<FileDownloadOutlinedIcon />} onClick={downloadMediaHandler}>
              Download All Media
            </AdasButton>
          </AdasBox>
        </AdasPaper>
      </AdasContainer>
    )
  ) : (
    <ErrorMessage />
  )
}
