import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { AdasBox, AdasButton, AdasCard } from '@components/wrapper-components'
import { AdsCustomModal } from '@components/wrapper-components'
import { StickyFooter } from '@components/sticky-footer'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { useOrganisationStore } from '@caradasstore/OrganisationStore'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useMessageStore } from '@caradasstore/MessageStore'
import { createAppointment, createAppointmentEstimate } from 'api/api'
import { validations } from '../../../utils/utils'
import { Logger } from 'logger'
import { appointmentInitialDetails } from './helper'
import { useLocation } from 'react-router-dom'
import { AppointmentForm } from './AppointmentForm'
import { AppointmentPayload } from './AppointmentPayload'
import { useUserStore } from '@caradasstore/UserStore'
import { MAX_FILE_SIZE_MB, DATE_FORMATS } from 'constants'
import { DataOverrideCard } from './DataOverrideCard'

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png'
]
export const CreateAppointmentForm = ({ onClose, setRefresh }) => {
  const [cancelAppointmentModal, setCancelAppointmentModal] = useState(false)
  const currentUser = useUserStore((store) => store.currentUser)
  const addMessage = useMessageStore((store) => store.addMessage)
  const [availabilities, setAvailabilities] = useState({
    overrides_date: '', // You can provide a default date or empty string
    slots: [] // Default empty array for slots
  })

  const [formValues, setFormValues] = useState({
    ...appointmentInitialDetails,
    appointmentDate: dayjs().format(DATE_FORMATS.ISO_DATE_STRING),
    useVin: appointmentInitialDetails.useVin || false,
    pickupContact: {
      fullName: '',
      address: '',
      city: '',
      phone: '',
      state: '',
      zipCode: ''
    }
  })
  const [formErrors, setFormErrors] = useState({
    recentEstimate: ''
  })
  const [formValid, setFormValid] = useState({
    fullName: true,
    primaryEmail: true,
    secondaryEmail: true,
    businessPhone: true,
    phone: true,
    referral: true,
    address: true,
    city: true,
    zipCode: true,
    state: true,
    aro: true,
    pickupContact: {
      fullName: true,
      address: true,
      city: true,
      phone: true,
      zipCode: true
    }
  })

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true) // New state for submit button
  const location = useLocation()
  const prePopulateVin = location.state?.prepopulateVin
  const organisationDetail = useOrganisationStore((store) => store.organisationDetail)
  const ccId = useCenterStore((store) => store.ccId)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const history = useHistory()

  const validationMap = {
    fullName: validations.requiredAlphanumericText,
    primaryEmail: validations.email,
    secondaryEmail: validations.optionalEmail,
    businessPhone: validations.optionalPhone,
    phone: validations.optionalPhone,
    referral: validations.alphanumericText,
    address: validations.alphanumericText,
    city: validations.alphabeticText,
    zipCode: validations.optionalZip,
    aro: validations.alphanumericText,
    pickupContact: {
      fullName: validations.alphanumericText,
      address: validations.alphanumericText,
      city: validations.alphabeticText,
      phone: validations.optionalPhone,
      zipCode: validations.optionalZip
    }
  }

  useEffect(() => {
    if (currentUser) {
      setFormValues((prevState) => ({
        ...prevState,
        userId: currentUser.id,
        userName: currentUser.name
      }))
    }
  }, [currentUser])

  useEffect(() => {
    const areRequiredFieldsFilled = () => {
      const { fullName, primaryEmail, appointmentDate, appointmentTime, businessPhone, vehicleYear, vehicleMake, vehicleModel } = formValues
      return fullName && primaryEmail && appointmentDate && appointmentTime && vehicleYear && vehicleMake && vehicleModel && businessPhone
    }

    const areAllFieldsValid = () => {
      const { fullName, primaryEmail, secondaryEmail, phone, referral, address, city, zipCode, state, aro, pickupContact, businessPhone } =
        formValid

      const {
        fullName: pickupFullName,
        address: pickupAddress,
        city: pickupCity,
        phone: pickupPhone,
        zipCode: pickupZipCode
      } = pickupContact

      const { vin, useVin } = formValues // Destructure vin and useVin from formValues

      const isVinValid = !useVin || (useVin && vin)

      return (
        fullName &&
        primaryEmail &&
        secondaryEmail &&
        businessPhone &&
        phone &&
        referral &&
        address &&
        city &&
        zipCode &&
        state &&
        aro &&
        pickupFullName &&
        pickupAddress &&
        pickupCity &&
        pickupPhone &&
        pickupZipCode &&
        isVinValid
      )
    }

    const isFormValid = () => {
      return areRequiredFieldsFilled() && areAllFieldsValid()
    }
    setIsSubmitDisabled(!isFormValid())
  }, [formValues])

  const handleChange = (eventOrValue) => {
    if (eventOrValue && eventOrValue.target) {
      const { name, value, type, checked, files } = eventOrValue.target

      if (name === 'recentEstimate' && files) {
        const selectedFile = files[0]
        if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
          setFormErrors((prev) => ({
            ...prev,
            recentEstimate: 'Invalid file type. Please upload a PDF, DOCX, JPG, or PNG file.'
          }))
          addMessage({
            text: 'Invalid file type. Please upload a PDF, DOCX, JPG, or PNG file.'
          })

          return
        }
        if (selectedFile.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
          setFormErrors((prev) => ({
            ...prev,
            recentEstimate: `File size exceeds the limit of ${MAX_FILE_SIZE_MB}MB.`
          }))
          addMessage({
            text: `File size exceeds the limit of ${MAX_FILE_SIZE_MB}MB.`
          })
          return
        }
        if (selectedFile.name?.length > 80) {
          setFormErrors((prev) => ({
            ...prev,
            recentEstimate: `The file name cannot exceed more than 80 characters.`
          }))
          addMessage({
            text: `The file name cannot exceed more than 80 characters.`
          })
          return
        }

        setFormValues((prev) => ({
          ...prev,
          [name]: selectedFile
        }))

        setFormErrors((prev) => ({
          ...prev,
          recentEstimate: ''
        }))

        if (validationMap[name]) {
          setFormValid((prev) => ({
            ...prev,
            [name]: validationMap[name](value.trim())
          }))
        }
      } else {
        if (validationMap[name]) {
          setFormValid((prev) => ({
            ...prev,
            [name]: validationMap[name](value.trim())
          }))
        }
        setFormValues((prev) => ({
          ...prev,
          [name]: type === 'checkbox' ? checked : value
        }))
      }
    } else if (eventOrValue) {
      setFormValues((prev) => ({
        ...prev,
        appointmentDate: eventOrValue
      }))
    }
  }

  const handleFileDelete = () => {
    setFormValues((prev) => ({
      ...prev,
      recentEstimate: null
    }))

    setFormErrors((prev) => ({
      ...prev,
      recentEstimate: ''
    }))
  }

  // const updateVehicleInfo = useCallback((props) => {
  //   if (!props) {
  //     console.error('Vehicle info update failed: No vehicle data provided.')
  //     return
  //   }
  //   const { year, make, model, vin } = props.data
  //   setFormValues((prevState) => ({
  //     ...prevState,
  //     vehicleYear: year || prevState.vehicleYear,
  //     vehicleMake: make || prevState.vehicleMake,
  //     vehicleModel: model || prevState.vehicleModel,
  //     vin: vin || prevState.vin
  //   }))
  // }, [])

  const extractMessage = (text) => {
    if (typeof text !== 'string' || !text.trim()) {
      return null
    }

    const messageMatch = text.match(/"message":"(.*?)"/)

    if (messageMatch && messageMatch[1]) {
      return messageMatch[1].replace('Bad arguments sent: ', '')
    }

    return null
  }

  const handleCustomError = (error) => {
    let errorMessage = ''
    if (error?.response?.status === 400) {
      errorMessage = extractMessage(error.response?.data?.message || '')
    }
    addMessage({
      text: errorMessage || error.response?.data?.message
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    const appointmentPayload = AppointmentPayload({
      formValues,
      organisationDetail
    })
    try {
      const response = await createAppointment(ccId, appointmentPayload)
      if (response && response.status === 200) {
        submitAppointmentEstimate(response.data.id)
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { appointmentPayload, path: 'handleSubmit' }
      })
      handleCustomError(error)
    } finally {
      setRefresh((prev) => !prev)
      setLoading(false)
      onClose()
    }
  }

  const submitAppointmentEstimate = async (appointmentId) => {
    if (formValues.recentEstimate instanceof File) {
      const formData = new FormData()
      formData.append('file', formValues.recentEstimate)

      const uploadResponse = await createAppointmentEstimate(ccId, appointmentId, formData)
    }
  }

  const handleCancel = () => {
    setCancelAppointmentModal(true)
  }

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    setCancelAppointmentModal(false)
    onClose()
  }

  return (
    <AdasCard sx={{ margin: '17px 24px', padding: '16px', backgroundColor: '#ffffff' }}>
      <AdasBox
        sx={{
          minHeight: availabilities?.overrides_date ? '58vh' : '75vh'
        }}
      >
        <AppointmentForm
          formValues={formValues}
          handleChange={handleChange}
          // updateVehicleInfo={updateVehicleInfo}
          prePopulateVin={prePopulateVin}
          type={false}
          setFormValues={setFormValues}
          formErrors={formErrors}
          handleFileDelete={handleFileDelete}
          formValid={formValid}
          setFormValid={setFormValid}
          validationMap={validationMap}
          setAvailabilities={setAvailabilities}
        />
      </AdasBox>
      <StickyFooter sx={{ backgroundColor: 'transparent' }}>
        {availabilities?.overrides_date && <DataOverrideCard availabilities={availabilities} />}
        <AdasBox display='flex' justifyContent='end' mb={2} gap={2}>
          <AdasButton variant='outlined' color='primary' onClick={handleCancel}>
            Cancel
          </AdasButton>
          <AdasButton
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={isSubmitDisabled} // Disable button based on form validation
          >
            Create
          </AdasButton>
        </AdasBox>
      </StickyFooter>

      <AdsCustomModal
        open={cancelAppointmentModal}
        onClose={handleCloseModal}
        title='Cancel Appointment'
        description='Are you sure you want to cancel this appointment?'
        successButtonTitle={'Confirm'}
        cancelButtonTitle={'No'}
        cancelButtonConfig={{
          variant: 'outlined'
        }}
      />
    </AdasCard>
  )
}

CreateAppointmentForm.propTypes = {
  onClose: PropTypes.func.isRequired
}
